import { useEffect, useRef, useState } from "react";
import { BodyText, BodyTextBold, SmallHeightDivider } from "theme/Styles";
import { useParams } from "react-router-dom";
import { Container } from "views/ServiceRequestedDetails/styles/ServiceRequestedDetailsStyles";
import { Divider, Grid, styled } from "@mui/material";
import DocumentsOfRequestsCard from "components/DocumentsOfRequestsCard/DocumentsOfRequestsCard";
import { useQueryClient } from "react-query";
import { format } from "date-fns";
import { replaceGuionToSlashFromString } from "utilities/functions/StringUtil";
import { Button as ButtonUnstyled } from "@mui/base";

const getLabelValue = (item) => {
  if (!item) return null;

  switch (item.type) {
    case "text":
      return item.value;
    case "textarea":
      return item.value;
    case "checkbox":
      return "Si";
    case "date":
      return new Date(replaceGuionToSlashFromString(item.value)).toLocaleDateString("es-MX");
    default:
      return item.labelValue;
  }
};

function Details() {
  let { requestID } = useParams();
  const queryClient = useQueryClient();
  const [showMore, setShowMore] = useState(false);

  const documentsRef = useRef(null);
  const requestData = queryClient.getQueryData(["serviceRequestedDetail", requestID]);

  const documentsData = requestData.request.request_document
    ?.sort((a, b) => {
      return new Date(b?.created_at) - new Date(a?.created_at);
    })
    .map((documentPersonal) => {
      if (documentPersonal.origin_doc === 0) {
        return documentPersonal;
      }
    })
    .map((document) => {
      if (typeof document === "object") {
        return {
          name: `${document.name}.${document.extension}`,
          //pending to change
          documentType: document.extension,
          date: format(
            new Date(replaceGuionToSlashFromString(document.created_at)),
            "yyyy-MM-dd HH:mm:ss"
          ),
          url: document.url,
          type: document.extension,
        };
      }
    })
    .filter((filteredDocument) => {
      return typeof filteredDocument === "object";
    });

  const documentsInstitutionData = requestData.request.request_document
    ?.sort((a, b) => {
      return new Date(b?.created_at) - new Date(a?.created_at);
    })
    .map((documentInstitutional) => {
      if (documentInstitutional.origin_doc === 1) {
        return documentInstitutional;
      }
    })
    .map((document) => {
      if (typeof document === "object")
        return {
          name: `${document.name}.${document.extension}`,
          //pending to change
          documentType: document.extension,
          date: format(
            new Date(replaceGuionToSlashFromString(document.created_at)),
            "yyyy-MM-dd HH:mm:ss"
          ),
          url: document.url,
          type: document.extension,
        };
    })
    .filter((filteredDocument) => {
      return typeof filteredDocument === "object";
    });

  useEffect(() => {
    if (window.location.hash === "#documents") {
      //When documents action required its send scroll to documents and remove hash
      documentsRef?.current?.scrollIntoView();
    }
  }, [window.location.hash]);

  const serviceData = () => {
    if (showMore) {
      return requestData.data;
    }
    return requestData.data.slice(0, 9);
  };

  return (
    <Container>
      <Grid
        alignItems="center"
        justifyContent="flex-start"
        container
        direction="row"
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 6, sm: 8, md: 12 }}
      >
        {requestData.request?.provisional === 0 && (
          <Grid item xs={6} sm={4} md={4}>
            <BodyTextBold>Número de solicitud:</BodyTextBold>
            <BodyText>{requestData.request.code}</BodyText>
          </Grid>
        )}

        <Grid item xs={6} sm={4} md={4}>
          <BodyTextBold>Estatus:</BodyTextBold>
          <BodyText>{requestData.request.status.name}</BodyText>
        </Grid>

        <Grid item xs={6} sm={4} md={4}>
          <BodyTextBold>Fecha:</BodyTextBold>
          <BodyText>
            {new Date(
              replaceGuionToSlashFromString(requestData.request.created_at)
            ).toLocaleDateString("es-MX")}
          </BodyText>
        </Grid>

        {serviceData()?.map((item, index) => (
          <Grid item key={index} xs={6} sm={4} md={4}>
            <BodyTextBold>{item?.label}</BodyTextBold>
            <BodyText
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "calc(80%)",
              }}
            >
              {getLabelValue(item)}
            </BodyText>
          </Grid>
        ))}

        {requestData?.data.length > 9 && (
          <Divider sx={{ width: "100%" }}>
            <ShowMoreButton onClick={() => setShowMore(!showMore)}>
              MOSTRAR {showMore ? "MENOS" : "MÁS"}
            </ShowMoreButton>
          </Divider>
        )}
      </Grid>

      <SmallHeightDivider />
      <SmallHeightDivider />

      {documentsInstitutionData?.length > 0 && (
        <div>
          <DocumentsOfRequestsCard
            data={documentsInstitutionData || []}
            title={"Documentos emitidos por la institución"}
            receipt={requestData.request.receipt_data}
            invoice={requestData.request.invoice_data}
          />
        </div>
      )}

      <SmallHeightDivider />
      <SmallHeightDivider />

      {documentsData?.length > 0 && (
        <div ref={documentsRef}>
          <DocumentsOfRequestsCard data={documentsData} title={"Documentos subidos"} />
        </div>
      )}
    </Container>
  );
}

const ShowMoreButton = styled(ButtonUnstyled)({
  all: "unset",
  padding: "10px",
  fontWeight: 600,

  color: "#134779",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  textTransform: "uppercase",

  "&:hover": {
    color: "#1393D0",
  },
});

export default Details;
