import backOfficeService from "services/ApiServiceCall";

export const getServiceDescription = async (serviceID) => {
  const { data } = await backOfficeService().get(`/services/${serviceID}`);
  return data;
};

export const getServiceCommentsAndRating = async (serviceID, page = 1) => {
  const { data } = await backOfficeService().get(
    `/services/extras/${serviceID}/rating?page=${page}`
  );
  return data;
};

export const getServiceDescriptionExtras = async (serviceSlug) => {
  const { data } = await backOfficeService().get(`/services/extras/slug?slug=${serviceSlug}`);
  return data;
};
