import { Divider, styled } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

export const StyledTabs = styled((props) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: 5,
  },

  "& .MuiTabs-indicatorSpan": {
    maxWidth: "400px",
    width: "100%",
    backgroundColor: "#1393D0",
  },

  "& .MuiTabScrollButton-root": {
    color: "white",
  },
});

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  color: "#134779",
  fontSize: "1rem",
  fontWeight: "bold",

  "&.Mui-selected": {
    color: "#134779",
  },

  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

export const TabsDivider = styled(Divider)(({ theme }) => ({
  borderColor: "#1393D0",
  height: 26,
  alignSelf: "center",
  opacity: 0.8,
}));

export function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
