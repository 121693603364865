import * as yup from "yup";

export const FormSchema = yup.object({
  questions: yup.array(
    yup.object({
      question: yup.string().required("Este campo es requerido"),
      answer: yup.string().required("Este campo es requerido"),
    })
  ),
  save_device: yup.boolean(),
});
