import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
export const localStepData = [
  {
    id: 1,
    key: "smile",
    label: "Sonríe",
    icon: <SentimentSatisfiedAltIcon />,
  },
  {
    id: 2,
    key: "eyesopen",
    label: "Ojos abiertos",
    icon: <RemoveRedEyeIcon />,
  },
  {
    id: 3,
    key: "eyesclose",
    label: "Ojos cerrados",
    icon: <VisibilityOffIcon />,
  },
  {
    id: 4,
    key: "mouthopen",
    label: "Boca abierta",
    icon: <SentimentVerySatisfiedIcon />,
  },
  {
    id: 5,
    key: "mouthclose",
    label: "Boca cerrada",
    icon: <SentimentSatisfiedIcon />,
  },
];
