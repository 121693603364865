import TextInformation from "components/TextInformation/TextInformation";
import { Container, MediumHeightDivider, StyledButtonOutlined } from "theme/Styles";
import { ButtonContainer } from "views/ServiceRequestedDetails/styles/ServiceRequestedDetailsStyles";
import { Link } from "react-router-dom";

export function Complains({ requestCode }) {
  const COMPLAIN_SERVICE = process.env.REACT_APP_CLAIMS_SERVICE_ID;
  return (
    <Container>
      <TextInformation title="Reclamaciones" />
      <MediumHeightDivider />
      <ButtonContainer>
        <StyledButtonOutlined
          component={Link}
          to={`/app/requestService/${COMPLAIN_SERVICE}?requestCode=${requestCode}`}
          variant="outlined"
        >
          NUEVA RECLAMACIÓN
        </StyledButtonOutlined>
      </ButtonContainer>
      <MediumHeightDivider />
    </Container>
  );
}
