import { SERVICE_ID } from "redux/actions/ServiceActions";

const initialState = {
  authenticated: false,
  profileImg: null,
};

const ServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_ID:
      return {
        ...state,
        service_id: action.payload,
        //profileImg: action.payload.profileImg,
      };
    // case LOGOUT:
    //   return {
    //     ...state,
    //     authenticated: false,
    //     profileImg: null,
    //   };
    default:
      return state;
  }
};

export default ServiceReducer;
