export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const hourToMilliseconds = (hour) => {
  return hour * 60 * 60 * 1000;
};

export const minutesToMilliseconds = (minutes) => {
  return minutes * 60000;
};

//function that return the number of years and months based on the amount of months
//example: 15 months = 1 year and 3 months
//example: 3 months = 3 months
//example: 1 month = 1 month

const getYearsAndMonths = (months) => {
  // Calculate the number of years and months
  let years = Math.floor(months / 12);
  let remainingMonths = months % 12;

  // Return an object
  return { years, remainingMonths };
};

function getYearsText(years) {
  return years > 0 ? `${years} año${years > 1 ? "s" : ""}` : "";
}

function getMonthsText(remainingMonths) {
  return remainingMonths > 0 ? `${remainingMonths} mes${remainingMonths > 1 ? "es" : ""}` : "";
}

export function dateRangeFromMonthAmount(months) {
  const { years, remainingMonths } = getYearsAndMonths(months);
  const yearsText = getYearsText(years);
  const monthsText = getMonthsText(remainingMonths);

  const divider = yearsText && monthsText ? " y " : "";

  return `${yearsText + divider + monthsText}`;
}
