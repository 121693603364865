import { useRef, useState } from "react";
import Header from "./components/Header";
import {
  Autocomplete,
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { getAllServices } from "api/ListOfServicesPerCategory";
import Footer from "components/Footer/Footer";
import CenterLoading from "components/CenterLoading/CenterLoading";
import { localDirections } from "views/AllServices/AllServicesConstants";
import LoginOrRegisterModal from "components/LoginOrRegisterModal/LoginOrRegisterModal";
import { useSelector } from "react-redux";
import ServiceCard from "components/ServiceCard/ServiceCard";
import CustomPagination from "components/CustomPagination";
import { CustomDivider } from "components/NewDesign/CustomDivider";
import { getHomeDataFromWordpress } from "api/Home";
import COLORS from "theme/Colors";
import { HomeStyledTab, HomeStyledTabs } from "./components/HomeTabs";
import { paginate } from "./HomeUtils";

// Icons
import TabsIcon from "assets/icons/svgs/tabs-icon.svg";
import SearchIcon from "assets/icons/pngs/search.png";

const textFieldStyle = {
  borderRadius: 0,
  "& .MuiOutlinedInput-root": {
    lineHeight: 1.5,
    fontSize: "0.875rem",
    height: "3rem",

    "& fieldset": {
      background: "background.default",
      border: "1px solid divider",
      borderRadius: 0,
    },

    "&:hover fieldset": {
      background: "action.hover",
      border: "1px solid",
      borderColor: "primary.main",
    },
    "&.Mui-focused fieldset": {
      background: "action.hover",
      border: "1px solid",
      borderColor: "primary.main",
    },
  },
};

const ITEMS_PER_PAGE = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);

function Home() {
  const history = useHistory();
  const matches = useMediaQuery("(min-width:600px)");
  const { authenticated } = useSelector((state) => state.authReducer);

  const [selectedTab, setSelectedTab] = useState(false);
  const [paginationPage, setPaginationPage] = useState(0);
  const serviceSection = useRef(null);

  // NEW HOOKS

  const [loginOrRegisterModalStatus, setLoginOrRegisterModalStatus] = useState(false);

  const handleServiceRequest = (service) => {
    if (authenticated) {
      history.push(`/app/requestService/${service.id}`);
    } else {
      setLoginOrRegisterModalStatus(!loginOrRegisterModalStatus);
    }
  };

  const { data: homeContent, isLoading: homeContentIsLoading } = useQuery(["homeData"], () =>
    getHomeDataFromWordpress()
  );
  const { data: listOfServices, isLoading: listOfServicesIsLoading } = useQuery(
    ["listOfServices"],
    () => getAllServices()
  );

  if (homeContentIsLoading || listOfServicesIsLoading) return <CenterLoading />;

  const ServicesForSearcher = [
    ...listOfServices[0].services,
    ...listOfServices[1].services,
    ...listOfServices[2].services,
  ].map((item) => {
    return {
      id: item.id,
      title: item.name,
    };
  });

  const handlePagination = (page) => {
    setPaginationPage(page - 1);
    serviceSection.current.scrollIntoView({ behavior: "smooth" });
  };

  const goToSelectedService = (service) => {
    history.push(`/app/serviceDescription/${service.id}`);
  };

  const mergeListOfServices = (service_id) => {
    if (service_id === false) return listOfServices.flatMap((item) => item.services);
    return listOfServices.find((service) => service.id === parseInt(service_id)).services;
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setPaginationPage(0);
  };

  return (
    <>
      <LoginOrRegisterModal
        open={loginOrRegisterModalStatus}
        onBackDropClick={() => setLoginOrRegisterModalStatus(false)}
        onCloseClick={() => setLoginOrRegisterModalStatus(false)}
      />

      {/*Index Background*/}
      <Header background={homeContent.image_url} />

      {/*Main Content*/}
      <Box
        sx={{
          marginTop: "20px",
          marginBottom: "40px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          paddingX: "20px",
        }}
      >
        <Typography
          color={COLORS.secondary}
          fontWeight={600}
          variant={"body1"}
          fontSize={{ xs: "1rem", md: "1.3rem" }}
          align={"center"}
          sx={{
            display: "flex",
            width: { xs: "90%", md: "70%", xl: "55%" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          En nuestro portal de servicios integrados de la Dirección General de Aduanas,
          <br /> encontrarás todos los servicios que tenemos a disposición de los contribuyentes.
        </Typography>

        <Box
          sx={{
            marginTop: "30px",
            display: "flex",
            width: { xs: "90%", md: "70%", xl: "55%" },
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              backgroundColor: COLORS.secondary,
              width: "100%",
              display: "flex",
              alignItems: "center",
              paddingTop: "1%",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Box
              component={"img"}
              src={TabsIcon}
              sx={{
                maxHeight: { xs: "25px", sm: "50px", md: "40px" },
                maxWidth: { xs: "25px", sm: "50px", md: "40px" },
                marginRight: "4%",
                paddingBottom: "1%",
                paddingLeft: "4%",
              }}
              onClick={() => setSelectedTab(false)}
            />

            <HomeStyledTabs
              value={selectedTab}
              onChange={handleTabChange}
              sx={{ width: "100%", paddingRight: "4%" }}
              variant={matches ? "fullWidth" : "scrollable"}
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              {listOfServices.map((service) => (
                <HomeStyledTab key={service.id} value={service.id} label={service.name} />
              ))}
            </HomeStyledTabs>
          </Box>

          <Box
            width={"100%"}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              marginTop: "3px",
            }}
          >
            {/* Search Input */}
            <Autocomplete
              options={ServicesForSearcher}
              getOptionLabel={(option) => option.title}
              autoHighlight
              freeSolo
              style={{ width: "100%" }}
              onChange={(e, newValue) => {
                goToSelectedService(newValue);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.defaultMuiPrevented = true;
                }
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth={true}
                  sx={textFieldStyle}
                  placeholder={"BUSCAR SERVICIOS"}
                  {...params}
                />
              )}
            />

            {/* Search Bar Icon */}
            <Box
              sx={{
                display: "flex",
                backgroundColor: COLORS.secondary,
                height: "3rem",
                width: "3rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component={"img"}
                src={SearchIcon}
                sx={{
                  maxHeight: "20px",
                  maxWidth: "20px",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* SERVICE LIST */}
      <Stack
        ref={serviceSection}
        direction={"column"}
        spacing={4}
        sx={{
          width: "100%",
          backgroundColor: COLORS.secondary,
          alignItems: "center",
          paddingY: "30px",
        }}
      >
        <Typography
          variant={"h4"}
          color={"white"}
          letterSpacing={4}
          fontSize={{ xs: "1.3rem", md: "1.7rem" }}
        >
          LISTADO DE SERVICIOS
        </Typography>

        <CustomDivider variant={"dark"} />

        <Box sx={{ width: { xs: "90%", md: "80%" } }}>
          <Grid container={true} spacing={2} marginY={"30px"}>
            {paginate(mergeListOfServices(selectedTab), ITEMS_PER_PAGE)[paginationPage].map(
              (item) => (
                <Grid item key={item.id} xs={6} md={4} xl={3}>
                  <ServiceCard
                    itemId={item.id}
                    title={item.name}
                    type={item.type_id}
                    subTitle={item.description}
                    createdAt={item.created_at}
                    relationTo={
                      localDirections.find((direction) => direction.id === item.direction_id).title
                    }
                    onRequestPress={() => handleServiceRequest(item)}
                    disableRequestButton={item.type_id === 1}
                    OnViewInformationPress={() =>
                      history.push(`/app/serviceDescription/${item.id}`)
                    }
                    onClick={() => history.push(`/app/serviceDescription/${item.id}`)}
                  />
                </Grid>
              )
            )}
          </Grid>
        </Box>
        <CustomPagination
          count={paginate(mergeListOfServices(selectedTab), ITEMS_PER_PAGE).length}
          onClick={handlePagination}
          value={paginationPage}
          variant={"dark"}
        />
      </Stack>
      <Footer />
    </>
  );
}

export default Home;
