import backOfficeService from "services/ApiServiceCall";

export const getSurveyForm = async (surveyID, citizenID, serviceID) => {
  const { data } = await backOfficeService().get(
    `/surveyforms/${surveyID}/${citizenID}/${serviceID}`
  );
  return data;
};

export const registerSurveyForm = async (requestData) => {
  const { data } = await backOfficeService().post("/surveyforms/saveAnswer", requestData);
  return data;
};
