import { memo, useEffect, useRef, useState } from "react";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import { Container, StyledPaper } from "./styles/LoggedInMenuStyles";
import { AuthLogout } from "redux/actions/AuthActions";
import { ShowGlobalLoading } from "redux/actions/UiActions";
import LocalStorageService from "services/LocalStorageService";
import { removeLocalStorageSessionData } from "auth/AuthFunctions";
import { Box, Paper, Typography } from "@mui/material";
import User from "assets/icons/pngs/User.png";
import { Link } from "react-router-dom";

function format(value = "", pattern = "") {
  if (!value) return pattern;
  let i = 0;
  const v = value.toString();
  return pattern.replace(/#/g, (_) => v[i++]);
}

function LoggedInMenu({ loggedIn }) {
  const dispatch = useDispatch();
  const { authenticated } = useSelector((state) => state.authReducer);

  const containerRef = useRef(null);
  const [hover, setHover] = useState(false);
  const [userInfo, setUserInfo] = useState({
    image: "",
    citizenId: "",
  });

  const handleHovering = (hoverState) => {
    setHover(hoverState);
  };

  const HandleLogOut = () => {
    dispatch(ShowGlobalLoading("Cerrando sesión"));
    //TO MAKE A LOGOUT USER EXPERIENCE
    setTimeout(() => {
      removeLocalStorageSessionData();
      dispatch(AuthLogout());
      window.location.reload();
    }, 500);
  };

  useEffect(() => {
    setUserInfo({
      image: LocalStorageService.getItem("profile_img"),
      citizenId: LocalStorageService.getItem("user_cedula"),
    });
  }, [authenticated]);

  return (
    <Container
      ref={containerRef}
      onMouseOver={() => handleHovering(true)}
      onMouseOut={() => handleHovering(false)}
    >
      <MyUser userImage={userInfo.image} citizenId={userInfo.citizenId} />
      <Popper
        open={hover}
        anchorEl={containerRef.current}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <StyledPaper>
              {loggedIn ? (
                <MenuList>
                  <MenuItem component={Link} to={"/app/myDesk"}>
                    Mi Escritorio
                  </MenuItem>
                  <Divider sx={{ width: "100%" }} />
                  <MenuItem onClick={HandleLogOut}>Cerrar Sesión</MenuItem>
                </MenuList>
              ) : (
                <MenuList>
                  <MenuItem component={Link} to={"/public/login"}>
                    Iniciar Sesión
                  </MenuItem>
                  <Divider sx={{ width: "100%" }} />
                  <MenuItem component={Link} to={"/app/register"}>
                    Registrar
                  </MenuItem>
                </MenuList>
              )}
            </StyledPaper>
          </Grow>
        )}
      </Popper>
    </Container>
  );
}

function MyUser({ userImage, citizenId }) {
  return (
    <Box>
      <Paper
        elevation={1}
        square={true}
        sx={{
          all: "unset",
          display: "flex",
          width: "100px",
          height: "110px",
          backgroundColor: "#13477A",
          borderTop: "10px solid #D0EAF6",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: "red",

          "&:hover": {
            cursor: "pointer",
          },
        }}
        component={"div"}
      >
        <Box
          sx={{
            backgroundColor: "transparent",
            width: "70px",
            height: "70px",
            borderRadius: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component={"img"}
            src={userImage ? userImage : User}
            sx={{
              maxHeight: "100%",
              maxWidth: "100%",
              borderRadius: !userImage ? "100%" : "100%",
              objectFit: "cover",
            }}
          />
        </Box>
        <Typography
          component={"p"}
          fontSize={"0.8rem"}
          marginTop={"12px"}
          color={"white"}
          textTransform={"uppercase"}
        >
          {citizenId ? format(citizenId, "###-#######-#") : "Mi Usuario"}
        </Typography>
      </Paper>
    </Box>
  );
}

export default memo(LoggedInMenu);
