import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import Comment from "./Comment/Comment";
import { PaginationContainer } from "views/ServiceDescription/styles/ServiceDescriptionStyles";
import { SmallHeightDivider, StyledPagination } from "theme/Styles";
import COLORS from "theme/Colors";
import { useQuery } from "react-query";
import { getServiceCommentsAndRating } from "api/ServiceDescription";
import CenterLoading from "components/CenterLoading/CenterLoading";

export default function CommentsTab({ serviceID }) {
  const [currentCommentsPage, setCurrentCommentsPage] = useState(1);

  const { data: serviceComments, isLoading: serviceCommentsIsLoading } = useQuery(
    ["serviceComments", serviceID, currentCommentsPage],
    () => getServiceCommentsAndRating(serviceID, currentCommentsPage)
  );

  if (serviceCommentsIsLoading) return <CenterLoading />;

  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{
        marginTop: "20px",
        "@media print": {
          display: "none",
        },
      }}
    >
      {/* WHEN THERE ARE COMMENTS AVAILABLE */}
      {serviceComments.data?.length > 0 ? (
        <>
          {serviceComments.data?.map((comment) => (
            <Comment
              key={comment.id}
              userName={`${comment.citizen.name} ${comment.citizen.surname}`}
              comment={comment.comment}
              date={comment.created_at}
              rating={comment.rating}
            />
          ))}

          <PaginationContainer>
            <StyledPagination
              count={serviceComments?.last_page}
              page={currentCommentsPage}
              onChange={(event, page) => {
                setCurrentCommentsPage(page);
              }}
              variant="outlined"
              shape="rounded"
              sx={{ color: COLORS.primary }}
            />
            <SmallHeightDivider />
          </PaginationContainer>
        </>
      ) : (
        <Stack direction={"column"} spacing={4} alignItems={"center"} padding={{ xs: 10, md: 20 }}>
          <Typography
            fontWeight={600}
            fontSize={{ xs: "2rem", md: "3rem" }}
            textAlign={"center"}
            color={"#134779"}
          >
            NO HAY COMENTARIOS DISPONIBLES
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
