import backOfficeService from "services/ApiServiceCall";

export const paymentValidation = async (requestData) => {
  const { data } = await backOfficeService().post("requests/extras/updatePaymentInfo", requestData);
  return data;
};

export const refreshPin = async (pin) => {
  const { data } = await backOfficeService().get(`invoice/pin/refresh/${pin}`);
  return data;
};
