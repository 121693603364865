import backOfficeService from "services/ApiServiceCall";

/**
 *
 * @param {Object} param
 * @returns
 */
export const getInstitutionalDocuments = (citizenID, page = 1, filter = "") => {
  //0 personal documents - 1 institutional documents
  return backOfficeService()
    .get(`/citizens/documentsPage/${citizenID}/1?filter=${filter}&page=${page}`)
    .then(({ data }) => data);
};
