import { Box, Stack } from "@mui/material";
import FacebookIcon from "assets/icons/svgs/facebook.svg";
import TwitterIcon from "assets/icons/svgs/twitter.svg";
import EmailIcon from "assets/icons/svgs/email.svg";

export function ShareButton(props) {
  return (
    <Box component={"button"} sx={{ all: "unset", cursor: "pointer" }} onClick={props.onClick}>
      <Box
        component={"img"}
        src={props.icon}
        sx={{
          height: "16px",
        }}
      />
    </Box>
  );
}

export function ShareButtonGroup({ href }) {
  return (
    <Stack
      spacing={1}
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      alignContent={"center"}
      sx={{
        "&:hover": {
          color: "#1393D0",
        },
      }}
    >
      <ShareButton
        icon={FacebookIcon}
        onClick={() =>
          window.open(
            `http://www.facebook.com/sharer.php?u=${href}`,
            "name",
            "width=600,height=400"
          )
        }
      />

      <ShareButton
        icon={TwitterIcon}
        onClick={() =>
          window.open(
            `https://twitter.com/share?text=Ahora es mas fácil solicitar trámites con el Portal de Servicios DGA😁&url=${href}&hashtags=DireccionGeneralAduanas,Aduanas,DGA`,
            "name",
            "width=600,height=400"
          )
        }
      />

      <ShareButton
        icon={EmailIcon}
        onClick={() =>
          window.open(
            `mailto:?subject=Ahora es mas fácil solicitar trámites con el Portal de Servicios DGA😁&body=${href}`
          )
        }
      />
    </Stack>
  );
}
