import useMediaQuery from "@mui/material/useMediaQuery";
import { Row } from "theme/Styles";
import { useQueryClient } from "react-query";
import { Box } from "@mui/material";
import MyDeskHeader from "components/MyDeskHeader";
import ServiceDirectoryMenu from "components/ServiceDirectoryMenu/ServiceDirectoryMenu";
import MyRequestsComponent from "./components/Request";
import MetricsCard from "./components/MetricsCard";
import { useLayoutEffect } from "react";
import { UpdateAppSubHeaderTitle } from "redux/actions/UiActions";
import { useDispatch } from "react-redux";

function MyRequests() {
  const matchesWidth = useMediaQuery("(min-width:768px)");
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData(["userData"]);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(UpdateAppSubHeaderTitle("Escritorio Ciudadano")); //SET SUBHEADER TITLE
  }, []);

  return (
    <MyDeskHeader
      header={"MIS SOLICITUDES"}
      canGoBack={true}
      userData={userData}
      citizenId={userData?.payload?.citizen_id}
    >
      <Row>
        {matchesWidth && <ServiceDirectoryMenu />}
        <Box
          sx={{
            width: "100%",
            padding: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MetricsCard />
          <MyRequestsComponent />
        </Box>
      </Row>
    </MyDeskHeader>
  );
}

export default MyRequests;
