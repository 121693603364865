import { Grid } from "@mui/material";
import {
  ImageContainer,
  ImageContainerHeader,
  ImageContainerTitle,
} from "views/ServiceRequestedDetails/styles/ServiceRequestedDetailsStyles";
import { LogoImage } from "views/Auth/Login/styles/LoginStyles";
import siritLogo from "assets/images/siritLogo.png";

const handleSiritePayment = (requestData, userData, paymentAmount) => {
  const siritePaymentConfig = {
    codigoCentroRecaudacion: requestData.request.service.institution.recaudationCode,
    codigoServicio: requestData.request.service.sirit_code,
    montoServicio: paymentAmount,
    nombre: `${userData.payload.name} ${userData.payload.first_last_name} ${userData.payload.second_last_name}`,
    numeroDocumento: userData.payload.citizen_id,
    tipoDocumento: "C",
    medioPago: "PagoEnLinea",
    idAutorizacionPortal: requestData.request.idAutorizacionPortal,
    urlRetorno: process.env.REACT_APP_SIRITE_RETURN_URL + requestData.request.id,
  };

  let form = document.createElement("form");
  form.style.display = "none";
  form.action = process.env.REACT_APP_SIRITE_ENVIROMENT;
  form.method = "POST";
  let siriteConfigKeys = Object.keys(siritePaymentConfig);
  siriteConfigKeys.forEach((key, index) => {
    const input = document.createElement("input");
    input.name = key;
    input.value = siritePaymentConfig[key];
    form.appendChild(input);
  });
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

export default function SiritPayment({ requestData, userData, paymentAmount }) {
  return (
    <Grid item xs={6} sm={4} md={4}>
      <ImageContainer
        title="Sirite"
        onClick={() => handleSiritePayment(requestData, userData, paymentAmount)}
      >
        <ImageContainerHeader>
          <ImageContainerTitle>PAGO CON TARJETA</ImageContainerTitle>
        </ImageContainerHeader>
        <LogoImage src={siritLogo} />
      </ImageContainer>
    </Grid>
  );
}
