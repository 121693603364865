import { styled } from "@mui/system";
import COLORS from "theme/Colors";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

export const MetricsTextContainer = styled("div")({
  position: "relative",
  textAlign: "center",
  alignItems: "center",
  width: "24%",
  padding: "2%",
});

export const MetricsTitle = styled("h1")({
  margin: 0,

  color: COLORS.secondary,
  alignSelf: "center",
  textTransform: "uppercase",
  "@media (min-width:320px)": {
    fontSize: "10px",
    height: "30px",
  },
  "@media (min-width:768px)": {
    fontSize: "15px",
    height: "45px",
  },
  "@media (min-width:1200px)": {
    fontSize: "17px",
    height: "45px",
  },
});

export const MetricsValue = styled("h1")({
  overflowWrap: "break-word",
  margin: 0,

  color: COLORS.secondary,
  "@media (min-width:320px)": {
    fontSize: "30px",
  },
  "@media (min-width:768px)": {
    fontSize: "50px",
  },
  "@media (min-width:1200px)": {
    fontSize: "70px",
  },
});

export const MetricsContentDivider = styled("div")({
  color: "white",
  backgroundColor: "white",
  width: "1px",
  height: "100%",
  alignSelf: "center",
});

export const SectionTitle = styled("h1")({
  marginTop: "3px",
  color: COLORS.secondary,

  textAlign: "left",
  "@media (min-width:320px)": {
    fontSize: "12px",
  },
  "@media (min-width:768px)": {
    fontSize: "18px",
  },
  "@media (min-width:1200px)": {
    fontSize: "20px",
  },
});

export const SectionTextDivider = styled("h1")({
  width: "5%",
});

export const DocumentsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const ListContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const SubHeadingContainer = styled("div")({
  display: "flex",
  marginBottom: "70px",
  color: COLORS.white,
  backgroundColor: COLORS.secondary,
});

export const SubHeadingImage = styled("img")({
  width: "70px",
  height: "70px",
  border: `solid 1px ${COLORS.white}`,
  borderRadius: "50%",
});

export const DeskItem = styled("div")({
  maxWidth: "355px",
  maxHeight: "275px",
  textAlign: "center",
  alignContent: "center",
  marginBottom: "100px",
  color: COLORS.secondary,
});

export const DeskItemImage = styled("img")({
  // width: "100px",
  // height: "101px"
  maxWidth: "50%",
  height: "50%",
});
