// TEST PARAMS: 000001-IC14-2112-0004AA
import { Grid } from "@mui/material";
import { getFormStatusVuce, getPostFormVuce, getPreFormVuce } from "api/myDesk";
import TextField from "components/TextField/TextField";
import { useState } from "react";
import { StyledButton } from "theme/Styles";
import { handleFetchAlert } from "views/MyDesk/utils/handleFetchAlert";
import { getFormIcon } from "../utils/getFormIcon";

function PreDeclarationForm() {
  const [requestNo, setRequestNo] = useState("");

  return (
    <>
      <Grid
        alignItems="flex-start"
        justifyContent="center"
        container
        direction="row"
        columnSpacing={2}
      >
        <Grid item xs={12}>
          <TextField
            title="Número de declaración"
            type="text"
            id="requestNo"
            onChange={(e) => setRequestNo(e.target.value)}
            required
          />
        </Grid>
      </Grid>
      <StyledButton
        onClick={() => {
          handleFetchAlert({
            fetchMethod: getPreFormVuce(requestNo),
            successMessage: (res) => res?.message?.status,
            errorMessage: (res) => res?.message,
          });
        }}
      >
        CONSULTAR
      </StyledButton>
    </>
  );
}

// TEST PARAMS: 000001-IC17-2112-000025
function PostDeclarationForm() {
  const [requestNo, setRequestNo] = useState("");
  return (
    <>
      <Grid
        alignItems="flex-start"
        justifyContent="center"
        container
        direction="row"
        columnSpacing={2}
      >
        <Grid item xs={12}>
          <TextField
            title="Número de declaración"
            type="text"
            id="requestNo"
            onChange={(e) => setRequestNo(e.target.value)}
            required
          />
        </Grid>
      </Grid>
      <StyledButton
        onClick={() => {
          handleFetchAlert({
            fetchMethod: getPostFormVuce(requestNo),
            successMessage: (res) => res?.message?.status,
            errorMessage: (res) => res?.message,
          });
        }}
      >
        CONSULTAR
      </StyledButton>
    </>
  );
}

function StatusSigaVuceForm() {
  const [requestNo, setRequestNo] = useState("");
  return (
    <>
      <Grid
        alignItems="flex-start"
        justifyContent="center"
        container
        direction="row"
        columnSpacing={2}
      >
        <Grid item xs={12}>
          <TextField
            title="Número de declaración"
            type="text"
            id="requestNo"
            onChange={(e) => setRequestNo(e.target.value)}
            required
          />
        </Grid>
      </Grid>
      <StyledButton
        onClick={() => {
          handleFetchAlert({
            fetchMethod: requestNo.includes("IC14")
              ? getPreFormVuce(requestNo) // IC14 = GetPreFormVuce
              : getPostFormVuce(requestNo), // IC17 = GetPostFormVuce
            successMessage: (res) => res?.message?.status,
            errorMessage: (res) => res?.message?.status || res?.message,
            icon: (res) => getFormIcon(res?.message?.status),
          });
        }}
      >
        CONSULTAR
      </StyledButton>
    </>
  );
}

/*
  En VUCE tenemos estos estatus:
    Borrador
    En proceso
    Aprobado
    Rechazado
    Expirado
    Desestimado
    No Objeción
    Pendiente de pago
    Devuelto para corrección
*/

// const testStatus = "Pendiente de pago";

// TEST PARAMS: TRM-0921-322640
function StatusDeclarationForm() {
  const [requestNo, setRequestNo] = useState("");
  return (
    <>
      <Grid
        alignItems="flex-start"
        justifyContent="center"
        container
        direction="row"
        columnSpacing={2}
      >
        <Grid item xs={12}>
          <TextField
            title="Número de declaración"
            type="text"
            id="requestNo"
            onChange={(e) => setRequestNo(e.target.value)}
            required
          />
        </Grid>
      </Grid>
      <StyledButton
        onClick={() => {
          handleFetchAlert({
            fetchMethod: getFormStatusVuce(requestNo),
            successMessage: (res) => res?.message?.status,
            errorMessage: (res) => res?.message?.status || res?.message,
            icon: (res) => getFormIcon(res?.message?.status),
          });
        }}
      >
        CONSULTAR
      </StyledButton>
    </>
  );
}

export { PostDeclarationForm, PreDeclarationForm, StatusDeclarationForm, StatusSigaVuceForm };
