import React, { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UpdateAppSubHeaderTitle } from "redux/actions/UiActions";

import { Box, Stack } from "@mui/material";
import MyDeskHeader from "components/MyDeskHeader";

import { Container } from "views/UseGuide/styles/UseGuide";
import FormModal from "components/FormModal/FormModal";
import { MediumHeightDivider, Title } from "theme/Styles";
import { forms, NEW_MY_DESK_OPTIONS } from "./MyDeskConstants";

import "swiper/scss";
import "./styles/index.css";

function MenuItem({ icon, title, href, onClick = () => {} }) {
  const history = useHistory();
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        spacing={2}
        sx={{
          width: { xs: "100px", md: "150px" },
          height: "150px",
          padding: "12px",
          margin: "10px",
          cursor: "pointer",
          borderRadius: "10px",
          userSelect: "none",
          "&:hover": {
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          },
        }}
        onClick={() => (href ? history.push(href) : onClick())}
      >
        <Box
          component={"img"}
          src={icon}
          sx={{
            width: "60%",
            height: "60%",
            aspectRatio: "1:1",
          }}
          alt="Institution Logo"
        />
        <Box
          component={"p"}
          sx={{
            fontSize: "1rem",
            fontWeight: 600,
            color: "#134779",
            textTransform: "uppercase",
            textAlign: "center",
            width: "100%",
          }}
        >
          {title}
        </Box>
      </Stack>
    </Stack>
  );
}

function MyDesk() {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [modal, setModal] = useState("");

  useLayoutEffect(() => {
    dispatch(UpdateAppSubHeaderTitle("Escritorio Ciudadano")); //SET SUBHEADER TITLE
  }, [dispatch]);

  return (
    <Container>
      <MyDeskHeader header={"Mi Escritorio"} canGoBack={true} />

      <FormModal
        open={openModal}
        fullWidth={true}
        onClose={() => {
          setOpenModal(false);
          setModal("");
        }}
        title={forms[modal]?.title}
      >
        {forms[modal]?.content}
      </FormModal>

      {/* GRID OPTIONS */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {NEW_MY_DESK_OPTIONS.map((section, index) => {
          return (
            <Stack key={index} spacing={4} width={{ xs: "90%", lg: "60%" }}>
              <Title sx={{ textAlign: "center" }}>{section?.title}</Title>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  columnGap: { xs: "20px", md: "100px" },
                  paddingBottom: "40px",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                {section?.options?.map((item) => (
                  <MenuItem
                    key={item.title}
                    icon={item.icon}
                    title={item.title}
                    href={item.href}
                    onClick={() => {
                      if (item.modal) {
                        setModal(item.modal);
                        setOpenModal(true);
                      }
                    }}
                  />
                ))}
              </Box>
            </Stack>
          );
        })}
      </Box>
      <MediumHeightDivider />
    </Container>
  );
}

export default MyDesk;
