import * as yup from "yup";
import { defaultString } from "utilities/functions/StringUtil";

export const FormSchema = [
  {
    step: 1,
    validations: yup
      .object({
        email: yup.string().email("Ingrese un email valido").required("Este campo es requerido"),
        emailConfirmation: yup
          .string()
          .email("Ingrese un email valido")
          .oneOf([yup.ref("email"), null], "Los correos no coinciden")
          .required("Este campo es requerido"),
        password: yup
          .string()
          .min(6, "La contraseña debe tener mínimo 6 caracteres")
          .required("Este campo es requerido"),
        password_confirmation: yup
          .string()
          .min(6, "La contraseña debe tener mínimo 6 caracteres")
          .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden")
          .required("Este campo es requerido"),
      })
      .required(),
  },
  {
    step: 2,
    validations: yup
      .object({
        document_type: yup.string().required("Este campo es requerido"),
        citizen_id: yup.string().required("Este campo es requerido"),
        name: yup.string().required("Este campo es requerido"),
        first_last_name: yup.string().required("Este campo es requerido"),
        second_last_name: yup.string(),
        occupation: yup.string(),
        province_id: yup.string().required("Este campo es requerido"),
        municipality_id: yup.string().required("Este campo es requerido"),
        sector_id: yup.string().required("Este campo es requerido"),
        phone: yup.string().required("Este campo es requerido"),
        phone2: yup
          .string()
          .notOneOf(
            [yup.ref("phone")],
            "El teléfono secundario no puede ser igual al teléfono primario"
          ),
        email2: yup.string().email("Ingrese un email valido"),
        address: yup.string().required("Este campo es requerido"),
      })
      .required(),
  },
  {
    step: 3,
    validations: yup
      .object({
        user_buys_for_dependent: yup.string().required("Este campo es requerido"),
        number_of_dependents: yup.number().when("user_buys_for_dependent", {
          is: "1",
          then: () =>
            yup.number().min(1, "Debe ser mayor o igual a 1").required("Este campo es requerido"),
        }),
        user_sells_merchandise: yup.string().required("Este campo es requerido"),
        types_of_merchandise: yup
          .array()
          .of(yup.object())
          .when("user_sells_merchandise", {
            is: "1",
            then: () =>
              yup
                .array()
                .of(yup.object())
                .min(1, "Debe seleccionar al menos una opción")
                .required(
                  "(seleccione una o varias opciones y/o digite en caso de no existir separado por coma)"
                ),
          }),
      })
      .required(),
  },
  {
    step: 4,
    validations: yup
      .object({
        first_citizen_question: yup.number().required("Este campo es requerido"),
        first_citizen_answer: yup.string().required("Este campo es requerido"),
        second_citizen_question: yup.number().required("Este campo es requerido"),
        second_citizen_answer: yup.string().required("Este campo es requerido"),
        third_citizen_question: yup.number().required("Este campo es requerido"),
        third_citizen_answer: yup.string().required("Este campo es requerido"),
      })
      .required(),
  },
  {
    step: 5,
    validations: yup
      .object({
        faceRecognitionIsValid: yup
          .boolean()
          .oneOf([true, defaultString.requiredValidation])
          .required("Este campo es requerido"),
      })
      .required(),
  },
  {
    step: 6,
    validations: yup
      .object({
        termsAndCondition: yup
          .boolean()
          .oneOf(
            [true],
            "Debe aceptar los términos y condiciones de uso y privacidad para continuar"
          ),
      })
      .required(),
  },
];

export const FormSchemaCertificate = yup.object({
  user: yup.string().required("Por favor escriba su usuario de viafirma"),
});

export const RegisterSteps = [
  {
    id: 1,
    title: "Información de Autenticación",
    elements: ["email", "emailConfirmation", "password", "password_confirmation"],
  },
  {
    id: 2,
    title: "Datos Personales",
    elements: [
      "document_type",
      "citizen_id",
      "name",
      "first_last_name",
      "second_last_name",
      "occupation",
      "province_id",
      "municipality_id",
      "sector_id",
      "phone",
      "phone2",
      "email2",
      "address",
    ],
  },
  {
    id: 3,
    title: "RUA",
  },
  {
    id: 4,
    title: "Información de seguridad",
    elements: [
      "first_citizen_question",
      "first_citizen_answer",
      "second_citizen_question",
      "second_citizen_answer",
      "third_citizen_question",
      "third_citizen_answer",
    ],
  },
  {
    id: 5,
    title: "Validación facial",
    elements: ["faceRecognitionIsValid"],
  },
  {
    id: 6,
    title: "Términos y condiciones",
    elements: ["termsAndCondition"],
  },
  {
    id: 7,
    title: "Activación de cuenta",
  },
];

export const identificationTypes = [
  {
    value: 1,
    label: "Cédula",
  },
  {
    value: 2,
    label: "Pasaporte",
  },
];
