import { styled } from "@mui/system";
import COLORS from "theme/Colors";
import { BodyText } from "theme/Styles";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import EmailIcon from "@mui/icons-material/Email";
import PrintIcon from "@mui/icons-material/Print";
import { Button, Fab } from "@mui/material";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

export const TopContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

export const TopItemContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const StyledFab = styled(Fab)({
  zIndex: 99999,
  color: COLORS.white,
  backgroundColor: COLORS.secondary,
  width: "200px",
  borderRadius: "50px",
  "&:hover": {
    backgroundColor: COLORS.primary,
  },
  bottom: 16,
  top: 16,
  marginTop: "50px",
  alignSelf: "flex-end",
  position: "sticky",
  "@media print": {
    display: "none",
  },
});

export const CommentsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  "@media print": {
    display: "none",
  },
});

export const ButtonContainer = styled("div")({
  width: "200px",
  marginTop: "20px",
});

export const TextListContainer = styled("ul")({
  margin: 0,
  paddingLeft: 17,
});

export const TextOrderedListContainer = styled("ol")({
  margin: 0,
  paddingLeft: 17,
});

export const PriceContainer = styled("div")({
  width: "100%",
  textAlign: "left",
});

export const CardPriceTitle = styled(BodyText)({
  marginTop: "5px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 1 /* number of lines to show */,
  "-webkit-box-orient": "vertical",
});

export const CardPriceGray = styled(BodyText)({
  marginTop: "10px",
  color: COLORS.gray,
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 1 /* number of lines to show */,
  "-webkit-box-orient": "vertical",
});

export const StyledFacebookIcon = styled(FacebookIcon)({
  color: COLORS.black,
  "@media (min-width:320px)": {
    fontSize: "18px",
  },
  "@media (min-width:768px)": {
    fontSize: "20px",
  },
  "@media (min-width:1200px)": {
    fontSize: "30px",
  },
});

export const StyledTwitterIcon = styled(TwitterIcon)({
  color: COLORS.black,
  "@media (min-width:320px)": {
    fontSize: "18px",
  },
  "@media (min-width:768px)": {
    fontSize: "20px",
  },
  "@media (min-width:1200px)": {
    fontSize: "30px",
  },
});

export const StyledEmailIcon = styled(EmailIcon)({
  color: COLORS.black,
  "@media (min-width:320px)": {
    fontSize: "18px",
  },
  "@media (min-width:768px)": {
    fontSize: "20px",
  },
  "@media (min-width:1200px)": {
    fontSize: "30px",
  },
});

export const StyledPrintIcon = styled(PrintIcon)({
  color: COLORS.black,
  "@media (min-width:320px)": {
    fontSize: "18px",
  },
  "@media (min-width:768px)": {
    fontSize: "20px",
  },
  "@media (min-width:1200px)": {
    fontSize: "30px",
  },
});

export const VariationsContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  "@media (min-width:320px)": {
    flexDirection: "column",
  },
  "@media (min-width:768px)": {
    flexDirection: "row",
  },
  "@media (min-width:1200px)": {
    flexDirection: "row",
  },
});

export const PaginationContainer = styled("div")({
  display: "flex",
  alignSelf: "center",
  alignItems: "center",
  width: "100%",
  justifyContent: "center",
});

export const AduanasDescription = styled("div")({
  display: "flex",
  gap: "10px",

  textAlign: "justify",
  marginBottom: "20px",
});

export const LineDivider = styled("div")({
  width: "100%",
  height: "1px",
  backgroundColor: COLORS.secondary,
});

export const ServiceTitle = styled("h3")({
  color: COLORS.secondary,

  textTransform: "uppercase",
});

export const InfoCard = styled("div")({
  fontWeight: "30px",
  backgroundColor: "#bfeeef",
  display: "flex",
  gap: "10px",
  color: COLORS.secondary,
  margin: "20px 0px",
  padding: "10px",
  minWidth: "400px",
});

export const InfoCardSentence = styled("p")({
  margin: "0px",
});

export const GeneralInfo = styled("div")({
  color: COLORS.gray,
  textAlign: "justify",
});

export const IconContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  marginTop: "20px",

  "@media (max-width:900px)": {
    // flexDirection: "row",
    justifyContent: "center",
  },
});

export const IconItem = styled("div")({
  display: "flex",
});

export const IconDescription = styled("div")({
  color: COLORS.gray,
  margin: "0px",
});

export const IconTitle = styled("h3")({
  margin: "0px 0px 0px 10px",
  fontWeight: "bold",
});

export const IconContent = styled("p")({
  margin: "0px 0px 0px 10px",
});

export const ServiceDescriptionContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",

  "@media (max-width:900px)": {
    flexDirection: "column-reverse",
  },
});

export const ServiceContent = styled("div")({
  width: "60%",
  paddingRight: "50px",

  "@media (max-width:900px)": {
    width: "100%",
    alignContent: "center",
  },
});

export const ServiceGeneralInfo = styled("div")({
  width: "40%",

  "@media (max-width:900px)": {
    width: "100%",
    alignContent: "center",
  },
});

export const ServiceButton = styled(Button)((props) => ({
  height: "35px",
  width: "50%",
  alignContent: "center",
  color: COLORS.white,
  backgroundColor: props.btnColor,
  borderTop: ` 1px solid ${COLORS.secondary}`,
  borderRadius: 0,
  "&:hover,&:focus,&:active": {
    backgroundColor: COLORS.primary,
    color: COLORS.white,
    borderColor: COLORS.primary,
  },
}));
