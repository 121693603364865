import * as yup from "yup";

export const UserQuestionsSchema = yup
  .object({
    first_citizen_question: yup.number().required("Este campo es requerido"),
    first_citizen_answer: yup.string().required("Este campo es requerido"),
    second_citizen_question: yup.number().required("Este campo es requerido"),
    second_citizen_answer: yup.string().required("Este campo es requerido"),
    third_citizen_question: yup.number().required("Este campo es requerido"),
    third_citizen_answer: yup.string().required("Este campo es requerido"),
    fourth_citizen_question: yup.number().required("Este campo es requerido"),
    fourth_citizen_answer: yup.string().required("Este campo es requerido"),
  })
  .required();
