import { logout } from "auth/AuthFunctions";
import axios from "axios";
import LocalStorageService from "./LocalStorageService";

const iucService = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_IDENTIDAD_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  axiosInstance.interceptors.request.use((config) => {
    let token = LocalStorageService.getItem("token");
    if (token) {
      config.headers.Authorization = `beater ${token}`;
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    async (response) => {
      //refreshToken NEED TEST
      const originalRequest = response.config;
      if (response.data?.msg === "El token ha expirado" && !originalRequest._retry) {
        // originalRequest._retry = true;
        // const access_token = await refreshToken();
        // axiosInstance.defaults.headers.common["Authorization"] = "beater " + access_token;
        // return axiosInstance(originalRequest);
        logout();
      }
      return response;
    },
    async function (error) {
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};

export default iucService;
