//descriptionGeneral is mockup this value is fetched from wordpress

export const localDirections = [
  {
    id: 0,
    wordpressID: 130,
    wordpressKey: "todosServicios",
    title: "Listado de Servicios",
  },
  {
    id: 1,
    wordpressID: 133,
    wordpressKey: "dpp",
    title: "Tramitación y Evaluación de Proyectos (DPP)",
  },
  {
    id: 2,
    wordpressID: 132,
    wordpressKey: "confotour",
    title: "Clasificaciones de Confotur",
  },
  {
    id: 3,
    wordpressID: 134,
    wordpressKey: "empresaServicios",
    title: "Licencias de Operación",
  },
];
