import backOfficeService from "services/ApiServiceCall";

export const getDraftsList = async (citizenID) => {
  const { data } = await backOfficeService().get(`/drafts/${citizenID}`);
  return data;
};

export const saveDraft = async (requestData) => {
  const { data } = await backOfficeService().post(`/drafts`, requestData);
  return data;
};
