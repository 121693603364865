import { styled } from "@mui/system";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

export const ButtonContainer = styled("div")({
  width: "200px",
  marginTop: "20px",
});
