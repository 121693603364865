import { memo } from "react";
import IconButton from "@mui/material/IconButton";
import { StyledCloseIcon } from "./styles/FormModalStyles";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import COLORS from "theme/Colors";

function FormModal({
  title,
  open,
  onClose,
  conditionalClose, // The modal doesn't close on backdrop click or close button click
  fullWidth,
  fullScreen,
  maxWidth = "lg",
  content,
  actions,
  children,
  width = "100%",
}) {
  return (
    <Dialog
      open={open}
      onClose={conditionalClose ? null : onClose}
      onBackdropClick={conditionalClose ? null : onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      PaperProps={{
        style: {
          overflowY: "visible",
          padding: "10px",
          width: width,
        },
      }}
    >
      <DialogTitle sx={{ color: COLORS.secondary, fontWeight: 600, letterSpacing: "2px" }}>
        {title}
        {!conditionalClose && (
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 12,
              top: 12,
            }}
          >
            <StyledCloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent>{content || children}</DialogContent>

      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
}

export default memo(FormModal);
