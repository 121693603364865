import ServicesHistoryIcon from "assets/icons/svgs/servicios_historial.svg";
import DocumentsIcon from "assets/icons/svgs/documents.svg";
import ServicesIcon from "assets/icons/svgs/services.svg";
import EditProfileIcon from "assets/icons/svgs/edit_profile.svg";
import CourierRegisterIcon from "assets/icons/svgs/icon-courier-status.svg";
import IconCalculator from "assets/icons/svgs/icon-vehicleTaxCalculator.svg";
import IconConsultaPre from "assets/icons/svgs/IconConsultas-pre.svg";
// import IconConsultaPost from "assets/icons/svgs/IconConsultaPost.svg";
import IconDeclarationStatus from "assets/icons/svgs/IconoConsultaVUCE-despuesDUA.svg";
import PinGeneratorIcon from "assets/icons/svgs/pin_generator.svg";
import {
  PostDeclarationForm,
  PreDeclarationForm,
  StatusDeclarationForm,
  StatusSigaVuceForm,
} from "./modals/vuceModals";
import { PackageStatusForm, PinGeneratorForm } from "./modals/miscModals";

export const requestFilterTypes = [
  {
    value: 1,
    label: "En proceso",
    variantTypes: [1, 2, 3, 4, 5, 6, 9],
  },
  {
    value: 2,
    label: "Finalizadas",
    variantTypes: [7],
  },
  // {
  //   value: 3,
  //   label: "Rechazadas",
  //   variantTypes: [8, 10],
  // },
];

export const NEW_MY_DESK_OPTIONS = [
  {
    options: [
      {
        icon: ServicesHistoryIcon,
        title: "Servicios | Historial",
        href: "/app/myRequests",
      },
      {
        icon: DocumentsIcon,
        title: "Documentos",
        href: "/app/myDocuments",
      },
      {
        icon: ServicesIcon,
        title: "Servicios De Interés",
        href: "/app/allServices",
      },
      {
        icon: EditProfileIcon,
        title: "Editar Perfil",
        href: "/app/myConfiguration",
      },
      {
        icon: CourierRegisterIcon,
        title: "Estatus Mercancías Courier",
        modal: "package-status",
      },
      {
        icon: IconCalculator,
        title: "CALCULADORA DE IMPUESTOS PARA VEHÍCULOS",
        href: "/app/vehicleTaxCalculator",
      },
      {
        icon: IconConsultaPre,
        title: "Consulta Estatus SIGA-VUCE",
        modal: "status-siga-vuce",
      },
      {
        icon: IconDeclarationStatus,
        title: "Consulta Estatus VUCE",
        modal: "status-declaration",
      },
      {
        icon: PinGeneratorIcon,
        title: "Generador de Pin para Personas Físicas o Jurídicas",
        modal: "generate-pin",
      },
    ],
  },

  // {
  //   //title: "CONSULTAS EN VUCE",
  //   options: [
  //     {
  //       icon: IconConsultaPre,
  //       title: "Estatus Pre-Declaración",
  //       modal: "pre-declaration",
  //     },
  //     {
  //       icon: IconConsultaPost,
  //       title: "Estatus Post-Declaración",
  //       modal: "post-declaration",
  //     },
  //     {
  //       icon: IconDeclarationStatus,
  //       title: "Estatus VUCE Externo",
  //       modal: "status-declaration",
  //     },
  //     {
  //       icon: PinGeneratorIcon,
  //       title: "Generador de Pin para Personas Físicas o Jurídicas",
  //       modal: "generate-pin",
  //     },
  //   ],
  // },
];

export const forms = {
  "status-siga-vuce": {
    title: "Consulta Estatus SIGA-VUCE",
    description: "Ingrese el número de trámite",
    content: <StatusSigaVuceForm />,
  },
  "pre-declaration": {
    title: "Consulta Estatus Pre-Declaración",
    description: "Ingrese el número de pre-declaración",
    content: <PreDeclarationForm />,
  },
  "post-declaration": {
    title: "Consulta Estatus Post-Declaración",
    description: "Ingrese el número de post-declaración",
    content: <PostDeclarationForm />,
  },
  "status-declaration": {
    title: "Consulta Estatus VUCE",
    description: "Ingrese el número de declaración",
    content: <StatusDeclarationForm />,
  },
  "package-status": {
    title: "Estatus mercancías courier",
    description: "Consultar Paquete",
    content: <PackageStatusForm />,
  },
  "generate-pin": {
    title: "Generar PIN de pago",
    description: "Generar nuevo PIN de pago bancario",
    content: <PinGeneratorForm />,
  },
};
