import { Fragment, useRef, useState } from "react";
import { MediumHeightDivider, Row, SmallHeightDivider, StyledButtonOutlined } from "theme/Styles";
import { useHistory } from "react-router-dom";
import { requestFilterTypes } from "views/MyDesk/MyDeskConstants";
import RequestCard from "components/RequestCard/RequestCard";
import { ListContainer, SectionTitle } from "views/MyDesk/styles/MyDeskStyles";
import { useQuery, useQueryClient } from "react-query";
import { getRequestedServicesWithFilters } from "api/RequestedServiceList";
import Select from "components/Select/Select";
import TextField from "components/TextField/TextField";
import { Box, IconButton, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CenterLoading from "components/CenterLoading/CenterLoading";
import { cacheConfig } from "cacheConfig";
import CustomPagination from "components/CustomPagination";
import { Fade } from "react-awesome-reveal";

function MyRequestsComponent() {
  const history = useHistory();

  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData(["userData"]);

  const [requestType, setRequestType] = useState(1);
  const [requestName, setRequestName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [stringFilter, setStringFilter] = useState("");
  const titleRef = useRef(null);

  const { data: requestsList, isLoading: requestListIsLoading } = useQuery(
    ["requestsList", requestType, stringFilter, currentPage],
    () =>
      getRequestedServicesWithFilters(
        userData.payload.citizen_id,
        currentPage,
        requestType,
        stringFilter
      ),
    {
      staleTime: cacheConfig.staleTimeForRequestedServicesList,
    }
  );

  const handleTypeChange = (value) => {
    handlePageChange(1);
    setRequestType(value.target.value);
    setRequestName("");
    titleRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handlePageChange = (value) => {
    setCurrentPage(value);
    titleRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleStringFilter = () => {
    handlePageChange(1);
    setStringFilter(requestName);
  };

  const handleRemoveFilters = () => {
    setStringFilter("");
    setRequestName("");
  };

  if (requestListIsLoading) return <CenterLoading />;

  return (
    <>
      <MediumHeightDivider />
      <Row style={{ justifyContent: "space-between" }}>
        <Row style={{ alignItems: "center", width: "75%" }}>
          <SectionTitle ref={titleRef}>
            {`Solicitudes ${requestFilterTypes
              .find((type) => type.value == requestType)
              ?.label?.toLowerCase()}`}
          </SectionTitle>
        </Row>
        <Select
          id="filterType"
          data={requestFilterTypes}
          value={requestType}
          onChange={handleTypeChange}
          disableEmptyValue
        />
      </Row>

      <div style={{ height: "5px" }} />

      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        alignContent={"center"}
        spacing={2}
      >
        <Box
          sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}
        >
          <TextField
            placeholder="Nombre o número de solicitud"
            type="text"
            id="requestID"
            value={requestName}
            onChange={(e) => setRequestName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleStringFilter();
              }
            }}
          />
        </Box>

        {stringFilter.length > 0 && (
          <IconButton onClick={() => handleRemoveFilters()} aria-label="delete">
            <DeleteIcon titleAccess="Eliminar filtro" color="error" sx={{ fontSize: "1.5em" }} />
          </IconButton>
        )}

        <Box sx={{ width: "20%" }}>
          <StyledButtonOutlined onClick={handleStringFilter} variant="outlined">
            Buscar
          </StyledButtonOutlined>
        </Box>
      </Stack>

      <Fade direction={"right"}>
        <ListContainer>
          {requestsList?.data?.map((request, index) => (
            <Fragment key={request.id}>
              {index > 0 && <SmallHeightDivider />}
              <RequestCard
                title={request.service.name}
                percent={request.progress + "%"}
                date={request.created_at}
                requestCode={request?.provisional === 0 ? request.code : null}
                status={request.status.name}
                actionRequired={request.request_actions}
                onClick={() => history.push(`/app/serviceRequestedDetails/${request.id}`)}
                statusID={request.status.id}
                solution={request.solution}
                solutionColor={request.status.color}
                projectName={request.nmtramite}
              />
            </Fragment>
          ))}

          <MediumHeightDivider />

          {requestsList?.data?.length > 0 ? (
            <CustomPagination
              variant={"light"}
              count={requestsList?.last_page}
              value={currentPage - 1}
              onClick={(page) => {
                handlePageChange(page);
              }}
            />
          ) : (
            <SectionTitle>No se encontraron registros</SectionTitle>
          )}

          <MediumHeightDivider />
        </ListContainer>
      </Fade>
    </>
  );
}

export default MyRequestsComponent;
