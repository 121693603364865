import { useEffect, useState } from "react";
import {
  BodyText,
  FlexStartContainer,
  FooterContainer,
  Image,
  LeftPanelContainer,
  LinkText,
  LogoImage,
  RowContainer,
  TextError,
  TextFieldContainer,
  Title,
} from "./styles/RequestPasswordStyles";
import { MediumHeightDivider, SmallHeightDivider, StyledButton } from "theme/Styles";
import COLORS from "theme/Colors";
import { useHistory } from "react-router-dom";
import TextField from "components/TextField/TextField";
import { useDispatch, useSelector } from "react-redux";
import { removeGuionFromString } from "utilities/functions/StringUtil";
import { useSnackbar } from "notistack";
import { restorePassword } from "api/RequestPassword";
import { HideGlobalLoading, ShowGlobalLoading } from "redux/actions/UiActions";
import Logo from "assets/images/LogoPNG.png";
import { getHomeDataFromWordpress } from "api/Home";
import { useQuery } from "react-query";
import CenterLoading from "components/CenterLoading/CenterLoading";

function RequestPassword() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { authenticated } = useSelector((state) => state.authReducer);
  const { enqueueSnackbar } = useSnackbar();

  const [errorMessage, setErrorMessage] = useState("");
  const [citizenId, setCitizenId] = useState("");
  const { data: homeContent, isLoading: homeContentIsLoading } = useQuery(["homeData"], () =>
    getHomeDataFromWordpress()
  );

  const onRequest = async (citizenId) => {
    try {
      dispatch(ShowGlobalLoading("Cargando"));
      const response = await restorePassword({
        citizen_id: removeGuionFromString(citizenId),
      });
      if (response?.success) {
        //IF passwordRequest SUCCESS
        enqueueSnackbar("Se ha enviado un mensaje a su correo electrónico", { variant: "success" });
        history.push("/public");
      } else {
        setErrorMessage(response?.msg);
        // enqueueSnackbar("Ha ocurrido un error favor intentar mas tarde.", { variant: 'error' })
      }
      dispatch(HideGlobalLoading());
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error favor intentar mas tarde.", { variant: "error" });
      dispatch(HideGlobalLoading());
    }
  };

  useEffect(() => {
    if (authenticated) {
      history.push("/app/myDesk");
    }
  }, []);

  if (homeContentIsLoading) return <CenterLoading />;

  return (
    <RowContainer>
      <LeftPanelContainer>
        <LogoImage src={Logo} />
        <FlexStartContainer>
          <Title>Restablecer contraseña</Title>
          <SmallHeightDivider />
          <SmallHeightDivider />

          <TextFieldContainer>
            <TextField
              placeholder="Documento"
              type="text"
              id="id"
              onChange={(e) => setCitizenId(e.target.value)}
            />
          </TextFieldContainer>

          <TextError>{errorMessage}</TextError>
          <MediumHeightDivider />
          <StyledButton onClick={() => onRequest(citizenId)}>Restablecer contraseña</StyledButton>
          <MediumHeightDivider />

          <LinkText
            to="/public/login"
            style={{
              color: COLORS.primary,
            }}
          >
            Regresar
          </LinkText>
          <FooterContainer>
            <BodyText>
              @{process.env.REACT_APP_CONFIG_YEAR} Dirección General de Aduanas de la República
              Dominicana. Todos los derechos reservados.
            </BodyText>
          </FooterContainer>
        </FlexStartContainer>
      </LeftPanelContainer>
      <Image
        style={{
          backgroundImage: `url(${homeContent?.image_url})`,
        }}
      />
    </RowContainer>
  );
}

export default RequestPassword;
