import { Fragment, memo, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Box, DialogActions, DialogContent, DialogTitle, Fade, Grid } from "@mui/material";
import {
  Title,
  ValidationButton,
  ValidationOutlinedButton,
} from "./styles/SecurityQuestionsValidationStyles";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextField from "components/TextField/TextField";
import ImportantInformationModal from "components/ImportantInformationModal/ImportantInformationModal";
import { useFormik } from "formik";
import { getUserQuestions, sendUserQuestions } from "api/SecurityQuetionsValidation";
import COLORS from "theme/Colors";
import LogoWithText from "assets/icons/pngs/logo-with-text.png";
import { FormSchema } from "./SecurityQuestionValidationConstant";
import { ShowGlobalLoading } from "redux/actions/UiActions";
import { removeLocalStorageSessionData } from "auth/AuthFunctions";
import { AuthLogout } from "redux/actions/AuthActions";
import { useDispatch } from "react-redux";
import { SweetAlert } from "components/SweetAlert/SweetAlert";
import CheckBox from "components/CheckBox/CheckBox";

function SecurityQuestionsValidation({
  showMessages = true,
  citizenID = "",
  validationFunction = sendUserQuestions,
  onFinishCallBack = () => {},
  onCancelClick = () => {},
}) {
  const [acceptedConditions, setAcceptedConditions] = useState(!showMessages);
  const [questionsData, setQuestionsData] = useState([]);
  const [isValidating, setIsValidating] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      questions: [
        {
          answer: "",
          question: 0,
        },
        {
          answer: "",
          question: 0,
        },
      ],
      save_device: false,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: FormSchema,
    onSubmit: (values) => {
      SweetAlert.showLoading();
      setIsValidating(true);
      return validationFunction(citizenID, values?.questions, values?.save_device)
        .then((res) => {
          if (res?.success) {
            SweetAlert.close();
            onFinishCallBack();
          } else {
            SweetAlert.fire({
              title: res?.is_blocked
                ? "Ha excedido el número de intentos."
                : "Preguntas de seguridad erróneas",
              text: res.message,
              icon: "error",
              confirmButtonColor: COLORS.secondary,
            });

            if (res?.is_blocked) HandleLogOut();
          }
        })
        .catch((error) => {
          SweetAlert.showValidationMessage(`Request failed: ${error}`);
        })
        .then(() => {
          setIsValidating(false);
        });
    },
  });

  const handleSecurityQuestionChange = (index, question, answer) => {
    formik.setFieldValue(`questions.${index}.question`, question);
    formik.setFieldValue(`questions.${index}.answer`, answer);
  };

  const HandleLogOut = () => {
    dispatch(ShowGlobalLoading("Cerrando sesión"));
    setTimeout(() => {
      removeLocalStorageSessionData();
      dispatch(AuthLogout());
      window.location.reload();
    }, 1500);
  };

  async function getQuestionData() {
    try {
      let response = await getUserQuestions(citizenID);
      if (response?.success) {
        setQuestionsData(
          response.payload?.map((question) => ({
            value: question.id,
            label: question.question,
          }))
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getQuestionData();
  }, []);

  if (!acceptedConditions) {
    return (
      <ImportantInformationModal
        open={true}
        title="Nuevo dispositivo detectado"
        CloseTitle="Cancelar"
        CloseButton
        buttonTitle="Continuar"
        buttonClick={() => {
          setAcceptedConditions(true);
        }}
        onCloseClick={() => {
          setAcceptedConditions(false);
          onCancelClick();
        }}
        content={
          <Fragment>
            <strong>
              Debido al nivel de criticidad con el que cuenta este servicio, es necesario confirmar
              su identidad.
            </strong>
            <br />
            <strong>
              <p>¿Desea continuar con la solicitud?</p>
            </strong>
          </Fragment>
        }
      />
    );
  }

  if (!questionsData) return null;

  return (
    <Dialog
      keepMounted
      disableEscapeKeyDown
      open={true}
      maxWidth={false}
      PaperProps={{ sx: { borderRadius: "15px" } }}
      TransitionComponent={Fade}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: COLORS.secondary,
        }}
      >
        <Box
          component="img"
          sx={{
            height: { xs: "40px", md: "50px" },
            marginTop: "20px",
          }}
          alt="Institution Logo"
          src={LogoWithText}
        />
        <Title sx={{ margin: 0 }}>Preguntas de Seguridad</Title>
      </DialogTitle>

      <DialogContent sx={{ marginTop: "30px" }}>
        <Grid alignItems="center" justifyContent="center" container direction="row">
          {questionsData.length > 0 ? (
            questionsData?.map((question, index) => {
              return (
                <Grid key={index} item xs={12} sm={12} md={12}>
                  <TextField
                    type="text"
                    title={question.label}
                    value={formik.values.questions[index]?.answer}
                    onChange={(e) => {
                      handleSecurityQuestionChange(index, question.value, e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={!!formik?.errors?.questions?.[index]?.answer}
                    helperText={formik?.errors?.questions?.[index]?.answer}
                    required
                  />
                </Grid>
              );
            })
          ) : (
            <>Error al cargar las preguntas de seguridad. Por favor, intente nuevamente.</>
          )}
          <CheckBox
            label={"Guardar este nuevo dispositivo"}
            value={formik.values.save_device}
            onChange={(e) => formik.setFieldValue("save_device", e.target.value)}
          />
        </Grid>
      </DialogContent>

      <DialogActions
        disableSpacing={true}
        sx={{
          padding: 0,
          flexDirection: "column",
        }}
      >
        <ValidationButton
          onClick={() => formik.submitForm()}
          disabled={isValidating}
          startIcon={<CheckCircleIcon />}
        >
          Validar y continuar
        </ValidationButton>

        <ValidationOutlinedButton
          disabled={isValidating}
          variant="outlined"
          onClick={onCancelClick}
          startIcon={<CancelIcon />}
        >
          Cancelar
        </ValidationOutlinedButton>
      </DialogActions>
    </Dialog>
  );
}

export default memo(SecurityQuestionsValidation);
