import { getSurveyForm, registerSurveyForm } from "api/Survey";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  HideGlobalLoading,
  ShowGlobalLoading,
  UpdateAppSubHeaderTitle,
} from "redux/actions/UiActions";
import {
  MediumHeightDivider,
  SmallHeightDivider,
  StyledCheckCircleIcon,
  SubTitle,
} from "theme/Styles";
import { arrayArrayToArray, transformField } from "utilities/functions/ArrayUtil";
import Form from "views/RequestService/components/Form/Form";
import { FIELD_TYPES } from "views/RequestService/components/Form/FormConstants";
import {
  Container,
  SuccessContainer,
} from "views/ServiceRequestedDetails/subViews/requiredForm/styles/RequiredFormStyles";
import GlobalLoading from "components/GlobalLoading/GlobalLoading";

export function Survey() {
  const history = useHistory();
  let { surveyID } = useParams(0);
  let { serviceID } = useParams(1);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const successRef = useRef(null);
  const formRef = useRef(null);
  const [formData, setFormData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showImportantInformationModal, setShowImportantInformationModal] = useState(true);

  const [selectedVariation, setSelectedVariation] = useState();
  // const [draftLoading, setDraftLoading] = useState(false);

  const [showRequestDetail, setShowRequestDetail] = useState(false);
  const [showSurveyService, setShowSurveyService] = useState(false);
  // const [showRestoreFormModal, setShowRestoreFormModal] = useState(false);

  const [state, setState] = useState({
    rules: [],
    data: {},
    grid: {},
    fakeStep: 0,
    step: 0,
    totalPayment: 0,
    variations: [],
  });

  const userData = queryClient.getQueryData(["userData"]);

  const getAndSetForm = async () => {
    try {
      dispatch(ShowGlobalLoading("Cargando"));
      setIsLoading(true);
      let response = await getSurveyForm(surveyID, userData.payload.citizen_id, serviceID);
      setShowSurveyService(response.service ? true : false);
      setFormData(response);
      setIsLoading(false);
      dispatch(HideGlobalLoading());
    } catch (error) {
      history.push("/public");
      setIsLoading(false);
      dispatch(HideGlobalLoading());
      throw new Error("An error has ocurred");
    }
  };

  useEffect(() => {
    if (surveyID) {
      getAndSetForm();
    }
  }, [surveyID, userData]);

  const getData = () => {
    //separating response by steps
    const plainData = [];
    const data = [formData.data];
    const _data = [];
    for (let i = 0; i < data.length; i++) {
      const step = data[i];
      let _step = [];
      for (let j = 0; j < step.length; j++) {
        const field = step[j];
        plainData.push(field);
        if (_step.length && field.subtype == "h1") {
          _data.push(_step);
          _step = [];
        }
        _step.push(field);
        if (step.length - 1 == j) {
          _data.push(_step);
          _step = [];
        }
      }
    }

    return {
      data: _data.map((step) => step.map(transformField)),
      plainData: plainData.map(transformField),
      date: Number(new Date()),
      //dev
      //data: _data.map(step => step.map(transformField)).reverse(),
    };
  };

  const sendRequest = async (valuesOfFormFromProps) => {
    let valuesOfForm = valuesOfFormFromProps;
    Object.keys(valuesOfForm).forEach((key) =>
      valuesOfForm[key] === undefined ? delete valuesOfForm[key] : {}
    );
    const formData = formRef.current?.saveForm();
    const _plainData = arrayArrayToArray(formData?.localData);
    //prepare the survey response
    let surveyRequest = {};
    Object.keys(valuesOfForm).map((fieldKey) => {
      const formField = _plainData.find((item) => item.fieldKey === fieldKey);
      if (formField?.type === FIELD_TYPES.radioGroup) {
        surveyRequest[formField?.fieldKey] = {
          answer: parseInt(valuesOfForm[formField?.fieldKey]),
          value: formField.data.find((item) => item.value === valuesOfForm[formField?.fieldKey])
            .label,
          required: formField?.required,
        };
      } else {
        surveyRequest[formField?.fieldKey] = {
          value: valuesOfForm[formField?.fieldKey],
          required: formField?.required ?? false,
        };
      }
    });
    dispatch(ShowGlobalLoading("Cargando"));
    try {
      let canSubmitForm = true;
      if (canSubmitForm) {
        let canFormContinue = true;
        const request = {
          destination: surveyID,
          userInfo: userData.payload.citizen_id,
          requestID: serviceID,
          data: surveyRequest,
        };
        dispatch(ShowGlobalLoading("Registrando encuesta"));
        let responseFormSubmit = await registerSurveyForm(request);
        if (responseFormSubmit.success) {
          dispatch(ShowGlobalLoading("Procesando encuesta"));
          if (canFormContinue) {
            // NEW: After a submitted form, remove the form from local storage
            //window.localStorage.removeItem(requestData?.request?.request_actions?.expertform_id);
            enqueueSnackbar("Encuesta enviada satisfactoriamente.", {
              variant: "success",
            });
            setShowRequestDetail(true);
            successRef?.current?.scrollIntoView();
            setTimeout(() => {
              history.push("/public");
            }, 5000);
          }
        } else {
          enqueueSnackbar("Ha ocurrido un error favor intentar mas tarde.", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("Ha ocurrido un error subiendo los documentos.", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error,contacte al soporte para mas información", {
        variant: "error",
      });
    }
    dispatch(HideGlobalLoading());
  };

  useLayoutEffect(() => {
    dispatch(UpdateAppSubHeaderTitle("Encuesta de Satisfacción"));
  }, [dispatch]);

  function handleInformationModalClose() {
    setShowImportantInformationModal(false);
    history.push("/");
  }

  if (isLoading) return <GlobalLoading showByProp={true} />;

  return (
    <Container>
      {showSurveyService && (
        <>
          <SmallHeightDivider />
          <SubTitle>
            {formData.service} - Solicitud No. {serviceID}
          </SubTitle>
        </>
      )}
      <SmallHeightDivider />
      <SmallHeightDivider />
      {/* <ImportantInformationModal
        open={showImportantInformationModal}
        onBackDropClick={() => { }}
        onCloseClick={handleInformationModalClose}
        CloseTitle="Cancelar"
        CloseButton
        buttonTitle="Confirmar"
        buttonClick={() => {
          setShowImportantInformationModal(false);
        }}
        content={
          <Fragment>
            <strong>
              Usted cuenta con una encuesta de satisfacción pendiente. ¿Desea continuar con la
              encuesta?
            </strong>
          </Fragment>
        }
      /> */}
      {!showRequestDetail ? (
        <>
          <Container>
            <Form
              ref={formRef}
              doRequest={sendRequest}
              data={getData().data}
              plainData={getData().plainData}
              // handleFormSave={handleFormSave}
              initialForm={state}
              variations={[selectedVariation?.id]}
              // isDraft={isDraft}
              isSurvey={true}
              multipleDocuments={"true"}
            />
          </Container>
        </>
      ) : (
        <Container ref={successRef}>
          <SuccessContainer>
            <StyledCheckCircleIcon />
            <SmallHeightDivider />
            <SubTitle>Encuesta enviada satisfactoriamente, Muchas Gracias.</SubTitle>
            <MediumHeightDivider />
          </SuccessContainer>
        </Container>
      )}
    </Container>
  );
}
