import { Fragment, useEffect, useLayoutEffect, useMemo, useState } from "react";
import {
  MediumHeightDivider,
  SmallHeightDivider,
  StyledButton,
  StyledButtonOutlined,
  StyledButtonOutlinedLoading,
  SubTitle,
  Title,
  WpRichTextContainer,
} from "theme/Styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  HideGlobalLoading,
  ShowGlobalLoading,
  UpdateAppSubHeaderTitle,
} from "redux/actions/UiActions";
import { ButtonContainer, ButtonsContainer, Container } from "./styles/RegisterStyles";
import MobileStepper from "@mui/material/MobileStepper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import Select from "components/Select/Select";
import TextField from "components/TextField/TextField";
import RadioButtonGroup from "components/RadioButtonGroup/RadioButtonGroup";
import {
  FormSchema,
  FormSchemaCertificate,
  identificationTypes,
  RegisterSteps,
} from "./RegisterConstants";
import { useFormik } from "formik";
import Typography from "@mui/material/Typography";
import iucService from "services/ApiServerCall";
import CheckBox from "components/CheckBox/CheckBox";
import wpCall from "services/WpServerCall";
import parse from "html-react-parser";
import { useSnackbar } from "notistack";
import PhoneTextField from "components/PhoneTextField/PhoneTextField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  generateVerificationCode,
  getMerchandiseTypes,
  registerCedulaValidation,
  registerEmailValidation,
  verifyCode,
} from "api/Auth";
import { SweetAlert } from "components/SweetAlert/SweetAlert";
import MultiSelect from "components/MultiSelect/MultiSelect";
import FormModal from "components/FormModal/FormModal";
import Swal from "sweetalert2";

import FaceRecognitionValidation from "components/FaceRecognitionValidation";
import { getRekognitionStatus } from "api/FaceRecognitionValidation";

import APPStore from "assets/images/app-icon-ios.png";
import PlayStore from "assets/images/app-icon-android.png";

const ModalContent = ({ changeOpen }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [registerWithCertificate, setRegisterWithCertificate] = useState(false);

  const formik = useFormik({
    initialValues: {
      user: "",
    },
    validationSchema: FormSchemaCertificate,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const handleSubmitForm = (e) => {
    if (
      Object.keys(formik.errors).length > 0 ||
      formik.touched.length < 1 ||
      Object.keys(formik.touched).length < 1
    ) {
      enqueueSnackbar("Debe de ingresar un usuario", { variant: "error" });
    } else {
      get_fiafirma_info();
    }
  };

  const get_fiafirma_info = async () => {
    try {
      dispatch(ShowGlobalLoading("Cargando"));
      let res = await iucService().get("/auth/register/get_info");

      if (res.status !== 200) throw Error("Error al pedir los datos al api IU");

      const redirectURI = window.location.href.includes("localhost")
        ? "https://desservicios.aduanas.gob.do/app/register"
        : window.location.href;

      window.location.href = `${res.data.url}/fortress/oauth2/v1/auth?scope=certificate&state=&redirect_uri=${redirectURI}&response_type=code&client_id=${process.env.REACT_APP_VIAFIRMA_ENVIOREMENT}&user_code=${formik.values.user}`;
    } catch (error) {
      console.error(error);
      dispatch(HideGlobalLoading());
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
    }
  };

  if (!registerWithCertificate)
    return (
      <Grid
        alignItems="flex-start"
        justifyContent="center"
        container
        direction="row"
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={4} sm={8} md={12}>
          <FormLabel>¿Desea registrarse con certificado digital?</FormLabel>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <StyledButton onClick={(e) => setRegisterWithCertificate(true)}>Si</StyledButton>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <StyledButton color="warning" onClick={(e) => changeOpen(false)}>
            Ahora no
          </StyledButton>
        </Grid>
      </Grid>
    );

  return (
    <Grow in={true} style={{ transformOrigin: "100 0 0" }} timeout={1000}>
      <Grid
        alignItems="flex-start"
        justifyContent="center"
        container
        direction="row"
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={4} sm={8} md={12}>
          <FormLabel>
            Si posee un certificado de viafirma puede utilizarlo para agilizar el proceso de
            registro
          </FormLabel>
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <TextField
            id={"user"}
            title="Usuario de viafirma"
            type="text"
            placeholder="Escriba su usuario"
            required
            value={formik.values.user}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.user && Boolean(formik.errors.user)}
            helperText={formik.touched.user && formik.errors.user}
          />
        </Grid>
        <StyledButton onClick={(e) => handleSubmitForm(e)}>CONFIRMAR</StyledButton>
      </Grid>
    </Grow>
  );
};

function Register() {
  const matchesWidth = useMediaQuery("(min-width:768px)");
  const history = useHistory();
  const dispatch = useDispatch();
  const { authenticated } = useSelector((state) => state.authReducer);
  const { enqueueSnackbar } = useSnackbar();
  const [validatedData, setValidatedData] = useState(false);
  const [viafirmaCode, setViafirmaCode] = useState("");

  const [activeRekognition, setActiveRekognition] = useState(true);

  const [registerSteps, setRegisterSteps] = useState(RegisterSteps);
  const stepsLength = registerSteps.length;
  const stepsTitles = useMemo(() => registerSteps.map((step) => step.title), [registerSteps]);
  const [activeStep, setActiveStep] = useState(0);

  // IN THIS CASE, IT IS -2 BECAUSE The last step is only a Message it does not contain a form
  const lastStep = stepsLength - 2 == activeStep;
  const [formSchema, setFormSchema] = useState(FormSchema);
  const [schemaValidation, setSchemaValidation] = useState({});

  const [verificationCode, setVerificationCode] = useState("");
  const [verificationType, setVerificationType] = useState("sms");
  const [isCodeGenerated, setCodeGenerated] = useState(false);
  const [userVerified, setUserVerified] = useState(false);
  const [userRegistered, setUserRegistered] = useState(false);
  const [provincesData, setProvincesData] = useState([]);
  const [municipalitiesData, setMunicipalitiesData] = useState([]);
  const [sectorsData, setSectorsData] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [wordpressContent, setWordpressContent] = useState();
  const [isValidatingCedula, setIsValidatingCedula] = useState(false);
  const [isValidatingEmail, setIsValidatingEmail] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);
  const [fieldsValidated, setFieldsValidated] = useState(true);
  const [modal_certificate_open, setModal_certificate_open] = useState(true);
  const [merchandiseTypesData, setMerchandiseTypesData] = useState([]);
  const [loadingCode, setLoadingCode] = useState(false);

  const [showFaceRecognition, setShowFaceRecognition] = useState(false);

  const getAndSetAllWordPressContent = async () => {
    let data = await wpCall().get("/pages/v1/page/terminos-y-condiciones");
    setWordpressContent(data?.data?.content);
  };

  const handleStepsValidation = (step) => {
    if (registerSteps[step]) {
      let stepElementError = false;
      for (let i = 0; i < registerSteps[step].elements?.length; i++) {
        const element = registerSteps[step].elements[i];
        if (formik.touched[element] && Boolean(formik.errors[element])) {
          stepElementError = true;
        }
      }
      return stepElementError;
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep + 1 === stepsLength ? prevActiveStep : prevActiveStep + 1
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? 1 : prevActiveStep - 1));
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowRepeatedPassword = () => setShowRepeatedPassword(!showRepeatedPassword);

  const formik = useFormik({
    initialValues: {
      email: "",
      emailConfirmation: "",
      password: "",
      password_confirmation: "",
      document_type: "1",
      citizen_id: "",
      name: "",
      first_last_name: "",
      second_last_name: "",
      occupation: "",
      province_id: "",
      municipality_id: "",
      sector_id: "",
      phone: "",
      phone2: "",
      email2: "",
      address: "",

      user_buys_for_dependent: "",
      number_of_dependents: 0,
      user_sells_merchandise: "",
      types_of_merchandise: [],

      first_citizen_question: "",
      first_citizen_answer: "",
      second_citizen_question: "",
      second_citizen_answer: "",
      third_citizen_question: "",
      third_citizen_answer: "",
      fourth_citizen_question: "",
      fourth_citizen_answer: "",
      faceRecognitionIsValid: false,
      termsAndCondition: false,
    },
    validationSchema: schemaValidation,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, actions) => {
      validateStepForm({ values, actions });
    },
  });

  const handleFieldsValidations = (errors) => {
    let fieldsKeys = Object.keys(errors);
    for (let i = 0; i < fieldsKeys.length; i++) {
      let error = errors[fieldsKeys[i]];
      formik.setFieldError(fieldsKeys[i], error[0]);
    }
  };

  function handleMultiSelectChange(e, value) {
    const formattedValue = value.map((item) => ({
      value: item.value || null,
      label: item.label || item,
    }));
    formik.setFieldValue("types_of_merchandise", formattedValue);
  }

  const validateStepForm = ({ values, actions }) => {
    if (lastStep) {
      handleRegister(values);
    } else {
      setActiveStep(activeStep + 1);
      actions?.setTouched({});
      actions?.setSubmitting(false);
    }
  };

  const handleSubmitForm = (e) => {
    let nextStepErrorHasAnError = false;
    Object.keys(formik.errors).forEach((item) => {
      if (registerSteps[activeStep + 1]?.elements?.includes(item)) {
        nextStepErrorHasAnError = true;
      }
    });

    window.scrollTo(0, 0);
    if (Object.keys(formik.errors).length > 0 && !nextStepErrorHasAnError && !fieldsValidated) {
      enqueueSnackbar(
        activeStep == 3
          ? "Debe completar la validación para continuar"
          : "Llene todos los campos requeridos",
        { variant: "error" }
      );
    } else {
      formik.handleSubmit(e);
    }
  };

  useEffect(() => {
    const innerSchema = formSchema[activeStep];
    if (typeof innerSchema === "object") {
      setSchemaValidation(innerSchema.validations);
    }
    return () => {};
  }, [activeStep]);

  const getProvincesData = async () => {
    try {
      let provincesData = await iucService().get("/get/provinces");
      if (provincesData) {
        setProvincesData(
          provincesData.data.provinces?.map((province) => ({
            value: province.bidclasif,
            label: province.ctituloclas,
          }))
        );
      }
      setMunicipalitiesData([]);
      setSectorsData([]);
    } catch (error) {}
  };

  const getRegisterQuestionsData = async () => {
    try {
      let questionsData = await iucService().get("/auth/get/getquestionandanswer");
      if (questionsData) {
        setQuestionsData(
          questionsData.data.payload?.map((question) => ({
            value: question.id,
            label: question.questions,
          }))
        );
      }
    } catch (error) {}
  };

  const getMunicipalitiesData = async (value) => {
    try {
      let municipalitiesData = await iucService().get(`/get/municipalities/${value}`);
      if (municipalitiesData) {
        setMunicipalitiesData(
          municipalitiesData.data.municipalities?.map((municipalities) => ({
            value: municipalities.bidclasif,
            label: municipalities.ctituloclas,
          }))
        );
      }
      setSectorsData([]);
    } catch (error) {}
  };

  const getSectorsData = async (value) => {
    try {
      let sectorsData = await iucService().get(`/get/sectors/${value}`);
      if (sectorsData) {
        setSectorsData(
          sectorsData.data.sectors?.map((sector) => ({
            value: sector.bidclasif,
            label: sector.ctituloclas,
          }))
        );
      }
    } catch (error) {}
  };

  const questionsForShow = (questionNumber) => {
    //questionNumber == 0,1,2
    let selectedOptions = [
      formik.values.first_citizen_question,
      formik.values.second_citizen_question,
      formik.values.third_citizen_question,
      formik.values.fourth_citizen_question,
    ];
    let optionsForRemove = selectedOptions.filter((option, index) => index !== questionNumber);
    return questionsData.filter((question) => !optionsForRemove.includes(question.value + ""));
  };

  const handleSecurityQuestionChange = (e) => {
    formik.setFieldValue(e.target.id, e.target.value);
  };

  const handleRegister = async (formData) => {
    dispatch(ShowGlobalLoading("Cargando"));
    try {
      let response = await iucService().post("/auth/register/portal", {
        document_type: formData.document_type,
        citizen_id: formData.citizen_id,
        email: formData.email,
        name: formData.name,
        first_last_name: formData.first_last_name,
        second_last_name: formData.second_last_name,
        occupation: formData.occupation,
        password: formData.password,
        password_confirmation: formData.password_confirmation,
        province_id: formData.province_id,
        municipality_id: formData.municipality_id,
        sector_id: formData.sector_id,
        phone: formData.phone,
        phone2: formData.phone2,
        email2: formData.email2,
        address: formData.address,
        viafirma_code: viafirmaCode,
        viafirma_user: validatedData?.viafirma?.user_code,
        user_buys_for_dependent: formData.user_buys_for_dependent === "1",
        number_of_dependents: formData.number_of_dependents,
        user_sells_merchandise: formData.user_sells_merchandise === "1",
        types_of_merchandise: formData.types_of_merchandise,
      });
      if (response.data?.success) {
        await iucService().post("/auth/register/questionandanswer", [
          {
            citizen_question: formData.first_citizen_question,
            citizen_answer: formData.first_citizen_answer,
            citizen_id: formData.citizen_id,
          },
          {
            citizen_question: formData.second_citizen_question,
            citizen_answer: formData.second_citizen_answer,
            citizen_id: formData.citizen_id,
          },
          {
            citizen_question: formData.third_citizen_question,
            citizen_answer: formData.third_citizen_answer,
            citizen_id: formData.citizen_id,
          },
          {
            citizen_question: formData.fourth_citizen_question,
            citizen_answer: formData.fourth_citizen_answer,
            citizen_id: formData.citizen_id,
          },
        ]);

        // If the registration is with a certificate, we skip the validation step
        if (response.data?.payload?.validated_certificate) {
          setUserRegistered(true);
          SweetAlert.fire({
            title: "Felicidades!",
            text: "Tu cuenta ha sido creada y activada",
            icon: "success",
            confirmButtonText: "Aceptar",
          }).then(() => {
            history.push("/");
          });
        } else {
          setUserRegistered(true);
          handleNext();
        }
      } else {
        //handle errors from server
        if (typeof response.data?.msg?.error === "object") {
          handleFieldsValidations(response.data?.msg?.error);
          enqueueSnackbar(
            "Hay uno o mas errores con la información introducida. Valídalos e inténtalo nuevamente",
            { variant: "error" }
          );
          window.scrollTo(0, 0);
        } else {
          enqueueSnackbar("Ha sucedido un error inténtelo mas tarde o contacte a soporte", {
            variant: "error",
          });
          window.scrollTo(0, 0);
        }
      }
    } catch (error) {
      //handle local/network errors
    }
    dispatch(HideGlobalLoading());
  };

  const get_code_from_viafirma = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const error = urlParams.get("error");
    const code = urlParams.get("code");
    const codeDB = sessionStorage.getItem(code);

    if (error == "access_denied" && !code) {
      enqueueSnackbar("No se pudo validar su cuenta de viafirma", { variant: "error" });
    }

    if (code && !codeDB) {
      // prevent a request with the same code from being repeated
      try {
        dispatch(ShowGlobalLoading("Cargando"));
        const res = await iucService().post("/auth/register/get_info", { code });
        if (res.status != 200) throw "Error al pedir los datos al api IU";

        if (res.data?.viafirma) {
          setValidatedData(res.data);
        }

        setViafirmaCode(code);
        setModal_certificate_open(false);
        sessionStorage.setItem(code, "");
        dispatch(HideGlobalLoading());
      } catch (error) {
        console.error(error);
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      }
    }
  };

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  async function handleValidatedDataChange() {
    if (validatedData) {
      try {
        const citizen_id = await registerCedulaValidation(
          validatedData.viafirma.certificate.subjectMap.SERIALNUMBER.replace("IDCDO-", "")
        );

        if (!citizen_id.success) {
          await Swal.fire({
            title: "Cédula ya registrada",
            text: "Esta cédula ya se encuentra registrada en el sistema, por favor inicie sesión",
            icon: "error",
          });
          history.push("/app");
          return;
        }

        const email = await registerEmailValidation(validatedData.viafirma.certificate.user.email);

        if (!email.success) {
          await Swal.fire({
            title: "Email ya registrado",
            text: "Este email ya se encuentra registrado en el sistema, por favor inicie sesión",
            icon: "error",
          });
          history.push("/app");
          return;
        }

        formik.setFieldValue("email", validatedData.viafirma.certificate.user.email);
        formik.setFieldValue("emailConfirmation", validatedData.viafirma.certificate.user.email);
        formik.setFieldValue(
          "citizen_id",
          validatedData.viafirma.certificate.subjectMap.SERIALNUMBER.replace("IDCDO-", "")
        );
        formik.setFieldValue("first_last_name", toTitleCase(validatedData.jcl.first_last_name));
        formik.setFieldValue("second_last_name", toTitleCase(validatedData.jcl.second_last_name));
        formik.setFieldValue("name", toTitleCase(validatedData.jcl.first_name));
        formik.setFieldValue(
          "phone",
          validatedData.viafirma.certificate.user.mobile.replace("+1", "")
        );
      } catch (error) {
        console.error(error);
      }
    }
  }

  useLayoutEffect(() => {
    //UPDATE APP HEADER SUBTITLE
    dispatch(UpdateAppSubHeaderTitle("Registro ciudadano")); // TITLE OF SUBHEADER APP
  }, []);

  useEffect(() => {
    handleValidatedDataChange();
  }, [validatedData]);

  useEffect(() => {
    if (authenticated) {
      history.push("/app/myDesk");
      return;
    }

    getProvincesData();
    getRegisterQuestionsData();
    getAndSetAllWordPressContent();
    get_code_from_viafirma();
    getMerchandiseTypes().then((response) => {
      if (!response) return;
      const options = response?.map((type) => ({ value: type.id, label: type.name }));
      setMerchandiseTypesData(options);
    });

    verifiedRekognitionStatus();
  }, []);

  const verifiedRekognitionStatus = async () => {
    try {
      const response = await getRekognitionStatus();

      if (!response?.status) {
        // Removing Face Rekognition Step
        const newFormSchema = formSchema.filter((item) => item.step !== 5);
        const newRegisterSteps = registerSteps.filter((item) => item.id !== 5);

        setActiveRekognition(false);
        setFormSchema(newFormSchema);
        setRegisterSteps(newRegisterSteps);
      } else {
        SweetAlert.fire({
          title: "Información Importante",
          text: "Es indispensable que usted cuente con una cámara web conectada a su computadora ya que el sistema le estará realizando un reconocimiento facial y prueba de vida, para garantizar el registro.",
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
      }
    } catch (error) {}
  };
  //  const isUserInfoInvalid = formik.values.citizen_id && formik.values.name && formik.values.first_last_name && !fieldsValidated;

  const handleUserInfoInvalid = () => {
    setFieldsValidated(false);
    formik.setFieldError("citizen_id", "");
    formik.setFieldError("name", "");
    formik.setFieldError("first_last_name", "");
  };

  const onFaceRecognitionHandleState = () => {
    setShowFaceRecognition(!showFaceRecognition);
  };

  const onFaceRecognitionFinishCallBack = () => {
    onFaceRecognitionHandleState();
    formik.setFieldValue("faceRecognitionIsValid", true);
  };

  // Debounced function to handle formik validation
  useEffect(() => {
    if (formik.values.citizen_id && formik.values.name && formik.values.first_last_name) {
      formik.setFieldTouched("citizen_id", true, true);
      formik.setFieldTouched("name", true, true);
      formik.setFieldTouched("first_last_name", true, true);

      const timeout = setTimeout(async () => {
        try {
          let validateCitizen = await iucService().post(`/validate/user/data`, {
            citizen_id: formik.values.citizen_id,
            name: formik.values.name,
            first_last_name: formik.values.first_last_name,
          });

          if (validateCitizen.data.success) {
            setFieldsValidated(true);
          } else {
            handleUserInfoInvalid();
          }
        } catch (error) {
          handleUserInfoInvalid();
        }
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [formik.values]);

  const validateCitizenID = async (e) => {
    formik.setFieldTouched("citizen_id", true, true);
    if (e.target.value.length > 0) {
      try {
        setIsValidatingCedula(true);
        let response = await registerCedulaValidation(e.target.value);
        setIsValidatingCedula(false);
        if (!response?.success) {
          formik.setFieldError(
            "citizen_id",
            response.msg || "Cédula no válida, introduzca otra cédula"
          );
        }
      } catch (error) {
        setIsValidatingCedula(false);
        formik.setFieldError("citizen_id", "Ha ocurrido un error validando la cédula");
      }
    }
  };

  const validateCitizenEmail = async (e) => {
    formik.setFieldTouched("email", true, true);
    if (e.target.value.length > 0) {
      try {
        setIsValidatingEmail(true);
        let response = await registerEmailValidation(e.target.value);
        setIsValidatingEmail(false);
        if (!response?.success && !response?.exist) {
          formik.setFieldError("email", response.msg || "Correo no valido, introduzca otro correo");
        }
      } catch (error) {
        setIsValidatingEmail(false);
        formik.setFieldError("email", "Ha ocurrido un error validando la cédula");
      }
    }
  };

  const filterOptions = (options, state) => {
    let newOptions = [];
    options.forEach((element) => {
      const found = formik.values.types_of_merchandise.some((x) => x.label === element.label);
      if (!found) {
        if (element.label.replace(",", "").toLowerCase().includes(state.inputValue.toLowerCase()))
          newOptions.push(element);
      }
    });
    return newOptions;
  };

  return (
    <Container>
      {matchesWidth && (
        <Stepper activeStep={activeStep} alternativeLabel>
          {stepsTitles.map((label, index) => {
            const labelProps = {};
            if (handleStepsValidation(index)) {
              labelProps.optional = (
                <Typography sx={{ marginLeft: "45%" }} variant="caption" color="error">
                  Error
                </Typography>
              );
              labelProps.error = true;
            }
            return (
              <Step key={label}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}

      <MediumHeightDivider />
      <SmallHeightDivider />
      {activeStep == 0 ? (
        <Grid
          alignItems="center"
          justifyContent="center"
          container
          direction="row"
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 8, sm: 8, md: 12 }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              title="Correo Electrónico"
              type="email"
              id="email"
              required
              isLoading={isValidatingEmail}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={validateCitizenEmail}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              autoComplete="off"
              disabled={validatedData !== false}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextField
              title="Confirmar correo electrónico"
              type="email"
              id="emailConfirmation"
              required
              value={formik.values.emailConfirmation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.emailConfirmation && Boolean(formik.errors.emailConfirmation)}
              helperText={formik.touched.emailConfirmation && formik.errors.emailConfirmation}
              disabled={validatedData !== false}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextField
              title="Contraseña"
              type={showPassword ? "text" : "password"}
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextField
              title="Confirmar contraseña"
              type={showRepeatedPassword ? "text" : "password"}
              id="password_confirmation"
              value={formik.values.password_confirmation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)
              }
              helperText={
                formik.touched.password_confirmation && formik.errors.password_confirmation
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowRepeatedPassword}>
                    {showRepeatedPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              required
            />
          </Grid>
        </Grid>
      ) : activeStep == 1 ? (
        <Grid
          alignItems="center"
          justifyContent="flex-start"
          container
          direction="row"
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 8, sm: 8, md: 12 }}
        >
          {/* THIS INPUT IS HIDDEN BECAUSE PASSPORT IS NOT SUPPORTED */}
          <Grid item xs={12} sm={12} md={6}>
            <RadioButtonGroup
              title="Tipo de documento"
              id="document_type"
              options={identificationTypes}
              value={formik.values.document_type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.document_type && Boolean(formik.errors.document_type)}
              helperText={formik.touched.document_type && formik.errors.document_type}
              required
              row
              hidden
              disabled={validatedData !== false}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Box sx={{ color: "red" }}>
              {!fieldsValidated && "Los datos introducidos no coinciden con los oficiales"}
            </Box>
          </Grid>

          <Grid item xs={8} sm={4} md={6}>
            <TextField
              title="Número de cédula"
              type="text"
              id="citizen_id"
              required
              isLoading={isValidatingCedula}
              mask={formik.values.document_type === "1" ? "999-9999999-9" : ""}
              unMaskedValue={true}
              value={formik.values.citizen_id}
              onChange={formik.handleChange}
              onBlur={formik.values.document_type === "1" ? validateCitizenID : formik.handleBlur}
              error={
                (formik.touched.citizen_id && Boolean(formik.errors.citizen_id)) || !fieldsValidated
              }
              helperText={formik.touched.citizen_id && formik.errors.citizen_id}
              disabled={validatedData !== false}
            />
          </Grid>

          <Grid item xs={8} sm={4} md={6}>
            <TextField
              title="Nombre(s)"
              type="text"
              id="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={(formik.touched.name && Boolean(formik.errors.name)) || !fieldsValidated}
              helperText={formik.touched.name && formik.errors.name}
              required
              disabled={validatedData !== false}
            />
          </Grid>

          <Grid item xs={8} sm={4} md={6}>
            <TextField
              title="Primer apellido"
              type="text"
              id="first_last_name"
              value={formik.values.first_last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (formik.touched.first_last_name && Boolean(formik.errors.first_last_name)) ||
                !fieldsValidated
              }
              helperText={formik.touched.first_last_name && formik.errors.first_last_name}
              required
              disabled={validatedData !== false}
            />
          </Grid>

          <Grid item xs={8} sm={4} md={6}>
            <TextField
              title="Segundo Apellido"
              type="text"
              id="second_last_name"
              value={formik.values.second_last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.second_last_name && Boolean(formik.errors.second_last_name)}
              helperText={formik.touched.second_last_name && formik.errors.second_last_name}
              //required
              disabled={validatedData !== false}
            />
          </Grid>

          <Grid item xs={8} sm={4} md={6}>
            <Select
              title="Provincia"
              type="text"
              id="province_id"
              data={provincesData}
              value={formik.values.province_id}
              onChange={(e) => {
                formik.setFieldValue("municipality_id", "");
                formik.setFieldValue("sector_id", "");
                formik.handleChange(e);
                getMunicipalitiesData(e.target.value);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.province_id && Boolean(formik.errors.province_id)}
              helperText={formik.touched.province_id && formik.errors.province_id}
              required
            />
          </Grid>

          <Grid item xs={8} sm={4} md={6}>
            <Select
              title="Municipio"
              type="text"
              id="municipality_id"
              disabled={!municipalitiesData.length > 0}
              data={municipalitiesData}
              value={formik.values.municipality_id}
              onChange={(e) => {
                formik.setFieldValue("sector_id", "");
                formik.handleChange(e);
                getSectorsData(e.target.value);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.municipality_id && Boolean(formik.errors.municipality_id)}
              helperText={formik.touched.municipality_id && formik.errors.municipality_id}
              required
            />
          </Grid>

          <Grid item xs={8} sm={4} md={6}>
            <Select
              title="Sector"
              type="text"
              id="sector_id"
              disabled={!sectorsData.length > 0}
              data={sectorsData}
              value={formik.values.sector_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.sector_id && Boolean(formik.errors.sector_id)}
              helperText={formik.touched.sector_id && formik.errors.sector_id}
              required
            />
          </Grid>

          <Grid item xs={8} sm={8} md={6}>
            <TextField
              title="Dirección"
              type="text"
              id="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              required
            />
          </Grid>

          <Grid item xs={8} sm={8} md={6}>
            <TextField
              title="Ocupación"
              type="text"
              id="occupation"
              value={formik.values.occupation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.occupation && Boolean(formik.errors.occupation)}
              helperText={formik.touched.occupation && formik.errors.occupation}
              required
            />
          </Grid>

          <Grid item xs={false} sm={false} md={6} />

          <Grid item xs={8} sm={4} md={6}>
            <PhoneTextField
              title="Número de celular"
              type="text"
              id="phone"
              required
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              disabled={validatedData !== false}
            />
          </Grid>

          <Grid item xs={8} sm={4} md={6}>
            <PhoneTextField
              title="Teléfono oficina"
              type="text"
              id="phone2"
              required
              value={formik.values.phone2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone2 && Boolean(formik.errors.phone2)}
              helperText={formik.touched.phone2 && formik.errors.phone2}
            />
          </Grid>
        </Grid>
      ) : activeStep == 2 ? (
        <Fragment>
          <Title> REGISTRO USUARIOS DE SERVICIOS COURIER</Title>
          <MediumHeightDivider />
          <SubTitle>DATOS DE IMPORTACIÓN</SubTitle>
          <MediumHeightDivider />
          <Grid
            container
            direction="row"
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 8, sm: 8, md: 12 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <RadioButtonGroup
                row
                id={"user_buys_for_dependent"}
                title={"¿Compra usted por internet para dependientes o familiares directos? "}
                value={formik.values.user_buys_for_dependent}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={[
                  { value: "1", label: "Si" },
                  { value: "2", label: "No" },
                ]}
                error={
                  formik.touched.user_buys_for_dependent &&
                  Boolean(formik.errors.user_buys_for_dependent)
                }
                helperText={
                  formik.touched.user_buys_for_dependent && formik.errors.user_buys_for_dependent
                }
                required={true}
              />
            </Grid>

            <Grid item xs={8} sm={8} md={6} hidden={formik.values.user_buys_for_dependent !== "1"}>
              <TextField
                title="Enumere cantidad de dependientes o familiares"
                type="number"
                min={1}
                id="number_of_dependents"
                value={formik.values.number_of_dependents}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.number_of_dependents && Boolean(formik.errors.number_of_dependents)
                }
                helperText={
                  formik.touched.number_of_dependents && formik.errors.number_of_dependents
                }
                required={formik.values.user_buys_for_dependent === "1"}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <RadioButtonGroup
                row
                id={"user_sells_merchandise"}
                title={"¿Compra mercancía con finalidad comercial (propósito de venta o lucro)?"}
                value={formik.values.user_sells_merchandise}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={[
                  { value: "1", label: "Si" },
                  { value: "2", label: "No" },
                ]}
                error={
                  formik.touched.user_sells_merchandise &&
                  Boolean(formik.errors.user_sells_merchandise)
                }
                helperText={
                  formik.touched.user_sells_merchandise && formik.errors.user_sells_merchandise
                }
                required={true}
              />
            </Grid>

            <Grid item xs={8} sm={8} md={6} hidden={formik.values.user_sells_merchandise !== "1"}>
              <MultiSelect
                freeSolo={true}
                title={"Tipos de mercancia que compra"}
                id="types_of_merchandise"
                options={merchandiseTypesData}
                filterOptions={filterOptions}
                value={formik.values.types_of_merchandise}
                onChange={handleMultiSelectChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.types_of_merchandise && Boolean(formik.errors.types_of_merchandise)
                }
                helperText={
                  formik.touched.types_of_merchandise && formik.errors.types_of_merchandise
                }
                required={formik.values.user_sells_merchandise === "1"}
              />
            </Grid>
          </Grid>
        </Fragment>
      ) : activeStep == 3 ? (
        <Grid
          alignItems="center"
          justifyContent="center"
          container
          direction="row"
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 8, sm: 8, md: 12 }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Select
              title="Pregunta de seguridad primaria"
              id="first_citizen_question"
              data={questionsForShow(0)}
              value={formik.values.first_citizen_question}
              onChange={handleSecurityQuestionChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.first_citizen_question &&
                Boolean(formik.errors.first_citizen_question)
              }
              helperText={
                formik.touched.first_citizen_question && formik.errors.first_citizen_question
              }
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextField
              title="Respuesta de seguridad primaria"
              type="text"
              id="first_citizen_answer"
              required
              value={formik.values.first_citizen_answer}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.first_citizen_answer && Boolean(formik.errors.first_citizen_answer)
              }
              helperText={formik.touched.first_citizen_answer && formik.errors.first_citizen_answer}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Select
              title="Pregunta de seguridad secundaria"
              id="second_citizen_question"
              data={questionsForShow(1)}
              value={formik.values.second_citizen_question}
              onChange={handleSecurityQuestionChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.second_citizen_question &&
                Boolean(formik.errors.second_citizen_question)
              }
              helperText={
                formik.touched.second_citizen_question && formik.errors.second_citizen_question
              }
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextField
              title="Respuesta de seguridad secundaria"
              type="text"
              id="second_citizen_answer"
              required
              value={formik.values.second_citizen_answer}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.second_citizen_answer && Boolean(formik.errors.second_citizen_answer)
              }
              helperText={
                formik.touched.second_citizen_answer && formik.errors.second_citizen_answer
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Select
              title="Pregunta de seguridad terciaria"
              id="third_citizen_question"
              data={questionsForShow(2)}
              value={formik.values.third_citizen_question}
              onChange={handleSecurityQuestionChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.third_citizen_question &&
                Boolean(formik.errors.third_citizen_question)
              }
              helperText={
                formik.touched.third_citizen_question && formik.errors.third_citizen_question
              }
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextField
              title="Respuesta de seguridad terciaria"
              type="text"
              id="third_citizen_answer"
              required
              value={formik.values.third_citizen_answer}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.third_citizen_answer && Boolean(formik.errors.third_citizen_answer)
              }
              helperText={formik.touched.third_citizen_answer && formik.errors.third_citizen_answer}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Select
              title="Pregunta de seguridad cuaternaria"
              id="fourth_citizen_question"
              data={questionsForShow(3)}
              value={formik.values.fourth_citizen_question}
              onChange={handleSecurityQuestionChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.fourth_citizen_question &&
                Boolean(formik.errors.fourth_citizen_question)
              }
              helperText={
                formik.touched.fourth_citizen_question && formik.errors.fourth_citizen_question
              }
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextField
              title="Respuesta de seguridad cuaternaria"
              type="text"
              id="fourth_citizen_answer"
              required
              value={formik.values.fourth_citizen_answer}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.fourth_citizen_answer && Boolean(formik.errors.fourth_citizen_answer)
              }
              helperText={
                formik.touched.fourth_citizen_answer && formik.errors.fourth_citizen_answer
              }
            />
          </Grid>
        </Grid>
      ) : activeStep === (activeRekognition ? 4 : false) ? (
        <Fragment>
          {showFaceRecognition && (
            <FaceRecognitionValidation
              citizenID={formik.values.citizen_id}
              onCancelClick={onFaceRecognitionHandleState}
              onFinishCallBack={onFaceRecognitionFinishCallBack}
            />
          )}
          <Grid
            alignItems="center"
            justifyContent="center"
            container
            direction="row"
            spacing={{ xs: 1, md: 1 }}
            columns={{ xs: 8, sm: 8, md: 12 }}
          >
            {formik.values.faceRecognitionIsValid ? (
              <Grid item xs={12} sm={12} md={12}>
                <SubTitle>Ciudadano validado satisfactoriamente, puede continuar.</SubTitle>
              </Grid>
            ) : (
              <Grid item xs={8} sm={8} md={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  direction="row"
                  spacing={{ xs: 1, md: 1 }}
                  columns={{ xs: 8, sm: 8, md: 12 }}
                >
                  <Grid item xs={8} sm={8} md={12}>
                    <SubTitle>
                      Con la finalidad de garantizar la veracidad y seguridad del registro en
                      nuestro sistema, a continuación, estaremos realizando un reconocimiento facial
                      y prueba de vida que valide la correcta identidad del usuario solicitante.
                    </SubTitle>
                    <br />
                    <SubTitle>
                      Nota: Si usted no cuenta con ese dispositivo, favor de descargar la APP DGA RD
                      en una de las tiendas virtuales.
                    </SubTitle>
                  </Grid>
                  <Grid item xs={8} sm={8} md={3.5}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <a href={process.env.REACT_APP_IOS_APP} target="_blank">
                        <img src={APPStore} width={"200px"} alt="App Store" />
                      </a>
                    </div>
                  </Grid>
                  <Grid item xs={8} sm={8} md={3.5} alignItems="center" justifyContent="center">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <a href={process.env.REACT_APP_ANDROID_APP} target="_blank">
                        <img src={PlayStore} width={"200px"} alt="Play Store" />
                      </a>
                    </div>
                  </Grid>
                  <Grid item xs={8} sm={8} md={12}>
                    <br />
                    <StyledButton onClick={onFaceRecognitionHandleState}>
                      Iniciar validación facial
                    </StyledButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Fragment>
      ) : activeStep === (activeRekognition ? 5 : 4) ? (
        <Fragment>
          {/*<Title>TÉRMINOS Y CONDICIONES</Title>*/}

          <div style={{ height: "300px", overflowY: "auto" }}>
            <WpRichTextContainer>{wordpressContent && parse(wordpressContent)}</WpRichTextContainer>
          </div>

          <SmallHeightDivider />
          <CheckBox
            id="termsAndCondition"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.termsAndCondition && Boolean(formik.errors.termsAndCondition)}
            helperText={formik.touched.termsAndCondition && formik.errors.termsAndCondition}
            value={formik.values.termsAndCondition}
            label="Acepto los términos y condiciones de uso y privacidad"
          />
        </Fragment>
      ) : (
        // STEP TO ACTIVATE ACCOUNT
        <Stack spacing={2}>
          <>
            <SubTitle>Activación de cuenta</SubTitle>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Tipo de verificación</FormLabel>
              <RadioGroup
                row={true}
                name={"verification_type"}
                onChange={(e) => setVerificationType(e.target.value)}
              >
                <FormControlLabel value="sms" control={<Radio />} label="Enviar vía SMS" />
                <FormControlLabel value="email" control={<Radio />} label="Enviar vía email" />
              </RadioGroup>
            </FormControl>
            <StyledButtonOutlinedLoading
              variant="outlined"
              loading={loadingCode}
              onClick={() => {
                setLoadingCode(true);
                generateVerificationCode({
                  citizen_id: formik.values.citizen_id,
                  verification_type: verificationType,
                }).then((res) => {
                  if (res?.success) {
                    setLoadingCode(false);
                    setCodeGenerated(true);
                  }
                });
              }}
            >
              {isCodeGenerated ? "Enviar código nuevamente" : "Enviar código"}
            </StyledButtonOutlinedLoading>

            {isCodeGenerated && (
              <>
                <TextField
                  title="Código de activación"
                  type="text"
                  id="verification_code"
                  required
                  onChange={(e) => setVerificationCode(e.target.value)}
                  value={verificationCode}
                />

                <StyledButton
                  onClick={() => {
                    verifyCode(verificationCode).then((res) => {
                      if (res?.success) {
                        setUserVerified(true);
                        SweetAlert.fire({
                          title: "Felicidades!",
                          text: "Tu cuenta ha sido activada",
                          icon: "success",
                          confirmButtonText: "Aceptar",
                        }).then(() => {
                          history.push("/public");
                        });
                      } else {
                        SweetAlert.fire({
                          title: "Error!",
                          text: res?.msg,
                          icon: "error",
                          confirmButtonText: "Aceptar",
                        });
                      }
                    });
                  }}
                >
                  ACTIVAR CUENTA
                </StyledButton>
              </>
            )}
          </>
        </Stack>
      )}

      <MediumHeightDivider />

      <ButtonsContainer>
        <ButtonContainer>
          <StyledButtonOutlined
            disabled={activeStep == 0 || userRegistered}
            onClick={handleBack}
            variant="outlined"
          >
            Retroceder
          </StyledButtonOutlined>
        </ButtonContainer>

        {
          //STEPPER WHEN DEVICE IS MOBILE
          !matchesWidth && (
            <MobileStepper
              variant="dots"
              steps={stepsLength}
              position="static"
              activeStep={activeStep}
            />
          )
        }

        <ButtonContainer>
          {userRegistered && userVerified ? (
            <StyledButton onClick={() => history.push("/public")}>Ir a inicio</StyledButton>
          ) : (
            <StyledButtonOutlined onClick={handleSubmitForm} variant="outlined">
              {lastStep ? "Registrar" : "Continuar"}
            </StyledButtonOutlined>
          )}
        </ButtonContainer>
      </ButtonsContainer>

      <SmallHeightDivider />
      <FormModal
        onClose={() => setModal_certificate_open(false)}
        open={modal_certificate_open}
        title={"REGISTRO CON CERTIFICADO DIGITAL"}
        content={<ModalContent changeOpen={setModal_certificate_open} />}
        maxWidth="sm"
        actions={""}
      />
    </Container>
  );
}

export default Register;
