import { Box, Stack, Typography } from "@mui/material";
import ServiceCardIcon from "assets/icons/pngs/service_card.png";

export default function CardOwner({ serviceDescription }) {
  return (
    <Stack
      direction={"row"}
      spacing={4}
      alignItems={{ xs: "center", md: "flex-end" }}
      display={{ xs: "none", md: "flex" }}
      paddingX={"2%"}
      sx={{
        backgroundColor: "#CCEEEF",
        filter: "drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06))",
      }}
    >
      <Box
        component="img"
        sx={{
          height: { xs: "80px", md: "110px" },
        }}
        alt="Institution Logo"
        src={ServiceCardIcon}
      />
      <Stack direction={"column"} paddingY={"8px"}>
        <Typography color={"#134779"} fontWeight={600} fontSize={{ xs: "1rem", md: "1.1rem" }}>
          OFRECIDO POR:
        </Typography>
        <Stack direction={"column"} color={"#134779"} fontSize={"0.5rem"}>
          <Typography fontSize={{ xs: "0.9rem", md: "0.85rem", lg: "0.97rem" }}>
            {serviceDescription.area.name}
          </Typography>

          {serviceDescription.responsibles.map((responsible) => (
            <div key={responsible.id}>
              <Typography
                fontWeight={600}
                fontSize={{ xs: "0.9rem", md: "0.85rem", lg: "0.97rem" }}
              >
                {responsible?.name}
              </Typography>
              <Typography
                fontSize={{ xs: "0.9rem", md: "0.85rem", lg: "0.97rem" }}
                textTransform={"lowercase"}
              >
                {responsible?.email}
              </Typography>
              <Typography fontSize={{ xs: "0.9rem", md: "0.85rem", lg: "0.97rem" }}>
                Tel: {responsible?.phone}
              </Typography>
            </div>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}
