import { Fragment, memo } from "react";
import COLORS from "theme/Colors";
import { Container, StyledOption } from "./styles/SelectStyles";
import { FieldTitle, Row, StyledTextInput } from "theme/Styles";
import { Autocomplete } from "@mui/material";

function Select({
  id,
  title,
  data,
  placeholder,
  helperText = " ",
  value,
  onChange,
  onBlur,
  error,
  required,
  disabled,
  search = false,
  disableEmptyValue = false,
}) {
  let objectValue = value ? data?.find((item) => item.value === value) : null;

  return (
    <Container>
      <Row>
        <FieldTitle>{title}</FieldTitle>
        <div style={{ width: "5px" }} />
        {required ? (
          <Fragment>
            <FieldTitle style={{ color: COLORS.red }}>*</FieldTitle>
          </Fragment>
        ) : null}
      </Row>
      {search ? (
        <Autocomplete
          disablePortal
          fullWidth={true}
          disableClearable
          select
          unselectable
          noOptionsText="No hay opciones"
          id={id}
          name={id}
          placeholder={placeholder}
          value={objectValue}
          disabled={disabled}
          options={data}
          getOptionLabel={(option) => option.label}
          onChange={(e, newValue) =>
            onChange &&
            onChange({
              target: {
                id: id,
                value: newValue.value,
              },
            })
          }
          ListboxProps={{ style: { margin: 0 } }}
          /* onBlur={(e,newValue) => onBlur && onBlur({
                    target:{
                        id:id,
                        value:newValue.value
                    }
                })}*/
          renderInput={(params) => (
            <StyledTextInput
              {...params}
              InputProps={{ ...params.InputProps, style: { padding: 3 } }}
              helperText={helperText}
              FormHelperTextProps={{ sx: { fontSize: "0.90rem" } }}
              error={error}
            />
          )}
        />
      ) : (
        <StyledTextInput
          fullWidth={true}
          id={id}
          name={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          helperText={helperText}
          FormHelperTextProps={{ sx: { fontSize: "0.90rem" } }}
          error={error}
          select
          disabled={disabled}
          SelectProps={{
            native: true, //for use option tag instead of MenuItem
          }}
        >
          {!disableEmptyValue && <option unselectable={true} />}

          {data?.map(
            (
              option //use an option or StyledOption instead of MenuItem because this tag has an bug
            ) => (
              <StyledOption key={option.value} value={option.value}>
                {option.label}
              </StyledOption>
            )
          )}
        </StyledTextInput>
      )}
    </Container>
  );
}

export default memo(Select);
