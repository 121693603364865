import { Redirect } from "react-router-dom";
import Login from "views/Auth/Login/Login";
import Register from "views/Auth/Register/Register";
import RequestPassword from "views/Auth/RequestPassword/RequestPassword";
import Contact from "views/Contact/Contact";
import Home from "views/Home/Home";
import ListOfServicesPerCategory from "views/ListOfServicesPerCategory/ListOfServicesPerCategory";
import MyDesk from "views/MyDesk/MyDesk";
import { PolicyPrivacy } from "views/PolicyPrivacy/PolicyPrivacy";
import RequestService from "views/RequestService/RequestService";
import ServiceDescription from "views/ServiceDescription/ServiceDescription";
import ServiceRequestedDetails from "views/ServiceRequestedDetails/ServiceRequestedDetails";
import { MyConfiguration } from "views/MyConfiguration/MyConfiguration";
import ActivateAccount from "views/Auth/ActivateAccount/ActivateAccount";
import ValidatePayment from "views/ValidatePayment/ValidatePayment";
import AllServices from "views/AllServices/AllServices";
import Documents from "views/MyDocuments/Documents";
import MyRequests from "views/MyDesk/subViews/myRequests/MyRequests";
import { UseGuide } from "views/UseGuide/UseGuide";
import QR from "views/QR/QR";
import CheckPackage from "views/MyDesk/subViews/checkPackage/checkPackage";
import VehicleTaxCalculator from "views/MyDesk/subViews/vehicleTaxCalculator/VehicleTaxCalculator";
import ChangePassword from "views/Auth/ChangePassword/ChangePassword";
import UserQuestions from "views/Auth/UserQuestions/UserQuestions";
import { Survey } from "views/Survey/Survey";
import RequestedServiceList from "views/RequestedServiceList/RequestedServiceList";
import { AccountDeletion } from "views/AccountDeletion/AccountDeletion";

const routes = [
  //PUT ALL loginRequired:false OBJECTS ROUTES on TOP of an array
  {
    path: "/",
    component: Home,
    exact: true,
    layout: "/public",
    loginRequired: false,
  },
  {
    path: "/login",
    component: Login,
    exact: true,
    layout: "/public",
    loginRequired: false,
  },
  {
    path: "/requestPassword",
    component: RequestPassword,
    exact: true,
    layout: "/public",
    loginRequired: false,
  },
  {
    path: "/changePassword/:citizenID",
    component: ChangePassword,
    exact: true,
    layout: "/public",
    loginRequired: false,
  },

  {
    path: "/register",
    component: Register,
    exact: true,
    layout: "/app",
    loginRequired: false,
  },

  {
    path: "/delete-account",
    component: AccountDeletion,
    exact: true,
    layout: "/public",
    loginRequired: false,
  },

  /*
    {  // FOR SEE ALL FORM FIELDS AND COMPONENTS ONLY DEVELOPMENT
      path: "/TEST-FORM",
      component: ExampleAppForm,
      exact: true,
      layout: "/app",
      loginRequired: false,
    },
 */

  {
    path: "/activateAccount",
    component: ActivateAccount,
    exact: true,
    layout: "/public",
    loginRequired: false,
  },
  {
    path: "/serviceDescription/:serviceID",
    component: ServiceDescription,
    exact: true,
    layout: "/app",
    loginRequired: false,
  },
  {
    path: "/listOfServices/:categoryID",
    component: ListOfServicesPerCategory,
    exact: true,
    layout: "/app",
    loginRequired: false,
  },
  {
    path: "/allServices",
    component: AllServices,
    exact: true,
    layout: "/app",
    loginRequired: false,
  },
  {
    path: "/contact",
    component: Contact,
    exact: true,
    layout: "/app",
    loginRequired: false,
  },

  {
    path: "/UseGuide",
    component: UseGuide,
    exact: true,
    layout: "/app",
    loginRequired: false,
  },

  {
    path: "/qr/:token",
    component: QR,
    exact: true,
    layout: "/app",
    loginRequired: false,
  },

  {
    path: "/policy",
    component: PolicyPrivacy,
    exact: true,
    layout: "/app",
    loginRequired: false,
  },

  {
    path: "/validatePayment/:requestID",
    component: ValidatePayment,
    exact: false,
    layout: "/app",
    loginRequired: false,
  },

  // ==== PRIVATE ROUTES ====
  {
    path: "/myDesk",
    component: MyDesk,
    exact: true,
    layout: "/app",
    loginRequired: true,
  },

  // My Desk Options
  {
    path: "/myDocuments",
    component: Documents,
    exact: true,
    layout: "/app",
    loginRequired: true,
  },
  {
    path: "/checkPackage",
    component: CheckPackage,
    exact: true,
    layout: "/app",
    loginRequired: true,
  },
  {
    path: "/myRequests",
    component: MyRequests,
    exact: true,
    layout: "/app",
    loginRequired: true,
  },
  {
    path: "/myConfiguration",
    component: MyConfiguration,
    exact: true,
    layout: "/app",
    loginRequired: true,
  },
  {
    path: "/vehicleTaxCalculator",
    component: VehicleTaxCalculator,
    exact: true,
    layout: "/app",
    loginRequired: true,
  },
  {
    path: "/requestService/:serviceID",
    component: RequestService,
    exact: true,
    layout: "/app",
    loginRequired: true,
  },
  {
    path: "/serviceRequestedDetails/:requestID",
    component: ServiceRequestedDetails,
    exact: true,
    layout: "/app",
    loginRequired: true,
  },
  {
    path: "/survey/:surveyID/:serviceID?",
    component: Survey,
    exact: true,
    layout: "/app",
    loginRequired: true,
  },
  {
    path: "/requestedServicesList/:servicesStatus",
    component: RequestedServiceList,
    exact: true,
    layout: "/app",
    loginRequired: true,
  },
  {
    path: "/user-questions",
    component: UserQuestions,
    exact: true,
    layout: "/app",
    loginRequired: true,
  },
  {
    path: "*",
    layout: "/public",
    component: () => <Redirect to="/public" />,
  },
  {
    path: "*",
    layout: "/app",
    component: () => <Redirect to="/public" />, //Can be changed for a not login required route inside app
  },
];

export default routes;
