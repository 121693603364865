import { Box, ButtonGroup, Grid, Stack } from "@mui/material";
import { getFilteredServices } from "api/RequestedServiceList";
import { cacheConfig } from "cacheConfig";
import CustomPagination from "components/CustomPagination";
import MyDeskHeader from "components/MyDeskHeader";
import ServiceCard from "components/ServiceCard/ServiceCard";
import { useLayoutEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { UpdateAppSubHeaderTitle } from "redux/actions/UiActions";
import COLORS from "theme/Colors";
import { localDirections } from "./AllServicesConstants";
import { AllServicesSearchBar } from "./components/AllServicesSearchBar";
import {
  ButtonsMenuContainer,
  SectionTitle,
  StyledButtonOutlined,
} from "./styles/AllServicesStyles";

function AllServices() {
  const history = useHistory();
  const dispatch = useDispatch();
  const headerRef = useRef(null);

  const [servicesType, setServicesType] = useState(1);
  const [activeMenu, setActiveMenu] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  const { data: getServiceList } = useQuery(
    ["getServiceList", servicesType, currentPage],
    () => getFilteredServices(servicesType, currentPage),
    {
      staleTime: cacheConfig.staleTimeServicesList,
    }
  );

  useLayoutEffect(() => {
    let localCurrentDirection = localDirections.find((direction) => direction.id === 0);
    dispatch(UpdateAppSubHeaderTitle(localCurrentDirection.title));
  }, [dispatch]);

  const handleChangeMenu = (menuID) => {
    setActiveMenu(menuID);
    setCurrentPage(1);
    setServicesType(menuID);
  };

  const handlePageChange = (value) => {
    setCurrentPage(value);
    headerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <MyDeskHeader header={"Servicios"} canGoBack={true}>
      <Stack
        direction={"column"}
        sx={{
          backgroundColor: COLORS.secondary,
          width: "100%",
          alignItems: "center",
          color: "white",
        }}
        ref={headerRef}
      >
        <Stack
          spacing={4}
          direction={"column"}
          sx={{ width: { xs: "90%", md: "80%" }, paddingY: "10px" }}
        >
          <ButtonsMenuContainer>
            <ButtonGroup size="large" sx={{ borderRadius: "0px" }}>
              <StyledButtonOutlined
                active={activeMenu === 1}
                onClick={() => handleChangeMenu(1)}
                sx={{
                  borderRadius: "0px",
                  fontWeight: "600",
                }}
              >
                Más Consultados
              </StyledButtonOutlined>
              <StyledButtonOutlined
                active={activeMenu === 2}
                onClick={() => handleChangeMenu(2)}
                sx={{
                  borderRadius: "0px",
                  fontWeight: "600",
                }}
              >
                Más Solicitados
              </StyledButtonOutlined>

              <StyledButtonOutlined
                active={activeMenu === 3}
                onClick={() => handleChangeMenu(3)}
                sx={{
                  borderRadius: "0px",
                  fontWeight: "600",
                }}
              >
                Más Valorados
              </StyledButtonOutlined>
            </ButtonGroup>
          </ButtonsMenuContainer>

          <AllServicesSearchBar />
        </Stack>
      </Stack>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          backgroundColor: COLORS.secondary,
          justifyContent: "center",
        }}
      >
        <Stack
          direction={"column"}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#13477A",
            paddingBottom: "20px",
            width: { xs: "90%", md: "80%" },
          }}
        >
          <Grid container={true} spacing={2} marginTop={"20px"}>
            {getServiceList?.data?.map((item) => (
              <Grid item key={item.id} xs={6} md={4} xl={3}>
                <ServiceCard
                  itemId={item.id}
                  title={item.name}
                  type={item.type_id}
                  createdAt={new Date().getDate()}
                  onClick={() => history.push(`/app/serviceDescription/${item.id}`)}
                />
              </Grid>
            ))}
          </Grid>

          <Box sx={{ marginTop: 10 }}>
            {getServiceList?.data?.length > 0 ? (
              <CustomPagination
                count={getServiceList?.last_page}
                onClick={(page) => {
                  handlePageChange(page - 1);
                }}
                value={currentPage}
                variant={"dark"}
              />
            ) : (
              <SectionTitle>No se encontraron registros</SectionTitle>
            )}
          </Box>
        </Stack>
      </Box>
    </MyDeskHeader>
  );
}

export default AllServices;
