import { Box, Stack } from "@mui/material";
import ServiceBtnIcon from "assets/icons/svgs/tabs-icon.svg";
import UserIcon from "assets/icons/svgs/user_icon.svg";

export function RequestButton(props) {
  return (
    <Stack direction={"row"}>
      <Box
        component={"button"}
        sx={{
          all: "unset",
          backgroundColor: "#134779",
          color: "#FFFFFF",
          fontSize: "1.1rem",
          paddingY: "8px",
          paddingX: "10px",
          display: "flex",
          alignItems: "center",
          userSelect: "none",
          width: { xs: "100%", md: "auto" },
          justifyContent: "space-between",

          "&:hover": {
            backgroundColor: "#1393D0",
            cursor: "pointer",
          },

          "&:active": {
            outline: "2px solid #134779",
            outlineOffset: "1px",
          },
        }}
        {...props}
      >
        <div>INICIAR SOLICITUD</div>
        <Box
          sx={{
            height: "30px",
            marginLeft: "10px",
          }}
        >
          <Box
            component="img"
            sx={{
              height: { xs: "30px", md: "30px" },
            }}
            alt="User Logo"
            src={ServiceBtnIcon}
          />
        </Box>
      </Box>
    </Stack>
  );
}

export function LogInButton(props) {
  return (
    <Stack direction={"row"}>
      <Box
        component={"button"}
        sx={{
          all: "unset",
          backgroundColor: "#134779",
          color: "#FFFFFF",
          fontSize: "1.1rem",
          paddingY: "8px",
          paddingX: "10px",
          display: "flex",
          alignItems: "center",
          userSelect: "none",
          width: { xs: "100%", md: "auto" },
          justifyContent: "space-between",

          "&:hover": {
            backgroundColor: "#1393D0",
            cursor: "pointer",
          },

          "&:active": {
            outline: "2px solid #134779",
            outlineOffset: "1px",
          },
        }}
        {...props}
      >
        <div>INICIAR SESIÓN</div>
        <Box
          sx={{
            height: "30px",
            marginLeft: "10px",
          }}
          hidden={false}
        >
          <Box
            component="img"
            sx={{
              height: { xs: "50px", md: "50px" },
            }}
            alt="User Logo"
            src={UserIcon}
          />
        </Box>
      </Box>
    </Stack>
  );
}
