import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { priceVariationToLaborableTime } from "utilities/functions/FormatterUtil";

const stringToCurrency = (string) =>
  new Intl.NumberFormat("es-DO", {
    style: "currency",
    currency: "DOP",
    minimumFractionDigits: 2,
  }).format(string);

const priceVariation = (price, external_pay, sirit_code) => {
  return parseFloat(price) < 1
    ? !!external_pay || !!sirit_code
      ? "Costo Variable"
      : "Gratis"
    : stringToCurrency(price);
};

export default function FaresTable({ price, external_pay, sirit_code }) {
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: { xs: 0, md: 650 },
          marginTop: "10px",
        }}
        size="small"
      >
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "#134779",
              color: "#fff",
            }}
          >
            <TableCell>
              <Typography variant="h6" fontSize={"1rem"} color={"#fff"}>
                Variaciones de la tarifa
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6" fontSize={"1rem"} color={"#fff"}>
                Tiempo de entrega
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6" fontSize={"1rem"} color={"#fff"}>
                Tarifa
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {price.variations.map((variation) => (
            <TableRow
              key={variation.id}
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
                borderBottom: "1px solid #1393D0",
              }}
            >
              <TableCell component="th" scope="row">
                {variation.concept}
              </TableCell>
              <TableCell>
                {priceVariationToLaborableTime(variation.delivery_time) || "Indefinido"}
              </TableCell>
              <TableCell>{priceVariation(variation.price, external_pay, sirit_code)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
