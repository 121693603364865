import { useSnackbar } from "notistack";
import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { paymentValidation } from "api/ValidatePayment";
import GlobalLoading from "components/GlobalLoading/GlobalLoading";
import { SweetAlert } from "components/SweetAlert/SweetAlert";
import { UpdateAppSubHeaderTitle } from "redux/actions/UiActions";
import {
  MediumHeightDivider,
  SmallHeightDivider,
  StyledButton,
  StyledCancelIcon,
  StyledCheckCircleIcon,
  SubTitle,
} from "theme/Styles";
import {
  ButtonContainer,
  ButtonsContainer,
  CenterContainer,
  Container,
} from "./styles/ValidatePaymentStyles";

function ValidatePayment() {
  const history = useHistory();
  const dispatch = useDispatch();
  let { requestID } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [paymentSuccess, setPaymentSuccess] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const handlePaymentValidation = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      let data;
      if (urlParams.has("numAprobacion") && urlParams.has("idAutorizacionPortal")) {
        data = {
          requestId: requestID,
          numAprobacion: urlParams.get("numAprobacion"),
          idAutorizacionPortal: urlParams.get("idAutorizacionPortal"),
        };
        let response = await paymentValidation(data);
        if (response.success) {
          setPaymentSuccess(true);
          setIsLoading(false);
        } else {
          setPaymentSuccess(false);
          setIsLoading(false);
        }
      } else {
        //history.push(`/app/serviceRequestedDetails/${requestID}`)
        setPaymentSuccess(false);
        setIsLoading(false);
      }
    } catch (error) {
      setPaymentSuccess(false);
      enqueueSnackbar("Ha ocurrido un error, contacte a soporte", { variant: "error" });
      //history.push(`/app/serviceRequestedDetails/${requestID}`)
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (paymentSuccess !== true && paymentSuccess !== false) {
      const unloadCallback = (event) => {
        event.preventDefault();
        event.returnValue = "Si cierra esta pestaña el proceso de la solicitud se vera afectado";
        return "Si cierra esta pestaña el proceso de la solicitud se vera afectado";
      };

      const alertUser = (event) => {
        SweetAlert.fire(
          "Advertencia",
          "Si cierra esta pestaña el proceso de la solicitud se vera afectado",
          "warning"
        );
        event.preventDefault();
        event.returnValue = "Si cierra esta pestaña el proceso de la solicitud se vera afectado";
        return "Si cierra esta pestaña el proceso de la solicitud se vera afectado";
      };
      window.addEventListener("beforeunload", alertUser);
      window.addEventListener("unload", unloadCallback);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
        window.removeEventListener("unload", unloadCallback);
      };
    }
  }, [paymentSuccess]);

  useLayoutEffect(() => {
    //UPDATE APP HEADER SUBTITLE
    dispatch(UpdateAppSubHeaderTitle("Verificación de pago")); // TITLE OF SUBHEADER APP
    handlePaymentValidation();
  }, []);

  return (
    <Container>
      <GlobalLoading showByProp={isLoading} textByProp={"Verificando pago"} />
      <SmallHeightDivider />
      {paymentSuccess == undefined ? null : (
        <CenterContainer>
          {paymentSuccess ? <StyledCheckCircleIcon /> : <StyledCancelIcon />}
          <SmallHeightDivider />
          <SubTitle>
            {paymentSuccess
              ? "¡Pago realizado satisfactoriamente! Los cambios se reflejarán en unos minutos."
              : "¡Ha ocurrido un error durante la validación del pago!"}
          </SubTitle>
          <MediumHeightDivider />
          <MediumHeightDivider />
          <ButtonsContainer>
            <ButtonContainer>
              <StyledButton onClick={() => history.push("/app/myDesk")}>
                Ir a mi escritorio
              </StyledButton>
            </ButtonContainer>
            <ButtonContainer>
              <StyledButton
                onClick={() => history.push(`/app/serviceRequestedDetails/${requestID}`)}
              >
                Ver detalle de solicitud
              </StyledButton>
            </ButtonContainer>
          </ButtonsContainer>
        </CenterContainer>
      )}
      <MediumHeightDivider />
      <MediumHeightDivider />
    </Container>
  );
}

export default ValidatePayment;
