import { Autocomplete } from "@mui/material";
import { Container, FieldTitle, Row, StyledTextInput } from "theme/Styles";
import { Fragment } from "react";
import COLORS from "theme/Colors";

export default function MultiSelect({
  id,
  options = [],
  defaultValue,
  placeholder,
  fullWidth,
  title,
  required,
  onChange,
  onBlur,
  error,
  helperText,
  value,
  freeSolo,
  filterOptions,
  ...props
}) {
  return (
    <Container sx={{ width: fullWidth ? "100%" : "auto" }}>
      <Row>
        <FieldTitle>{title} </FieldTitle>
        <div style={{ width: "5px" }} />
        {required ? (
          <Fragment>
            <FieldTitle style={{ color: COLORS.red }}>*</FieldTitle>
          </Fragment>
        ) : null}
      </Row>

      <Autocomplete
        multiple
        freeSolo={freeSolo}
        id={id}
        options={options}
        value={value}
        getOptionLabel={(option) => option.label || option}
        defaultValue={defaultValue}
        filterOptions={filterOptions}
        //filterSelectedOptions={true}
        renderInput={(params) => (
          <StyledTextInput
            {...params}
            error={error}
            helperText={helperText}
            placeholder={placeholder}
          />
        )}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
    </Container>
  );
}
