import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import COLORS from "theme/Colors";

export const BoxContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "uppercase",
});

export const BoxRow = styled(Box)({
  // paddingX: { xs: "20px", md: "190px" },
  display: "flex",

  alignItems: "center",
  textTransform: "uppercase",
  "@media (min-width:320px)": {
    fontSize: "90%",
  },
  "@media (min-width:768px)": {
    width: "90%",
  },
  "@media (min-width:1200px)": {
    width: "80%",
  },
});

export const Title = styled(Typography)({
  textAlign: "left",
  color: COLORS.white,

  padding: "10px",
  "@media (min-width:320px)": {
    fontSize: "22px",
  },
  "@media (min-width:768px)": {
    fontSize: "32px",
  },
  "@media (min-width:1200px)": {
    fontSize: "42px",
  },
});

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "center",
  height: "220px",
  width: "100%",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
});

export const DarkOverlay = styled("div")({
  zIndex: 1,
  backgroundColor: "rgba(0, 0, 0, 0.35)",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  justifyContent: "center",
});

export const Image = styled("img")({
  width: "20%",
  heigth: "5vh",
  "@media (max-width:768px)": {
    width: "170px",
  },
});
