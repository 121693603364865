import { Box, Grid } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import useMediaQuery from "@mui/material/useMediaQuery";
import ImportantInformationModal from "components/ImportantInformationModal/ImportantInformationModal";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { Fragment, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { MediumHeightDivider, SmallHeightDivider, StyledButtonOutlined } from "theme/Styles";
import { ArraysContainSimilarStrings, localToArray } from "utilities/functions/ArrayUtil";
import { localToObject } from "utilities/functions/ObjectUtil";
import { localToString } from "utilities/functions/StringUtil";
import * as yup from "yup";
import { FIELD_TYPES } from "./FormConstants";
import { fieldRuleChanger, getFieldValidation, getFirstStep } from "./FormFunctions";
import { ruleMaskValidation } from "./MaskFunctions";
import RenderField from "./components/RenderField";
import { ButtonContainer, ButtonsContainer, Container } from "./styles/FormStyles";

export default forwardRef(function Form(props, ref) {
  const matchesWidth = useMediaQuery("(min-width:768px)");
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef(null);

  const [disableStepsButtons, setDisableStepsButtons] = useState(false);

  const [localFieldErrors, setLocalFieldErrors] = useState({});
  const [localData, setLocalData] = useState([]);

  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const [firstStep, setFirstStep] = useState(0);
  const [fakeStep, setFakeStep] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [fakeSteps, setFakeSteps] = useState([]);
  const [fakeStepsToShow, setFakeStepsToShow] = useState([]);

  const fakeStepsLength = localToArray(fakeSteps).length;
  const fakeLastStep = fakeStepsLength - 1 === fakeStep;

  const state = {};

  const [appliedRuleList, setAppliedRuleList] = useState([]);
  const [schemaValidation, setSchemaValidation] = useState({});

  const { service_id } = useSelector((state) => state.serviceReducer);

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    setFieldError,
    setErrors,
    setValues,
    validateForm,
  } = useFormik({
    onSubmit: (values, actions) => localDoRequest({ values, actions }),
    validationSchema: yup.object().shape(schemaValidation),
    enableReinitialize: true,
  });

  //Hooks
  useImperativeHandle(ref, () => ({
    saveForm: () => {
      return {
        appliedRuleList: appliedRuleList,
        values: values,
        fakeStep: fakeStep,
        fakeSteps: fakeSteps,
        step: activeStep,
        errors: { ...localToObject(errors), ...localFieldErrors },
        localData: localData,
      };
    },
  }));

  useEffect(() => {
    if (localToArray(localData).length > 0) {
      const notHidden = localData.filter((step) => !step[0].hidden);
      let data = notHidden.map((step, index) => {
        return {
          label: step[0].label,
          index: index,
          realIndexInLocalData: localData.findIndex(
            (localDataStep) => localDataStep[0].key === step[0].key
          ),
        };
      });
      setFakeSteps(data);
      const newSliceValue =
        fakeStep >= data[data.length - 1]?.index + 1 - 6
          ? data[data.length - 1]?.index + 1 - 6
          : fakeStep;
      const slicedArray = data.slice(newSliceValue >= 0 ? newSliceValue : 0, newSliceValue + 6);
      setFakeStepsToShow(slicedArray);
    }
    return () => {};
  }, [localData, fakeStep]);

  useEffect(() => {
    if (localToArray(localData).length > 0) {
      const innerSchema = {};
      localData[activeStep].map((field) => {
        const validator = getFieldValidation(field);
        if (validator) {
          innerSchema[field.fieldKey] = getFieldValidation(field);
        }
      });
      setSchemaValidation(innerSchema);
    }
    return () => {};
  }, [localData, activeStep]);

  useEffect(() => {
    const _data = localToArray(props.data);

    if (_data.length > 0) {
      // setting every key to undefined so formik mark red error
      let innerState = { ...values, ...state };

      let innerRules = [];
      for (const field of props.plainData) {
        if (field.Mask === "50") {
          innerState[field.fieldKey] = service_id;
        }

        if (!innerState[field.fieldKey]) {
          innerState[field.fieldKey] = undefined;
        }
      }

      //initialValues from dynamic form
      let initialState = localToArray(
        props.plainData.find((field) => field.type === FIELD_TYPES.initialValues)?.rules
      );

      // verify if the initial state has values that can be set
      if (initialState.length) {
        for (const row of initialState) {
          const _row = localToArray(JSON.parse(row));
          if (_row.length > 0) {
            for (const field of _row) {
              const originalObject = props.plainData.find(
                (plainField) => plainField.name == field?.name
              );

              switch (field.type) {
                case FIELD_TYPES.checkboxGroup:
                  const checkRule = originalObject?.values.find(
                    (values) => values.value == field?.value
                  )?.rule;
                  if (checkRule) {
                    innerRules.push(checkRule);
                  }
                  innerState[field.name] = [field.value];
                  break;

                case FIELD_TYPES.radioGroup:
                  const radioRule = originalObject?.values.find(
                    (values) => values.value == field?.value
                  )?.rule;
                  if (radioRule) {
                    innerRules.push(radioRule);
                  }
                  innerState[field.name] = field?.value;
                  break;

                case FIELD_TYPES.select:
                  const selectObject = originalObject?.data?.find(
                    (values) => values.value == field?.value
                  );

                  if (selectObject?.rule) {
                    innerRules.push(selectObject?.rule);
                  }

                  innerState[field.name] = selectObject?.value;
                  break;

                default:
                  innerState[field.name] = field?.value;
                  break;
              }
            }
          }
        }
      }

      //Initial rules price
      const rulesPrice = localToArray(
        props.plainData.find((field) => field.type == FIELD_TYPES.rulesPrice)?.data
      ).filter((rulePrice) =>
        [...props.variations, props.initialForm?.variations].includes(rulePrice?.id)
      );

      if (rulesPrice.length) {
        for (const row of rulesPrice) {
          const _row = localToArray(JSON.parse(row?.rules));
          for (const field of _row) {
            const originalObject = props.plainData.find(
              (plainField) => plainField.name == field.name
            );
            switch (field.type) {
              case FIELD_TYPES.checkboxGroup:
                const checkRule = originalObject?.values.find(
                  (values) => values.value == field.name
                )?.rule;
                if (checkRule) {
                  innerRules.push(checkRule);
                }
                innerState[field.name] = [field.value];
                break;
              case FIELD_TYPES.radioGroup:
                const radioRule = originalObject?.values.find(
                  (values) => values.value == field.value
                )?.rule;
                if (radioRule) {
                  innerRules.push(radioRule);
                }
                innerState[field.name] = field.value;
                break;
              case FIELD_TYPES.select:
                const selectObject = originalObject?.data?.find(
                  (values) => values.value == field.value
                );
                if (selectObject?.rule) {
                  innerRules.push(selectObject?.rule);
                }
                innerState[field.name] = selectObject.value;
                break;
              default:
                innerState[field.name] = field.value;
                break;
            }
            innerRules.push(`2:${field.name}`);
          }
        }
      }

      //setting initial rules && values
      const initialFormRules = localToArray(props.initialForm?.rules);

      if (props.isDraft) {
        changeRule(initialFormRules, _data, true);
      } else {
        changeRule(innerRules, _data, true);
      }

      const newState = {
        ...innerState,
        ...localToObject(props.initialForm?.data),
        ...localToObject(props.initialForm?.grid),
      };

      setValues(newState);

      // set as active step the first step that is not hidden
      (() => {
        const _firstStep = getFirstStep(_data) || 0;
        setFirstStep(_firstStep);

        if (props?.isDraft) {
          setActiveStep(props.initialForm?.step || 0);
          setFakeStep(props.initialForm?.fakeStep || 0);
        } else {
          setActiveStep(_firstStep);
          setFakeStep(0);
        }
      })();
    }
    return () => {};
  }, [props.data, props.initialForm, props.variations]);

  function handleValidateForm() {
    validateForm().then((_errors) => {
      if (
        Object.keys(_errors).length !== 0
        /* && touched[Object.keys(_errors)[0]] */
      ) {
        enqueueSnackbar("Llene todos los campos requeridos", {
          variant: "error",
        });
        document
          .getElementById(Object.keys(_errors)[0])
          ?.scrollIntoView({ block: "center", behavior: "smooth" });
      } else {
        window.scrollTo(0, 0);
        props.isSurvey && props.doRequest(values);
      }
    });
  }

  const handleSubmitForm = (e) => {
    handleSubmit(e);
    handleValidateForm();
  };

  const localDoRequest = ({ values, actions }) => {
    //localFieldErrors can be from another step this prevents errors when you push on back button and then you push next
    const formFieldsKeys = localData[activeStep]?.map((a) => a?.name);
    const localFieldErrorKeys = Object.keys(localFieldErrors);

    const currentStepHasLocalError = ArraysContainSimilarStrings(
      formFieldsKeys,
      localFieldErrorKeys
    );

    if (currentStepHasLocalError) {
      for (let i = 0; i < localFieldErrorKeys.length; i++) {
        const key = localFieldErrorKeys[i];
        setFieldTouched(key, true, true);
      }
      document
        .getElementById(currentStepHasLocalError[0])
        ?.scrollIntoView({ block: "center", behavior: "smooth" });
      return;
    }

    if (fakeLastStep && typeof props.doRequest == "function") {
      setShowSubmitModal(true);
    } else {
      props?.handleFormSave();
      let extraStep = 0;
      for (let i = activeStep + 1; i < localData.length; i++) {
        const fields = localData[i];
        if (fields[0].hidden) {
          extraStep++;
        } else {
          break;
        }
      }

      setActiveStep(activeStep + 1 + extraStep);
      setFakeStep(fakeStep + 1);
      actions?.setTouched({});
      actions?.setSubmitting(false);
    }
  };

  const handleModalSubmit = () => {
    setShowSubmitModal(false);
    props.doRequest(values);
  };

  const handleBack = () => {
    setErrors({});
    window.scrollTo(0, 0);
    let extraStep = 0;
    for (let i = activeStep - 1; i > 0; i--) {
      const fields = localData[i];
      if (fields[0].hidden) {
        extraStep++;
      } else {
        break;
      }
    }
    setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? 1 : prevActiveStep - 1 - extraStep));
    setFakeStep(fakeStep - 1);
  };

  function setFieldProps({ field = "", prop = {} }) {
    // console.log("field", field, "prop", prop);
    // let form = localData?.flat()?.find((localField) => localField.name === field);
    // if (!form) return;
    // form = { ...form, ...prop };

    setLocalData((prevLocalData) => {
      let _localData = [...prevLocalData];
      let form = _localData?.flat()?.find((localField) => localField.name === field);
      if (!form) return prevLocalData;
      form = { ...form, ...prop };
      return _localData;
    });
  }

  function getFieldProps(field) {
    return localData?.flat()?.find((localField) => localField.name === field);
  }

  const changeRule = (rule, initialData, initialCall = false, mainField) => {
    if (!initialCall && (!rule || !rule.length)) {
      return;
    } else if (initialCall && (!rule || !rule.length)) {
      setLocalData(localToArray(initialData ?? localData));
      return;
    }

    const ruleList = Array.isArray(rule) ? localToArray(rule) : [localToString(rule)];
    let _localData = localToArray(initialData ?? localData);
    for (let index = 0; index < ruleList.length; index++) {
      if (!ruleList[index] || ruleList[index] === "") continue;

      const ruleSeparated = localToString(ruleList[index]).split(":");
      let ruleAction = localToString(ruleSeparated[0]).split(",");
      let ruleField = localToString(ruleSeparated[1]).split(",");

      // REMOVE ERRORS FROM FIELDS AFFECTED BY RULES
      ruleField.forEach((field) => {
        if (localFieldErrors[field]) {
          let _errors = errors;
          let _localFieldErrors = localFieldErrors;

          delete _errors[field];
          delete _localFieldErrors[field];

          setErrors({ ..._errors });
          setLocalFieldErrors({ ..._localFieldErrors });
        }
      });

      ruleAction = ruleAction.filter((action, index) => {
        if (action === "validation") {
          const [field, mask, maskParam] = ruleField[index]?.split("->");

          ruleField.splice(index, 1);

          const citizenId = localStorage.getItem("user_cedula").replaceAll('"', "");

          const _value = field === "idsolicitante" ? citizenId : values?.[field];

          if (_value && mask)
            // Mock the props as they would be in the RenderField component
            ruleMaskValidation(_value, {
              // fieldKey: field === "idsolicitante" ? mainField : field,
              fieldKey: mainField || field,
              formValues: values,
              Mask: mask,
              MaskParam: maskParam,
              localFieldErrors,
              setFieldValue,
              getFieldProps,
              setDisableStepsButtons,
              setLocalFieldErrors,
            });
          return false;
        }
        return true;
      });

      _localData = _localData.map((step) => {
        return step.map((field) => {
          //Main field modifier
          let _field = fieldRuleChanger({
            field,
            ruleAction,
            ruleField,
            ruleList,
            values,
            setFieldValue,
          });

          // APPLY RULES TO GRID FIELDS
          if (field.type === FIELD_TYPES.grid && localToArray(field.fields).length) {
            _field = {
              ..._field,
              fields: field.fields.map((fieldsField) => {
                return fieldRuleChanger({
                  field: fieldsField,
                  ruleAction,
                  ruleField,
                  ruleList,
                  values,
                  setFieldValue,
                });
              }),
            };
          }

          // REMOVE ERRORS FROM FIELDS AFFECTED BY RULES
          let fieldsWithErrors = localFieldErrors;

          if (ruleField.includes(_field.fieldKey)) {
            if (fieldsWithErrors[_field.fieldKey]) {
              delete errors[_field.fieldKey];
              delete fieldsWithErrors[_field.fieldKey];
            }
          }

          return _field;
        });
      });
    }

    if (initialCall) {
      setAppliedRuleList(ruleList);
    } else {
      setAppliedRuleList([...appliedRuleList, ...ruleList]);
    }

    setLocalData(_localData);
  };

  const LocalRenderField = ({ item, index, formRef }) => {
    return (
      <RenderField
        {...item}
        key={activeStep + "-" + index}
        //key={item.id}
        fieldKey={item.fieldKey}
        index={index}
        value={values[item.fieldKey]}
        fatherValue={values[localToString(item.father_id)]}
        placeholder={item.placeholder}
        error={
          touched[item.fieldKey] &&
          Boolean(errors[item.fieldKey] || localFieldErrors[item.fieldKey])
        }
        helperText={
          touched[item.fieldKey] && (errors[item.fieldKey] || localFieldErrors[item.fieldKey])
        }
        onChange={setFieldValue}
        setFieldValue={setFieldValue}
        setFieldError={setFieldError}
        setFieldProps={setFieldProps}
        getFieldProps={getFieldProps}
        setFieldTouched={setFieldTouched}
        changeRule={changeRule}
        setLocalFieldErrors={setLocalFieldErrors}
        setDisableStepsButtons={setDisableStepsButtons}
        localFieldErrors={localFieldErrors}
        multipleDocuments={props?.multipleDocuments}
        plainData={props.plainData}
        formValues={values}
        citizenId={props.userData?.payload?.citizen_id}
        formRef={formRef}
      />
    );
  };

  return (
    <Container>
      {!props.isSurvey && (
        <>
          <ImportantInformationModal
            open={showSubmitModal}
            CloseButton
            CloseTitle="Cancelar"
            buttonTitle="Confirmar"
            buttonClick={handleModalSubmit}
            onBackDropClick={() => setShowSubmitModal(false)}
            onCloseClick={() => setShowSubmitModal(false)}
            content={
              <Fragment>
                <Box
                  as={"p"}
                  sx={{
                    fontSize: "1.15rem",
                  }}
                >
                  Estimado Contribuyente, favor de validar que todos los datos suministrados
                  incluyendo los documentos anexos son correctos, en caso de tener alguna
                  información errónea, puede volver al paso anterior y corregirla. Al enviar esta
                  solicitud usted es responsable de los datos colocados y los documentos enviados,{" "}
                  <strong>
                    sí el servicio posee costo, el monto pagado no será reembolsado y su solicitud
                    será cancelada.
                  </strong>
                </Box>
                <Box
                  as={"strong"}
                  sx={{
                    fontSize: "1rem",
                  }}
                >
                  YO DECLARO QUE ESTAS INFORMACIONES SON VERÍDICAS Y AL ENVIAR ESTA SOLICITUD
                  AUTORIZO A LA DIRECCIÓN GENERAL DE ADUANAS A CONSULTAR MIS DATOS PERSONALES EN LAS
                  DISTINTAS INSTITUCIONES QUE INTERVIENEN EN LA PRESENTE SOLICITUD.
                </Box>
              </Fragment>
            }
          />
        </>
      )}
      {matchesWidth && (
        <Stepper activeStep={fakeStep} alternativeLabel>
          {fakeStepsToShow?.map((stepData) => {
            const labelProps = {};
            return (
              <Step index={stepData.index} key={stepData.index}>
                <StepLabel {...labelProps}>{stepData.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
      <SmallHeightDivider />
      <SmallHeightDivider />
      <Grid
        alignItems="center"
        justifyContent="flex-start"
        container
        direction="row"
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 3, sm: 6, md: 12 }}
        ref={formRef}
      >
        {localToArray(localData[activeStep]).map((item, index) => {
          return LocalRenderField({ item, index, formRef });
        })}
      </Grid>
      <MediumHeightDivider />
      {matchesWidth && (
        <ButtonsContainer>
          <ButtonContainer>
            <StyledButtonOutlined
              disabled={parseInt(activeStep) === parseInt(firstStep) || disableStepsButtons}
              onClick={handleBack}
              variant="outlined"
            >
              Retroceder
            </StyledButtonOutlined>
          </ButtonContainer>

          <ButtonContainer>
            <StyledButtonOutlined
              onClick={handleSubmitForm}
              disabled={disableStepsButtons}
              variant="outlined"
            >
              {fakeLastStep ? "Enviar" : "Continuar"}
            </StyledButtonOutlined>
          </ButtonContainer>
        </ButtonsContainer>
      )}

      {
        //STEPPER WHEN DEVICE IS MOBILE
        !matchesWidth && (
          <MobileStepper
            variant="progress"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignSelf: "center",
            }}
            LinearProgressProps={{
              style: {
                margin: "5%",
              },
            }}
            steps={fakeStepsLength}
            position="bottom"
            activeStep={activeStep}
            backButton={
              <ButtonContainer>
                <StyledButtonOutlined
                  disabled={activeStep == 0 || disableStepsButtons}
                  onClick={handleBack}
                  variant="outlined"
                >
                  Retroceder
                </StyledButtonOutlined>
              </ButtonContainer>
            }
            nextButton={
              <ButtonContainer>
                <StyledButtonOutlined
                  onClick={handleSubmitForm}
                  disabled={disableStepsButtons}
                  variant="outlined"
                >
                  {fakeLastStep ? "Enviar" : "Continuar"}
                </StyledButtonOutlined>
              </ButtonContainer>
            }
          />
        )
      }
    </Container>
  );
});
