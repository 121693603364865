import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthLogin } from "redux/actions/AuthActions";
import { HideGlobalLoading, ShowGlobalLoading } from "redux/actions/UiActions";
import LocalStorageService from "services/LocalStorageService";
import { getUser } from "api/Auth";

import { useQuery } from "react-query";
import { removeLocalStorageSessionData } from "./AuthFunctions";

function Auth({ children }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { authenticated } = useSelector((state) => state.authReducer);

  const [isValidating, setIsValidating] = useState(true);

  useQuery(["userData"], async () => {
    const TOKEN = LocalStorageService.getItem("token");
    if (TOKEN == null) {
      // I write this 3 times because router cant be mounted before auth validation
      setIsValidating(false);
      throw Error;
    }
    try {
      //Test current token & refreshToken (apiCall internal refresh the token in case of server return token invalid)
      dispatch(ShowGlobalLoading());
      let response = await getUser();
      if (response.success) {
        dispatch(
          AuthLogin({
            authenticated: true,
          })
        );
      } else {
        dispatch(HideGlobalLoading());
        setIsValidating(false);
        removeLocalStorageSessionData();
        history.push("/public");
      }
      return response;
    } catch (error) {}
  });

  useEffect(() => {
    if (authenticated) {
      setIsValidating(false);
      setTimeout(() => {
        dispatch(HideGlobalLoading());
      }, 2000);
    }
  }, [authenticated, dispatch]);

  return <div>{isValidating ? null : children}</div>;
}

export default Auth;
