import { LOGIN_SUCCESS, LOGOUT } from "redux/actions/AuthActions";

const initialState = {
  authenticated: false,
  profileImg: null,
  hasQuestions: false,
  authorizedDevice: false,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: action.payload.authenticated,
        profileImg: action.payload.profileImg,
        hasQuestions: action.payload.hasQuestions,
        authorizedDevice: action.payload.authorizedDevice,
      };
    case LOGOUT:
      return {
        ...state,
        authenticated: false,
        profileImg: null,
        hasQuestions: false,
        authorizedDevice: false,
      };
    default:
      return state;
  }
};

export default AuthReducer;
