export function stringToDominicanCedula(string = "") {
  if (string.length == 11) {
    let firstValues = string.substring(0, 3);
    let centerValues = string.substring(3, 10);
    let lastValue = string.substring(10, 11);
    return firstValues + "-" + centerValues + "-" + lastValue;
  } else {
    return string;
  }
}

export function stringToDominicanPhoneNumber(string = "") {
  if (string.length == 10) {
    let firstValues = string.substring(0, 3);
    let centerValues = string.substring(3, 6);
    let lastValue = string.substring(6, 10);
    return firstValues + "-" + centerValues + "-" + lastValue;
  } else {
    return string;
  }
}

export const priceVariationToLaborableTime = (variation) => {
  if (!variation) return "Indefinido";

  if (variation.months > 0 && variation.days > 0) {
    return `${variation.months} Meses y ${variation.days} días laborables`;
  }
  if (variation.days > 0 && variation.hours > 0) {
    return `${variation.days} Días y ${variation.hours} horas laborables`;
  }
  if (variation.months > 0) {
    return `${variation.months} Meses laborables`;
  }
  if (variation.days > 0) {
    return `${variation.days} Días laborables`;
  }
  if (variation.hours > 0) {
    return `${variation.hours} Horas laborables`;
  }
};

export const hourIn24To12hours = (hour) => {
  //hour format should be 00:00 or 00:00:00
  const _hour = hour.substring(0, 5);
  if (_hour.substring(0, 2) > 12) {
    return `${_hour.substring(0, 2) - 12}${_hour.substring(2, 5)}PM`;
  }
  if (_hour.substring(0, 2) == 12) {
    return `${_hour}PM`;
  }
  if (_hour.substring(0, 2) < 12) {
    return `${_hour}AM`;
  }
};
