import { Box, Divider, Stack } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import { Text } from "components/NewDesign/Text";
import User from "assets/icons/pngs/User.png";
import { ShowGlobalLoading } from "redux/actions/UiActions";
import { removeLocalStorageSessionData } from "auth/AuthFunctions";
import { AuthLogout } from "redux/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import COLORS from "theme/Colors";
import { useEffect, useState } from "react";
import LocalStorageService from "services/LocalStorageService";

function ChevronsLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-chevrons-left"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <polyline points="11 7 6 12 11 17"></polyline>
      <polyline points="17 7 12 12 17 17"></polyline>
    </svg>
  );
}

function format(value = "", pattern = "") {
  if (!value) return pattern;
  let i = 0;
  const v = value.toString();
  return pattern.replace(/#/g, (_) => v[i++]);
}

export default function MyDeskHeader({ header, canGoBack = false, reference = null, children }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { authenticated } = useSelector((state) => state.authReducer);
  const userData = queryClient.getQueryData(["userData"]);
  const citizenId = userData?.payload?.citizen_id;
  const [userImage, setUserImage] = useState("");

  const HandleLogOut = () => {
    dispatch(ShowGlobalLoading("Cerrando sesión"));
    setTimeout(() => {
      removeLocalStorageSessionData();
      dispatch(AuthLogout());
      window.location.reload();
    }, 1500);
  };

  useEffect(() => {
    setUserImage(LocalStorageService.getItem("profile_img"));
  }, []);

  if (!authenticated)
    return (
      <Stack direction={"column"} paddingTop={"20px"} width={"100%"} bgcolor={COLORS.secondary}>
        {children}
      </Stack>
    );

  return (
    <Stack direction={"column"} width={"100%"} alignItems={"center"} ref={reference}>
      <Stack
        direction={"row"}
        justifyContent={"center"}
        sx={{
          backgroundColor: "#134779",
          width: "100%",
          paddingTop: "20px",
          paddingBottom: "20px",
          color: "white",
        }}
      >
        <Stack direction={"column"} sx={{ width: "80%" }} spacing={2}>
          <Stack direction={"row"} spacing={2} justifyContent={"flex-end"} sx={{ width: "100%" }}>
            <Text
              component={Link}
              sx={{
                all: "unset",
                fontSize: "0.8rem",
                letterSpacing: "1px",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              to={"/app/myConfiguration"}
            >
              EDITAR
            </Text>
            <Text
              sx={{
                fontSize: "0.8rem",
                letterSpacing: "1px",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onClick={HandleLogOut}
            >
              CERRAR SESIÓN
            </Text>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignContent={"bottom"}
            alignItems={"end"}
          >
            <Stack direction={"column"} width={"60%"} spacing={2}>
              <Stack
                //component={Link}
                direction={"row"}
                alignContent={"center"}
                alignItems={"center"}
                display={canGoBack ? "flex" : "none"}
                sx={{
                  color: "white",
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={() => history.goBack()}
              >
                <ChevronsLeft />
                <Text
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: "lighter",
                  }}
                >
                  Regresar
                </Text>
              </Stack>

              <Text
                sx={{
                  fontSize: { xs: "1.1rem", md: "1.3rem", lg: "2rem" },
                  fontWeight: 600,
                  textTransform: "uppercase",
                }}
              >
                {header}
              </Text>
            </Stack>

            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              alignItems={{ xs: "flex-end", md: "center" }}
            >
              <Stack direction={"column"}>
                <Text
                  sx={{
                    fontSize: { xs: "0.9rem", md: "0.9rem", lg: "1.2rem" },
                    fontWeight: 600,
                    textTransform: "uppercase",
                    whiteSpace: "pre-line",
                  }}
                >
                  {userData?.payload?.name + " " + userData?.payload?.first_last_name}
                </Text>
                <Text
                  sx={{
                    letterSpacing: "1px",
                    fontSize: { xs: "0.7rem", md: "1rem" },
                  }}
                >
                  CED {format(citizenId, "###-#######-#")}
                </Text>
              </Stack>

              <Box
                sx={{
                  backgroundColor: "transparent",
                  width: "70px",
                  height: "70px",
                  //borderRadius: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component={"img"}
                  src={userImage ? userImage : User}
                  sx={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                    border: "1px solid white",
                    borderRadius: !userImage ? "100%" : "100%",
                    aspectRatio: "1/1",
                    objectFit: "cover",
                    padding: "1px",
                  }}
                />
              </Box>
            </Stack>
          </Stack>

          <Divider
            variant="middle"
            sx={{
              height: "1px",
              border: 0,
              width: "100%",
              marginTop: "20px",
              backgroundImage:
                "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 255.75), rgba(0, 0, 0, 0))",
            }}
          />
        </Stack>
      </Stack>

      {/* Children */}
      {children}
    </Stack>
  );
}
