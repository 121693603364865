export const SERVICE_ID = "SERVICE_ID";
//export const LOGOUT = "LOGOUT";

export const ServiceID = (payload) => {
  return {
    type: SERVICE_ID,
    payload: payload,
  };
};
//
// export const AuthLogout = () => {
//   return {
//     type: LOGOUT,
//   }
// }
