import backOfficeService from "services/ApiServiceCall";

export const cedulaValidationService = async (cedula) => {
  const { data } = await backOfficeService().get(`/validate/${cedula}`);
  return data;
};

export const rncValidationService = async (rnc) => {
  const { data } = await backOfficeService().get(`/get/contribuyente/${rnc}`);
  return data;
};

export const passportValidationService = async (passport) => {
  const { data } = await backOfficeService().get(`/siga/economy/validate/${passport}`);
  return data;
};

export const companyValidationService = async (companyId, profileType) => {
  const { data } = await backOfficeService().get(`/siga/company/profile/${companyId}`);
  let type;
  if (profileType === "1") type = "Importador";
  if (profileType === "2") type = "Exportador";
  return {
    type: type || "Importador / Exportador",
    existSIGA: !profileType
      ? data?.company_type?.["Importador"] || data?.company_type?.["Exportador"]
      : data?.company_type?.[type],
    razon_social: data?.razon_social,
  };
};

export const companyValidationServiceDGII = async (companyId, profileType) => {
  const { data } = await backOfficeService().get(`get/company/exist/${companyId}`);
  let type;
  if (profileType === "1") type = "Importador";
  if (profileType === "2") type = "Exportador";
  return {
    type: type || "Importador / Exportador",
    existSIGA: !profileType
      ? data?.exist_siga?.company_type?.["Importador"] ||
        data?.exist_siga?.company_type?.["Exportador"]
      : data?.exist_siga?.company_type?.[type],
    existDGII: data?.exist_dgii,
    razon_social: data?.razon_social,
  };
};

export const companyProfileValidation = async (id, profileType, subProfileType = "") => {
  const { data } = await backOfficeService().get(
    `/siga/company/profile-state/${id}/${profileType}/${subProfileType}`
  );
  return data;
};

export const citizenProfileValidation = async (id, profileType, subProfileType = "") => {
  const { data } = await backOfficeService().get(
    `/siga/profile-state/${id}/${profileType}/${subProfileType}`
  );
  return data;
};

export const validateOldInvoice = async (invoice, citizenId) => {
  const { data } = await backOfficeService().get(`get/dealer/request/${invoice}/${citizenId}`);
  return data;
};

export const manifestValidation = async (manifestNumber, blNumber) => {
  const { data } = await backOfficeService().post(`/manifest/validate`, {
    manifestNumber,
    blNumber,
  });
  return data;
};

export const declarationStatusValidation = async (declarationNo, status = "") => {
  const { data } = await backOfficeService().post(`/declaration/status`, {
    declarationNo,
    status,
  });
  return data;
};

export const registroMercantilStatus = async (identification, municipio) => {
  const { data } = await backOfficeService().post(`/registro-mercantil/expiration`, {
    identification,
    municipio,
  });
  return data;
};

export const customBrokerValidation = async ({ documentNo, documentType }) => {
  const { data } = await backOfficeService().post(`/siga/get/customs-broker`, {
    documentNo,
    documentType,
  });
  return data;
};
