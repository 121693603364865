const {
  cedulaValidationService,
  rncValidationService,
  passportValidationService,
  companyValidationService,
  companyValidationServiceDGII,
  validateOldInvoice,
  companyProfileValidation,
  citizenProfileValidation,
  manifestValidation,
  declarationStatusValidation,
  registroMercantilStatus,
  customBrokerValidation,
} = require("api/RenderField");
const { MASK_LIST } = require("./FormConstants");
const { handleMaskValidation } = require("./FormFunctions");
const { setSocialReason, setValueAndBlockField } = require("./components/RenderFieldFunctions");
const { isBefore, subYears } = require("date-fns");

export async function fieldMaskValidation(value, props) {
  const params = String(props?.MaskParam || "").split(/[~&]/);

  switch (MASK_LIST[props?.Mask || ""]) {
    // VALIDATE CÉDULA
    case MASK_LIST[0]:
      await handleMaskValidation({
        validationFunction: cedulaValidationService(value),
        condition: (response) => response?.exist,
        invalidMessage: "Cédula no válida, introduzca otra cédula.",
        props,
      });
      break;

    // VALIDATE RNC
    case MASK_LIST[1]:
      await handleMaskValidation({
        validationFunction: rncValidationService(value),
        condition: (response) => response?.success,
        invalidMessage: (response) => response?.message,
        props,
      }).then((response) => setSocialReason(props, response, params[0]));
      break;

    // PASSPORT DON'T EXIST IN SIGA
    case MASK_LIST[14]:
      await handleMaskValidation({
        validationFunction: passportValidationService(value),
        condition: (response) => response?.exist === false,
        invalidMessage: "Este pasaporte ya existe en SIGA. Favor colocar un pasaporte valido.",
        props,
      });
      break;

    // COMPANY DOES EXIST IN SIGA
    case MASK_LIST[15]:
      await handleMaskValidation({
        validationFunction: companyValidationService(value),
        condition: (response) => response?.existSIGA === true,
        invalidMessage: (response) =>
          `Esta compañía no se encuentra registrada como ${response?.type}.`,
        props,
      });
      break;

    // PASSPORT DOES EXIST IN SIGA
    case MASK_LIST[16]:
      await handleMaskValidation({
        validationFunction: passportValidationService(value),
        condition: (response) => response?.exist === true,
        invalidMessage: "Este pasaporte no existe en SIGA. Favor colocar un pasaporte valido.",
        props,
      });
      break;

    // COMPANY DON'T EXIST IN SIGA
    case MASK_LIST[17]:
      {
        const profileType = props?.formValues?.["perfilsolicita"]; // Importador o Exportador
        await handleMaskValidation({
          validationFunction: companyValidationService(value, profileType),
          condition: (response) => response?.existSIGA === false,
          invalidMessage: (response) =>
            `Esta compañía ya se encuentra registrada como ${response?.type}.`,
          props,
        });
      }
      break;

    // COMPANY DOES EXIST IN SIGA, WITH RNC MASK
    case MASK_LIST[18]:
      {
        const profileType = props?.formValues?.["perfilsolicita"]; // Importador o Exportador
        await handleMaskValidation({
          validationFunction: companyValidationServiceDGII(value, profileType),
          condition: (response) => response?.existSIGA && response?.existDGII,
          invalidMessage: (response) => {
            if (response.existDGII === false)
              return "RNC no válido, por favor inténtelo nuevamente.";
            if (response.existSIGA === false)
              return `Esta compañía no se encuentra registrada como ${response?.type}.`;
          },
          props,
        }).then((response) => setSocialReason(props, response, params[0]));
      }
      break;

    // COMPANY DON'T EXIST IN SIGA, WITH RNC MASK
    case MASK_LIST[19]:
      {
        const profileType = props?.formValues?.["perfilsolicita"]; // Importador o Exportador
        await handleMaskValidation({
          validationFunction: companyValidationServiceDGII(value, profileType),
          condition: (response) => !response?.existSIGA && response?.existDGII,
          invalidMessage: (response) => {
            if (response.existDGII === false)
              return "RNC no válido, por favor inténtelo nuevamente.";
            if (response.existSIGA === true)
              return `Esta compañía ya se encuentra registrada como ${response?.type}.`;
          },
          props,
        }).then((response) => setSocialReason(props, response, params[0]));
      }
      break;

    case MASK_LIST[20]:
      {
        const hasMoreThanThreeYears = (response) =>
          isBefore(new Date(response?.payload?.["FECHA_INSCRIPCION"]), subYears(new Date(), 3));

        await handleMaskValidation({
          validationFunction: rncValidationService(value),
          condition: (response) => hasMoreThanThreeYears(response) && response?.success,
          invalidMessage: (response) => {
            if (!response?.success) return "RNC no válido, por favor inténtelo nuevamente.";
            if (!hasMoreThanThreeYears(response))
              return "La empresa debe tener más de 3 años de constituida.";
          },
          props,
        }).then((response) => {
          setSocialReason(props, response);
          const constitutionDateField = props?.MaskParam || "fechaconstitucion";

          if (!response?.valid) {
            props?.setFieldValue(constitutionDateField, new Date());
            return;
          }

          if (constitutionDateField in props?.formValues) {
            props?.setFieldValue(
              constitutionDateField,
              new Date(response?.payload?.["FECHA_INSCRIPCION"])
            );
          } else {
            console.error("Campo de fecha constitución no existe en el formulario");
          }
        });
      }
      break;

    // INVOICE VALIDATION - REGISTRO DE DEALER
    case MASK_LIST[21]:
      await handleMaskValidation({
        validationFunction: validateOldInvoice(value, props?.citizenId),
        condition: (response) => response?.success,
        invalidMessage: (response) => response?.data,
        props,
      }).then((response) => {
        if (response?.valid) {
          const { rnc1, rnc2 } = response.data;

          Object.entries(rnc1).forEach(([key, value]) => {
            setValueAndBlockField(props, key + "1", value);
          });

          Object.entries(rnc2).forEach(([key, value]) => {
            setValueAndBlockField(props, key + "2", value);
          });
        }
      });
      break;

    // COMPANY PROFILE VALIDATION
    case MASK_LIST[22]:
      if (!params) return;

      await handleMaskValidation({
        validationFunction: companyProfileValidation(value, params[0], params[1]),
        condition: (response) =>
          !!response?.state && response?.state.toString().toUpperCase() === "USADO",
        invalidMessage: (response) =>
          response?.message ||
          "Su solicitud no puede ser iniciada, favor comunicarse con la Unidad de Registro para más información.",
        props,
      }).then((response) => {
        const socialReasonField = params[2] || props?.getFieldProps("rnc2").razonsocial_field;
        setSocialReason(props, response, socialReasonField);

        if (response.valid) {
          setValueAndBlockField(props, "rnc2", response?.rnc);
        } else {
          props?.setFieldValue("rnc2", "");
        }
      });

      break;

    // CITIZEN PROFILE VALIDATION
    case MASK_LIST[23]:
      if (!params) return;

      await handleMaskValidation({
        validationFunction: citizenProfileValidation(value, params[0], params[1]),
        condition: (response) =>
          !!response?.state && response?.state.toString().toUpperCase() === "USADO",
        invalidMessage: (response) =>
          response?.message ||
          "Su solicitud no puede ser iniciada, favor comunicarse con la Unidad de Registro para más información.",
        props,
      }).then((response) => {
        const socialReasonField = params[2] || props?.getFieldProps("rnc2")?.razonsocial_field;
        setSocialReason(props, response, socialReasonField);

        if (response.valid) {
          setValueAndBlockField(props, "rnc2", response?.rnc);
        } else {
          props?.setFieldValue("rnc2", "");
        }
      });

      break;
    case MASK_LIST[24]:
      await handleMaskValidation({
        validationFunction: manifestValidation(value, params[0]),
        condition: (response) => response?.success,
        invalidMessage: "No. Manifiesto no válido, por favor inténtelo nuevamente.",
        errorMessage: "No. Manifiesto no válido, por favor inténtelo nuevamente.",
        props,
      });
      break;
    case MASK_LIST[25]:
      {
        const manifestNo = props?.formValues?.[params[0]];
        await handleMaskValidation({
          validationFunction: manifestValidation(manifestNo, value),
          condition: (response) => response?.success,
          invalidMessage:
            "No. de BL no es válido o no corresponde al No. de manifiesto, por favor inténtelo nuevamente.",
          errorMessage:
            "No. de BL no es válido o no corresponde al No. de manifiesto, por favor inténtelo nuevamente.",
          props,
        });
      }
      break;
    case MASK_LIST[26]:
      await handleMaskValidation({
        validationFunction: declarationStatusValidation(value, params[0]),
        condition: (response) => response?.success,
        invalidMessage: (response) =>
          response.message || "No. de Declaración no válido, por favor inténtelo nuevamente.",
        errorMessage: (response) =>
          response.message || "No. de Declaración no válido, por favor inténtelo nuevamente.",
        props,
      });
      break;

    case MASK_LIST[27]: {
      // params[0] should contain the field name of the municipio's select
      const municipioFieldName = params[0];
      if (!municipioFieldName) break;

      // get the select's current value
      const municipioOID = props?.formValues?.[municipioFieldName];
      const validOIDs = ["DO01", "DO25", "DO32"];
      if (!municipioOID || !validOIDs.includes(municipioOID)) break;

      // use the field's props to get the field's values
      const field = props?.getFieldProps(municipioFieldName);
      if (!field) break;

      // find the municipio's name between the field's values using municipioOID as reference
      const municipioName = field.values.find((item) => item.value === municipioOID)?.label;
      if (!municipioName) break;

      await handleMaskValidation({
        validationFunction: registroMercantilStatus(value, municipioName.toUpperCase()),
        condition: (response) => response?.success && !response?.expired,
        invalidMessage: "No. de Registro Mercantil no válido, por favor inténtelo nuevamente.",
        errorMessage: "No. de Registro Mercantil no válido, por favor inténtelo nuevamente.",
        props,
      });
      break;
    }

    case MASK_LIST[28]: {
      await handleMaskValidation({
        validationFunction: customBrokerValidation({
          documentNo: value,
          documentType: params[0] || "SA13-CED",
        }),
        condition: (response) => response?.success,
        invalidMessage: (response) => response.message,
        errorMessage: (response) => response.message,
        props,
      }).then((response) => {
        if (response?.success) {
          props?.setFieldValue?.(params[1], response?.message.documentNumber);
          props?.setFieldValue?.(params[2], response?.message.name);
        } else {
          props?.setFieldValue?.(params[1], "");
          props?.setFieldValue?.(params[2], "");
        }
      });
      break;
    }

    default:
      break;
  }
}

export async function ruleMaskValidation(value, props) {
  await fieldMaskValidation(value, props);
}
