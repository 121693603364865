import React from "react";
import {
  BodyText,
  ColorHeader,
  Container,
  ContainerIcon,
  Image,
  RowContainer,
} from "./styles/GuideCardStyles";

function GuideCard({ iconName, color, title, subTitle }) {
  return (
    <Container>
      <ContainerIcon>
        {iconName === null ? (
          <>
            <div style={{ width: "60%" }}>
              <ColorHeader background={color} />
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Image src={iconName} />
          </div>
        )}
      </ContainerIcon>
      <RowContainer>
        <div
          style={{
            height: "100%",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          <BodyText>
            <strong> {title} </strong> {subTitle}
          </BodyText>
        </div>
      </RowContainer>
    </Container>
  );
}

export default GuideCard;
