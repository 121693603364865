import { Grid } from "@mui/material";
import FormModal from "components/FormModal/FormModal";
import { useFormik } from "formik";
import { memo, useEffect, useState } from "react";
import { StyledButton } from "theme/Styles";
import { localToArray } from "utilities/functions/ArrayUtil";
import { localToObject } from "utilities/functions/ObjectUtil";
import { localToString } from "utilities/functions/StringUtil";
import RenderField from "views/RequestService/components/Form/components/RenderField";
import * as yup from "yup";
import { getFieldValidation } from "../FormFunctions";

function ModalForm(props) {
  const [state, setState] = useState({});
  const [schemaValidation, setSchemaValidation] = useState({});
  const listIndex = localToObject(props.isVisible).listIndex;
  const isModifying = listIndex !== undefined;

  function transformGridValues(values) {
    let _values = {};

    Object.entries(values).forEach(([key, value]) => {
      const isHidden = !!props.fields.find((x) => x.name === key)?.hidden;
      if (!isHidden) {
        _values = {
          ..._values,
          [key]: value,
        };
      }
    });

    return _values;
  }

  const { errors, setFieldTouched, setFieldValue, values, touched, handleSubmit, resetForm } =
    useFormik({
      initialValues: state,
      onSubmit: (values, actions) => localDoRequest({ values, actions }),
      validationSchema: yup.object().shape(schemaValidation),
      enableReinitialize: true,
    });

  useEffect(() => {
    if (props.isVisible && localToArray(props.fields).length > 0) {
      const innerState = { ...state, ...localToObject(props.isVisible) };
      const innerSchema = {};
      props.fields.map((field) => {
        const validator = getFieldValidation(field);
        if (validator) {
          innerSchema[field.fieldKey] = getFieldValidation(field);
        }
        if (!innerState[field.fieldKey]) {
          innerState[field.fieldKey] = undefined;
        }
      });
      setState(innerState);
      setSchemaValidation(innerSchema);
    }
    return () => {};
  }, [props.isVisible, props.fields]);

  useEffect(() => {
    if (!props.isVisible) {
      resetForm();
      setState({});
    }
    return () => {};
  }, [props.isVisible]);

  const localDoRequest = ({ values }) => {
    const _values = transformGridValues(values);
    props.doRequest({ values: _values, fatherKey: props.fatherKey, listIndex });
  };

  function getFatherValue(item) {
    const fatherName = localToString(item.father_id);
    return values[localToString(item.father_id)] || props.formValues[fatherName];
  }

  const LocalRenderGrid = ({ item }) => {
    return (
      <RenderField
        {...item}
        key={item.key}
        fieldKey={item.fieldKey}
        value={values[item.fieldKey]}
        fatherValue={getFatherValue(item)}
        placeholder={item.placeholder}
        error={touched[item.fieldKey] && Boolean(errors[item.fieldKey])}
        helperText={touched[item.fieldKey] && errors[item.fieldKey]}
        onChange={setFieldValue}
        setFieldTouched={setFieldTouched}
        changeRule={props.changeRule}
        formValues={props?.formValues}
      />
    );
  };

  const content = () => {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        direction="row"
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 3, sm: 6, md: 12 }}
        sx={{ overflowY: "hidden" }}
      >
        {props.fields.map((item, index) => LocalRenderGrid({ item, index }))}
      </Grid>
    );
  };

  const actions = () => {
    return (
      <StyledButton onClick={handleSubmit}>{isModifying ? "Guardar" : "Confirmar"}</StyledButton>
    );
  };

  return (
    <FormModal
      fullWidth
      maxWidth="xl"
      open={props.isVisible}
      onClose={() => props.onVisibleChange(false)}
      sx={{ overflowY: "visible", backgroundColor: "red" }}
      content={content()}
      actions={actions()}
    />
  );
}

export default memo(ModalForm);
