import { Grid } from "@mui/material";
import TextInformation from "components/TextInformation/TextInformation";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  CardBodyText,
  CardBodyTitle,
  CardContainer,
  CardTextContainer,
  SmallHeightDivider,
} from "theme/Styles";
import { Container } from "views/ServiceRequestedDetails/styles/ServiceRequestedDetailsStyles";
import ShowPin from "./components/PinPayment/ShowPin";
import SiritPayment from "./components/SiritPayment";

export default function Payment() {
  let { requestID } = useParams();
  const queryClient = useQueryClient();
  const requestData = queryClient.getQueryData(["serviceRequestedDetail", requestID]);
  const userData = queryClient.getQueryData(["userData"]);

  const [paymentAmount, setPaymentAmount] = useState();

  useEffect(() => {
    if (requestData?.request !== undefined) {
      if (requestData.request.request_actions_id === 7) {
        setPaymentAmount(requestData.request.payment.payment_amount);
        return;
      }

      if (requestData.request.payment.payment_status === "PENDIENTE") {
        setPaymentAmount(requestData.request.payment.payment_amount);
        return;
      }

      if (requestData.request.payment.payment_status === "GENERADO") {
        setPaymentAmount(requestData.request.payment.payment_amount);
      }
    }
  }, [requestData]);

  return (
    <Container>
      {requestData.request.payment.payment_status === "PENDIENTE" ? (
        <>
          <TextInformation
            title="Método de pago"
            rightTitle={paymentAmount ? `Monto a pagar DOP $${paymentAmount}` : null}
          />
          <SmallHeightDivider />
          <Grid
            alignSelf="center"
            justifyContent="space-evenly"
            container
            direction="row"
            spacing={{ xs: 1, md: 1 }}
            columns={{ xs: 6, sm: 8, md: 12 }}
          >
            {requestData.request.service.sirit_code !== null && (
              <SiritPayment
                requestData={requestData}
                userData={userData}
                paymentAmount={paymentAmount}
              />
            )}

            {requestData.request.pin != null && <ShowPin requestData={requestData} />}

            {/* {requestData.request.invoice_id && <VerifyPayment requestData={requestData} />} */}
          </Grid>
          <SmallHeightDivider />
        </>
      ) : null}

      {requestData?.request?.payment?.payment_status === "GENERADO" && (
        <TextInformation title={`Su pago de $${paymentAmount} está en proceso de validación.`} />
      )}

      <SmallHeightDivider />

      {requestData?.priceRequest?.length > 0 && (
        <>
          <TextInformation title="Mis pagos" />
          <SmallHeightDivider />

          {requestData?.priceRequest?.map((payment) => (
            <CardContainer>
              <CardTextContainer>
                <Grid
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  container
                  direction="row"
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 6, sm: 8, md: 12 }}
                >
                  <Grid item xs={6} sm={4} md={4}>
                    <CardBodyTitle>Pago</CardBodyTitle>
                    <CardBodyText>{payment.concept}</CardBodyText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={4}>
                    <CardBodyTitle>ID de pago</CardBodyTitle>
                    <CardBodyText>{payment.approval_number}</CardBodyText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={4}>
                    <CardBodyTitle>Método de pago</CardBodyTitle>
                    <CardBodyText>{payment.payment_method}</CardBodyText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={4}>
                    <CardBodyTitle>Monto pagado</CardBodyTitle>
                    <CardBodyText>{payment.price}</CardBodyText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={4}>
                    <CardBodyTitle>Moneda</CardBodyTitle>
                    <CardBodyText>{payment.coin}</CardBodyText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={4}>
                    <CardBodyTitle>Fecha de pago</CardBodyTitle>
                    <CardBodyText>
                      {format(new Date(payment.created_at.replace(" ", "T")), "dd 'de' MMMM yyyy", {
                        locale: es,
                      })}
                    </CardBodyText>
                  </Grid>
                </Grid>
              </CardTextContainer>
            </CardContainer>
          ))}
        </>
      )}
    </Container>
  );
}
