import { Box, Dialog, Divider, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Fragment, memo, useState } from "react";
import { DocumentViewer } from "react-documents";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { StyledButton } from "theme/Styles";
import { downloadFile } from "utilities/functions/DownloadUtil";
import { typesForDownload, typesForShow } from "./DocumentsOfRequestsCardConstants";
import {
  StyledCheckBoxIcon,
  StyledCheckBoxOutlineBlankIcon,
  StyledClearIcon,
  StyledCloseIcon,
  StyledDescriptionIcon,
  StyledDownloadIcon,
} from "./styles/DocumentsOfRequestsCardStyles";

import PDFIcon from "assets/icons/svgs/pdf_plain_icon.svg";
import { Text } from "components/NewDesign/Text";

function DocumentsOfRequestsCard({
  title,
  data,
  onSelectClick,
  onDeleteClick,
  hideSeeButton = false,
  showDeleteButton = false,
  showSelectButton = false,
  // disableCardStyle = false,
  hideDownloadButton = false,
  selectedItemsByIndex = [],
  // invoice,
  // receipt
  searchComponent = <></>,
}) {
  const [currentDocumentURL, setCurrentCurrentURL] = useState();
  const [viewerOpen, setViewerOpen] = useState(false);

  const handleViewer = async ({ url, extension }) => {
    const _extension =
      extension === "vnd.ms-excel"
        ? "xls"
        : extension === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ? "xlsx"
          : extension;
    if (url) {
      setViewerOpen(true);
      if (
        _extension === "pdf" ||
        _extension === "xls" ||
        _extension === "xlsx" ||
        _extension === "sheet"
      ) {
        const type = typesForDownload[_extension];
        let downloadedDocument = await downloadFile(url, type);
        setCurrentCurrentURL({ url: downloadedDocument, type: _extension });
      } else {
        setCurrentCurrentURL({ url: url, type: _extension });
      }
    } else {
      setCurrentCurrentURL(undefined);
      setViewerOpen(false);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: 0,
        boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
      }}
    >
      <Stack
        direction={"column"}
        sx={{
          width: "100%",
        }}
      >
        <Text
          sx={{
            fontSize: "1.4rem",
            backgroundColor: "#134779",
            color: "white",
            paddingX: "20px",
            paddingY: "14px",
            marginY: "3px",
            textTransform: "uppercase",
            fontWeight: 200,
          }}
        >
          {title}
        </Text>

        {searchComponent}

        <Stack direction={"row"} sx={{ width: "100%" }}>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#C7D3DF",
              color: "#13477A",
              marginX: "1px",
              padding: "1px",
              justifyContent: "center",
              textTransform: "uppercase",
            }}
          >
            <Text>Nombre del Documento</Text>
          </Box>

          <Box
            sx={{
              width: "30%",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#C7D3DF",
              color: "#13477A",
              marginX: "1px",
              padding: "1px",
              justifyContent: "center",
              textTransform: "uppercase",
            }}
          >
            <Text>Fecha</Text>
          </Box>

          <Box
            sx={{
              width: "20%",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#C7D3DF",
              color: "#13477A",
              marginX: "1px",
              padding: "1px",
              justifyContent: "center",
              textTransform: "uppercase",
            }}
          >
            <Text>Acción</Text>
          </Box>
        </Stack>
        <Stack
          height={"400px"}
          sx={{
            overflowY: "auto",
          }}
        >
          {data?.map((item, index) => {
            const thisItemIsPreviouslySelected = selectedItemsByIndex.includes(index);
            return (
              <Box key={index}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  alignContent={"center"}
                  sx={{
                    width: "100%",
                    paddingY: "6px",
                    borderBottom: "1px solid #C7D3DF",
                  }}
                >
                  <Stack direction={"row"} width={"100%"} paddingX={"20px"}>
                    <Stack direction={"row"} alignItems={"center"} width={"50%"} spacing={2}>
                      <Box
                        component="img"
                        sx={{
                          height: { xs: "20px", md: "40px" },
                        }}
                        alt="Institution Logo"
                        src={PDFIcon}
                      />
                      <Text
                        sx={{
                          fontSize: "1.1rem",
                          color: "#13477A",
                        }}
                      >
                        {item.name}
                      </Text>
                    </Stack>

                    <Stack
                      direction={"row"}
                      width={"30%"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Text
                        sx={{
                          fontSize: "1.1rem",
                          color: "#13477A",
                        }}
                      >
                        {item.date}
                      </Text>
                    </Stack>

                    <Stack
                      width={"20%"}
                      direction={"row"}
                      justifyContent={"center"}
                      spacing={2}
                      alignContent={"center"}
                      alignItems={"center"}
                    >
                      {typesForShow.includes(item.documentType) && !hideSeeButton ? (
                        <IconButton
                          title="Ver"
                          onClick={() =>
                            handleViewer({ url: item.url, extension: item.documentType })
                          }
                          sx={{ padding: 0 }}
                        >
                          <StyledDescriptionIcon title="Ver" />
                        </IconButton>
                      ) : (
                        <div style={{ width: "20px" }} />
                      )}

                      {!hideDownloadButton && (
                        <IconButton
                          component="a"
                          download={item.name}
                          href={item.url}
                          title="Descargar"
                          sx={{ padding: 0 }}
                        >
                          <StyledDownloadIcon title="Descargar" />
                        </IconButton>
                      )}

                      {showSelectButton && (
                        <IconButton
                          title={"Seleccionar"}
                          onClick={() => {
                            thisItemIsPreviouslySelected
                              ? onDeleteClick(item, index)
                              : onSelectClick(item, index);
                          }}
                          sx={{ padding: 0 }}
                        >
                          {thisItemIsPreviouslySelected ? (
                            <StyledCheckBoxIcon />
                          ) : (
                            <StyledCheckBoxOutlineBlankIcon />
                          )}
                        </IconButton>
                      )}

                      {showDeleteButton && (
                        <IconButton
                          title="Eliminar"
                          onClick={() => onDeleteClick(item, index)}
                          sx={{ padding: 0 }}
                        >
                          <StyledClearIcon title="Eliminar" />
                        </IconButton>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            );
          })}
        </Stack>

        <Dialog
          open={viewerOpen}
          onClose={handleViewer}
          onBackdropClick={handleViewer}
          fullWidth
          maxWidth="xl"
        >
          {currentDocumentURL?.type === "pdf" ? (
            <DocumentViewer
              style={{ height: "90vh", width: "100%" }}
              viewer="url"
              url={currentDocumentURL?.url}
            />
          ) : (
            <Fragment>
              <IconButton
                sx={{
                  zIndex: 99999999,
                  width: "50px",
                  alignSelf: "flex-end",
                  position: "absolute",
                  backgroundColor: "#FFF",
                }}
                onClick={handleViewer}
              >
                <StyledCloseIcon />
              </IconButton>
              <TransformWrapper>
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <Fragment>
                    <TransformComponent
                      wrapperStyle={{
                        alignSelf: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      {...rest}
                    >
                      <img
                        src={currentDocumentURL?.url}
                        alt={"document"}
                        style={{ maxWidth: "100%", alignSelf: "center" }}
                      />
                    </TransformComponent>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <StyledButton style={{ borderRadius: 0 }} onClick={() => zoomIn()}>
                        Zoom +
                      </StyledButton>
                      <StyledButton style={{ borderRadius: 0 }} onClick={() => zoomOut()}>
                        Zoom -
                      </StyledButton>
                      <StyledButton style={{ borderRadius: 0 }} onClick={() => resetTransform()}>
                        Reiniciar
                      </StyledButton>
                    </div>
                  </Fragment>
                )}
              </TransformWrapper>
            </Fragment>
          )}
        </Dialog>
      </Stack>
      <Divider
        variant="middle"
        sx={{
          height: "1px",
          border: 0,
          width: "100%",
          marginTop: "20px",
          backgroundImage:
            "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(19, 147, 208, 1), rgba(0, 0, 0, 0))",
        }}
      />
    </Box>
  );
}
export default memo(DocumentsOfRequestsCard);
