import { Fragment, useLayoutEffect } from "react";
import { MediumHeightDivider } from "theme/Styles";
import { useDispatch } from "react-redux";
import { UpdateAppSubHeaderTitle } from "redux/actions/UiActions";
import { Container, Row } from "./styles/UseGuide";
import { DataGuiUse } from "./UseGuideConstant.js";
import { Grid } from "@mui/material";
import GuideCard from "./components/GuideCard/GuideCard";

export const UseGuide = () => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    //UPDATE APP HEADER SUBTITLE
    dispatch(UpdateAppSubHeaderTitle("Guía De Uso")); // TITLE OF SUBHEADER APP
  }, [dispatch]);

  return (
    <Container
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Fragment>
        <MediumHeightDivider />
        <MediumHeightDivider />
        <Row>
          <Grid container={true} spacing={2} width={{ xs: "100%", md: "100%", lg: "80%" }}>
            {DataGuiUse?.map((item) => (
              <Grid item key={item.id} xs={12} sm={12} md={6} xl={6}>
                <GuideCard
                  itemId={item.id}
                  title={item.title}
                  subTitle={item.description}
                  descriptionStrong={item.descriptionStrong}
                  iconName={item.icon}
                  component={item?.component}
                  color={item.color}
                />
              </Grid>
            ))}
          </Grid>
        </Row>
      </Fragment>
      <MediumHeightDivider />
    </Container>
  );
};
