import Swal from "sweetalert2";
import COLORS from "theme/Colors";
import "./styles/SweetAlert.css";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const SweetAlert = MySwal.mixin({
  customClass: {
    popup: "swal2-popup-custom",
    container: "swal2-container-custom",
  },
  confirmButtonColor: COLORS.secondary,
});
