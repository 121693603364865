import { Box, Button, Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import COLORS from "theme/Colors";
import moment from "moment";
import { makeStyles } from "@mui/styles";

const LINES_TO_SHOW = 3;

// src: https://stackoverflow.com/a/13924997/8062659
const useStyles = makeStyles({
  multiLineEllipsis: {
    color: "#13477A",
    width: "calc(90%)",
    fontWeight: "600",

    overflow: "hidden",
    textAlign: "center",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": LINES_TO_SHOW,
    "-webkit-box-orient": "vertical",
  },
});

export default function ServiceCard({ header = true, ...props }) {
  let cardHeaderColor;
  let cardHeaderTitle;
  let cardTitleColor;
  const today = moment();
  const created_at = moment(props.createdAt);
  const numMonths = moment.duration(today.diff(created_at)).asMonths();
  const isNew = numMonths < 2;
  const classes = useStyles();

  switch (props.type) {
    case 1:
      cardTitleColor = "black";
      cardHeaderColor = COLORS.secondary;
      cardHeaderTitle = "INFORMATIVO";
      break;
    case 2:
      cardTitleColor = "black";
      cardHeaderColor = COLORS.primary;
      cardHeaderTitle = "INTERACTIVO";
      break;
    case 3:
      cardTitleColor = COLORS.white;
      cardHeaderColor = COLORS.tertiary;
      cardHeaderTitle = "TRANSACCIONAL";
      break;
    default:
      cardHeaderColor = COLORS.tertiary;
      cardHeaderTitle = "TRANSACCIONAL";
      break;
  }

  return (
    <Card
      sx={{
        borderRadius: 0,
        padding: 0,
        width: "100%",
        maxWidth: "100%",
        transition:
          "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, translateY 250ms",
      }}
    >
      <CardHeader
        title={header && cardHeaderTitle}
        sx={{
          backgroundColor: cardHeaderColor,
          height: "2px",
          border: props.type !== 1 ? `1px solid ${cardHeaderColor}` : "1px solid #A1D4EC",

          "& .MuiCardHeader-content	": {
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          },

          "& .MuiCardHeader-title": {
            fontSize: "1rem",
            fontWeight: "normal",
            textTransform: "uppercase",
          },
        }}
      />

      <CardContent
        sx={{
          height: "160px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          padding: "0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 0,
          paddingTop: "12px",
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
      >
        <Box
          className={classes.multiLineEllipsis}
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "1rem", md: "1.2rem" },
          }}
        >
          {props.title}
        </Box>

        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: isNew ? "flex" : "none",
              justifyContent: "center",
              paddingY: "1px",
              backgroundColor: "#A1D4EC",
            }}
          >
            <Typography
              variant={"body2"}
              component={"div"}
              fontWeight={"bold"}
              color={"#13477A"}
              fontSize={"0.7rem"}
              letterSpacing={3}
            >
              NUEVO
            </Typography>
          </Box>

          <Divider
            sx={{
              height: "1px",
              width: "100%",
              border: 0,
              backgroundColor: "#13477A",
            }}
          />

          <Button
            onClick={props.onClick}
            sx={{
              all: "unset",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              textAlign: "center",
              width: "100%",
              paddingY: "2.5%",
              textTransform: "uppercase",
              color: "#13477A",

              "&:hover": {
                cursor: "pointer",
                outline: "2px solid #A1D4EC",
                outlineOffset: "-2px",
                color: "#A1D4EC",
                backgroundColor: "#13477A",
              },
            }}
          >
            VER INFORMACIóN
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
