import { Box } from "@mui/material";
import MyDeskHeader from "components/MyDeskHeader";
import { useLayoutEffect } from "react";
import { UpdateAppSubHeaderTitle } from "redux/actions/UiActions";
import { useDispatch } from "react-redux";

export default function VehicleTaxCalculator() {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(UpdateAppSubHeaderTitle("Escritorio Ciudadano")); //SET SUBHEADER TITLE
  }, [dispatch]);

  return (
    <MyDeskHeader header={"CALCULADORA DE IMPUESTOS PARA VEHÍCULOS"} canGoBack={true}>
      <Box
        component={"iframe"}
        title={"VehicleTaxCalculator"}
        src="https://siga.aduanas.gob.do:8080/dga_calculadora_impuesto_layout.html"
        sx={{
          width: "100%",
          height: { xs: "1200px", md: "500px" },
          border: "0",
          backgroundColor: "#426C95",
          paddingY: "50px",
        }}
      />
    </MyDeskHeader>
  );
}
