import iucService from "services/ApiServerCall";
import backOfficeService from "services/ApiServiceCall";

export function registerCedulaValidation(cedula) {
  return iucService()
    .get(`/validate/citizen/${cedula}`)
    .then(({ data }) => data);
}

export function registerEmailValidation(cedula) {
  return iucService()
    .get(`/validate/email/${cedula}`)
    .then(({ data }) => data);
}

export const registerLoggedUserInServiceBackend = (requestData) => {
  return backOfficeService()
    .post("/citizens", requestData)
    .then(({ data }) => data);
};

export const getUser = () => {
  return iucService()
    .get("/get/auth/user")
    .then(({ data }) => data);
};

export const generateVerificationCode = (requestData) => {
  return iucService()
    .post(`/send/users/activate/code`, {
      citizen_id: requestData.citizen_id,
      verification_type: requestData.verification_type,
    })
    .then(({ data }) => data);
};

export const getMerchandiseTypes = () => {
  return backOfficeService()
    .get("/get/products")
    .then(({ data }) => data);
};

export const verifyCode = (verificationCode) => {
  return iucService()
    .get(`/users/activate/${verificationCode}`)
    .then(({ data }) => data);
};
