import { Box, Divider, Grid, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import InstitutionsCarousel from "./components/InstitutionsCarousel";
import LogoPlain from "assets/icons/pngs/logo-plain.png";
import EscudoPng from "assets/images/escudo-300x300.png";
import FacebookIcon from "assets/icons/pngs/facebook.png";
import TwitterIcon from "assets/icons/pngs/twitter.png";
import InstagramIcon from "assets/icons/pngs/instagram.png";
import YoutubeIcon from "assets/icons/pngs/youtube.png";

function SocialIcon({ icon, to = "#" }) {
  return (
    <Link href={to} target="_blank">
      <Box
        component={"img"}
        src={icon}
        sx={{
          maxHeight: { xs: "30px", md: "30px" },
          maxWidth: { xs: "30px", md: "30px" },
        }}
      />
    </Link>
  );
}

function SocialMedia() {
  return (
    <Stack direction={"column"} textOverflow={"ellipsis"} paddingLeft={{ xs: "0px", md: "20px" }}>
      <Typography fontSize={"1rem"} fontWeight={600}>
        SÍGUENOS
      </Typography>

      <Stack direction={"row"} spacing={2}>
        <SocialIcon icon={FacebookIcon} to={"https://facebook.com/aduanard"} />
        <SocialIcon icon={TwitterIcon} to={"https://twitter.com/aduanard"} />
        <SocialIcon icon={InstagramIcon} to={"https://instagram.com/aduanasrd"} />
        <SocialIcon icon={YoutubeIcon} to={"https://youtube.com/Aduanasrd1"} />
      </Stack>
    </Stack>
  );
}

function Footer() {
  return (
    <>
      <InstitutionsCarousel />
      <Box
        sx={{
          paddingTop: "20px",
          backgroundColor: "#13477A",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          color: "#D0EAF6",
        }}
      >
        <Grid container spacing={2} paddingX={{ xs: "40px", md: "160px" }}>
          <Grid item xs={false} md={8} />
          <Grid item xs={4} md={2}>
            <SocialMedia />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          paddingBottom: "60px",
          backgroundColor: "#13477A",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          color: "#D0EAF6",
        }}
      >
        <Divider
          variant="middle"
          sx={{
            height: "1px",
            border: 0,
            width: "100%",
            marginTop: "20px",
            backgroundImage:
              "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 255.75), rgba(0, 0, 0, 0))",
          }}
        />

        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
          marginTop={"30px"}
          spacing={3}
          paddingX={{ xs: "40px", md: "160px" }}
        >
          <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={2}>
            <Box
              component="img"
              sx={{
                height: { xs: "50px", md: "60px" },
              }}
              alt="Institution Logo"
              src={EscudoPng}
            />
            <Divider
              orientation={"vertical"}
              flexItem={true}
              color="white"
              sx={{
                height: "80px",
              }}
            />
            <Box
              component="img"
              sx={{
                height: { xs: "40px", md: "50px" },
              }}
              alt="Institution Logo"
              src={LogoPlain}
            />
          </Stack>
          <h5
            style={{
              fontSize: "1rem",
              fontWeight: "bold",
              opacity: "0.8",
            }}
          >
            DIRECCIÓN GENERAL DE ADUANAS
          </h5>

          <Stack
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
            fontSize={"0.8rem"}
          >
            <Typography fontSize={"0.75rem"} display={{ xs: "none", md: "block" }}>
              VERSIÓN DE ESCRITORIO
            </Typography>
            <Typography fontSize={"0.75rem"} display={{ xs: "none", md: "block" }}>
              ABRAHAM LINCOLN 1101, EDIF. MIGUEL COCCO, STO. DGO. R.D. 809-547-7070
              INFO@ADUANAS.GOB.DO
            </Typography>
            <Stack
              direction={"row"}
              spacing={2}
              divider={<Divider orientation="vertical" flexItem color={"#D0EAF6"} />}
              display={{ xs: "none", md: "flex" }}
            >
              <CustomLink to={{ pathname: "https://www.aduanas.gob.do/terminos" }} target="_blank">
                <Typography fontSize={"0.75rem"}>TÉRMINOS DE USO</Typography>
              </CustomLink>

              <CustomLink to={"/app/policy"}>
                <Typography fontSize={"0.75rem"}>POLITICAS DE PRIVACIDAD</Typography>
              </CustomLink>

              <CustomLink
                to={{ pathname: "https://www.aduanas.gob.do/preguntas-frecuentes/" }}
                target="_blank"
              >
                <Typography fontSize={"0.75rem"}>PREGUNTAS FRECUENTES</Typography>
              </CustomLink>
            </Stack>
            <Typography fontSize={"0.75rem"}>DIRECCIÓN GENERAL DE ADUANAS</Typography>
            <Typography fontSize={"0.75rem"}>REPUBLICA DOMINICANA</Typography>
            <Typography fontSize={"0.75rem"}>
              ® {new Date().getFullYear()}, TODOS LOS DERECHOS RESERVADOS.
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}

function CustomLink(props) {
  return (
    <Link component={RouterLink} to={props.to} underline="hover" color={"#D0EAF6"} {...props}>
      {props.children}
    </Link>
  );
}

export default Footer;
