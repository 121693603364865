import { memo, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { getAllServices } from "api/ListOfServicesPerCategory";
import { Container, LinkText, MinDivider, Title } from "./styles/ServiceDirectoryMenuStyles";
import COLORS from "theme/Colors";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Stack } from "@mui/material";
import { Fade } from "react-awesome-reveal";

function ServiceDirectoryMenu() {
  const { data: listOfServices, isLoading } = useQuery(["listOfServices"], () => getAllServices());

  if (isLoading) return null;

  return (
    <Container>
      <div style={{ width: "80%", alignSelf: "center", marginTop: "30px" }}>
        <Title
          style={{ textTransform: "uppercase", color: COLORS.secondary, letterSpacing: "2px" }}
        >
          Trámites
        </Title>
        <MinDivider />
        {listOfServices.map((item) => (
          <SidebarDropdown key={item.id} {...item} />
        ))}
      </div>
    </Container>
  );
}

const SidebarDropdown = (item) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  return (
    <div key={item.id} style={{ marginTop: "10px", width: "100%" }}>
      <Stack
        component={"div"}
        direction={"row"}
        justifyContent={"space-between"}
        sx={{
          width: "100%",
          cursor: "pointer",
          mb: "8px",
          fontWeight: 600,

          color: COLORS.secondary,
          letterSpacing: "2px",
        }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {item.name}
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Stack>

      {open && (
        <Fade cascade direction={"down"} duration={10} triggerOnce>
          {item?.services?.map((service) => (
            <LinkText
              key={service.id}
              onClick={() => {
                history.push(`/app/serviceDescription/${service.id}`);
              }}
            >
              {service.name}
            </LinkText>
          ))}
        </Fade>
      )}
    </div>
  );
};

export default memo(ServiceDirectoryMenu);
