import { CheckCircleOutlined, CopyAll } from "@mui/icons-material";
import { Alert, Snackbar, Stack, Tooltip, Typography } from "@mui/material";
import { useState } from "react";

const CopyToClipboardButton = ({ text = "" }) => {
  const [showToast, setShowToast] = useState(false);
  const clipboard = window.navigator.clipboard;

  const handleClick = () => {
    setShowToast(true);
    if (clipboard) clipboard.writeText(text);
  };

  return (
    <>
      <Tooltip title="Copiar texto">
        <Stack
          onClick={navigator.clipboard && handleClick}
          direction={"row"}
          spacing={2}
          sx={{
            all: "unset",
            paddingY: "0.5rem",
            paddingX: "1rem",
            margin: "1rem",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            displayPrint: "none",
            fontSize: "1.5rem",
            backgroundColor: "transparent",
            width: "100%",
            color: showToast ? "rgb(76, 175, 80)" : "#000000",
            border: `2px solid ${showToast ? "rgb(76, 175, 80)" : "#000000"}`,
          }}
        >
          <Typography sx={{ fontSize: "inherit", width: "100%" }}>{text}</Typography>
          {navigator.clipboard && (
            <>
              {showToast ? (
                <CheckCircleOutlined
                  color={"inherit"}
                  fontSize={"inherit"}
                  sx={{ width: "30px" }}
                />
              ) : (
                <CopyAll color={"inherit"} fontSize={"inherit"} sx={{ width: "30px" }} />
              )}
            </>
          )}
        </Stack>
      </Tooltip>

      <Snackbar
        open={showToast}
        onClose={() => setShowToast(false)}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setShowToast(false)} severity="success" sx={{ width: "100%" }}>
          Texto copiado!
        </Alert>
      </Snackbar>
    </>
  );
};
export default CopyToClipboardButton;
