import { useLayoutEffect } from "react";
import TextInformation from "components/TextInformation/TextInformation";
import { MediumHeightDivider, SmallHeightDivider, StyledButtonOutlined } from "theme/Styles";
import { useDispatch } from "react-redux";
import {
  HideGlobalLoading,
  ShowGlobalLoading,
  UpdateAppSubHeaderTitle,
} from "redux/actions/UiActions";
import { ButtonContainer, MapContainer } from "./styles/ContactStyles";
import { useFormik } from "formik";
import { FormSchema, relationToData } from "./ContactConstants";
import { Box, Grid, Stack } from "@mui/material";
import TextField from "components/TextField/TextField";
import { useQuery } from "react-query";
import { getContactDataFromWordpress, sendMessage } from "api/Contact";
import PhoneTextField from "components/PhoneTextField/PhoneTextField";
import CenterLoading from "components/CenterLoading/CenterLoading";
import Select from "components/Select/Select";
import { useSnackbar } from "notistack";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import COLORS from "theme/Colors";

function Contact() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading } = useQuery(["contactData"], () => getContactDataFromWordpress());

  const formik = useFormik({
    initialValues: {
      fullName: "",
      relationTo: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    validationSchema: FormSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      handleSendMessage(values);
    },
  });

  const handleSendMessage = async () => {
    dispatch(ShowGlobalLoading(""));
    const reqData = {
      name: formik.values.fullName,
      mail: formik.values.email,
      phone: formik.values.phoneNumber,
      relation: relationToData.find((relation) => relation.value == formik.values.relationTo)
        ?.label,
      message: formik.values.message,
    };
    try {
      await sendMessage(reqData);
      enqueueSnackbar("Mensaje enviado satisfactoriamente", { variant: "success" });
      formik.resetForm();
      dispatch(HideGlobalLoading());
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
    }
  };

  useLayoutEffect(() => {
    //UPDATE APP HEADER SUBTITLE
    dispatch(UpdateAppSubHeaderTitle("Contacto de Ayuda")); // TITLE OF SUBHEADER APP
  }, []);

  if (isLoading) return <CenterLoading />;

  return (
    <Box
      sx={{
        width: { xs: "90%", md: "95%", lg: "90%", xl: "90%" },
        marginTop: "30px",
        paddingBottom: "40px",
      }}
    >
      <TextInformation title="Información de contacto" />
      <SmallHeightDivider />

      <Grid container={true} spacing={6}>
        {/*{data?.map((content) => (*/}
        {/*  <Grid item={true} xs={4} direction={"column"}>*/}
        {/*    {content && Object.entries(content)?.map(([key, value]) => (*/}
        {/*      <Box sx={{ fontWeight: key === "title" ? "bold" : "normal" }}>*/}
        {/*        {value}*/}
        {/*      </Box>*/}
        {/*    ))}*/}
        {/*  </Grid>*/}
        {/*))}*/}

        {data?.map((content) => (
          <Grid item={true} xs={12} md={4} lg={3} direction={"column"}>
            <Stack spacing={1}>
              {content.title && (
                <Box sx={{ fontWeight: "bold", mb: "6px", fontSize: "1.1rem" }}>
                  {content.title}
                </Box>
              )}
              {content.address && <Box>{content.address}</Box>}
              {content.phoneNumber && (
                <Stack
                  direction={"row"}
                  alignContent={"center"}
                  sx={{ display: "flex" }}
                  spacing={0.5}
                >
                  <LocalPhoneOutlinedIcon />
                  <strong style={{ marginRight: "6px" }}>Número:</strong>
                  {content.phoneNumber}
                </Stack>
              )}

              {content.email && (
                <Stack
                  direction={"row"}
                  alignContent={"center"}
                  sx={{ display: "flex" }}
                  spacing={0.5}
                >
                  <EmailOutlinedIcon />
                  <Box
                    component={"a"}
                    href={`mailto:${content.email}`}
                    sx={{ color: COLORS.primary }}
                  >
                    {content.email}
                  </Box>
                </Stack>
              )}

              {content.website && (
                <Stack
                  direction={"row"}
                  alignContent={"center"}
                  sx={{ display: "flex" }}
                  spacing={0.5}
                >
                  <LanguageOutlinedIcon />
                  <Box component={"a"} href={`${content.website}`} sx={{ color: COLORS.primary }}>
                    {content.website}
                  </Box>
                </Stack>
              )}
            </Stack>
          </Grid>
        ))}
      </Grid>

      <MediumHeightDivider />
      <TextInformation title="Ponte en contacto con nosotros" />
      <SmallHeightDivider />

      <Grid
        alignItems="center"
        justifyContent="center"
        container
        direction="row"
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 8, sm: 8, md: 12 }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            title="Nombre completo"
            type="text"
            id="fullName"
            required
            value={formik.values.fullName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.fullName && Boolean(formik.errors.fullName)}
            helperText={formik.touched.fullName && formik.errors.fullName}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Select
            title="Relacionado a: "
            id="relationTo"
            required
            value={formik.values.relationTo}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.relationTo && Boolean(formik.errors.relationTo)}
            helperText={formik.touched.relationTo && formik.errors.relationTo}
            data={relationToData}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            title="Correo Electrónico"
            type="email"
            id="email"
            required
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <PhoneTextField
            title="Teléfono de contacto"
            type="text"
            id="phoneNumber"
            required
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            title="Mensaje"
            type="text"
            id="message"
            multiline
            value={formik.values.message}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            required
          />
        </Grid>
      </Grid>
      <SmallHeightDivider />
      <ButtonContainer>
        <StyledButtonOutlined onClick={formik.handleSubmit} variant="outlined">
          ENVIAR MENSAJE
        </StyledButtonOutlined>
      </ButtonContainer>

      <MediumHeightDivider />

      <MapContainer>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.0654376332277!2d-69.93918509283753!3d18.480694882967033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eaf89ec3878f8b7%3A0xf4721a2dbd849128!2sDirecci%C3%B3n%20General%20De%20Aduanas!5e0!3m2!1sen!2sdo!4v1658241501400!5m2!1sen!2sdo"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen={false}
          loading="lazy"
        />
      </MapContainer>
    </Box>
  );
}

export default Contact;
