import { Stack } from "@mui/material";
import TextField from "components/TextField/TextField";
import { useFormik } from "formik";
import iucService from "services/ApiServerCall";
import Swal from "sweetalert2";
import { StyledButtonOutlined } from "theme/Styles";
import { removeGuionFromString } from "utilities/functions/StringUtil";
import { AccountDeletionSchema } from "./AccountDeletionConstants";

export function DeleteAccountForm({
  initialValues = {
    citizenId: "",
    password: "",
  },
}) {
  const formik = useFormik({
    initialValues: {
      citizenId: initialValues.citizenId || "",
      password: initialValues.password || "",
    },
    validationSchema: AccountDeletionSchema,
    onSubmit: async (values) => {
      await handleSubmit(values);
    },
  });

  async function handleSubmit(values) {
    let response = await iucService().post("/auth/login", {
      citizen_id: removeGuionFromString(values.citizenId),
      password: values.password,
    });

    if (response.data?.success) {
      const { token } = response.data.payload;

      const iucResponse = await iucService().post(
        `/app/citizen/delete`,
        {
          citizen_id: removeGuionFromString(values.citizenId),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (iucResponse.data?.success) {
        Swal.fire({
          title: "Cuenta eliminada",
          text: "La cuenta ha sido eliminada correctamente",
          icon: "success",
        });
        formik.resetForm();
      } else {
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido un error al eliminar la cuenta",
          icon: "error",
        });
      }
    } else {
      Swal.fire({
        title: "Error",
        text: "Cédula de identidad o contraseña incorrecta",
        icon: "error",
      });
    }
  }

  return (
    <Stack spacing={2} width={"100%"}>
      <TextField
        title="Cédula de identidad"
        type="citizenId"
        id="citizenId"
        required
        fullWidth
        value={formik.values.citizenId}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.citizenId && Boolean(formik.errors.citizenId)}
        helperText={formik.touched.citizenId && formik.errors.citizenId}
      />

      <TextField
        title="Contraseña"
        type="password"
        id="password"
        required
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />

      <StyledButtonOutlined onClick={() => formik.handleSubmit()}>
        Eliminar cuenta
      </StyledButtonOutlined>
    </Stack>
  );
}
