//descriptionGeneral is mockup this value is fetched from wordpress

export const localDirections = [
  {
    id: 1,
    wordpressID: 130,
    wordpressKey: "todosServicios",
    title: "trámites GENERALES",
  },

  {
    id: 2,
    wordpressID: 134,
    wordpressKey: "importacion",
    title: "IMPORTACIÓN ",
  },
  {
    id: 3,
    wordpressID: 132,
    wordpressKey: "exportacion",
    title: "EXPORTACIÓN",
  },
];
