import backOfficeService from "services/ApiServiceCall";

export const getNotifications = (citizenID) => {
  return backOfficeService()
    .get(`/notification/${citizenID}`)
    .then(({ data }) => data);
};

export const removeNotification = ({ citizenID, requestData }) => {
  return backOfficeService()
    .post(`/notification/${citizenID}`, requestData)
    .then(({ data }) => data);
};
