import { styled } from "@mui/system";
import COLORS from "theme/Colors";
import { boxShadowConfig } from "theme/Styles";
import DownloadIcon from "@mui/icons-material/Download";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PrintIcon from "@mui/icons-material/Print";

export const Container = styled("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: COLORS.white,
  width: "100%",
  boxShadow: props.disableCardStyle ? null : boxShadowConfig,
}));

export const ContentContainer = styled("div")((props) => ({
  width: props.disableCardStyle ? "100%" : "100%",
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
}));

export const RowContainer = styled("div")({
  padding: 0,
  width: "100%",
  paddingLeft: "20px",
  paddingRight: "20px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

export const Column = styled("div")({
  display: "inline-block",
  flexDirection: "column",
  textAlign: "left",
  alignItems: "flex-start",
  alignSelf: "center",
  wordWrap: "break-word",
});

export const Title = styled("h1")({
  backgroundColor: "#134779",
});

export const BodyText = styled("p")({
  margin: 0,
  marginTop: "1%",
  color: COLORS.black,
  textAlign: "start",
  "@media (min-width:320px)": {
    fontSize: "10px",
  },
  "@media (min-width:768px)": {
    fontSize: "13px",
  },
  "@media (min-width:1200px)": {
    fontSize: "15px",
  },
});

export const LineDivider = styled("div")({
  width: "100%",
  display: "flex",
  height: "1px",
  marginTop: "10px",
  backgroundColor: COLORS.grayPlaceholder,
});

export const StyledDescriptionIcon = styled(VisibilityIcon)({
  color: "#1393D0",
  "@media (min-width:320px)": {
    fontSize: "18px",
  },
  "@media (min-width:768px)": {
    fontSize: "20px",
  },
  "@media (min-width:1200px)": {
    fontSize: "23px",
  },
});

export const StyledDownloadIcon = styled(DownloadIcon)({
  color: "#1393D0",
  "@media (min-width:320px)": {
    fontSize: "18px",
  },
  "@media (min-width:768px)": {
    fontSize: "20px",
  },
  "@media (min-width:1200px)": {
    fontSize: "23px",
  },
});

export const StyledPrintIcon = styled(PrintIcon)({
  color: "#1393D0",
  "@media (min-width:320px)": {
    fontSize: "18px",
  },
  "@media (min-width:768px)": {
    fontSize: "20px",
  },
  "@media (min-width:1200px)": {
    fontSize: "23px",
  },
});

export const StyledCheckIcon = styled(CheckIcon)({
  color: "#1393D0",
  "@media (min-width:320px)": {
    fontSize: "18px",
  },
  "@media (min-width:768px)": {
    fontSize: "20px",
  },
  "@media (min-width:1200px)": {
    fontSize: "23px",
  },
});

export const StyledClearIcon = styled(ClearIcon)({
  color: "#1393D0",
  "@media (min-width:320px)": {
    fontSize: "18px",
  },
  "@media (min-width:768px)": {
    fontSize: "20px",
  },
  "@media (min-width:1200px)": {
    fontSize: "23px",
  },
});

export const StyledCheckBoxIcon = styled(CheckBoxIcon)({
  color: "#1393D0",
  "@media (min-width:320px)": {
    fontSize: "18px",
  },
  "@media (min-width:768px)": {
    fontSize: "20px",
  },
  "@media (min-width:1200px)": {
    fontSize: "23px",
  },
});

export const StyledCheckBoxOutlineBlankIcon = styled(CheckBoxOutlineBlankIcon)({
  color: "#1393D0",
  "@media (min-width:320px)": {
    fontSize: "18px",
  },
  "@media (min-width:768px)": {
    fontSize: "20px",
  },
  "@media (min-width:1200px)": {
    fontSize: "23px",
  },
});

export const StyledCloseIcon = styled(CloseIcon)({
  color: COLORS.black,
  "@media (min-width:320px)": {
    fontSize: "18px",
  },
  "@media (min-width:768px)": {
    fontSize: "20px",
  },
  "@media (min-width:1200px)": {
    fontSize: "30px",
  },
});
