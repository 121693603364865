import { MetricsTextContainer, MetricsTitle, MetricsValue } from "views/MyDesk/styles/MyDeskStyles";
import { Divider } from "@mui/material";
import { CardContainer } from "theme/Styles";
import { useQuery, useQueryClient } from "react-query";
import { getMetricsData } from "api/myDesk";
import CenterLoading from "components/CenterLoading/CenterLoading";

export default function MetricsCard() {
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData(["userData"]);
  const { data: metricsData, isLoading: metricsDataIsLoading } = useQuery(
    ["myDeskMetricsData"],
    () => getMetricsData(userData.payload.citizen_id),
    {
      enabled: userData?.payload?.citizen_id !== undefined,
    }
  );

  if (metricsDataIsLoading) return <CenterLoading />;

  return (
    <CardContainer>
      <MetricsTextContainer>
        <MetricsTitle>Solicitudes en proceso</MetricsTitle>
        <MetricsValue>{metricsData?.reqsOpen}</MetricsValue>
      </MetricsTextContainer>

      <Divider
        orientation="vertical"
        flexItem
        color={"white"}
        sx={{
          marginY: { xs: "4%", md: "2%" },
        }}
      />

      <MetricsTextContainer>
        <MetricsTitle>Solicitudes completadas</MetricsTitle>
        <MetricsValue>{metricsData?.reqsComplete}</MetricsValue>
      </MetricsTextContainer>

      <Divider
        orientation="vertical"
        flexItem
        color={"white"}
        sx={{
          marginY: { xs: "4%", md: "2%" },
        }}
      />

      <MetricsTextContainer>
        <MetricsTitle>Documentos subidos</MetricsTitle>
        <MetricsValue>{metricsData?.documents}</MetricsValue>
      </MetricsTextContainer>

      <Divider
        orientation="vertical"
        flexItem
        color={"white"}
        sx={{
          marginY: { xs: "4%", md: "2%" },
        }}
      />

      <MetricsTextContainer>
        <MetricsTitle>Solicitudes rechazadas</MetricsTitle>
        <MetricsValue>{metricsData?.reqsRejected}</MetricsValue>
      </MetricsTextContainer>
    </CardContainer>
  );
}
