import { Stack } from "@mui/material";
import MyDocuments from "views/MyDesk/subViews/myDocuments/MyDocuments";
import MyInstitutionalDocuments from "views/MyDesk/subViews/myInstitutionalDocuments/MyInstitutionalDocuments";
import MyDeskHeader from "components/MyDeskHeader";
import { useDispatch } from "react-redux";
import { useLayoutEffect } from "react";
import { UpdateAppSubHeaderTitle } from "redux/actions/UiActions";

export default function Documents() {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(UpdateAppSubHeaderTitle("Escritorio Ciudadano")); //SET SUBHEADER TITLE
  }, []);

  return (
    <MyDeskHeader header={"Mis Documentos"} canGoBack={true}>
      <Stack
        direction={"column"}
        spacing={2}
        sx={{ width: { xs: "95%", md: "80%" }, marginTop: "1%" }}
      >
        <MyDocuments />
        <MyInstitutionalDocuments />
      </Stack>
    </MyDeskHeader>
  );
}
