import * as React from "react";
import { useSelector } from "react-redux";
import { DarkOverlay } from "./styles/SubHeaderStyles";
import { Box, Typography } from "@mui/material";

function SubHeader() {
  const USING_IMAGE = false; //MOCKUP VALUE
  const { title } = useSelector((state) => state.uiReducer);

  return (
    <>
      {USING_IMAGE ? (
        <DarkOverlay>
          <HeaderTitle>{title}</HeaderTitle>
        </DarkOverlay>
      ) : (
        <HeaderTitle>{title}</HeaderTitle>
      )}
    </>
  );
}

function HeaderTitle({ children, ...props }) {
  return (
    <Box
      sx={{
        marginLeft: { xs: "20px", md: "40px", lg: "60px", xl: "180px" },
        width: "80%",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        textTransform: "uppercase",
      }}
    >
      <Typography
        variant="h3"
        fontSize={{ xs: "1.5rem", xl: "2.5rem" }}
        gutterBottom
        component="div"
        color={"white"}
        textAlign={"left"}
        {...props}
      >
        {children}
      </Typography>
    </Box>
  );
}

export default SubHeader;
