import { Stack } from "@mui/material";
import { getAllServices } from "api/ListOfServicesPerCategory";
import { SearchInput } from "components/SearchInput";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

export function AllServicesSearchBar() {
  const history = useHistory();

  const { data: serviceList, isLoading: isServiceListLoading } = useQuery(["serviceList"], () =>
    getAllServices()
  );

  if (isServiceListLoading) return null;

  const servicesForSearcher = serviceList
    .flatMap((item) => item.services)
    .map((item) => {
      return {
        id: item.id,
        title: item.name,
      };
    });

  const goToSelectedService = (service) => {
    history.push(`/app/serviceDescription/${service.id}`);
  };

  return (
    <Stack direction={"row"} spacing={3} justifyContent={"flex-end"}>
      <SearchInput
        isServiceListLoading={isServiceListLoading}
        servicesForSearcher={servicesForSearcher}
        goToSelectedService={goToSelectedService}
      />
    </Stack>
  );
}
