import { isAndroid, isIOS } from "react-device-detect";
import { useRouteMatch } from "react-router-dom";

const routes = {
  android: process.env.REACT_APP_ANDROID_APP,
  ios: process.env.REACT_APP_IOS_APP,
};

export function IsMobileWrapper({ children }) {
  let match = useRouteMatch([
    "/app/validatePayment/:requestID",
    "/app/qr/:token",
    "/public/changePassword/:citizenID",
    "/public/requestPassword",
    "/public/delete-account",
  ]);

  if (!match) {
    if (isAndroid && routes.android) window.location.href = routes.android;
    if (isIOS && routes.ios) window.location.href = routes.ios;
  }

  return children;
}
