import { styled } from "@mui/system";

import COLORS from "theme/Colors";
import { boxShadowConfig } from "theme/Styles";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "row",
  backgroundColor: COLORS.white,
  textAlign: "start",
  justifyContent: "flex-end",

  height: "220px",
  minWidth: "95%",
  border: " 1px solid rgba(157,157,157,.35)",
  "&:hover,&:focus": {
    boxShadow: boxShadowConfig,
    cursor: "pointer",
  },
});

export const RowContainer = styled("div")({
  display: "inline-block",
  flexDirection: "row",
  width: "70%",
  textAlign: "left",
  alignItems: "center",
  verticalAlign: "middle",
  //overflowY: 'auto',
});

export const ColorHeader = styled("div")((props) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  padding: "10px",
  justifyContent: "center",
  alignItems: "center",

  fontSize: props.type == "title" ? "24px" : "18px",
  background:
    props.background === "darkBlue"
      ? `${COLORS.secondary}!important`
      : props.background === "lightBlue"
        ? COLORS.primary
        : COLORS.tertiary,
  height: "24px",
  margin: " 0 auto 0.5rem auto",
  // borderRadius: "06px",
}));

export const BodyText = styled("p")({
  //  re
  color: COLORS.black,
  // margin: '10px',
  lineHeight: 1.3, //LINE-HEIGHT 22.4px
  /* top | right | bottom | left */
  // margin: '0 10px 0 0',
  //marginTop: 0,
  //marginBottom: '1rem',
  padding: "10px",
  fontSize: "19px",
  textAlign: "left",
  // textOverflow: 'ellipsis',
  // display: '-webkit-box',
  //  WebkitLineClamp: 6, /* number of lines to show */
  // '-webkit-box-orient': 'vertical',

  "@media (min-width:360px)": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 6 /* number of lines to show */,
    fontSize: "14px",
  },

  "@media (min-width:480px)": {
    // WebkitLineClamp: 10, /* number of lines to show */
    fontSize: "15px",
  },
  "@media (min-width:768px)": {
    WebkitLineClamp: 10 /* number of lines to show */,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",

    fontSize: "15px",
  },

  "@media (min-width:1200px)": {
    fontSize: "15px",
    //WebkitLineClamp: 10, /* number of lines to show */
  },
});

export const Title = styled("p")({
  margin: "3px",
  overflow: "hidden",
  textOverflow: "ellipsis",

  color: "#13477A",
  fontSize: "0.7rem",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  "-webkit-box-orient": "vertical",
  textTransform: "uppercase",
});

export const ContainerIcon = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "35%",
  height: "100%",
  color: COLORS.secondary,
  paddingRight: "1rem",
  paddingLeft: "1rem",
  borderRight: " 1px solid rgba(157,157,157,.35)",
});

export const Image = styled("img")({
  ":hover": {
    cursor: "pointer",
  },
  "@media (min-width:320px)": {
    width: "50px",
    marginBottom: "10px",
  },
  "@media (min-width:768px)": {
    width: "65px",
  },
  "@media (min-width:1200px)": {
    width: "75px",
  },

  "@media (min-width:1800px)": {
    width: "85px",
  },
});

export const ButtonsContainer = styled("div")({
  flexDirection: "column",
  width: "100%",
  overflow: "hidden",
});
