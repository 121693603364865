import { ThemeProvider, createTheme } from "@mui/material";
import Zoom from "@mui/material/Zoom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Auth from "./auth/Auth";
import { cacheConfig } from "./cacheConfig";
import GlobalLoading from "./components/GlobalLoading/GlobalLoading";
import { IsMobileWrapper } from "./components/IsMobileWrapper";
import Store from "./redux/store/Store";
import Layouts from "./router/Layouts";
import Router from "./router/Router";
import COLORS from "./theme/Colors";

//ACCESSIBILITY IS A SCRIPT INSIDE public/index.html
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Twenty-four hours / One-day time of data from server is considered fresh
      staleTime: cacheConfig.staleTimeGeneral,
      cacheTime: cacheConfig.cacheTime,
    },
  },
});

const theme = createTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      '"Myriad Pro"',
      "Roboto",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  status: {
    success: COLORS.tertiary,
  },
  palette: {
    success: {
      main: "rgb(140, 198, 64, 1)",
    },
  },
});

export default function App() {
  return (
    <Provider store={Store}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            TransitionComponent={Zoom}
          >
            <GlobalLoading />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <BrowserRouter>
                <IsMobileWrapper>
                  <Auth>
                    <Router routes={Layouts} />
                  </Auth>
                </IsMobileWrapper>
              </BrowserRouter>
            </LocalizationProvider>
          </SnackbarProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ThemeProvider>
    </Provider>
  );
}
