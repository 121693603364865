import iucService from "services/ApiServerCall";

//expected response
//{status:true or false}
export const getRekognitionStatus = () => {
  return iucService()
    .get("/recognition/status")
    .then(({ data }) => data);
};

//expected response
//[{ id: 'mouthopen' }, { id: 'mouthclose' }, { id: 'eyesopen' }]
export const getGesturesService = () => {
  return iucService()
    .get("/recognition/config")
    .then(({ data }) => data);
};

//expected response
//{ok:true or false}
export const validateCurrentGestureService = (formData) => {
  return iucService()
    .post("/recognition/face", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => data);
};
