import { Box } from "@mui/material";
import Carousel from "views/Home/components/Carousel";

export default function InstitutionsCarousel() {
  const SLIDE_COUNT = 6;
  const slides = Array.from(Array(SLIDE_COUNT).keys());

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#F7F9FC",
        paddingY: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginRight: { xs: "10%", md: "4%", lg: "15%" },
          marginLeft: { xs: "10%", md: "4%", lg: "15%" },
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Carousel slides={slides} />
        </Box>
      </Box>
    </Box>
  );
}
