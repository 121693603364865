import { Box, Grid, Stack } from "@mui/material";
import { getUser } from "api/Auth";
import { modifyEmail, modifyPassword, modifyUserData } from "api/myConfiguration";
import FormModal from "components/FormModal/FormModal";
import MyDeskHeader from "components/MyDeskHeader";
import PhoneTextField from "components/PhoneTextField/PhoneTextField";
import Select from "components/Select/Select";
import TextField from "components/TextField/TextField";
import TextInformation from "components/TextInformation/TextInformation";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useLayoutEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import {
  HideGlobalLoading,
  ShowGlobalLoading,
  UpdateAppSubHeaderTitle,
} from "redux/actions/UiActions";
import iucService from "services/ApiServerCall";
import LocalStorageService from "services/LocalStorageService";
import {
  BodyText,
  MediumHeightDivider,
  SmallHeightDivider,
  StyledButton,
  StyledButtonOutlined,
} from "theme/Styles";
import { stringToDominicanCedula } from "utilities/functions/FormatterUtil";
import { cleanStringFromNumbers } from "utilities/functions/NumberUtil";
import { FormEmailSchema, FormPasswordSchema, FormSchema } from "./MyConfigurationConstants";
import {
  ProfileImage,
  Title,
  TopContainer,
  UserDataContainer,
} from "./styles/MyConfigurationStyles";

import { removeLocalStorageSessionData } from "auth/AuthFunctions";
import { SweetAlert } from "components/SweetAlert/SweetAlert";
import { AuthLogout } from "redux/actions/AuthActions";

import User from "assets/icons/svgs/user_icon.svg";
import CenterLoading from "components/CenterLoading/CenterLoading";
import COLORS from "theme/Colors";
import { DeleteAccountForm } from "views/AccountDeletion/DeleteForm";

export const MyConfiguration = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [userImage, setUserImage] = useState("");

  const [provincesData, setProvincesData] = useState([]);
  const [municipalitiesData, setMunicipalitiesData] = useState([]);
  const [sectorsData, setSectorsData] = useState([]);

  const [openModifyPasswordModal, setOpenModifyPasswordModal] = useState(false);
  const [openModifyEmailModal, setOpenModifyEmailModal] = useState(false);
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState(false);

  const { data, isLoading } = useQuery(["userData"], () => getUser());
  const mutation = useMutation(modifyUserData);

  const formik = useFormik({
    initialValues: {
      address: data?.payload?.address || "",
      province_id: data?.payload?.province_id || "",
      municipality_id: data?.payload?.municipality_id || "",
      sector_id: data?.payload?.sector_id || "",
      phone: data?.payload?.phone || "",
      phone2: data?.payload?.phone2 || "",
      notificationsWithEmail: false,
      notificationsSms: false,
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleModifyUserData(values);
    },
  });

  const formikPasswordChange = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      new_password_confirmation: "",
    },
    validationSchema: FormPasswordSchema,
    onSubmit: (values) => {
      handleModifyPassword(values);
    },
  });

  const formikEmailChange = useFormik({
    initialValues: {
      password: "",
      email: "",
      new_email_confirmation: "",
    },
    validationSchema: FormEmailSchema,
    onSubmit: (values) => {
      handleModifyEmail(values);
    },
  });

  const handleModifyPasswordModal = () => {
    formikPasswordChange.resetForm();
    setOpenModifyPasswordModal(!openModifyPasswordModal);
  };

  const handleModifyEmailModal = () => {
    formikEmailChange.resetForm();
    setOpenModifyEmailModal(!openModifyEmailModal);
  };

  const handleDeleteAccountModal = () => {
    setOpenDeleteAccountModal(!openDeleteAccountModal);
  };

  const HandleLogOut = () => {
    dispatch(ShowGlobalLoading("Cerrando sesión"));
    setTimeout(() => {
      removeLocalStorageSessionData();
      dispatch(AuthLogout());
      window.location.reload();
    }, 1500);
  };

  const handleModifyPassword = async (formData) => {
    try {
      const response = await modifyPassword(formData);
      if (response.data.success) {
        enqueueSnackbar("Se ha modificado su contraseña", { variant: "success" });
        handleModifyPasswordModal();
        setTimeout(() => {
          HandleLogOut();
        }, 1500);
      } else {
        enqueueSnackbar("Ha ocurrido un error, inténtelo mas tarde", {
          variant: "error",
        });
      }
    } catch (error) {
      const {
        response: {
          data: { msg },
        },
      } = error;

      enqueueSnackbar(msg || "Ha ocurrido un error, inténtelo mas tarde", { variant: "error" });
    }
  };

  const handleModifyEmail = async (formData) => {
    try {
      dispatch(ShowGlobalLoading("Cargando"));
      const response = await modifyEmail(formData);
      if (response.data.success) {
        handleModifyEmailModal();

        await SweetAlert.fire({
          title: "Email modificado satisfactoriamente",
          text: "Inicie sesión nuevamente",
          icon: "warning",
          confirmButtonText: "Ok",
        });

        setTimeout(() => {
          removeLocalStorageSessionData();
          window.location.reload();
        }, 2500);
      } else {
        enqueueSnackbar("Ha ocurrido un error, inténtelo mas tarde", { variant: "error" });
      }
    } catch (error) {
      const {
        response: {
          data: { msg },
        },
      } = error;

      enqueueSnackbar(msg || "Ha ocurrido un error, inténtelo mas tarde", { variant: "error" });
    } finally {
      dispatch(HideGlobalLoading());
    }
  };

  const handleModifyUserData = async (formData) => {
    const request = {
      phone: formData.phone,
      phone2: formData.phone2,
      province_id: formData.province_id,
      municipality_id: formData.municipality_id,
      sector_id: formData.sector_id,
      address: formData.address,
    };
    mutation.mutate(request, {
      onSuccess: (data) => {
        if (data.data.success) {
          // refresh cache of userData
          // queryClient.invalidateQueries("userData");
          enqueueSnackbar("Se ha modificado su información de usuario", { variant: "success" });
        } else {
          enqueueSnackbar(data.data.msg || "Ha ocurrido un error", { variant: "error" });
        }
      },
    });
  };

  const getProvincesData = async () => {
    try {
      let provincesData = await iucService().get("/get/provinces");
      if (provincesData) {
        setProvincesData(
          provincesData.data.provinces?.map((province) => ({
            value: province.bidclasif,
            label: province.ctituloclas,
          }))
        );
      }
      setMunicipalitiesData([]);
      setSectorsData([]);
    } catch (error) {
      console.error(error);
    }
  };

  const getMunicipalitiesData = async (value) => {
    try {
      let municipalitiesData = await iucService().get(`/get/municipalities/${value}`);
      if (municipalitiesData.data.success) {
        setMunicipalitiesData(
          municipalitiesData.data.municipalities?.map((municipalities) => ({
            value: municipalities.bidclasif,
            label: municipalities.ctituloclas,
          }))
        );
      }
      setSectorsData([]);
    } catch (error) {
      console.error(error);
    }
  };

  const getSectorsData = async (value) => {
    try {
      let sectorsData = await iucService().get(`/get/sectors/${value}`);
      if (sectorsData.data.success) {
        setSectorsData(
          sectorsData.data.sectors?.map((sector) => ({
            value: sector.bidclasif,
            label: sector.ctituloclas,
          }))
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useLayoutEffect(() => {
    //UPDATE APP HEADER SUBTITLE
    setUserImage(LocalStorageService.getItem("profile_img"));
    dispatch(UpdateAppSubHeaderTitle("Mi configuración")); // TITLE OF SUBHEADER APP
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      await getProvincesData();
      if (data) {
        await getMunicipalitiesData(formik.values.province_id);
        await getSectorsData(formik.values.municipality_id);
      }
    })();
  }, [data, formik.values.municipality_id, formik.values.province_id]);

  if (isLoading) return <CenterLoading />;

  return (
    <MyDeskHeader header={"Editar Perfil"}>
      <Box
        sx={{
          marginTop: "20px",
          width: { xs: "95%", md: "80%" },
        }}
      >
        <TopContainer>
          <UserDataContainer>
            <Box
              sx={{
                color: "white",
                padding: "10px",
                backgroundColor: userImage ? "#F6F6F6" : "#13477A",
                marginRight: "10px",
              }}
            >
              <ProfileImage src={userImage || User} />
            </Box>

            <div style={{ marginBottom: "20px" }}>
              <Title>
                {data &&
                  data.payload.name +
                    " " +
                    data.payload.first_last_name +
                    " " +
                    data.payload.second_last_name}
              </Title>
              <BodyText>
                <strong style={{ color: COLORS.secondary }}> Cédula: </strong>{" "}
                {data && stringToDominicanCedula(data.payload.citizen_id)}
              </BodyText>
            </div>
          </UserDataContainer>

          <Stack direction={"column"} spacing={2} sx={{ width: { xs: "100%", md: "auto" } }}>
            <StyledButtonOutlined variant="outlined" onClick={handleModifyPasswordModal}>
              Cambiar contraseña
            </StyledButtonOutlined>
            <StyledButtonOutlined variant="outlined" onClick={handleModifyEmailModal}>
              Cambiar Correo Principal
            </StyledButtonOutlined>
            <StyledButtonOutlined
              variant="outlined"
              sx={{
                color: COLORS.error,
                borderColor: COLORS.error,
              }}
              onClick={handleDeleteAccountModal}
            >
              Eliminar cuenta
            </StyledButtonOutlined>
          </Stack>
        </TopContainer>

        <FormModal
          onClose={handleModifyPasswordModal}
          open={openModifyPasswordModal}
          title="Modificar contraseña"
          content={
            <Grid
              container
              alignItems="flex-start"
              justifyContent="center"
              direction="row"
              columnSpacing={2}
            >
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  title="Contraseña actual"
                  type="password"
                  id="old_password"
                  value={formikPasswordChange.values.old_password}
                  onChange={formikPasswordChange.handleChange}
                  onBlur={formikPasswordChange.handleBlur}
                  error={
                    formikPasswordChange.touched.old_password &&
                    Boolean(formikPasswordChange.errors.old_password)
                  }
                  helperText={
                    formikPasswordChange.touched.old_password &&
                    formikPasswordChange.errors.old_password
                  }
                  required
                />
              </Grid>

              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  title="Nueva Contraseña"
                  type="password"
                  id="password"
                  value={formikPasswordChange.values.password}
                  onChange={formikPasswordChange.handleChange}
                  onBlur={formikPasswordChange.handleBlur}
                  error={
                    formikPasswordChange.touched.password &&
                    Boolean(formikPasswordChange.errors.password)
                  }
                  helperText={
                    formikPasswordChange.touched.password && formikPasswordChange.errors.password
                  }
                  required
                />
              </Grid>

              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  title="Repetir nueva contraseña"
                  type="password"
                  id="new_password_confirmation"
                  value={formikPasswordChange.values.new_password_confirmation}
                  onChange={formikPasswordChange.handleChange}
                  onBlur={formikPasswordChange.handleBlur}
                  error={
                    formikPasswordChange.touched.new_password_confirmation &&
                    Boolean(formikPasswordChange.errors.new_password_confirmation)
                  }
                  helperText={
                    formikPasswordChange.touched.new_password_confirmation &&
                    formikPasswordChange.errors.new_password_confirmation
                  }
                  required
                />
              </Grid>
            </Grid>
          }
          actions={
            <StyledButton onClick={() => formikPasswordChange.handleSubmit()}>
              CONFIRMAR
            </StyledButton>
          }
        />

        <FormModal
          onClose={handleModifyEmailModal}
          open={openModifyEmailModal}
          title="Modificar Email"
          content={
            <Grid
              alignItems="flex-start"
              justifyContent="center"
              container
              direction="row"
              columnSpacing={2}
            >
              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  title="Nuevo email"
                  type="text"
                  id="email"
                  name="email"
                  value={formikEmailChange.values.email}
                  onChange={formikEmailChange.handleChange}
                  onBlur={formikEmailChange.handleBlur}
                  error={formikEmailChange.touched.email && Boolean(formikEmailChange.errors.email)}
                  helperText={formikEmailChange.touched.email && formikEmailChange.errors.email}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  title="Repetir nuevo email"
                  type="text"
                  id="new_email_confirmation"
                  name="new_email_confirmation"
                  value={formikEmailChange.values.new_email_confirmation}
                  onChange={formikEmailChange.handleChange}
                  onBlur={formikEmailChange.handleBlur}
                  error={
                    formikEmailChange.touched.new_email_confirmation &&
                    Boolean(formikEmailChange.errors.new_email_confirmation)
                  }
                  helperText={
                    formikEmailChange.touched.new_email_confirmation &&
                    formikEmailChange.errors.new_email_confirmation
                  }
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  title="Contraseña"
                  type="password"
                  id="password"
                  name="password"
                  value={formikEmailChange.values.password}
                  onChange={formikEmailChange.handleChange}
                  onBlur={formikEmailChange.handleBlur}
                  error={
                    formikEmailChange.touched.password && Boolean(formikEmailChange.errors.password)
                  }
                  helperText={
                    formikEmailChange.touched.password && formikEmailChange.errors.password
                  }
                  required
                />
              </Grid>
            </Grid>
          }
          actions={
            <StyledButton onClick={() => formikEmailChange.handleSubmit()}>CONFIRMAR</StyledButton>
          }
        />

        <FormModal
          title={"Eliminar cuenta"}
          content={
            <DeleteAccountForm
              initialValues={{
                citizenId: stringToDominicanCedula(data.payload.citizen_id),
              }}
            />
          }
          open={openDeleteAccountModal}
          onClose={handleDeleteAccountModal}
        />

        <MediumHeightDivider />
        <TextInformation title="Datos de contacto" />
        <SmallHeightDivider />

        <Grid alignItems="center" justifyContent="center" container direction="row" spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              title="Dirección"
              type="text"
              id="address"
              required
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Select
              title="Provincia"
              type="text"
              id="province_id"
              data={provincesData}
              value={formik.values.province_id}
              onChange={(e) => {
                formik.setFieldValue("municipality_id", "");
                formik.setFieldValue("sector_id", "");
                formik.handleChange(e);
                getMunicipalitiesData(e.target.value);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.province_id && Boolean(formik.errors.province_id)}
              helperText={formik.touched.province_id && formik.errors.province_id}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Select
              title="Municipio"
              type="text"
              id="municipality_id"
              disabled={formik.values["province_id"].length <= 0}
              data={municipalitiesData}
              value={formik.values.municipality_id}
              onChange={(e) => {
                formik.setFieldValue("sector_id", "");
                formik.handleChange(e);
                getSectorsData(e.target.value);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.municipality_id && Boolean(formik.errors.municipality_id)}
              helperText={formik.touched.municipality_id && formik.errors.municipality_id}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Select
              title="Sector"
              type="text"
              id="sector_id"
              disabled={formik.values["municipality_id"].length <= 0}
              data={sectorsData}
              value={formik.values.sector_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.sector_id && Boolean(formik.errors.sector_id)}
              helperText={formik.touched.sector_id && formik.errors.sector_id}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <PhoneTextField
              title="Teléfono móvil"
              type="text"
              id="phone"
              required
              value={cleanStringFromNumbers(formik.values.phone)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <PhoneTextField
              title="Teléfono secundario"
              type="text"
              id="phone2"
              required
              value={cleanStringFromNumbers(formik.values.phone2)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone2 && Boolean(formik.errors.phone2)}
              helperText={formik.touched.phone2 && formik.errors.phone2}
            />
          </Grid>
        </Grid>
        {/*
          <Fragment>
            <SmallHeightDivider />
            <SmallHeightDivider />
            <TextInformation title="Preferencias de Notificación" />
            <SmallHeightDivider />

            <Grid alignItems="center" justifyContent="center" container direction="row" spacing={{ xs: 2, md: 3 }} columns={{ xs: 8, sm: 8, md: 12 }}>
              <Grid item xs={12} sm={4} md={6}>
                <CheckBox label="Correo Electrónico" id="notificationsWithEmail"
                  value={formik.values.notificationsWithEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.notificationsWithEmail && Boolean(formik.errors.notificationsWithEmail)}
                  helperText={formik.touched.notificationsWithEmail && formik.errors.notificationsWithEmail}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={6}>
                <CheckBox label="SMS" id="notificationsSms"
                  value={formik.values.notificationsSms}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.notificationsSms && Boolean(formik.errors.notificationsSms)}
                  helperText={formik.touched.notificationsSms && formik.errors.notificationsSms}
                />
              </Grid>
            </Grid>
          </Fragment>
        */}
        <MediumHeightDivider />
        <Box
          sx={{
            display: "flex",
            alignSelf: "center",
            width: { xs: "100%", md: "400px" },
          }}
        >
          <StyledButtonOutlined variant="outlined" onClick={formik.handleSubmit}>
            Guardar
          </StyledButtonOutlined>
        </Box>

        <MediumHeightDivider />
      </Box>
    </MyDeskHeader>
  );
};
