import { Swiper, SwiperSlide } from "swiper/react";
import { Box, useMediaQuery } from "@mui/material";

import { Autoplay, Navigation, Pagination } from "swiper";
import { getInstitutionsCarouselFromWordpress } from "api/Home";
import { useQuery } from "react-query";

function SwiperComponent({ src, website }) {
  return (
    <Box
      component={"a"}
      href={website}
      target={"_blank"}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        userSelect: "none",
        width: { xs: "100px", md: "120px", xl: "150px" },
        height: { xs: "100px", md: "120px", xl: "150px" },
        padding: "0.5rem",
        backgroundColor: "white",
        cursor: "pointer",

        "&:hover": {
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Box
        component={"img"}
        src={src}
        sx={{
          width: "90%",
          backgroundColor: "white",
        }}
        alt="Institution Logo"
      />
    </Box>
  );
}

const Carousel = (props) => {
  const { children, ...rest } = props;
  const small = useMediaQuery("(min-width:600px)");
  const medium = useMediaQuery("(min-width:900px)");
  const { data: institutionsLogo, isLoading: institutionsLogoIsLoading } = useQuery(
    ["institutionsLogo"],
    () => getInstitutionsCarouselFromWordpress()
  );

  if (institutionsLogoIsLoading) {
    return null;
  }

  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={medium ? 6 : small ? 4 : 3}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Pagination, Navigation]}
      loopedSlides={institutionsLogo.length}
      {...rest}
    >
      {institutionsLogo.map((src, index) => (
        <SwiperSlide key={index}>
          <SwiperComponent src={src["image_url"]} website={src["website"]} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
