import { useLayoutEffect, useState } from "react";
import ServiceDirectoryMenu from "components/ServiceDirectoryMenu/ServiceDirectoryMenu";
import TextInformation from "components/TextInformation/TextInformation";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MediumHeightDivider } from "theme/Styles";
import { Grid, Stack } from "@mui/material";

import { localDirections } from "./ListOfServicesPerCategoryConstants";
import { useHistory } from "react-router-dom";
import LoginOrRegisterModal from "components/LoginOrRegisterModal/LoginOrRegisterModal";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppSubHeaderTitle } from "redux/actions/UiActions";
import { useParams } from "react-router-dom";
import { Container } from "./styles/ListOfServicesPerCategoryStyles";
import { getAllServices, getGeneralInformationsFromWordpress } from "api/ListOfServicesPerCategory";
import { useQuery } from "react-query";
import CenterLoading from "components/CenterLoading/CenterLoading";
import ServiceCard from "components/ServiceCard/ServiceCard";

function ListOfServicesPerCategory() {
  const matchesWidth = useMediaQuery("(min-width:768px)");
  const history = useHistory();
  let { categoryID } = useParams();
  const dispatch = useDispatch();
  const { authenticated } = useSelector((state) => state.authReducer);

  const { data: generalInformationData, isLoading: generalInformationDataLoading } = useQuery(
    ["generalInformationData"],
    () => getGeneralInformationsFromWordpress()
  );

  const { data: listOfServices, isLoading: listOfServicesLoading } = useQuery(
    ["listOfServices"],
    () => getAllServices()
  );

  const [loginOrRegisterModalStatus, setLoginOrRegisterModalStatus] = useState(false);
  const [currentDirection, setCurrentDirection] = useState();
  const [ListServicesState, setListServicesState] = useState([]);

  const handleServiceRequest = (service) => {
    if (authenticated) {
      history.push(`/app/requestService/${service.id}`);
    } else {
      setLoginOrRegisterModalStatus(!loginOrRegisterModalStatus);
    }
  };

  useLayoutEffect(() => {
    if (categoryID == 1 || categoryID == 2 || categoryID == 3) {
      //find direction in local info
      let localCurrentDirection = localDirections.find((direction) => direction.id == categoryID);
      // TITLE OF SUBHEADER APP
      dispatch(UpdateAppSubHeaderTitle(localCurrentDirection.title));
      setCurrentDirection(localCurrentDirection);
      if (listOfServices != undefined) {
        setListServicesState(
          listOfServices.find((direction) => direction.id == categoryID).services
        );
      }
    } else {
      //IF ENTERED CATEGORY AS PARAM DOES`NT EXISTS REDIRECT TO ALL SERVICES
      history.push("/app/allServices");
    }
  }, [categoryID, listOfServices]);

  if (generalInformationDataLoading || listOfServicesLoading) return <CenterLoading />;

  return (
    <Container>
      <LoginOrRegisterModal
        open={loginOrRegisterModalStatus}
        onBackDropClick={() => setLoginOrRegisterModalStatus(false)}
        onCloseClick={() => setLoginOrRegisterModalStatus(false)}
      />
      <Stack direction={"row"} spacing={2}>
        {matchesWidth && <ServiceDirectoryMenu />}
        <Container sx={{ width: "100%", paddingX: "20px" }}>
          <MediumHeightDivider />
          <TextInformation
            title="Información general"
            content={
              generalInformationData?.find((item) => item.id === currentDirection?.wordpressID)
                ?.descriptionGeneral
            }
          />

          <Stack
            direction={"column"}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "20px",
              width: "100%",
            }}
          >
            <TextInformation title="Trámites" />
            <Grid container={true} spacing={2} width={"100%"} marginTop={"20px"}>
              {ListServicesState?.map((item) => (
                <Grid item xs={6} sm={6} md={4} xl={3}>
                  <ServiceCard
                    itemId={item.id}
                    title={item.name}
                    type={item.type_id}
                    subTitle={item.description}
                    createdAt={item.created_at}
                    relationTo={
                      localDirections.find((direction) => direction.id === item.direction_id).title
                    }
                    onRequestPress={() => handleServiceRequest(item)}
                    disableRequestButton={item.type_id === 1}
                    OnViewInformationPress={() =>
                      history.push(`/app/serviceDescription/${item.id}`)
                    }
                    onClick={() => history.push(`/app/serviceDescription/${item.id}`)}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
          <MediumHeightDivider />
        </Container>
      </Stack>
    </Container>
  );
}

export default ListOfServicesPerCategory;
