import { Stack } from "@mui/material";
import { Text } from "components/NewDesign/Text";

export function ProgressBar({ progress = 0, status = "En proceso" }) {
  return (
    <Stack
      direction={"row"}
      paddingY={0.5}
      sx={{
        position: "relative",
        display: "flex",
        border: "1px solid #1393D0",
        overflow: "hidden",
        backgroundColor: "#1393D0",
      }}
    >
      <Stack
        direction={"row"}
        marginX={0.5}
        paddingX={0.5}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          background: "#1393D0",
          color: "white",
          textTransform: "uppercase",
        }}
      >
        <Text sx={{ fontSize: "1.2rem", fontWeight: 600 }}>{status}</Text>
        <Text sx={{ fontSize: "1rem", fontWeight: 600 }}>{progress} % </Text>
      </Stack>

      <Stack
        direction={"row"}
        marginX={0.5}
        paddingX={0.5}
        sx={{
          position: "absolute",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          left: 0,
          right: 0,
          background: "white",
          color: "#1393D0",
          clipPath: `inset(0 0 0 ${progress}%)`,
          "-webkit-clip-path": `inset(0 0 0 ${progress}%)`,
          transition: "clip-path 1s linear",
          textTransform: "uppercase",
        }}
      >
        <Text sx={{ fontSize: "1.2rem", fontWeight: 600 }}>{status}</Text>
        <Text sx={{ fontSize: "1rem", fontWeight: 600 }}>{progress} % </Text>
      </Stack>
    </Stack>
  );
}
