import { Box, styled } from "@mui/material";

export const Text = styled(Box)({
  fontSize: "1rem",
  fontWeight: "normal",

  "@media (min-width:320px)": {},
  "@media (min-width:768px)": {},
  "@media (min-width:1200px)": {},
});
