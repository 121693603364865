import { Box, Grid, IconButton, Stack } from "@mui/material";
import moment from "moment/moment";
import TextField from "components/TextField/TextField";
import { StyledButton } from "theme/Styles";
import { generatePin, getPackageStatus } from "api/myDesk";
import { useState } from "react";
import CopyToClipboard from "components/CopyToClipboard";
import { Refresh } from "@mui/icons-material";
import { handleFetchAlert } from "views/MyDesk/utils/handleFetchAlert";

const Item = ({ title = "", value = "" }) => {
  return (
    <div>
      <b>{title}:</b> {value}
    </div>
  );
};

const packageContent = (message) => {
  return (
    <Stack direction={"column"}>
      <Item title={"Mercancía"} value={message?.mercancia} />
      <Item
        title={"Fecha de llegada"}
        value={moment(message?.fechallegada).format("DD-MM-YYYY HH:MM")}
      />
      <Item title={"Peso"} value={message?.peso} />
      <Item title={"Valor"} value={message?.valor} />
      <Item title={"Estado Retenido"} value={message?.estadoRetenido} />
      <Item title={"Razón de retención"} value={message?.razonRetencion} />
    </Stack>
  );
};

function PackageStatusForm() {
  const [packageCode, setPackageCode] = useState("");
  const [citizenId, setCitizenId] = useState("");

  return (
    <>
      <Grid
        alignItems="flex-start"
        justifyContent="center"
        container
        direction="row"
        columnSpacing={2}
      >
        <Grid item xs={12}>
          <TextField
            title="Cédula"
            type="text"
            id="packageCode"
            onChange={(e) => setCitizenId(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            title="Número de guía"
            type="text"
            id="packageCode"
            onChange={(e) => setPackageCode(e.target.value)}
            required
          />
        </Grid>
      </Grid>

      <StyledButton
        onClick={() => {
          if (!citizenId || !packageCode) return;
          handleFetchAlert({
            fetchMethod: getPackageStatus(citizenId, packageCode).then((res) => {
              if (res?.message?.mercancia) return res;
              throw Error("An error has ocurred when trying to get the package status");
            }),
            successMessage: ({ message }) => packageContent(message),
            errorMessage: (res) => res?.msg,
            autoClose: false,
          });
        }}
      >
        CONSULTAR
      </StyledButton>
    </>
  );
}

const pinContent = (pin) => {
  return (
    <Stack direction={"column"}>
      <Box alignSelf={"center"}>Copiar el PIN de pago</Box>
      <Stack direction={"row"} alignItems="center">
        <CopyToClipboard text={pin} />
        <IconButton aria-label="refresh" size="large">
          <Refresh fontSize="inherit" />
        </IconButton>
      </Stack>
    </Stack>
  );
};

// TEST PARAMS: TRM-0921-322640
function PinGeneratorForm() {
  const [invoiceNo, setInvoiceNo] = useState("");
  const [citizenId, setCitizenId] = useState("");

  return (
    <>
      <Grid
        alignItems="flex-start"
        justifyContent="center"
        container
        direction="row"
        columnSpacing={2}
      >
        <Grid item xs={12}>
          <TextField
            title="Número de documento"
            type="text"
            id="citizenId"
            onChange={(e) => setCitizenId(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            title="Número de factura"
            type="text"
            id="invoiceNo"
            onChange={(e) => setInvoiceNo(e.target.value)}
            required
          />
        </Grid>
      </Grid>

      <StyledButton
        onClick={() => {
          handleFetchAlert({
            fetchMethod: generatePin(citizenId, invoiceNo),
            successMessage: ({ message }) => pinContent(message),
            errorMessage: (res) => res?.message,
            autoClose: false,
          });
        }}
      >
        GENERAR
      </StyledButton>
    </>
  );
}

export { PackageStatusForm, PinGeneratorForm };
