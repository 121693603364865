import backOfficeService from "services/ApiServiceCall";
import wpCall from "services/WpServerCall";

export const getHomeDataFromWordpress = () => {
  return wpCall()
    .get("/web/v1/homeImage")
    .then(({ data }) => data);
};

export const getInstitutionsCarouselFromWordpress = () => {
  return wpCall()
    .get("/web/v1/institutions_carousel_new")
    .then(({ data }) => data);
};

export const getMobileAppsFromWordpress = () => {
  return wpCall()
    .get("/web/v1/mobile_apps")
    .then(({ data }) => data);
};
