import {
  BodyText,
  FlexStartContainer,
  FooterContainer,
  Image,
  LeftPanelContainer,
  LogoImage,
  RowContainer,
  TextFieldContainer,
  Title,
} from "views/Auth/RequestPassword/styles/RequestPasswordStyles";
import Logo from "assets/images/LogoPNG.png";
import { MediumHeightDivider, SmallHeightDivider, StyledButton } from "theme/Styles";
import TextField from "components/TextField/TextField";
import { useQuery } from "react-query";
import { getHomeDataFromWordpress } from "api/Home";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { FormSchema } from "./ChangePasswordConstants";
import { updatePassword } from "api/ChangePassword";
import { useParams } from "react-router-dom";
import { SweetAlert } from "components/SweetAlert/SweetAlert";

const ShowPasswordErrors = ({ response = {} }) => {
  return response[0].password.map((error) => (
    <div>
      {error}
      <br />
    </div>
  ));
};

export default function ChangePassword() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { citizenID } = useParams();

  const { data: homeContent } = useQuery(["homeData"], () => getHomeDataFromWordpress());

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      setLoading(true);

      updatePassword(citizenID, {
        password: values.password,
        password_confirmation: values.passwordConfirmation,
      }).then((response) => {
        setLoading(false);

        if (response?.success) {
          SweetAlert.fire({
            title: "¡Éxito!",
            text: "Su contraseña ha sido cambiada exitosamente.",
            icon: "success",
            confirmButtonText: "Aceptar",
          }).then(() => {
            history.push("/");
          });
        } else {
          const errorMessage =
            response?.message || <ShowPasswordErrors response={response} /> ||
            "Ha ocurrido un error al cambiar su contraseña. Por favor, intente nuevamente más tarde.";

          SweetAlert.fire({
            title: "¡Error!",
            html: errorMessage,
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      });
    },
  });

  return (
    <RowContainer>
      <LeftPanelContainer>
        <LogoImage src={Logo} />
        <FlexStartContainer>
          <Title>Nueva Contraseña</Title>
          <SmallHeightDivider />
          <SmallHeightDivider />

          <TextFieldContainer>
            <TextField
              placeholder="Nueva Contraseña"
              type="password"
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </TextFieldContainer>

          <TextFieldContainer>
            <TextField
              placeholder="Confirmar Contraseña"
              type="password"
              id="passwordConfirmation"
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)
              }
              helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
            />
          </TextFieldContainer>
          <MediumHeightDivider />
          <StyledButton loading={loading} onClick={() => formik.handleSubmit()}>
            Restablecer contraseña
          </StyledButton>
          <MediumHeightDivider />

          <FooterContainer>
            <BodyText>
              @{process.env.REACT_APP_CONFIG_YEAR} Dirección General de Aduanas de la República
              Dominicana. Todos los derechos reservados.
            </BodyText>
          </FooterContainer>
        </FlexStartContainer>
      </LeftPanelContainer>
      <Image style={{ backgroundImage: `url(${homeContent?.image_url})` }} />
    </RowContainer>
  );
}
