import * as yup from "yup";

export const FormSchema = yup
  .object({
    password: yup
      .string()
      .min(8, "La contraseña debe tener mínimo 8 caracteres")
      .required("Este campo es requerido")
      // password should contain at least one uppercase letter, and 8 characters
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "La contraseña debe tener al menos una letra mayúscula, y 8 caracteres"
      ),
    passwordConfirmation: yup
      .string()
      .min(6, "La contraseña debe tener mínimo 6 caracteres")
      .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden")
      .required("Este campo es requerido")
      // password should contain at least one uppercase letter, and 8 characters
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "La contraseña debe tener al menos una letra mayúscula, y 8 caracteres"
      ),
  })
  .required();
