import { styled } from "@mui/system";
import FONT_FAMILY from "theme/FontsFamily";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});
export const Row = styled("div")({
  display: "flex",

  justifyContent: "center",
  "@media (min-width:360px)": {
    width: "100%",
  },
  "@media (min-width:768px)": {
    width: "100%",
  },

  "@media (min-width:1200px)": {
    width: "85%",
  },
});

export const ButtonContainer = styled("div")({
  width: "200px",
  marginTop: "20px",
});

export const ContactInfoContainer = styled("div")({
  fontFamily: FONT_FAMILY.regular.body,
});

export const MapContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "300px",
});
