import { styled } from "@mui/material";
import COLORS from "theme/Colors";
import { StyledButton, StyledButtonOutlined } from "theme/Styles";

export const Container = styled("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  width: "700px",
  minHeight: "300px",
  backgroundColor: COLORS.white,
  alignSelf: "center",
  "@media (min-width:320px)": {
    width: "320px",
  },
  "@media (min-width:768px)": {
    width: "500px",
  },
  "@media (min-width:1200px)": {
    width: "700px",
  },
}));

export const TitleContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  height: "150px",
  marginBottom: "50px",
  backgroundColor: COLORS.secondary,
});

export const StepperContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  marginBottom: "50px",
});

export const Title = styled("h1")({
  color: COLORS.white,
  "@media (min-width:320px)": {
    fontSize: "25px",
  },
  "@media (min-width:768px)": {
    fontSize: "28px",
  },
  "@media (min-width:1200px)": {
    fontSize: "35px",
  },
});

export const CameraContainer = styled("div")({
  dispaly: "flex",
  flexDirection: "column",
  position: "relative",
  borderRadius: "100%",
  overflow: "hidden",
  alingItems: "center",
  "@media (min-width:320px)": {
    width: "150px",
    height: "150px",
  },
  "@media (min-width:768px)": {
    width: "300px",
    height: "300px",
  },
  "@media (min-width:1200px)": {
    width: "300px",
    height: "300px",
  },
});

export const CameraOverlay = styled("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  background: " rgba(0, 0, 0, 0.5)",
  color: "#f1f1f1",
  width: "100%",
  height: "100%",
  bottom: "0",
  borderRadius: "50%",
  transition: ".5s ease",
  opacity: props.showOverlay ? 1 : 0,
  fontSize: "20px",
  textAlign: "center",
  justifyContent: "center",
  "&:hover": {
    opacity: 1,
  },
}));

export const IconOverlayContainer = styled("div")((props) => ({
  color: "white",
  svg: {
    fontSize: "100px",
  },
}));

export const CameraOverlayTitle = styled("h1")({
  color: COLORS.white,
  "@media (min-width:320px)": {
    fontSize: "25px",
  },
  "@media (min-width:768px)": {
    fontSize: "28px",
  },
  "@media (min-width:1200px)": {
    fontSize: "30px",
  },
});

export const ButtonContainer = styled("div")({
  width: "100%",
});

export const ValidationOutlinedButton = styled(StyledButtonOutlined)({
  borderRadius: 0,
  paddingTop: "8px",
  paddingBottom: "8px",
});

export const ValidationButton = styled(StyledButton)({
  borderRadius: 0,
  paddingTop: "8px",
  paddingBottom: "8px",
});
