import { styled } from "@mui/system";
import { Button, TextField } from "@mui/material";
import COLORS from "./Colors";
import Pagination from "@mui/material/Pagination";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";

export const boxShadowConfig = "0 4px 8px 0 rgba(0,0,0,0.2)";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
});

export const Row = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
});

export const StyledButton = styled(Button)({
  minHeight: "38px",
  width: "100%",
  borderRadius: "30px",
  color: COLORS.white,
  backgroundColor: COLORS.secondary,
  "@media (min-width:320px)": {
    fontSize: "12px",
  },
  "@media (min-width:768px)": {
    fontSize: "13.5px",
  },
  "@media (min-width:1200px)": {
    fontSize: "15px",
  },

  "&:hover,&:focus,&:active": {
    color: COLORS.white,
    backgroundColor: COLORS.primary,
  },
});

// export const StyledButton = styled(ButtonUnstyled)({
//   color: "white",
//   backgroundColor: "#134779",
//   border: "1px solid #134779",
//   fontSize: "1rem",
//   minHeight: "40px",
//   display: "flex",
//   width: "100%",
//   alignItems: "center",
//   justifyContent: "center",
//   textTransform: "uppercase",
//   cursor: "pointer",
//   textAlign: "center",
//   margin: "2px",
//   borderRadius: "0.25rem",
//
//
//   "&:hover": {
//     backgroundColor: "#1393D0",
//     borderColor: "#1393D0",
//   },
//
//   "&:active": {
//     outline: "2px solid #134779",
//     outlineOffset: "1px",
//   },
// });

export const StyledButtonOutlined = styled(Button)((props) => ({
  minHeight: "38px",
  width: "100%",
  borderColor: COLORS.secondary,
  border: "1px solid #134779",
  borderRadius: "30px",
  color: props.active ? COLORS.white : COLORS.secondary,
  backgroundColor: props.active ? COLORS.secondary : COLORS.white,
  "@media (min-width:320px)": {
    fontSize: "12px",
  },
  "@media (min-width:768px)": {
    fontSize: "13.5px",
  },
  "@media (min-width:1200px)": {
    fontSize: "15px",
  },

  "&:hover,&:focus,&:active": {
    color: COLORS.white,
    backgroundColor: COLORS.primary,
    borderColor: COLORS.primary,
  },
}));

export const StyledButtonOutlinedLoading = styled(LoadingButton)((props) => ({
  minHeight: "38px",
  width: "100%",
  borderColor: COLORS.secondary,
  border: "1px solid #134779",
  borderRadius: "30px",
  color: props.active ? COLORS.white : COLORS.secondary,
  backgroundColor: props.active ? COLORS.secondary : COLORS.white,
  "@media (min-width:320px)": {
    fontSize: "12px",
  },
  "@media (min-width:768px)": {
    fontSize: "13.5px",
  },
  "@media (min-width:1200px)": {
    fontSize: "15px",
  },

  "&:hover,&:focus,&:active": {
    color: COLORS.white,
    backgroundColor: COLORS.primary,
    borderColor: COLORS.primary,
  },
}));

export const StyledButtonLoading = styled(LoadingButton)((props) => ({
  minHeight: "38px",
  width: "100%",
  borderRadius: "30px",
  color: COLORS.white,
  backgroundColor: props.loading ? COLORS.primary : COLORS.secondary,
  "@media (min-width:320px)": {
    fontSize: "12px",
  },
  "@media (min-width:768px)": {
    fontSize: "13.5px",
  },
  "@media (min-width:1200px)": {
    fontSize: "15px",
  },

  "&:hover,&:focus,&:active": {
    color: COLORS.white,
    backgroundColor: COLORS.primary,
  },
}));

// export const StyledButtonOutlined = styled(StyledButton)({
//   color: "#134779",
//   border: "1px solid #134779",
//   backgroundColor: "transparent",
//
//   "&:hover": {
//     backgroundColor: "#1393D0",
//     borderColor: "#1393D0",
//     color: "white",
//   },
// });

export const StyledTextInput = styled(TextField)((props) => ({
  width: "100%",
  alignSelf: "center",
  borderWidth: props.hiddenBorder ? 0 : "5px",
  "& .MuiInputBase-input ": {
    width: "100%",
    fontSize: "17px",
    backgroundColor: COLORS.white,
    padding: 10,
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: COLORS.secondary,
      borderRadius: "10px",
      minHeight: "45px",
      borderWidth: props.hiddenBorder ? 0 : "1px",
    },

    "&.Mui-focused fieldset": {
      borderColor: COLORS.secondary,
      borderWidth: props.hiddenBorder ? 0 : "1px",
    },
  },
}));

export const BodyText = styled("p")({
  margin: 0,
  textAlign: "left",
  fontSize: "15px",
  color: "#666666",

  textTransform: "uppercase",
});

export const BodyTextBold = styled("p")({
  margin: 0,
  textAlign: "left",
  fontSize: "1.1rem",
  color: "#1393D0",

  textTransform: "uppercase",
  //fontWeight: "bold",
});

export const Title = styled("h1")({
  margin: 0,

  fontSize: "40px",
  "@media(max-width:768px)": {
    fontSize: "30px",
  },
  color: COLORS.secondary,
});

export const SubTitle = styled("h4")({
  margin: 0,

  fontSize: "25px",
  "@media(max-width:768px)": {
    fontSize: "20px",
  },
  color: COLORS.secondary,
});

export const SmallHeightDivider = styled("div")({
  height: "15px",
});

export const MediumHeightDivider = styled("div")({
  height: "40px",
});

export const WpRichTextContainer = styled("div")({});

export const CardContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "center",
  boxShadow: boxShadowConfig,
  "@media (min-width:320px)": {
    minHeight: "100px",
  },
  "@media (min-width:768px)": {
    minHeight: "130px",
  },
  "@media (min-width:1200px)": {
    minHeight: "200px",
  },
});

export const CardBodyTitle = styled("p")({
  margin: 0,
  color: COLORS.black,
  "@media(min-width:320px)": {
    fontSize: "12px",
  },
  "@media(min-width:768px)": {
    fontSize: "12px",
  },
  "@media(min-width:1200px)": {
    fontSize: "15px",
  },
});

export const CardBodyText = styled("p")({
  width: "100%",
  margin: 0,

  color: COLORS.secondary,
  "@media(min-width:320px)": {
    fontSize: "13px",
  },
  "@media(min-width:768px)": {
    fontSize: "13px",
  },
  "@media(min-width:1200px)": {
    fontSize: "17px",
  },
});

export const CardTextContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignSelf: "center",
  width: "90%",
  padding: "2%",
});

export const FieldTitle = styled("p")({
  marginTop: "3px",
  color: COLORS.black,

  "@media (min-width:320px)": {
    fontSize: "11px",
  },
  "@media (min-width:768px)": {
    fontSize: "15px",
  },
  "@media (min-width:1200px)": {
    fontSize: "17px",
  },
});

export const StyledPagination = styled(Pagination)(() => ({
  "& .MuiPaginationItem-rounded": {
    "&:hover,&:focus": {
      backgroundColor: COLORS.primary,
      color: COLORS.white,
    },
  },
  "& .Mui-selected": {
    backgroundColor: `${COLORS.primary} !important`,
    color: COLORS.white,
    fontWeight: "bold",
  },
  "& .MuiPaginationItem-root": {
    "&:hover,&:focus": {
      backgroundColor: COLORS.primary,
      color: COLORS.white,
    },
  },
}));

export const StyledCheckCircleIcon = styled(CheckCircleIcon)({
  color: COLORS.success,
  "@media (min-width:320px)": {
    fontSize: "150px",
  },
  "@media (min-width:768px)": {
    fontSize: "200px",
  },
  "@media (min-width:1200px)": {
    fontSize: "250px",
  },
});

export const StyledCancelIcon = styled(CancelIcon)({
  color: COLORS.error,
  "@media (min-width:320px)": {
    fontSize: "150px",
  },
  "@media (min-width:768px)": {
    fontSize: "200px",
  },
  "@media (min-width:1200px)": {
    fontSize: "250px",
  },
});
