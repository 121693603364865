import { FormControl, FormGroup, FormHelperText, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { getPersonalDocuments } from "api/MyDocuments";
import { cacheConfig } from "cacheConfig";
import CustomPagination from "components/CustomPagination";
import DocumentsOfRequestsCard from "components/DocumentsOfRequestsCard/DocumentsOfRequestsCard";
import FormModal from "components/FormModal/FormModal";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { Fragment, memo, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import COLORS from "theme/Colors";
import { FieldTitle, Row, SmallHeightDivider, StyledButton, StyledTextInput } from "theme/Styles";
import { replaceGuionToSlashFromString } from "utilities/functions/StringUtil";
import { types, typesForExcel, typesForSelectedList } from "./UploadFileConstants";
import {
  Container,
  InputFile,
  InputFileButtonContainer,
  PaginationContainer,
  StyledSearchIcon,
  StyledUploadFileIcon,
} from "./styles/UploadFileStyles";

import SearchIcon from "assets/icons/pngs/search.png";
import { textFieldStyle } from "./UploadFileConstants";
function UploadFile({
  id,
  title,
  placeholder,
  onChange,
  value,
  disabled,
  error,
  required,
  hideDownloadButton,
  extension,
  helperText = " ",
  findDocuments = false,
  multipleDocuments = false,
  ignoreType = [],
  maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE),
  maxFiles = parseInt(process.env.REACT_APP_MAX_CONTAINER_FILES || 5),
}) {
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData(["userData"]);
  const { enqueueSnackbar } = useSnackbar();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchFilter, setSearchFilter] = useState("");

  const [filter, setFilter] = useState("");

  const { data: documentsData, isLoading: documentsDataLoading } = useQuery(
    ["documentsData", currentPage, searchFilter],
    () => getPersonalDocuments(userData.payload.citizen_id, currentPage, filter),
    {
      staleTime: cacheConfig.staleTimeForRequestedServiceDetail,
      enabled: findDocuments === true,
    }
  );

  const [selectedFilesModalIsOpen, setSelectedFilesModalIsOpen] = useState(false);
  const [myDocumentsModalIsOpen, setMyDocumentsModalIsOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(value?.files ? value.files : []);
  const [selectedFileName, setSelectedFileName] = useState(value?.files[0]?.name);
  const [selectedItemsByIndexInDocuments, setSelectedItemsByIndexInDocuments] = useState(
    value?.selectedDocumentsArray ? value?.selectedDocumentsArray : []
  );

  const validateAndChangeSelectedFiles = (action, e) => {
    if (multipleDocuments === false && e.target.files.length > 1) {
      enqueueSnackbar("No se puede agregar mas de un documento", { variant: "error" });
      return;
    }

    const totalFiles = selectedFiles.length + e.target.files.length;

    if (multipleDocuments === true && totalFiles > maxFiles) {
      enqueueSnackbar(`La cantidad máxima es de ${maxFiles} documentos por contenedor`, {
        variant: "error",
      });
      return;
    }

    let filesLength = e.target.files.length;
    if (filesLength > 0) {
      let loopError = false;
      let data = [];
      for (let i = 0; i < filesLength; i++) {
        const file = e.target.files[i];
        const fileExtension =
          e.target.files[i].name.substring(
            e.target.files[i].name.lastIndexOf(".") + 1,
            e.target.files[i].name.length
          ) || e.target.files[i].name;
        const fileSize = file.size / 1024 / 1024;

        if (fileSize > maxFileSize) {
          alert(`El peso límite por archivo es de ${maxFileSize} MB.`);
          loopError = true;
          break;
        }

        if (ignoreType?.includes(fileExtension)) {
          alert("Documento no permitido");
          loopError = true;
          break;
        }
        //Good select file by file type specified on extension
        if (extension != undefined) {
          //Validate File extension valid in constants types and fileType
          if (
            (types.find((type) => type.includes(extension)) && file.type.includes(extension)) ||
            //Validate File extension valid in constands types and fileType in file string name example file.pdf
            (types.find((type) => type.includes(extension)) &&
              types.find((type) => type.includes(fileExtension)) &&
              extension.includes(fileExtension)) ||
            //Validate File extension valid in constands types and fileType in file string name example file.pdf for XLSX AND XLS
            (fileExtension === "xlsx" && extension === "xls") ||
            (fileExtension === "xls" && extension === "xlsx") ||
            (fileExtension === "xlsx" && extension === "xlsx") ||
            (fileExtension === "xls" && extension === "xls")
          ) {
            setSelectedFileName(file.name);
            data.push(file);
            continue;
          } else {
            //bad
            setSelectedFileName("");
            alert(`El archivo requiere una extension .${extension}`);
            loopError = true;
            break;
          }
        }
        //Good select valid file
        if (
          types.find((type) => type === file.type) ||
          types.find((type) => type === fileExtension)
        ) {
          setSelectedFileName(file.name);
          data.push(file);
          continue;
        }
        //bad
        setSelectedFileName("");
        alert("Documento no permitido");
        loopError = true;
        break;
      }

      if (loopError == false) {
        //Good
        let concatData = [];
        if (multipleDocuments === false) {
          setSelectedItemsByIndexInDocuments([]);
          concatData = [...data];
        } else {
          concatData = [...selectedFiles, ...data];
        }
        setSelectedFiles(concatData);
        action({
          target: {
            id,
            value: {
              files: concatData ?? [],
              selectedDocumentsArray: selectedItemsByIndexInDocuments,
            },
          },
        });
      }
    }
  };

  const handleDocumentSelect = (file, index) => {
    if (
      multipleDocuments === false &&
      selectedFiles.length >= 1 &&
      selectedItemsByIndexInDocuments.length >= 1
    ) {
      enqueueSnackbar("No se puede agregar mas de un documento", { variant: "error" });
      return;
    }

    if (
      multipleDocuments === true &&
      selectedFiles.length >= maxFiles &&
      selectedItemsByIndexInDocuments.length >= maxFiles
    ) {
      enqueueSnackbar(`La cantidad máxima es de ${maxFiles} documentos por contenedor`, {
        variant: "error",
      });
      return;
    }

    const fileExtension =
      file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length) || file.name;

    const _extension =
      extension === "xls"
        ? "application/vnd.ms-excel"
        : extension === "xlsx"
          ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          : extension;

    if (_extension != undefined) {
      if (
        (types.find((type) => type.includes(_extension)) && file.type.includes(_extension)) ||
        (types.find((type) => type.includes(fileExtension)) &&
          _extension.includes(fileExtension)) ||
        //Validate File extension valid in constands types and fileType in file string name example file.pdf for XLSX AND XLS
        (fileExtension === "xlsx" && extension === "xls") ||
        (fileExtension === "xls" && extension === "xlsx") ||
        (fileExtension === "xlsx" && extension === "xlsx") ||
        (fileExtension === "xls" && extension === "xls")
      ) {
        //Good check selected file extension is equal than field required extension
      } else {
        //bad
        setSelectedFileName("");
        alert(`El archivo requiere una extension .${extension}`);
        return;
      }
    }

    //Good select a valid file
    setSelectedItemsByIndexInDocuments((prev) => [...prev, index]);
    setSelectedFileName(file.name);
    enqueueSnackbar("Documento agregado", { variant: "success" });

    if (Boolean(multipleDocuments) === false) handleMyDocumentsModalVisibility();

    let concatData;
    if (multipleDocuments === true) {
      concatData = [
        ...selectedFiles,
        {
          indexInDocumentsArray: index,
          isARoute: true,
          name: file.nameClear,
          extension: file.type,
          type:
            file.type === "xls" || file.type === "xlsx"
              ? typesForExcel[file.type]
              : types.find((type) => type.includes(file.type)),
          route: file.route,
          size_mb: file.size_mb,
        },
      ];
    } else {
      setSelectedItemsByIndexInDocuments([index]);
      concatData = [
        {
          indexInDocumentsArray: index,
          isARoute: true,
          name: file.nameClear,
          extension: file.type,
          type:
            file.type === "xls" || file.type === "xlsx"
              ? typesForExcel[file.type]
              : types.find((type) => type.includes(file.type)),
          route: file.route,
          size_mb: file.size_mb,
        },
      ];
    }

    setSelectedFiles(concatData);

    onChange({
      target: {
        id,
        value: {
          files: concatData ?? [],
          selectedDocumentsArray: selectedItemsByIndexInDocuments,
        },
      },
    });
  };

  const handleRemoveFileFromSelectedFiles = (file, position) => {
    enqueueSnackbar("Documento removido", { variant: "success" });
    setSelectedFileName("");
    let newSelectedItemsByIndexInDocuments = selectedItemsByIndexInDocuments;
    if (file?.isARoute) {
      const realPositionInSelectedItemsByIndex = selectedFiles.find(
        (selectedFile) => selectedFile?.route === file?.route
      )?.indexInDocumentsArray;
      newSelectedItemsByIndexInDocuments = selectedItemsByIndexInDocuments.filter(
        (index) => index !== realPositionInSelectedItemsByIndex
      );
      setSelectedItemsByIndexInDocuments(newSelectedItemsByIndexInDocuments);
    }
    let newFiles = [];
    selectedFiles.forEach((item, index) => {
      if (index != position) {
        newFiles.push(item);
      }
    });
    setSelectedFiles(newFiles);
    onChange({
      target: {
        id,
        value: {
          files: newFiles,
          selectedDocumentsArray: newSelectedItemsByIndexInDocuments,
        },
      },
    });
  };

  const handleMyDocumentsModalVisibility = () => {
    setMyDocumentsModalIsOpen(!myDocumentsModalIsOpen);
  };

  const handleSelectedFilesModalVisibility = () => {
    setSelectedFilesModalIsOpen(!selectedFilesModalIsOpen);
  };

  const documentsDataForShow = documentsData?.data?.map((document) => {
    const typeForName =
      document.extension === "vnd.ms-excel"
        ? "xls"
        : document.extension === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ? "xlsx"
          : document.extension;
    return {
      isARoute: true,
      name: `${document.name}.${typeForName}`,
      nameClear: document.name,
      documentType: document.extension,
      date: format(new Date(replaceGuionToSlashFromString(document.created_at)), "yyyy-MM-dd"),
      url: document.url,
      type: document.extension,
      route: document.route,
      size_mb: document.size_mb || 0,
    };
  });

  const selectedFilesDataForShow = selectedFiles?.map((file) => {
    const typeForName = typesForSelectedList?.[file.type || file.extension];
    return {
      isARoute: file?.isARoute,
      indexInDocumentsArray: file?.indexInDocumentsArray,
      name: `${file.name}${file?.isARoute ? `.${typeForName}` : ""} `,
      nameClear: "",
      documentType: "",
      date: format(new Date(), "yyyy-MM-dd"),
      url: "",
      type: "",
      route: file?.route,
      size_mb: file.size_mb || 0,
    };
  });

  // Debounce search filter
  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchFilter(filter);
    }, 300);

    return () => clearTimeout(timeout);
  }, [filter]);

  if (documentsDataLoading) return null;

  return (
    <Container>
      <Row>
        <FieldTitle>{title} </FieldTitle>
        <div style={{ width: "5px" }} />
        {required && (
          <Fragment>
            <FieldTitle style={{ color: COLORS.red }}>*</FieldTitle>
          </Fragment>
        )}
      </Row>

      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        {multipleDocuments ? (
          <FormControl disabled={disabled} required={required}>
            <FormGroup row>
              <StyledButton onClick={handleSelectedFilesModalVisibility}>
                {value?.files.length > 0
                  ? `Ver archivos seleccionados (${value?.files.length})`
                  : "No hay archivos seleccionados"}
              </StyledButton>

              {helperText && (
                <FormHelperText sx={{ fontSize: "0.90rem", color: error ? "red" : "" }}>
                  {helperText?.files ? helperText.files : helperText}
                </FormHelperText>
              )}
            </FormGroup>
          </FormControl>
        ) : (
          <StyledTextInput
            fullWidth
            placeholder={placeholder}
            value={selectedFileName}
            helperText={helperText}
            FormHelperTextProps={{ sx: { fontSize: "0.90rem" } }}
            //error={error}
            InputProps={{
              readOnly: true,
            }}
          />
        )}

        {findDocuments && (
          <InputFileButtonContainer
            title="Seleccionar documento"
            onClick={handleMyDocumentsModalVisibility}
          >
            <StyledSearchIcon />
          </InputFileButtonContainer>
        )}

        <InputFileButtonContainer title="Buscar archivo en mi dispositivo" htmlFor={id}>
          <StyledUploadFileIcon />
        </InputFileButtonContainer>

        <InputFile
          id={id}
          type="file"
          value={""}
          multiple={multipleDocuments}
          accept={extension === "pdf" ? "application/pdf" : "*"}
          onChange={(event) => validateAndChangeSelectedFiles(onChange, event)}
        />
      </Stack>

      <FormModal
        open={selectedFilesModalIsOpen}
        onClose={handleSelectedFilesModalVisibility}
        content={
          <DocumentsOfRequestsCard
            title={`${title} - Archivos`}
            data={selectedFilesDataForShow}
            disableCardStyle
            hideSeeButton
            hideDownloadButton
            showDeleteButton
            onDeleteClick={handleRemoveFileFromSelectedFiles}
          />
        }
      />

      <FormModal
        open={myDocumentsModalIsOpen}
        onClose={handleMyDocumentsModalVisibility}
        content={
          <>
            <DocumentsOfRequestsCard
              title={"Mis Documentos"}
              data={documentsDataForShow}
              disableCardStyle
              showSelectButton
              hideDownloadButton={hideDownloadButton}
              onSelectClick={handleDocumentSelect}
              onDeleteClick={handleRemoveFileFromSelectedFiles}
              selectedItemsByIndex={selectedItemsByIndexInDocuments}
              searchComponent={
                <Stack
                  direction={"row"}
                  bgcolor={COLORS.primary}
                  width={"100%"}
                  alignItems={"center"}
                  mt={"4px"}
                  mb={"8px"}
                >
                  <TextField
                    fullWidth={true}
                    size="small"
                    placeholder={"BUSCAR SERVICIOS"}
                    style={{
                      width: "100%",
                      backgroundColor: "white",
                    }}
                    InputProps={{
                      sx: textFieldStyle,
                    }}
                    debounceMs={300}
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />

                  <Box
                    component={"img"}
                    src={SearchIcon}
                    height={"22px"}
                    margin={"22px"}
                    mx={"11px"}
                    m={0}
                  />
                </Stack>
              }
            />
            <SmallHeightDivider />
            <PaginationContainer>
              <CustomPagination
                count={documentsData?.last_page}
                onClick={(page) => {
                  setCurrentPage(page);
                }}
                value={currentPage - 1}
                variant={"light"}
              />
            </PaginationContainer>
          </>
        }
      />
    </Container>
  );
}

export default memo(UploadFile);
