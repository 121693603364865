import { Rating, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import React, { memo } from "react";
import { replaceGuionToSlashFromString } from "utilities/functions/StringUtil";
import StarIcon from "@mui/icons-material/Star";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";

function Comment({ userName, date, comment, rating = 0 }) {
  return (
    // <Container>
    //   <ContentContainer>
    //     <strong>
    //       <BodyText>{userName}</BodyText>
    //     </strong>
    //     <BodyText>{comment}</BodyText>
    //
    //     <CommentDateText>
    //       {format(
    //         new Date(replaceGuionToSlashFromString(date)),
    //         "dd 'de' MMMM yyyy hh:mm a",
    //         { locale: es }
    //       )}
    //     </CommentDateText>
    //     <Rating value={rating} precision={0.5} readOnly size="small" />
    //   </ContentContainer>
    // </Container>
    <Stack direction={"row"} alignItems={"center"}>
      <Stack
        direction={"column"}
        sx={{
          backgroundColor: "#CCEEEF",
          padding: "15px",
          borderRadius: "0 30px 30px 30px",
          paddingRight: "40px",
          paddingLeft: "20px",
          filter:
            "drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06))",
        }}
      >
        <Typography fontWeight={600} fontSize={"1.15rem"} color={"#134779"}>
          {userName}
        </Typography>
        <Typography fontSize={"1.1rem"} fontWeight={"normal"} color={"black"}>
          {comment}
        </Typography>
        <Stack direction={"row"} spacing={3}>
          <Typography fontSize={"1rem"} fontWeight={"normal"} color={"#black"}>
            {format(new Date(replaceGuionToSlashFromString(date)), "dd 'de' MMMM yyyy hh:mm a", {
              locale: es,
            })}
          </Typography>

          {/*<Rating value={rating} precision={0.5} readOnly size="small" />*/}

          <Rating
            value={rating}
            precision={0.5}
            readOnly
            size="small"
            icon={<StarIcon color={"primary"} fontSize={"inherit"} />}
            emptyIcon={<StarBorderOutlinedIcon color={"primary"} fontSize={"inherit"} />}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default memo(Comment);
