import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Notifications from "components/Notifications/Notifications";
import { AuthLogout } from "redux/actions/AuthActions";
import { ShowGlobalLoading } from "redux/actions/UiActions";
import { removeLocalStorageSessionData } from "auth/AuthFunctions";
import LocalStorageService from "services/LocalStorageService";
import COLORS from "theme/Colors";
import User from "assets/icons/pngs/User.png";

const ROUTES = [
  { path: "/", label: "Inicio" },
  { path: "/app/listOfServices/0", label: "Servicios" },
  { path: "/app/UseGuide", label: "Guía de uso" },
  {
    path: "https://www.aduanas.gob.do/preguntas-frecuentes/",
    label: "Preguntas frecuentes",
    external: true,
  },
  { path: "/app/contact", label: "Ayuda" },
];

function HeaderLink({ children, href, ...props }) {
  return (
    <Link
      href={href}
      underline="hover"
      color="white"
      sx={{
        fontWeight: 600,
        fontSize: { xs: "0.8rem", md: "1rem", xl: "1rem" },
        textTransform: "uppercase",
      }}
      {...props}
    >
      {children}
    </Link>
  );
}

export function HeaderMenu({ negativeMargin }) {
  return (
    <Stack
      direction="row"
      display="flex"
      spacing={{ xs: 2, md: 3, xl: 4 }}
      alignItems="center"
      sx={{ marginTop: negativeMargin }}
    >
      {ROUTES.map((route, index) => (
        <HeaderLink key={index} href={route.path}>
          {route.label}
        </HeaderLink>
      ))}
      <Notifications color="white" />
    </Stack>
  );
}

export function HeaderMenuMobile({ authenticated }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData(["userData"]);
  const [userImage, setUserImage] = useState("");
  const fullName = `${userData?.payload?.name} ${userData?.payload?.first_last_name} ${userData?.payload?.second_last_name}`;

  const handleLogOut = () => {
    dispatch(ShowGlobalLoading("Cerrando sesión"));
    setTimeout(() => {
      removeLocalStorageSessionData();
      dispatch(AuthLogout());
      window.location.reload();
    }, 1500);
  };

  useEffect(() => {
    setUserImage(LocalStorageService.getItem("profile_img"));
  }, []);

  return (
    <>
      <Box>
        <List sx={{ marginTop: "40px" }}>
          {ROUTES.map((route, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton component="a" href={route.path}>
                <ListItemText
                  primary={route.label}
                  sx={{ "& .MuiListItemText-primary": { fontWeight: 600 } }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Divider />

        {authenticated ? (
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => history.push("/app/myDesk")}>
                <ListItemText
                  primary="Mi escritorio"
                  sx={{ "& .MuiListItemText-primary": { fontWeight: 600 } }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={handleLogOut}>
                <ListItemText
                  primary="Cerrar sesión"
                  sx={{ "& .MuiListItemText-primary": { fontWeight: 600 } }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        ) : (
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => history.push("public/login")}>
                <ListItemText
                  primary="Iniciar sesión"
                  sx={{ "& .MuiListItemText-primary": { fontWeight: 600 } }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => history.push("/app/register")}>
                <ListItemText
                  primary="Registrar"
                  sx={{ "& .MuiListItemText-primary": { fontWeight: 600 } }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        )}
      </Box>

      {authenticated && (
        <Stack direction="column" spacing={1}>
          <Divider />
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            paddingY="10px"
            paddingX="10px"
            sx={{ backgroundColor: COLORS.secondary, color: "white" }}
          >
            <Typography
              variant="subtitle1"
              fontWeight={600}
              width="60%"
              sx={{ textTransform: "uppercase" }}
            >
              {fullName}
            </Typography>
            <Box
              sx={{
                backgroundColor: "transparent",
                width: "50px",
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src={userImage || User}
                sx={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  border: "1px solid white",
                  borderRadius: "100%",
                  aspectRatio: "1/1",
                  objectFit: "cover",
                  padding: "1px",
                }}
              />
            </Box>
          </Stack>
        </Stack>
      )}
    </>
  );
}
