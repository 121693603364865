import { Fragment, memo } from "react";
import COLORS from "theme/Colors";
import { Container } from "./styles/DatePickerStyles";
import { FieldTitle, Row, StyledTextInput } from "theme/Styles";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function DatePicker({
  id,
  title,
  placeholder,
  helperText = " ",
  value,
  initialDate = new Date(),
  onChange,
  onBlur,
  disabled,
  error,
  required,
  maxDate = null,
  minDate = null,
  successHelper = "",
}) {
  return (
    <Container>
      <Row>
        <FieldTitle>{title} </FieldTitle>
        <div style={{ width: "5px" }} />
        {required ? (
          <Fragment>
            <FieldTitle style={{ color: COLORS.red }}>*</FieldTitle>
          </Fragment>
        ) : null}
      </Row>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          id={id}
          cancelText="Cancelar"
          okText="Aceptar"
          toolbarTitle={title}
          inputFormat="dd/MM/yyyy"
          maxDate={maxDate}
          minDate={minDate}
          slots={{
            textField: (props) => (
              <StyledTextInput
                {...props}
                FormHelperTextProps={{
                  sx: { fontSize: "0.90rem", color: successHelper && "green !important" },
                }}
                helperText={successHelper || helperText}
                error={error}
              />
            ),
          }}
          toolbarPlaceholder={placeholder}
          value={value ? value : initialDate}
          onChange={(e) => {
            onChange({
              target: {
                id: id,
                value: e,
              },
            });
          }}
          inputProps={{ readOnly: true }}
          onBlur={(e) => {
            onBlur &&
              onBlur({
                target: {
                  id: id,
                  value: e,
                },
              });
          }}
          disabled={disabled}
        />
      </LocalizationProvider>
    </Container>
  );
}

export default memo(DatePicker);
