import { Fragment, memo } from "react";
import COLORS from "theme/Colors";
import { Container } from "./styles/TimePickerStyles";
import { FieldTitle, Row, StyledTextInput } from "theme/Styles";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function TimePicker({
  id,
  title,
  placeholder,
  helperText = " ",
  value,
  onChange,
  onBlur,
  disabled,
  error,
  required,
}) {
  return (
    <Container>
      <Row>
        <FieldTitle>{title} </FieldTitle>
        <div style={{ width: "5px" }} />
        {required ? (
          <Fragment>
            <FieldTitle style={{ color: COLORS.red }}>*</FieldTitle>
          </Fragment>
        ) : null}
      </Row>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileTimePicker
          id={id}
          cancelText="Cancelar"
          okText="Aceptar"
          toolbarTitle={title}
          slots={{
            textField: (props) => (
              <StyledTextInput
                {...props}
                helperText={helperText}
                FormHelperTextProps={{ sx: { fontSize: "0.90rem" } }}
                error={error}
              />
            ),
          }}
          toolbarPlaceholder={placeholder}
          value={value}
          onChange={(e) => {
            onChange({
              target: {
                id: id,
                value: e,
              },
            });
          }}
          inputProps={{ readOnly: true }}
          onBlur={(e) => {
            onBlur &&
              onBlur({
                target: {
                  id: id,
                  value: e,
                },
              });
          }}
          disabled={disabled}
        />
      </LocalizationProvider>
    </Container>
  );
}

export default memo(TimePicker);
