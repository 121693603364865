import iucService from "services/ApiServerCall";

export const getUserQuestions = (citizenId) => {
  return iucService()
    .get(`/get/questions/${citizenId}`)
    .then(({ data }) => data);
};

export const updatePassword = (citizenId, data) => {
  return iucService()
    .post(`/citizens/update/password/${citizenId}`, data)
    .then(({ data }) => data);
};
