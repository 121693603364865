import { QueryClient } from "react-query";
import LocalStorageService from "services/LocalStorageService";
import Swal from "sweetalert2";

export const removeLocalStorageSessionData = () => {
  LocalStorageService.removeItem("profile_img");
  LocalStorageService.removeItem("token");
  LocalStorageService.removeItem("token_duration");
  LocalStorageService.removeItem("user_cedula");
  LocalStorageService.removeItem("user_primary_email");
};

export function logout() {
  const queryClient = new QueryClient();
  const path = window.location.pathname;

  if (path.includes("requestService") || path.includes("serviceRequestedDetails")) return;

  Swal.fire({
    icon: "info",
    title: "Sesión cerrada",
    text: "Por favor, inicie sesión nuevamente.",
  }).then(() => {
    queryClient.removeQueries(["userData"]);
    removeLocalStorageSessionData();
  });
}
