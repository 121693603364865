import React, { useState } from "react";
import Footer from "components/Footer/Footer";
import routes from "router/AppRoutes";
import Router from "router/Router";
import SubHeader from "./components/SubHeader/SubHeader";
import { LayoutBodyContainer, LayoutContainer } from "./styles/AppLayoutStyles";
import ScrollToTopWithRouter from "router/ScrollToTopWithRouter";
import background from "assets/images/background.jpg";
import { Box, Divider, Drawer, IconButton, Stack } from "@mui/material";
import LogoWithText from "assets/icons/pngs/logo-with-text.png";
import { HeaderMenu, HeaderMenuMobile } from "./components/Header/HeaderMenu";
import LoggedInMenu from "components/LoggedInMenu/LoggedInMenu";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import { useQuery } from "react-query";
import { getHomeDataFromWordpress } from "api/Home";
import CenterLoading from "components/CenterLoading/CenterLoading";

const NEGATIVE_MARGIN = "-2vw";
const drawerWidth = "60%";

export function AppHeader() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { authenticated } = useSelector((state) => state.authReducer);

  const container = window !== undefined ? () => window.document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div>
      <Box
        sx={{
          color: "white",
          display: "flex",
          paddingX: { xs: "20px", md: "40px", lg: "60px", xl: "180px" },
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        {/* Left Side */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: { xs: "20px", md: NEGATIVE_MARGIN },
          }}
        >
          <Box component={RouterLink} to={"/app/"}>
            <Box
              component="img"
              sx={{
                height: { xs: "40px", md: "50px" },
                marginRight: "20px",
              }}
              alt="Institution Logo"
              src={LogoWithText}
            />
          </Box>
        </Box>

        {/* Right Side */}
        <Stack direction={"row"} spacing={4} display={{ xs: "none", md: "flex" }}>
          <HeaderMenu negativeMargin={NEGATIVE_MARGIN} />
          <LoggedInMenu loggedIn={authenticated} />
        </Stack>

        <IconButton
          color="inherit"
          size={"large"}
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 8, display: { md: "none" } }}
        >
          <MenuIcon sx={{ fontSize: "40px" }} />
        </IconButton>

        <Drawer
          anchor={"right"}
          container={container}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              justifyContent: "space-between",
            },
          }}
        >
          <HeaderMenuMobile authenticated={authenticated} />
        </Drawer>
      </Box>

      <Divider
        variant="middle"
        sx={{
          height: "1px",
          border: 0,
          marginTop: { xs: "20px", md: NEGATIVE_MARGIN },
          backgroundImage:
            "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 255.75), rgba(0, 0, 0, 0))",
        }}
      />
    </div>
  );
}

const AppLayout = () => {
  const { data: homeContent, isLoading: homeContentIsLoading } = useQuery(["homeData"], () =>
    getHomeDataFromWordpress()
  );

  const getAppRoutes = () => {
    return routes.filter((route) => route.layout === "/app");
  };

  if (homeContentIsLoading) return <CenterLoading />;

  return (
    <LayoutContainer>
      <Box
        sx={{
          background: `
            linear-gradient(rgb(19, 71, 122, 0.35), rgb(19, 71, 122, 0.35)),
            url(${homeContent.image_url || background})`,
          height: { xs: "50vw", sm: "35vw", md: "30vw", lg: "25vw", xl: "15vw" },
          width: "100%",
          backgroundPosition: "10% 10%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          margin: "0 auto",
          borderBottom: "10px solid rgb(140, 198, 64, 1)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <AppHeader />
          <SubHeader />
        </Box>
      </Box>

      <LayoutBodyContainer>
        <ScrollToTopWithRouter>
          <Router routes={getAppRoutes()} />
        </ScrollToTopWithRouter>
      </LayoutBodyContainer>

      <Footer />
    </LayoutContainer>
  );
};

export default AppLayout;
