import * as React from "react";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Fade, LinearProgress } from "@mui/material";
import { Container, LogoImage, Title } from "./styles/GlobalLoadingStyles";
import { SmallHeightDivider } from "theme/Styles";
import { useSelector } from "react-redux";
import Logo from "assets/logo.png";

function GlobalLoading({ showByProp, textByProp }) {
  const { showGlobalLoading, globalLoadingMessage } = useSelector((state) => state.uiReducer);

  const [showLocalLoading, setShowLocalLoading] = useState(false);
  const [showLogo, setShowLogo] = useState(true);

  return (
    <Dialog
      fullScreen
      open={showByProp ? showByProp : showGlobalLoading}
      TransitionComponent={Fade}
    >
      <Container>
        <Fade
          in={showLogo}
          onEntered={() => setShowLogo(false)}
          onExited={() => setShowLocalLoading(true)}
          unmountOnExit={true}
        >
          <LogoImage src={Logo} />
        </Fade>
        {/* <LinearProgress sx={{ width: "40%", height: "10px" }} /> */}
        {showLocalLoading && (
          <LinearProgress sx={{ width: { xs: "60%", md: "40%" }, height: "10px" }} />
        )}
        <SmallHeightDivider />
        <Title>{textByProp ? textByProp : globalLoadingMessage}</Title>
      </Container>
    </Dialog>
  );
}

export default GlobalLoading;
