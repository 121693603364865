import { memo, useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Fade, Step, StepLabel, Stepper } from "@mui/material";
import {
  ButtonContainer,
  CameraContainer,
  CameraOverlay,
  CameraOverlayTitle,
  Container,
  IconOverlayContainer,
  StepperContainer,
  StyledWebCam,
  Title,
  TitleContainer,
  ValidationButton,
  ValidationOutlinedButton,
} from "./styles/FaceRecognitionValidationStyles";
import { MediumHeightDivider } from "theme/Styles";
import { localStepData } from "./FaceRecognitionValidationContants";
import LogoWithText from "assets/icons/pngs/logo-with-text.png";
import Webcam from "react-webcam";
import CancelIcon from "@mui/icons-material/Cancel";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { getGesturesService, validateCurrentGestureService } from "api/FaceRecognitionValidation";
import Swal from "sweetalert2";

function FaceRecognitionValidation({ citizenID, onFinishCallBack, onCancelClick }) {
  const [stepsListData, setStepsListData] = useState([]);
  // 0 value counts as 1
  const [isValidating, setIsValidating] = useState(false);
  const [showOverlay, setShowOverlay] = useState(true);

  const [currentStep, setCurrentStep] = useState(0);
  const [stepFailed, setStepFailed] = useState(null);

  const webcamRef = useRef(Webcam);

  //you can move this function to utilities/functions/FormatterUtil.js
  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const isStepFailed = (step) => {
    return step === stepFailed;
  };

  const handleNextClick = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    validateCurrentStep(imageSrc, currentStep);
  };

  const validateCurrentStep = async (photo, step) => {
    try {
      setIsValidating(true);
      const facialKey = stepsListData[step]?.key;
      const photoFile = dataURLtoFile(photo, `${facialKey}.jpeg`);
      const formData = new FormData();
      formData.append("citizen_id", citizenID);
      formData.append("target", photoFile);
      formData.append("detection", facialKey);
      const response = await validateCurrentGestureService(formData);
      if (response?.success) {
        Swal.fire({
          icon: "success",
          title: "Validación exitosa",
          showConfirmButton: false,
          showCancelButton: false,
          timer: 1100,
        });
        if (step === 2) {
          typeof onFinishCallBack === "function" && onFinishCallBack();
          //show successmsg
        }
        if (step !== 2) {
          setCurrentStep((prev) => prev + 1);
          setShowOverlay(true);
          setStepFailed(null);
        }
      } else {
        // setStepFailed(step -1) because you need pass the index of the step
        setShowOverlay(true);
        setStepFailed(step);
      }
    } catch (error) {
      setShowOverlay(true);
      setStepFailed(step);
    } finally {
      setIsValidating(false);
      setTimeout(() => {
        setShowOverlay(false);
      }, 2000);
    }
  };

  const getAndSetSteps = async () => {
    try {
      const response = await getGesturesService();
      let newData = response?.payload.map((item) => {
        const _listStep = localStepData?.find((localStep) => localStep.key == item?.action);
        return {
          ..._listStep,
          description: item.message,
        };
      });
      setStepsListData(newData);
    } catch (error) {}
  };

  //componenMount
  useEffect(() => {
    setTimeout(() => {
      setShowOverlay(false);
    }, 2000);
  }, []);

  //componentDidMount
  useEffect(() => {
    getAndSetSteps();
    return () => {};
  }, []);

  return (
    <Dialog
      keepMounted
      disableEscapeKeyDown
      open={true}
      maxWidth="xl"
      PaperProps={{ style: { borderRadius: "15px" } }}
      TransitionComponent={Fade}
    >
      <Container>
        <TitleContainer>
          <Box
            component="img"
            sx={{
              height: { xs: "40px", md: "50px" },
              marginTop: "20px",
            }}
            alt="Institution Logo"
            src={LogoWithText}
          />
          <Title>Reconocimiento facial</Title>
        </TitleContainer>
        <CameraContainer>
          <StyledWebCam
            audio={false}
            ref={webcamRef}
            width={"500px"}
            height={"500px"}
            screenshotFormat="image/jpeg"
          />
          <CameraOverlay showOverlay={showOverlay}>
            <IconOverlayContainer>{stepsListData[currentStep]?.icon}</IconOverlayContainer>
            <CameraOverlayTitle>{stepsListData[currentStep]?.label}</CameraOverlayTitle>
          </CameraOverlay>
        </CameraContainer>
        <MediumHeightDivider />
        <StepperContainer>
          <Stepper sx={{ width: "100%" }} activeStep={currentStep} item alternativeLabel>
            {stepsListData.map((item, index) => {
              const labelProps = {};
              if (isStepFailed(index)) {
                labelProps.error = true;
              }
              return (
                <Step key={item.id}>
                  <StepLabel error={labelProps.error}>{item.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </StepperContainer>
        <ButtonContainer>
          <ValidationButton
            disabled={isValidating}
            onClick={handleNextClick}
            startIcon={<CameraAltIcon />}
          >
            Validar y continuar
          </ValidationButton>
          <ValidationOutlinedButton
            disabled={isValidating}
            variant="outlined"
            onClick={onCancelClick}
            startIcon={<CancelIcon />}
          >
            Cancelar
          </ValidationOutlinedButton>
        </ButtonContainer>
      </Container>
    </Dialog>
  );
}

export default memo(FaceRecognitionValidation);
