export const FIELD_TYPES = {
  header: "header",
  select: "select",
  text: "text",
  textarea: "textarea",
  date: "date",
  time: "time",
  radioGroup: "radio-group",
  checkboxGroup: "checkbox-group",
  file: "file",
  grid: "grid",
  initialValues: "rules",
  rulesPrice: "rulesprice",
  subtitle: "subtitle",
};

export const RULE_LIST = {
  0: "ocultar",
  1: "visualizar",
  2: "inhabilitado",
  3: "habilitado",
  4: "requerido",
  5: "clickear",
  6: "no Requerido",
  7: "limpiar",
  8: "cambiarACedula",
  9: "cambiarAPasaporte",
  10: "cambiarARnc",
  13: "deshabilitarMascara",
  14: "habilitarMascara",
};

//How it's set in DB
export const MASK_LIST = {
  0: "Cédula",
  1: "RNC",
  2: "telefono",
  3: "celular",
  5: "email",
  6: "solo numero",
  7: "solo letra",
  8: "inmueble",
  9: "rando de fecha",
  10: "fecha",
  11: "hora",
  12: "decimal",
  14: "pasaporte no existe en SIGA",
  15: "compañia existe en SIGA",
  16: "pasaporte existe en SIGA",
  17: "compañia no existe en SIGA",
  18: "compañia existe en SIGA con RNC",
  19: "compañia no existe en SIGA con RNC",
  20: "rnc fecha de constitucion mayor a 3",
  21: "pago ya existe",
  22: "validar perfil compañia",
  23: "validar perfil persona",
  24: "numero de manifiesto",
  25: "estatus de numero de bl",
  26: "estatus de declaracion",
  27: "estatus registro mercantil",
  28: "certificacion de agente de aduanas",
  50: "solicitudes por usuario",
};

export const MASK_TYPE = {
  0: "identification",
  1: "rnc",
  2: "phone",
  3: "phone",
  // '5': 'email',
  6: "solo numero",
  // '7': 'solo letra',
  // '8': 'inmueble',
  // '9': 'COD.sistema caasd',
  // '10': 'fecha',
  // '11': 'hora',
  // '12': 'decimal',
  18: "rnc-2",
  19: "rnc-2",
};

/*
 * LOCATION MASKS BY ID
 * 51 - PAÍS
 * 52 - PROVINCIA
 * 53 - MUNICIPIO
 * 54 - SECTOR
 * */
export const LOCATION_MASKS = ["51", "52", "53", "54", "55"];
