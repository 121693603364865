import { Box } from "@mui/material";
import { SweetAlert } from "components/SweetAlert/SweetAlert";
import { BsCheck } from "react-icons/bs";
import COLORS from "theme/Colors";

export const handleFetchAlert = ({
  fetchMethod = async () => {},
  successMessage = () => {},
  errorMessage = () => {},
  autoClose = true,
  icon = () => ({
    Component: BsCheck,
    color: COLORS.success,
  }),
}) => {
  SweetAlert.fire({
    title: "Cargando...",
    showConfirmButton: false,
    didOpen: () => {
      SweetAlert.showLoading();

      return fetchMethod
        .then((res) => {
          const { Component: AlertIcon, color: iconColor } = icon(res);
          if (res?.success) {
            SweetAlert.fire({
              title: successMessage(res) || "Consulta realizada correctamente!",
              iconHtml: <Box component={AlertIcon} fontSize={"40px"} />,
              iconColor: iconColor,
              timer: autoClose ? 3000 : null,
            });
          } else {
            SweetAlert.fire({
              title: errorMessage(res) || "Ha ocurrido un error.",
              icon: "error",
              confirmButtonColor: COLORS.secondary,
            });
          }
        })
        .catch((error) => {
          SweetAlert.showValidationMessage(`Request failed: ${error}`);
        });
    },
  });
};
