import { styled } from "@mui/material";
import { keyframes } from "@emotion/react";

const keyframe = (color = "orange") =>
  keyframes({
    from: {
      color: "#134779",
    },
    to: {
      color: color,
    },
  });

const BlinkingText = styled("span")(({ color = "orange" }) => ({
  width: "100%",
  animation: `${keyframe(color)} 1.5s linear infinite`,
}));

export default BlinkingText;
