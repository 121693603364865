import { useEffect, useLayoutEffect, useState } from "react";
import {
  MediumHeightDivider,
  SmallHeightDivider,
  StyledButton,
  StyledButtonOutlinedLoading,
} from "theme/Styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppSubHeaderTitle } from "redux/actions/UiActions";
import { generateVerificationCode, verifyCode } from "api/Auth";
import TextField from "components/TextField/TextField";
import { SweetAlert } from "components/SweetAlert/SweetAlert";
import {
  BodyText,
  FlexStartContainer,
  FooterContainer,
  Image,
  LeftPanelContainer,
  LinkText,
  LogoImage,
  RowContainer,
  Title,
} from "views/Auth/RequestPassword/styles/RequestPasswordStyles";
import Logo from "assets/images/LogoPNG.png";
import COLORS from "theme/Colors";
import { useQuery } from "react-query";
import { getHomeDataFromWordpress } from "api/Home";
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import useQueryParams from "utilities/hooks/useQueryParams";

function ActivateAccount() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { authenticated } = useSelector((state) => state.authReducer);

  const [citizenID, setCitizenId] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [isCodeGenerated, setCodeGenerated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verificationType, setVerificationType] = useState("sms");

  const query = useQueryParams();

  const { data: homeContent } = useQuery(["homeData"], () => getHomeDataFromWordpress());

  function handleCodeVerification(code) {
    verifyCode(code).then((res) => {
      if (res?.success) {
        SweetAlert.fire({
          title: "Felicidades!",
          text: "Tu cuenta ha sido activada",
          icon: "success",
          confirmButtonText: "Aceptar",
        }).then(() => {
          history.push("/");
        });
      } else {
        SweetAlert.fire({
          title: "Error!",
          text: res?.msg,
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    });
  }

  useLayoutEffect(() => {
    dispatch(UpdateAppSubHeaderTitle("Activación de cuenta")); // TITLE OF SUBHEADER APP
  }, [dispatch]);

  useEffect(() => {
    const token = query.get("token");
    if (token) {
      handleCodeVerification(token);
    }

    if (authenticated) {
      history.push("/app/myDesk");
    }
  }, [authenticated, handleCodeVerification, history, query]);

  return (
    <RowContainer>
      <LeftPanelContainer>
        <LogoImage src={Logo} />
        <FlexStartContainer>
          <Title>Activación de cuenta</Title>
          <SmallHeightDivider />
          <SmallHeightDivider />

          <Box sx={{ width: "100%" }}>
            <TextField
              title="Documento de Identidad"
              type="text"
              id="citizen_id"
              mask={"999-9999999-9"}
              unMaskedValue={true}
              value={citizenID}
              onChange={(e) => setCitizenId(e.target.value)}
              required
              fullWidth={true}
            />
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Tipo de verificación</FormLabel>
              <RadioGroup
                row={true}
                name={"verification_type"}
                value={verificationType}
                onChange={(e) => setVerificationType(e.target.value)}
                required
              >
                <FormControlLabel value="sms" control={<Radio />} label="Enviar vía SMS" />
                <FormControlLabel value="email" control={<Radio />} label="Enviar vía email" />
              </RadioGroup>
            </FormControl>
            <SmallHeightDivider />

            <StyledButtonOutlinedLoading
              variant="outlined"
              loading={loading}
              onClick={() => {
                setLoading(true);
                generateVerificationCode({
                  citizen_id: citizenID,
                  verification_type: verificationType,
                }).then(async (res) => {
                  setLoading(false);
                  if (res?.success) {
                    setCodeGenerated(true);
                  } else {
                    await SweetAlert.fire({
                      icon: "error",
                      title: "Error",
                      text: res?.msg || "No se pudo generar el código de verificación",
                    });
                  }
                });
              }}
            >
              {isCodeGenerated ? "Enviar código nuevamente" : "Enviar código"}
            </StyledButtonOutlinedLoading>
          </Box>

          {isCodeGenerated && (
            <Box sx={{ mt: "30px", width: "100%" }}>
              <TextField
                title="Código de activación"
                type="text"
                id="verification_code"
                required
                onChange={(e) => setVerificationCode(e.target.value)}
                value={verificationCode}
                fullWidth={true}
              />

              <StyledButton onClick={() => handleCodeVerification(verificationCode)}>
                ACTIVAR CUENTA
              </StyledButton>
            </Box>
          )}
          <MediumHeightDivider />

          <LinkText
            to="/public/login"
            style={{
              color: COLORS.primary,
            }}
          >
            Regresar
          </LinkText>
          <FooterContainer>
            <BodyText>
              @{process.env.REACT_APP_CONFIG_YEAR} Dirección General de Aduanas de la República
              Dominicana. Todos los derechos reservados.
            </BodyText>
          </FooterContainer>
        </FlexStartContainer>
      </LeftPanelContainer>
      <Image
        style={{
          backgroundImage: `url(${homeContent?.image_url})`,
        }}
      />
    </RowContainer>
  );
}

export default ActivateAccount;
