import { Grid } from "@mui/material";
import Select from "components/Select/Select";
import TextField from "components/TextField/TextField";
import { useFormik } from "formik";
import { UserQuestionsSchema } from "./UserQuestionsConstants";
import iucService from "services/ApiServerCall";
import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { UpdateAppSubHeaderTitle } from "redux/actions/UiActions";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "views/Auth/Register/styles/RegisterStyles";
import { useHistory } from "react-router-dom";
import { MediumHeightDivider, StyledButtonOutlined } from "theme/Styles";
import { SweetAlert } from "components/SweetAlert/SweetAlert";
import { AuthLogin } from "redux/actions/AuthActions";
import ImportantInformationModal from "components/ImportantInformationModal/ImportantInformationModal";

function QuestionsForm() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.authReducer);
  const history = useHistory();

  const [questionsData, setQuestionsData] = useState([]);
  const [showImportantInformationModal, setShowImportantInformationModal] = useState(true);

  const formik = useFormik({
    initialValues: {
      first_citizen_question: "",
      first_citizen_answer: "",
      second_citizen_question: "",
      second_citizen_answer: "",
      third_citizen_question: "",
      third_citizen_answer: "",
      fourth_citizen_question: "",
      fourth_citizen_answer: "",
    },
    validationSchema: UserQuestionsSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      await saveUserAnswers(values);
    },
  });

  const getRegisterQuestionsData = async () => {
    try {
      return await iucService().get("/auth/get/getquestionandanswer");
    } catch (error) {}
  };

  const questionsForShow = (questionNumber) => {
    //questionNumber == 0,1,2
    let selectedOptions = [
      formik.values.first_citizen_question,
      formik.values.second_citizen_question,
      formik.values.third_citizen_question,
      formik.values.fourth_citizen_question,
    ];
    let optionsForRemove = selectedOptions.filter((option, index) => index !== questionNumber);
    return questionsData.filter((question) => !optionsForRemove.includes(question.value + ""));
  };

  async function saveUserAnswers(formData) {
    const citizenId = JSON.parse(localStorage.getItem("user_cedula"));
    iucService()
      .post("/auth/register/questionandanswer", [
        {
          citizen_question: formData.first_citizen_question,
          citizen_answer: formData.first_citizen_answer,
          citizen_id: citizenId,
        },
        {
          citizen_question: formData.second_citizen_question,
          citizen_answer: formData.second_citizen_answer,
          citizen_id: citizenId,
        },
        {
          citizen_question: formData.third_citizen_question,
          citizen_answer: formData.third_citizen_answer,
          citizen_id: citizenId,
        },
        {
          citizen_question: formData.fourth_citizen_question,
          citizen_answer: formData.fourth_citizen_answer,
          citizen_id: citizenId,
        },
      ])
      .then((response) => {
        if (response.data.success === true) {
          SweetAlert.fire({
            title: "Exito",
            text: response.data.msg || "Preguntas de seguridad guardadas exitosamente",
            icon: "success",
            timer: 2000,
          })
            .then(() => {
              dispatch(
                AuthLogin({
                  ...state,
                  hasQuestions: true,
                  authorizedDevice: true,
                })
              );
            })
            .then(() => {
              history.push("/app/myDesk");
            });
        } else {
          SweetAlert.fire({
            title: "Error",
            text: response.data.msg || "Ocurrio un error al guardar las preguntas de seguridad",
            icon: "error",
            timer: 2000,
          });
        }
      });
  }

  const handleSecurityQuestionChange = (e) => {
    formik.setFieldValue(e.target.id, e.target.value);
  };

  useLayoutEffect(() => {
    //UPDATE APP HEADER SUBTITLE
    dispatch(UpdateAppSubHeaderTitle("PREGUNTAS DE SEGURIDAD")); // TITLE OF SUBHEADER APP
  }, [dispatch]);

  useEffect(() => {
    if (state.authenticated && state.hasQuestions) {
      history.push("/");
      return;
    }
    getRegisterQuestionsData().then((response) => {
      setQuestionsData(
        response.data.payload?.map((question) => ({
          value: question.id,
          label: question.questions,
        }))
      );
    });
  }, [history, state]);

  return (
    <>
      <ImportantInformationModal
        open={showImportantInformationModal}
        CloseButton
        CloseTitle="Cancelar"
        buttonTitle="Confirmar"
        buttonClick={() => setShowImportantInformationModal(false)}
        onBackDropClick={() => {}}
        onCloseClick={() => {
          setShowImportantInformationModal(false);
          history.push("/");
        }}
        content={
          <Fragment>
            <strong>
              Hemos detectado que usted no cuenta con el banco de preguntas de seguridad
              establecidos en la “Plataforma de Servicios DGA”, favor de proceder a crearlas para
              así nosotros garantizar la protección de sus datos, gracias.
            </strong>
          </Fragment>
        }
      />
      <Grid
        alignItems="center"
        justifyContent="center"
        container
        direction="row"
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 8, sm: 8, md: 12 }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <Select
            title="Pregunta de seguridad primaria"
            id="first_citizen_question"
            data={questionsForShow(0)}
            value={formik.values.first_citizen_question}
            onChange={handleSecurityQuestionChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.first_citizen_question && Boolean(formik.errors.first_citizen_question)
            }
            helperText={
              formik.touched.first_citizen_question && formik.errors.first_citizen_question
            }
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextField
            title="Respuesta de seguridad primaria"
            type="text"
            id="first_citizen_answer"
            required
            value={formik.values.first_citizen_answer}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.first_citizen_answer && Boolean(formik.errors.first_citizen_answer)
            }
            helperText={formik.touched.first_citizen_answer && formik.errors.first_citizen_answer}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Select
            title="Pregunta de seguridad secundaria"
            id="second_citizen_question"
            data={questionsForShow(1)}
            value={formik.values.second_citizen_question}
            onChange={handleSecurityQuestionChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.second_citizen_question &&
              Boolean(formik.errors.second_citizen_question)
            }
            helperText={
              formik.touched.second_citizen_question && formik.errors.second_citizen_question
            }
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextField
            title="Respuesta de seguridad secundaria"
            type="text"
            id="second_citizen_answer"
            required
            value={formik.values.second_citizen_answer}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.second_citizen_answer && Boolean(formik.errors.second_citizen_answer)
            }
            helperText={formik.touched.second_citizen_answer && formik.errors.second_citizen_answer}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Select
            title="Pregunta de seguridad terciaria"
            id="third_citizen_question"
            data={questionsForShow(2)}
            value={formik.values.third_citizen_question}
            onChange={handleSecurityQuestionChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.third_citizen_question && Boolean(formik.errors.third_citizen_question)
            }
            helperText={
              formik.touched.third_citizen_question && formik.errors.third_citizen_question
            }
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextField
            title="Respuesta de seguridad terciaria"
            type="text"
            id="third_citizen_answer"
            required
            value={formik.values.third_citizen_answer}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.third_citizen_answer && Boolean(formik.errors.third_citizen_answer)
            }
            helperText={formik.touched.third_citizen_answer && formik.errors.third_citizen_answer}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Select
            title="Pregunta de seguridad cuaternaria"
            id="fourth_citizen_question"
            data={questionsForShow(3)}
            value={formik.values.fourth_citizen_question}
            onChange={handleSecurityQuestionChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.fourth_citizen_question &&
              Boolean(formik.errors.fourth_citizen_question)
            }
            helperText={
              formik.touched.fourth_citizen_question && formik.errors.fourth_citizen_question
            }
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextField
            title="Respuesta de seguridad cuaternaria"
            type="text"
            id="fourth_citizen_answer"
            required
            value={formik.values.fourth_citizen_answer}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.fourth_citizen_answer && Boolean(formik.errors.fourth_citizen_answer)
            }
            helperText={formik.touched.fourth_citizen_answer && formik.errors.fourth_citizen_answer}
          />
        </Grid>
      </Grid>
      <MediumHeightDivider />
      <StyledButtonOutlined onClick={() => formik.submitForm()}>Registrar</StyledButtonOutlined>
    </>
  );
}

export default function UserQuestions() {
  return (
    <Container>
      <QuestionsForm />
      <MediumHeightDivider />
    </Container>
  );
}

UserQuestions.Questions = QuestionsForm;
