import { styled, Tab, Tabs } from "@mui/material";

export const HomeStyledTabs = styled((props) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: 5,
  },

  "& .MuiTabs-indicatorSpan": {
    maxWidth: { xs: "200px", xl: "400px" },
    width: "100%",
    backgroundColor: "#7FB847",
  },

  "& .MuiTabScrollButton-root": {
    color: "white",
  },

  "& button": {
    minWidth: { xs: "0", md: "200px" },
  },
});

export const HomeStyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 600,
  fontSize: { xs: "0.9rem", md: "1rem", lg: "1.2rem" },
  marginRight: theme.spacing(1),
  color: "rgba(255, 255, 255)",
  marginBottom: theme.spacing(1),

  "&.Mui-selected": {
    color: "#fff",
  },

  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));
