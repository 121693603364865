import { Box, Link, Stack, Typography } from "@mui/material";
import { StyledTab, StyledTabs, TabsDivider } from "components/NewDesign/Tabs";
import FaresTable from "./FaresTable";
import { useState } from "react";
import urlify from "utilities/functions/Urlify";

const headingAvailable = (text) => <span className="home_post_text">{urlify(text)}</span>;

export default function DetailsTab({ serviceDescription, requirements }) {
  const [procedureToShow, setProcedureToShow] = useState(0);

  return (
    <>
      <Stack direction={"column"} spacing={4} marginY={"20px"}>
        <Box
          component={"p"}
          sx={{
            all: "unset",
            color: "#666666",
            fontSize: "1rem",
            fontWeight: "normal",
          }}
        >
          {serviceDescription?.description}
        </Box>
      </Stack>
      <Stack direction={"column"}>
        <Typography variant="h6" fontWeight={600} fontSize={"1rem"} color={"#666666"}>
          REQUERIMIENTOS O REQUISITOS
        </Typography>
        <Stack direction={"column"}>
          {requirements?.map((requirement) => (
            <div key={requirement.id}>
              <Box
                fontSize={"0.9rem"}
                sx={{
                  all: "unset",
                  fontSize: "0.9rem",
                  color: "#666666",
                }}
              >
                &bull; {requirement?.name}
                {requirement.route && (
                  <Link sx={{ cursor: "pointer" }} href={requirement.route}>
                    {" "}
                    (DESCARGAR)
                  </Link>
                )}
              </Box>

              <Typography variant="body1" fontSize={"0.9rem"} color={"#666666"} mb={2} ml={2}>
                {requirement?.description}
              </Typography>
            </div>
          ))}
        </Stack>
      </Stack>

      {/* PROCEDURES */}
      <Stack
        direction={"row"}
        spacing={4}
        sx={{
          borderBottom: "1px solid #1393D0",
          paddingLeft: "15px",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Box
          sx={{
            textTransform: "none",
            color: "#134779",

            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          PROCEDIMIENTOS A SEGUIR
        </Box>
        <StyledTabs
          value={procedureToShow}
          onChange={(e, val) => setProcedureToShow(val)}
          allowScrollButtonsMobile
        >
          <StyledTab value={0} label="EN LINEA" />
          <TabsDivider orientation={"vertical"} />
          <StyledTab value={1} label="PRESENCIAL" />
        </StyledTabs>
      </Stack>

      <Stack component={"ol"} direction={"column"}>
        {serviceDescription.procedures
          .filter((procedures) => procedures.type === procedureToShow)
          .map((requirement) => (
            <li key={requirement.id}>
              <Typography variant="body1" fontSize={"1rem"} color={"#666666"}>
                {headingAvailable(requirement.step)}
              </Typography>
            </li>
          ))}
      </Stack>

      <Stack direction={"column"}>
        <Box
          sx={{
            textTransform: "none",
            color: "#134779",

            fontSize: "1rem",
            fontWeight: "bold",
            borderBottom: "1px solid #1393D0",
            paddingLeft: "15px",
            marginTop: "14px",
            paddingBottom: "10px",
          }}
        >
          TARIFAS DE SERVICIO
        </Box>

        <Stack direction={"column"} marginTop={"10px"}>
          {serviceDescription.prices.map((price) => (
            <Box key={price.id}>
              <Typography variant="h6" fontSize={"1rem"} color={"#666666"}>
                <strong style={{ color: "#134779", paddingLeft: "16px" }}>CONCEPTO:</strong>
                {" " + price.concept}
              </Typography>

              <FaresTable
                price={price}
                external_pay={serviceDescription?.external_pay}
                sirit_code={serviceDescription?.sirit_code}
              />
            </Box>
          ))}
        </Stack>
      </Stack>
    </>
  );
}
