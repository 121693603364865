import { combineReducers } from "redux";
import UiReducer from "./UiReducer";
import AuthReducer from "./AuthReducer";
import ServiceReducer from "./ServiceReducer";

const RootReducer = combineReducers({
  uiReducer: UiReducer,
  authReducer: AuthReducer,
  serviceReducer: ServiceReducer,
});

export default RootReducer;
