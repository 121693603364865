import {
  BsArrowReturnLeft,
  BsCheck,
  BsClockFill,
  BsCurrencyDollar,
  BsEraser,
  BsHandThumbsDown,
  BsInfo,
} from "react-icons/bs";
import { LuCalendarOff } from "react-icons/lu";

export const getFormIcon = (status = "") => {
  switch (status.toUpperCase()) {
    case "BORRADOR":
      return {
        Component: BsEraser,
        color: "#87adbd",
      };
    case "EN PROCESO":
      return {
        Component: BsClockFill,
        color: "#87adbd",
      };
    case "APROBADO":
      return {
        Component: BsCheck,
        color: "#a5dc86",
      };
    case "RECHAZADO":
      return {
        Component: BsHandThumbsDown,
        color: "#f44336",
      };
    case "EXPIRADO":
      return {
        Component: LuCalendarOff,
        color: "#f8bb86",
      };
    case "DESESTIMADO":
      return {
        Component: BsHandThumbsDown,
        color: "#f8bb86",
      };
    case "NO OBJECIÓN":
      return {
        Component: BsInfo,
        color: "#3fc3ee",
      };
    case "PENDIENTE DE PAGO":
      return {
        Component: BsCurrencyDollar,
        color: "#87adbd",
      };
    case "DEVUELTO PARA CORRECCIÓN":
      return {
        Component: BsArrowReturnLeft,
        color: "#87adbd",
      };
    default:
      return {
        Component: BsInfo,
        color: "#87adbd",
      };
  }
};
