import { Stack } from "@mui/material";
import { getPersonalDocuments } from "api/MyDocuments";
import CenterLoading from "components/CenterLoading/CenterLoading";
import CustomPagination from "components/CustomPagination";
import DocumentsOfRequestsCard from "components/DocumentsOfRequestsCard/DocumentsOfRequestsCard";
import { format } from "date-fns";
import { useLayoutEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { UpdateAppSubHeaderTitle } from "redux/actions/UiActions";
import { SmallHeightDivider } from "theme/Styles";
import { replaceGuionToSlashFromString } from "utilities/functions/StringUtil";
import { SectionTitle } from "views/MyDesk/styles/MyDeskStyles";

function MyDocuments() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData(["userData"]);
  const [currentPage, setCurrentPage] = useState(1);

  const { data: documentsData, isLoading: documentsDataLoading } = useQuery(
    ["documentsData", currentPage],
    () => getPersonalDocuments(userData.payload.citizen_id, currentPage)
  );

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const documentsDataForShow = documentsData?.data
    ?.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    })
    ?.map((document) => {
      const typeForName =
        document.extension === "vnd.ms-excel"
          ? "xls"
          : document.extension === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ? "xlsx"
            : document.extension;
      return {
        name: `${document.name}.${typeForName}`,
        documentType: document.extension,
        date: format(
          new Date(replaceGuionToSlashFromString(document.created_at)),
          "yyyy-MM-dd HH:mm:ss"
        ),
        url: document.url,
        type: document.extension,
      };
    });

  useLayoutEffect(() => {
    dispatch(UpdateAppSubHeaderTitle("Escritorio Ciudadano")); //SET SUBHEADER TITLE
  }, []);

  if (documentsDataLoading) return <CenterLoading />;

  return (
    <Fade>
      {documentsData?.data?.length > 0 ? (
        <Stack direction={"column"} alignItems={"center"} spacing={2}>
          <DocumentsOfRequestsCard title={"Documentos Personales"} data={documentsDataForShow} />
          <CustomPagination
            variant={"light"}
            count={documentsData?.last_page}
            value={currentPage - 1}
            onClick={(page) => {
              handleChangePage(page);
            }}
          />
        </Stack>
      ) : (
        <SectionTitle>No hay documentos personales</SectionTitle>
      )}
      <SmallHeightDivider />
    </Fade>
  );
}

export default MyDocuments;
