import backOfficeService from "services/ApiServiceCall";

export const sendRequiredAction = (requestData) => {
  return backOfficeService()
    .post("/requests/action/save", requestData)
    .then(({ data }) => data);
};

export const sendMessage = (requestData) => {
  return backOfficeService()
    .post("/comment", requestData)
    .then(({ data }) => data);
};

export const assignDocumentsForRequiredActionInSoftExpert = (requestData) => {
  return backOfficeService()
    .post("/assigndocumentsoftexpert", requestData)
    .then(({ data }) => data);
};
