import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const COLORS = (variant) => {
  return {
    text: variant === "dark" ? "#D0EAF6" : "#13477A",
    border: variant === "dark" ? "#D0EAF6" : "#13477A",
    selectedText: variant === "dark" ? "#13477A" : "#FFFFFF",
    selectedBackground: variant === "dark" ? "#D0EAF6" : "#13477A",
  };
};

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
});

const PageButton = styled("button")(({ variant, theme }) => ({
  all: "unset",
  display: "none",
  justifyContent: "center",
  textAlign: "center",
  border: `1px solid ${COLORS(variant).border}`,
  height: "2rem",
  alignItems: "center",
  paddingLeft: "0.7rem",
  paddingRight: "0.7rem",
  textTransform: "uppercase",
  cursor: "pointer",

  [theme.breakpoints.up("md")]: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    display: "flex",
  },
}));

const ControlButton = styled("button")(({ variant, theme }) => ({
  all: "unset",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  border: `1px solid ${COLORS(variant).border}`,
  height: "2rem",
  alignItems: "center",
  paddingLeft: "0.7rem",
  paddingRight: "0.7rem",
  textTransform: "uppercase",
  cursor: "pointer",
  color: COLORS(variant).text,

  [theme.breakpoints.up("md")]: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },

  "&:hover": {
    backgroundColor: COLORS(variant).selectedBackground,
    color: COLORS(variant).selectedText,
  },
}));

const Ellipsis = styled("button")(({ variant, theme }) => ({
  all: "unset",
  display: "none",
  paddingLeft: "0.7rem",
  paddingRight: "0.7rem",
  border: `1px solid ${COLORS(variant).border}`,
  height: "2rem",
  alignItems: "center",
  textAlign: "center",
  color: COLORS(variant).text,
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

/**
 * @param {number} count - number of items in the list
 * @param {function} onClick - callback for when a page button is clicked
 * @param value
 * @param {"light" || "dark"} variant - pagination theme
 * @returns {JSX.Element}
 * @constructor
 */
export default function CustomPagination({
  count,
  onClick,
  value = 1,
  variant = "dark" || "light",
}) {
  const { items } = usePagination({
    count: count,
    onChange: (e, page) => onClick(page),
    page: value + 1,
    showFirstButton: true,
    showLastButton: true,
  });

  return (
    <nav>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children;

          switch (type) {
            case "start-ellipsis":
              children = <Ellipsis variant={variant}>...</Ellipsis>;
              break;
            case "end-ellipsis":
              children = <Ellipsis variant={variant}>...</Ellipsis>;
              break;
            case "page":
              children = (
                <PageButton
                  type="button"
                  variant={variant}
                  sx={{
                    width: { md: "3rem" },
                    fontWeight: selected ? "bold" : "normal",
                    backgroundColor: selected ? COLORS(variant).selectedBackground : "transparent",
                    color: selected ? COLORS(variant).selectedText : COLORS(variant).text,
                  }}
                  {...item}
                >
                  {page}
                </PageButton>
              );
              break;
            case "previous":
              children = (
                <ControlButton type="button" variant={variant} {...item}>
                  <ChevronLeft />
                </ControlButton>
              );
              break;
            case "next":
              children = (
                <ControlButton type="button" variant={variant} {...item}>
                  <ChevronRight />
                </ControlButton>
              );
              break;
            case "first":
              children = (
                <ControlButton type="button" variant={variant} {...item}>
                  INICIO
                </ControlButton>
              );
              break;
            case "last":
              children = (
                <ControlButton type="button" variant={variant} {...item}>
                  FINAL
                </ControlButton>
              );
              break;
            default:
              children = null;
          }

          return <li key={index}>{children}</li>;
        })}
      </List>
    </nav>
  );
}
