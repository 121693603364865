import { Fragment, useEffect, useRef, useState } from "react";
import {
  MediumHeightDivider,
  SmallHeightDivider,
  StyledCheckCircleIcon,
  SubTitle,
} from "theme/Styles";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { HideGlobalLoading, ShowGlobalLoading } from "redux/actions/UiActions";
import { useParams } from "react-router-dom";
import { Container, SuccessContainer } from "./styles/RequiredFormStyles";
import { arrayArrayToArray, localToArray, transformField } from "utilities/functions/ArrayUtil";
import Form from "views/RequestService/components/Form/Form";
import { useQueryClient } from "react-query";
import {
  getForm,
  linkingDocumentsToRequestInSoftExpert,
  registerForm,
  uploadFormDocuments,
} from "api/RequestService";
import {
  reverseTransformFormData,
  reverseTransformFormGrid,
  transformFileData,
  transformFormData,
  transformFormGrid,
} from "views/RequestService/RequestServiceUtils";
import { cleanString } from "utilities/functions/StringUtil";
import { useSnackbar } from "notistack";
import { FIELD_TYPES } from "views/RequestService/components/Form/FormConstants";

import { isEmpty } from "utilities/functions/ValidationUtil";
import ImportantInformationModal from "components/ImportantInformationModal/ImportantInformationModal";
import { localToNumber } from "utilities/functions/NumberUtil";
import { saveDraft } from "api/Drafts";

function RequiredForm() {
  const history = useHistory();
  let { requestID } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const successRef = useRef(null);
  const formRef = useRef(null);
  const [isDraft, setIsDraft] = useState(false);
  const [formData, setFormData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  // const [successResponse, setSuccessResponse] = useState();
  // const [priceModalIsOpen, setPriceModalIsOpen] = useState(true);
  const [selectedVariation, setSelectedVariation] = useState();
  const [draftLoading, setDraftLoading] = useState(false);

  const [showRequestDetail, setShowRequestDetail] = useState(false);
  const [showRestoreFormModal, setShowRestoreFormModal] = useState(false);

  const [hasDraft, setHasDraft] = useState(true);

  const [state, setState] = useState({
    rules: [],
    data: {},
    grid: {},
    fakeStep: 0,
    step: 0,
    totalPayment: 0,
    variations: [],
  });

  const requestData = queryClient.getQueryData(["serviceRequestedDetail", requestID]);
  const userData = queryClient.getQueryData(["userData"]);

  const getAndSetForm = async () => {
    try {
      dispatch(ShowGlobalLoading("Cargando"));
      setIsLoading(true);
      let response = await getForm(
        requestData?.request?.request_actions?.expertform_id,
        userData.payload.citizen_id
      );
      setFormData(response);
      setIsLoading(false);
      dispatch(HideGlobalLoading());
    } catch (error) {
      history.push("/public");
      setIsLoading(false);
      dispatch(HideGlobalLoading());
      throw new Error("An error has ocurred");
    }
  };

  useEffect(() => {
    if (requestData?.request?.request_actions?.expertform_id && userData.payload.citizen_id) {
      getAndSetForm();
    }
  }, [requestData, userData]);

  const getData = () => {
    //separating response by steps
    const plainData = [];
    const data = formData.fields;
    const _data = [];
    for (let i = 0; i < data.length; i++) {
      const step = data[i];
      let _step = [];
      for (let j = 0; j < step.length; j++) {
        const field = step[j];
        plainData.push(field);
        if (_step.length && field.subtype == "h1") {
          _data.push(_step);
          _step = [];
        }
        _step.push(field);
        if (step.length - 1 == j) {
          _data.push(_step);
          _step = [];
        }
      }
    }

    return {
      formulary_data: formData.formulary_data,
      data: _data.map((step) => step.map(transformField)),
      plainData: plainData.map(transformField),
      saved_fields: formData.saved_fields,
      date: Number(new Date()),
      //dev
      //data: _data.map(step => step.map(transformField)).reverse(),
    };
  };

  const handleFormSave = async () => {
    const formData = formRef.current?.saveForm();
    const _plainData = arrayArrayToArray(formData?.localData);

    const request = {
      citizen_id: userData.payload.citizen_id,
      service_id: requestData?.request?.service?.id,
      expertform_id: requestData?.request?.request_actions?.expertform_id,
      data: transformFormData(formData?.values, _plainData, formData?.errors).filter(
        (field) => field.type !== FIELD_TYPES.file
      ),
      grid: transformFormGrid(formData?.values, _plainData),
      appliedRuleList: localToArray(formData?.appliedRuleList), //Array.from(noDuplicates).reverse(),
      fakeStep: formData?.fakeStep,
      step: formData?.step,
      variations: [selectedVariation?.id],
      totalPayment: selectedVariation?.price,
    };
    if (isEmpty(request.data)) {
      return;
    }
    try {
      await saveDraft(request);
    } catch (error) {}
  };

  const handleRestoreForm = () => {
    setShowRestoreFormModal(false);
    // setPriceModalIsOpen(false);
    setIsDraft(true);
  };

  const handleRestoreFormModal = () => {
    setShowRestoreFormModal(!showRestoreFormModal);
  };

  const sendRequest = async (valuesOfForm) => {
    const formData = formRef.current?.saveForm();
    const _plainData = arrayArrayToArray(formData?.localData);
    dispatch(ShowGlobalLoading("Cargando"));
    try {
      const FilesOfForm = transformFileData(valuesOfForm, _plainData);

      let canSubmitForm = true;
      let uploadedFilesRoutes = FilesOfForm.oldFile;

      const formDataOfFiles = new FormData();
      if (FilesOfForm?.newFile.length > 0) {
        for (let i = 0; i < FilesOfForm.newFile.length; i++) {
          formDataOfFiles.append(
            "file[]",
            FilesOfForm.newFile[i].file,
            FilesOfForm.newFile[i].file.name
          );
        }
        const uploadFilesConfig = {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            dispatch(ShowGlobalLoading(`Subiendo documentos ${percentCompleted}%`));
          },
        };

        let responseFilesUploaded = await uploadFormDocuments(formDataOfFiles, uploadFilesConfig);
        if (responseFilesUploaded.success) {
          uploadedFilesRoutes = [
            ...uploadedFilesRoutes,
            ...responseFilesUploaded.files.map((item, index) => {
              return {
                ...item,
                label: FilesOfForm.newFile[index].label,
              };
            }),
          ];
        } else {
          canSubmitForm = false;
        }
      }

      if (canSubmitForm) {
        let canFormContinue = true;

        const requestHasFiles = Boolean(
          FilesOfForm?.newFile?.length > 0 || FilesOfForm?.oldFile?.length > 0
        );

        const request = {
          req: {
            service_id: requestData?.request?.service?.id,
            doc_identification: userData.payload.citizen_id,
            name_service: requestData?.request?.service?.name,
            form_version: cleanString(getData().formulary_data?.version),
          },
          form: {
            citizen_record_id: userData.payload.citizen_id,
            expertform_id: requestData?.request?.request_actions?.expertform_id,
            data: transformFormData(valuesOfForm, _plainData).filter(
              (field) => field.type != FIELD_TYPES.file && field.type != FIELD_TYPES.grid
            ),
            grid: transformFormGrid(valuesOfForm, _plainData),
          },
          required_action: true,
          request_id: requestID,
          execute_activity: !requestHasFiles, // if request has files, execute activity will be false
        };

        dispatch(ShowGlobalLoading("Registrando formulario"));

        let responseFormSubmit = await registerForm(request);

        if (responseFormSubmit.success) {
          if (uploadedFilesRoutes.length > 0) {
            dispatch(ShowGlobalLoading("Procesando formulario"));
            if (
              requestData?.request?.service?.send === 1 &&
              requestData?.request?.service?.provider === "softexpert"
            ) {
              //  let uploadSoftExpertArrayAxios = [];
              for (let i = 0; i < uploadedFilesRoutes.length; i++) {
                //SOFTEXPERT HAVE A LIMIT OF 25MB, AND I REMOVE ALL uploadedFilesRoutes with > 25mb of size
                if (
                  uploadedFilesRoutes[i]?.size_mb < parseInt(process.env.REACT_APP_MAX_FILE_SIZE)
                ) {
                  const uploadSoftExpertConfig = {
                    documents: [
                      {
                        ...uploadedFilesRoutes[i],
                      },
                    ],
                    size_mb: uploadedFilesRoutes[i]?.size_mb,
                    title: responseFormSubmit.title,
                    record_id: responseFormSubmit.code,
                    attribute: responseFormSubmit.attributes,
                    process_id: requestData?.request?.service?.process_id,
                    acronym: responseFormSubmit.acronym,
                    names: [uploadedFilesRoutes[i].label],
                    activity_id: requestData?.request?.activity?.activity_id,
                    // SET FALSE ON THE LAST ITEM, OR IF THERE IS ONLY ONE
                    new_request:
                      i !== uploadedFilesRoutes.length - 1 && uploadedFilesRoutes.length > 1,
                  };
                  //uploadSoftExpertArrayAxios.push(linkingDocumentsToRequestInSoftExpert(uploadSoftExpertConfig));
                  await linkingDocumentsToRequestInSoftExpert(uploadSoftExpertConfig);
                }
              }
              //   await axios.all(uploadSoftExpertArrayAxios);
            }

            /* let requestBackOffice = {
              documents: uploadedFilesRoutes
            };
            let responseBackOffice = await linkingDocumentsToRequestInBackOffice(requestBackOffice, responseFormSubmit.RequestID);
            if (responseBackOffice.success) {
              //GOOD
            } else {
              canFormContinue = false;
              enqueueSnackbar("Ha ocurrido un error favor intentar mas tarde.", { variant: 'error' })
              throw Error;
            } */
          }

          if (canFormContinue) {
            // NEW: After a submitted form, remove the form from local storage
            window.localStorage.removeItem(requestData?.request?.request_actions?.expertform_id);

            enqueueSnackbar("Formulario enviado satisfactoriamente.", {
              variant: "success",
            });
            //     history.push(`/app/serviceRequestedDetails/${responseFormSubmit.RequestID}payment`)
            //queryClient.invalidateQueries('serviceForm')
            await queryClient.invalidateQueries("requestsList");

            //setSuccessResponse(responseFormSubmit);
            setShowRequestDetail(true);
            successRef?.current?.scrollIntoView();
            setTimeout(() => {
              //history.push(`/app/serviceRequestedDetails/${responseFormSubmit.RequestID}`)
              window.location.reload();
            }, 2000);
          }
        } else {
          enqueueSnackbar("Ha ocurrido un error favor intentar mas tarde.", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("Ha ocurrido un error subiendo los documentos.", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Ha ocurrido un error,contacte al soporte para mas información", {
        variant: "error",
      });
    }
    dispatch(HideGlobalLoading());
  };

  //componentDidUpdate
  useEffect(() => {
    if (formData === undefined) {
      return;
    }
    if (localToArray(getData()?.saved_fields?.data).length > 0 && isDraft !== true) {
      setShowRestoreFormModal(true);
      return;
    }
    if (
      !localToArray(getData()?.plainData).length ||
      !getData()?.saved_fields ||
      !localToArray(getData()?.saved_fields?.data).length ||
      isDraft === false
    ) {
      return;
    }

    const { appliedRuleList, data, grid, fakeStep, step, totalPayment, variations } =
      getData()?.saved_fields;
    dispatch(ShowGlobalLoading("Restableciendo"));
    setDraftLoading(true);
    setState({
      rules: localToArray(appliedRuleList),
      fakeStep: localToNumber(fakeStep),
      data: reverseTransformFormData(data, getData()?.plainData),
      grid: reverseTransformFormGrid(grid, getData()?.plainData),
      step: localToNumber(step),
      totalPayment: totalPayment,
      variations: variations,
    });

    setTimeout(() => {
      //Simulate loading for 2.5s
      //Bug with React 17 for update some component value is needed to unmount and mount the Form component
      setDraftLoading(false);
      dispatch(HideGlobalLoading());
    }, 2500);

    return () => {};
  }, [formData, isDraft]);

  // // check if form has draft available in local storage
  // useEffect(() => {
  //   if (requestData?.request?.request_actions?.expertform_id) {
  //     let draft = window.localStorage.getItem(requestData?.request?.request_actions?.expertform_id);
  //     if (!draft) setHasDraft(false);
  //   }
  // }, [requestData]);

  //componentDidUpdate
  useEffect(() => {
    if (formData === undefined) return;
    return () => {};
  }, [formData, isDraft]);

  if (isLoading) return null;

  if (showRestoreFormModal) {
    return (
      <ImportantInformationModal
        open={hasDraft}
        onBackDropClick={() => {}}
        onCloseClick={handleRestoreFormModal}
        CloseTitle="Cancelar"
        CloseButton
        buttonTitle="Confirmar"
        buttonClick={handleRestoreForm}
        content={
          <Fragment>
            <strong>Se ha encontrado información previa de una solicitud sin terminar.</strong>
            <br />
            <strong>
              <p>¿Desea cargarla para esta solicitud?</p>
            </strong>
          </Fragment>
        }
      />
    );
  }

  return (
    <Container>
      {!showRequestDetail ? (
        <>
          <Container>
            <Form
              ref={formRef}
              doRequest={sendRequest}
              data={getData().data}
              plainData={getData().plainData}
              handleFormSave={handleFormSave}
              initialForm={state}
              variations={[selectedVariation?.id]}
              isDraft={isDraft}
              isSurvey={false}
              multipleDocuments={requestData?.request?.service?.multiple_document === "true"}
              formID={requestData?.request?.request_actions?.expertform_id}
            />
          </Container>
        </>
      ) : (
        <Container ref={successRef}>
          <SuccessContainer>
            <StyledCheckCircleIcon />
            <SmallHeightDivider />
            <SubTitle>Formulario enviado satisfactoriamente.</SubTitle>
            <MediumHeightDivider />
          </SuccessContainer>
        </Container>
      )}
    </Container>
  );
}

export default RequiredForm;
