import backOfficeService from "services/ApiServiceCall";

//TO DO ADD STATUS
export const getRequestedServices = (citizenID, page, status) => {
  return backOfficeService()
    .get(`/requests/extras/user/${citizenID}?page=${page}&status=${status}&type=1`)
    .then(({ data }) => data);
};

export const getRequestedServicesWithFilters = (citizenID, page, status, name) => {
  return backOfficeService()
    .get(`/requests/extras/service/${citizenID}?page=${page}&name=${name}&status=${status}&type=1`)
    .then(({ data }) => data);
};

export const getListServicesMostConsulted = (page) => {
  return backOfficeService()
    .get(`/services/extras/mostconsulted?page=${page}`)
    .then(({ data }) => data);
};

export const getFilteredServices = (requestType, page) => {
  return backOfficeService()
    .get(`/services/filterByType/${requestType}?page=${page}`)
    .then(({ data }) => data);
};
