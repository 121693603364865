import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { polyfill } from "seamless-scroll-polyfill";
import "./index.css";
import "./components/SweetAlert/styles/SweetAlert.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

polyfill();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
