import { SweetAlert } from "components/SweetAlert/SweetAlert";
import { safeValExtraction } from "utilities/functions/ObjectUtil";
import { defaultString, localToString } from "utilities/functions/StringUtil";
import * as yup from "yup";
import { FIELD_TYPES, MASK_LIST, RULE_LIST } from "./FormConstants";

export const getFieldValidation = (field) => {
  if (
    !field ||
    !field.type ||
    field.hidden ||
    (field.type === FIELD_TYPES.select && !field.required)
  ) {
    return;
  }

  let validator;

  const params = String(field?.MaskParam || "").split(/[~&]/);

  switch (field.type) {
    case FIELD_TYPES.time:
      validator = yup.date();
      break;
    case FIELD_TYPES.date:
      validator = yup.date();
      break;
    case FIELD_TYPES.radioGroup:
      validator = yup.string();
      break;
    case FIELD_TYPES.text:
      const { min, max } = field;
      validator = yup.string().trim();

      if (min) {
        validator = validator.min(min, defaultString.minText);
      }

      if (max) {
        validator = validator.max(max, defaultString.maxText);
      }

      break;
    case FIELD_TYPES.textarea:
      validator = yup.string().trim();
      break;
    case FIELD_TYPES.select:
      validator = yup.string();
      break;
    case FIELD_TYPES.file:
      validator = field?.required
        ? yup.object().shape({
            files: yup
              .array()
              .min(1, defaultString.requiredText)
              .required(defaultString.requiredText),
          })
        : yup.object().shape({
            files: yup.array(),
          });
      break;
    case FIELD_TYPES.grid:
      validator = yup.array();

      const maxGridSize = params[0] || field?.maxGridSize;
      const minGridSize = params[1] || field?.minGridSize;

      if (maxGridSize) {
        validator = validator.max(parseInt(maxGridSize), defaultString.gridMaxSize);
      }

      if (field?.required || minGridSize) {
        validator = validator
          .min(minGridSize ? parseInt(minGridSize) : 1, defaultString.gridMinSize)
          .required(defaultString.requiredText);
      }
      break;
    default:
      break;
  }

  if (field?.required && validator) {
    validator = validator.required(defaultString.requiredText);
  }

  switch (MASK_LIST[localToString(field.Mask)]) {
    case MASK_LIST[0]:
      validator = validator.min(11, defaultString.validCedula);
      break;
    case MASK_LIST[2]:
      validator = validator.min(10, defaultString.validPhone);
      break;
    case MASK_LIST[3]:
      validator = validator.min(10, defaultString.validPhone);
      break;
    case MASK_LIST[5]:
      validator = validator.email(defaultString.validEmail);
      break;
    default:
      break;
  }

  return validator;
};

export const fieldRuleChanger = ({
  field,
  ruleAction,
  ruleField,
  ruleList,
  values,
  setFieldValue,
}) => {
  const findRuleField = ruleField.find((fieldName) => field?.fieldKey === fieldName);

  let _field = {
    ...field,
  };

  //Main field modifier
  if (findRuleField) {
    const rulesToApply = ruleField
      .map((fieldName, index) => {
        if (field?.fieldKey === fieldName) {
          return ruleAction[index];
        } else {
          return null;
        }
      })
      .filter((item) => item !== null);

    //add more ruleList if its rule five and the other field (select) has value
    if (
      rulesToApply.find((item) => item === RULE_LIST[5]) &&
      safeValExtraction(values[field?.fieldKey], "rule")
    ) {
      ruleList.push(safeValExtraction(values[field?.fieldKey], "rule"));
    }

    //return the modified object
    _field = {
      ...dataObjectRuleChanger(field, rulesToApply, setFieldValue),
    };
  }

  return _field;
};

export const dataObjectRuleChanger = (item, rules, valChange) => {
  const _item = { ...item };
  for (const ruleOptions of rules) {
    // de-structure ruleOptions, so we can get the rule, mask and maskParam
    const ruleSplit = ruleOptions.split("$");
    const rule = RULE_LIST[ruleSplit[0]];
    const ruleMask = ruleSplit[1];
    const ruleMaskParam = ruleSplit[2];

    switch (rule) {
      case RULE_LIST[0]:
        _item.hidden = true;
        break;
      case RULE_LIST[1]:
        _item.hidden = false;
        break;
      case RULE_LIST[2]:
        _item.enabled = false;
        break;
      case RULE_LIST[3]:
        _item.enabled = true;
        break;
      case RULE_LIST[4]:
        _item.required = true;
        break;
      case RULE_LIST[5]:
        //
        break;
      case RULE_LIST[6]:
        _item.required = false;
        break;
      case RULE_LIST[7]:
        if (_item?.type === "file") valChange(_item?.fieldKey, undefined);
        else valChange(_item?.fieldKey, "");
        break;
      case RULE_LIST[8]:
        // Cambiar a cédula
        _item.Mask = "0";
        _item.label = "Número de documento";
        break;
      case RULE_LIST[9]:
        // Cambiar a pasaporte
        _item.Mask = null;
        _item.label = "Número de documento";
        break;
      case RULE_LIST[10]:
        // Cambiar a RNC
        _item.Mask = "1";
        _item.label = "Número de documento";
        break;
      case RULE_LIST[13]:
        _item.Mask = "";
        break;
      case RULE_LIST[14]:
        _item.Mask = ruleMask;
        _item.MaskParam = ruleMaskParam;
        break;
      default:
        break;
    }
  }
  return _item;
};

export function maskValidationAlert({ condition = false, invalidMessage = () => {}, props = {} }) {
  if (condition) {
    const _localFieldErrors = { ...props.localFieldErrors };
    delete _localFieldErrors[props.fieldKey];
    delete _localFieldErrors.undefined;
    props?.setLocalFieldErrors && props?.setLocalFieldErrors(_localFieldErrors);
  } else {
    const _invalidMessage =
      invalidMessage || "Ha ocurrido un error durante la validación, por favor intente de nuevo";

    SweetAlert.fire({
      title: "Error en la validación",
      text: _invalidMessage,
      icon: "error",
      confirmButtonText: "Ok",
    });

    const _localFieldErrors = {
      ...props?.localFieldErrors,
    };

    _localFieldErrors[props.fieldKey] = _invalidMessage;
    delete _localFieldErrors.undefined;
    props?.setLocalFieldErrors && props?.setLocalFieldErrors(_localFieldErrors);
  }
}

// create jsDoc for handleMaskValidation

/**
 * @typedef {Object} MaskValidationOptions
 * @property {Promise} validationFunction - The function that will be called to validate the mask
 * @property {function} [condition] - The condition that will be used to validate the response
 * @property {string|function} [invalidMessage] - The message that will be displayed if the condition is not met
 * @property {string} [errorMessage] - The message that will be displayed if the validationFunction throws an error
 * @property {Object} [props] - The props that will be used to set the localFieldErrors
 */

// TODO: refactor code to implement onSuccess and onError functions
export async function handleMaskValidation({
  validationFunction,
  condition = () => {},
  invalidMessage = "",
  errorMessage = "Ha ocurrido un error en la validación",
  onSuccess = () => {},
  onError = () => {},
  props,
}) {
  try {
    props?.setDisableStepsButtons && props?.setDisableStepsButtons(true);

    const res = await validationFunction;
    const response = { ...res, valid: condition(res) };

    props?.setDisableStepsButtons && props?.setDisableStepsButtons(false);

    const parsedInvalidMessage =
      typeof invalidMessage === "function" ? invalidMessage(response) : invalidMessage;

    maskValidationAlert({
      condition: response.valid,
      invalidMessage: parsedInvalidMessage || "Ha ocurrido un error en la validación",
      props,
    });

    // return the response along with the condition to be used in the next step
    return { ...response, valid: condition(response) };
  } catch (error) {
    console.error(error);
    const { response } = error;

    maskValidationAlert({
      condition: false,
      invalidMessage: response?.data?.message || errorMessage,
      props,
    });

    props?.setDisableStepsButtons && props?.setDisableStepsButtons(false);
  }
}

export function getFirstStep(steps) {
  for (let i = 0; i < steps.length; i++) {
    const step = steps[i];
    if (step[0].type === "header" && !step[0].hidden) {
      return i;
    }
  }
}
