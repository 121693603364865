import { Fragment, memo } from "react";
import COLORS from "theme/Colors";
import { Container } from "./styles/TextFieldStyles";
import InputMask from "react-input-mask";
import { FieldTitle, Row, StyledTextInput } from "theme/Styles";
import CircularProgress from "@mui/material/CircularProgress";

function TextField({
  id,
  title,
  placeholder,
  value,
  onChange,
  onBlur,
  type,
  error,
  mask,
  unMaskedValue,
  useMaskPresets,
  required,
  multiline,
  minLength,
  maxLength: _maxLength,
  max,
  min,
  minNumber,
  maxNumber,
  disabled,
  isLoading,
  endAdornment,
  helperText = " ",
  minRows = 2,
  onKeyDown,
  length,
  fullWidth = false,
  ...props
}) {
  const maxLength = _maxLength || length || multiline ? 4000 : 250;

  const removeTextFromInputMaskValue = (value) => {
    return value.replace(/[^0-9.]+/g, "");
  };

  const maskPresets = {
    phone: "(999)-999-9999",
    identification: "999-9999999-9",
    passport: "*",
    rnc: "99999999999",
    "rnc-2": "999-99999-9",
    "solo numero": "999999999999999999999999999999999999",
  };

  let fieldMask = useMaskPresets ? maskPresets[mask] || maskPresets["passport"] : mask;

  //limit min & max length on fieldMask
  if (fieldMask && (length || maxLength)) {
    fieldMask = fieldMask.slice(0, length || maxLength);
  }

  return (
    <Container sx={{ width: fullWidth ? "100%" : "auto" }}>
      <Row>
        <FieldTitle>{title} </FieldTitle>
        <div style={{ width: "5px" }} />
        {required ? (
          <Fragment>
            <FieldTitle style={{ color: COLORS.red }}>*</FieldTitle>
          </Fragment>
        ) : null}
      </Row>

      {mask ? (
        <InputMask
          {...props}
          id={id}
          mask={fieldMask}
          maskChar={null}
          value={value}
          disabled={disabled}
          onKeyDown={onKeyDown}
          min={2}
          minLength={2}
          onBlur={
            unMaskedValue
              ? (e) => {
                  onBlur &&
                    onBlur({
                      target: {
                        id: e.target.id,
                        value: removeTextFromInputMaskValue(e.target.value),
                      },
                    });
                }
              : onBlur
          }
          onChange={
            unMaskedValue
              ? (e) => {
                  onChange({
                    target: {
                      id: e.target.id,
                      value: removeTextFromInputMaskValue(e.target.value),
                    },
                  });
                }
              : onChange
          }
        >
          {(inputProps) => (
            <StyledTextInput
              {...inputProps}
              disabled={disabled}
              fullWidth
              variant="outlined"
              placeholder={placeholder}
              type={type}
              helperText={helperText}
              FormHelperTextProps={{ sx: { fontSize: "0.90rem" } }}
              error={error}
              InputProps={{
                endAdornment: isLoading ? (
                  <CircularProgress size="1em" />
                ) : endAdornment !== undefined ? (
                  endAdornment
                ) : null,
              }}
              onKeyDown={onKeyDown}
            />
          )}
        </InputMask>
      ) : (
        <StyledTextInput
          {...props}
          fullWidth
          disabled={disabled}
          multiline={multiline}
          id={id}
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          type={type}
          helperText={helperText}
          FormHelperTextProps={{ sx: { fontSize: "0.90rem" } }}
          error={error}
          minRows={minRows}
          min={minNumber}
          max={maxNumber}
          inputProps={{
            maxLength: maxLength || length,
            minLength: minLength,
            max: max,
            min: min,
          }}
          InputProps={{
            endAdornment: isLoading ? (
              <CircularProgress size="1em" />
            ) : endAdornment !== undefined ? (
              endAdornment
            ) : null,
          }}
          onKeyDown={onKeyDown}
        />
      )}
    </Container>
  );
}

export default memo(TextField);
