import backOfficeService from "services/ApiServiceCall";

export const getMetricsData = async (citizenID) => {
  const { data } = await backOfficeService().get(`/statistics/${citizenID}`);
  return data;
};

export const getPackageStatus = async (citizenID, bl) => {
  const { data } = await backOfficeService().get(`/siga/get/bl/data/${citizenID}/${bl}/`);
  return data;
};

export const getPreFormVuce = async (requestNo) => {
  const { data } = await backOfficeService().get(`/siga/get/pre/form/vuce/${requestNo}`);
  return data;
};

export const getPostFormVuce = async (requestNo) => {
  const { data } = await backOfficeService().get(`/siga/get/post/form/vuce/${requestNo}`);
  return data;
};

export const getFormStatusVuce = async (requestNo) => {
  const { data } = await backOfficeService().get(`/siga/get/form/vuce/${requestNo}`);
  return data;
};

export const generatePin = async (citizenId, invoiceNo) => {
  const { data } = await backOfficeService().post(
    `/invoice/pin/refresh/${citizenId}/${invoiceNo}`,
    {}
  );
  return data;
};
