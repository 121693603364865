import { Dialog, Grid } from "@mui/material";
import { getUser } from "api/Auth";
import { saveDraft } from "api/Drafts";
import {
  getForm,
  getFormByPortal,
  linkingDocumentsToRequestInBackOffice,
  linkingDocumentsToRequestInSoftExpert,
  registerForm,
  requestCodesByUser,
  uploadFormDocuments,
} from "api/RequestService";
import { getServiceDescription } from "api/ServiceDescription";
import CenterLoading from "components/CenterLoading/CenterLoading";
import FaceRecognitionValidation from "components/FaceRecognitionValidation";
import ImportantInformationModal from "components/ImportantInformationModal/ImportantInformationModal";
import SecurityQuestionsValidation from "components/SecurityQuestionsValidation/SecurityQuestionsValidation";
import { SweetAlert } from "components/SweetAlert/SweetAlert";
import { format } from "date-fns";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  HideGlobalLoading,
  ShowGlobalLoading,
  UpdateAppSubHeaderTitle,
} from "redux/actions/UiActions";
import {
  MediumHeightDivider,
  SmallHeightDivider,
  StyledCheckCircleIcon,
  SubTitle,
  Title,
} from "theme/Styles";
import { arrayArrayToArray, localToArray, transformField } from "utilities/functions/ArrayUtil";
import { cleanStringFromNumbers, localToNumber } from "utilities/functions/NumberUtil";
import { cleanString } from "utilities/functions/StringUtil";
import { isEmpty } from "utilities/functions/ValidationUtil";
import {
  getMaritalStatus,
  registerUserToBackoffice,
  reverseTransformFormData,
  reverseTransformFormGrid,
  transformFileData,
  transformFormData,
  transformFormGrid,
} from "./RequestServiceUtils";
import Form from "./components/Form/Form";
import { FIELD_TYPES } from "./components/Form/FormConstants";
import PaymentCard from "./components/PaymentCard/PaymentCard";
import {
  Container,
  PricesContainer,
  PricesItemContainer,
  SuccessContainer,
} from "./styles/RequestServicesStyles";

function readFile(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };

    fr.onerror = () => {
      reject(new Error(`Error reading ${file.name} file`));
    };

    fr.readAsDataURL(file);
  });
}

function RequestService() {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { serviceID } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { service_id } = useSelector((state) => state.serviceReducer);

  const { data: userData, isLoading: userDataIsLoading } = useQuery(["userData"], async () => {
    try {
      dispatch(ShowGlobalLoading("Cargando"));
      const response = await getUser();
      dispatch(HideGlobalLoading());
      return response;
    } catch (error) {
      history.push("/public");
      dispatch(HideGlobalLoading());
      throw new Error("An error has ocurred");
    }
  });

  const { data: serviceDescription, isLoading: serviceDescriptionIsLoading } = useQuery(
    ["serviceDescription", serviceID],
    async () => {
      try {
        dispatch(ShowGlobalLoading("Cargando"));
        const response = await getServiceDescription(serviceID, userData.payload.citizen_id);
        dispatch(HideGlobalLoading());
        return response;
      } catch (error) {
        history.push("/public");
        dispatch(HideGlobalLoading());
        throw new Error("An error has ocurred");
      }
    }
  );

  // Load the data here to be used in the RenderField function
  const [hasRequestCodeMask, setHasRequestCodeMask] = useState(false);
  useQuery(
    ["requestCodesByUser", userData?.payload?.citizen_id],
    () => requestCodesByUser(userData?.payload?.citizen_id),
    {
      enabled: !!userData?.payload?.citizen_id && hasRequestCodeMask,
    }
  );

  const formRef = useRef(null);
  const successRef = useRef(null);

  const [formData, setFormData] = useState();
  const [isDraft, setIsDraft] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [draftLoading, setDraftLoading] = useState(false);
  const [showRestoreFormModal, setShowRestoreFormModal] = useState(false);

  const [securityQuestion, setSecurityQuestion] = useState(false);
  const [faceRecognition, setFaceRecognition] = useState(false);

  const [priceModalIsOpen, setPriceModalIsOpen] = useState(true);

  const [selectedVariation, setSelectedVariation] = useState();
  const [selectedVariations, setSelectedVariations] = useState();
  const [selectedPaymentCard, setSelectedPaymentCard] = useState(
    serviceDescription?.prices.length === 1 ? 0 : null
  );

  const [servicePrice, setServicePrice] = useState(0);
  const [showRequestDetail, setShowRequestDetail] = useState(false);
  const [state, setState] = useState({
    rules: [],
    data: {},
    grid: {},
    fakeStep: 0,
    step: 0,
    totalPayment: 0,
    variations: [],
  });

  const getAndSetForm = async () => {
    try {
      dispatch(ShowGlobalLoading("Cargando"));
      setIsLoading(true);
      let response;
      if (serviceDescription.provider === "softexpert") {
        response = await getForm(serviceDescription.expertform_id, userData.payload.citizen_id);
      } else {
        response = await getFormByPortal(
          serviceDescription.formulary_id,
          userData.payload.citizen_id
        );
      }
      setFormData(response);
      const formString = JSON.stringify(response.fields);
      setHasRequestCodeMask(formString.includes(`"Mask":"50"`));
      setIsLoading(false);
      dispatch(HideGlobalLoading());
    } catch (error) {
      //history.push("/public");
      setIsLoading(false);
      dispatch(HideGlobalLoading());
      throw new Error("An error has ocurred");
    }
  };

  useEffect(() => {
    if (service_id) {
      setPriceModalIsOpen(false);
      setSelectedVariation(serviceDescription?.prices[0]?.variations[0]?.price);
    }
    if (serviceDescription && userData) {
      getAndSetForm();
    }
  }, [serviceDescription, userData, service_id]);

  const handleSelectVariation = (val, variations, servicePrice) => {
    let maskField = formData.fields[0].find((field) => field.Mask === "9");
    let dateRange = 0;
    let countYears = true;
    if (maskField) {
      variations.map((item, index) => {
        if (item.quantity || item.value > 1) {
          if (!index) {
            dateRange += item.value;
            if (item.value > 6 && variations[1].quantity === 1) countYears = false;
          } else if (countYears) {
            dateRange += item.quantity * 12;
          }
        }
      });
      maskField.MaskParam = dateRange + "&" + maskField.MaskParam.split("&")[1];
    }

    handleModalVisibility();
    setSelectedVariation(val);
    setSelectedVariations(variations);
    setServicePrice(servicePrice);
  };

  const handleModalVisibility = () => {
    setPriceModalIsOpen(!priceModalIsOpen);
  };

  const handleRestoreFormModal = () => {
    setShowRestoreFormModal(!showRestoreFormModal);
  };

  const handleRestoreForm = () => {
    setShowRestoreFormModal(false);
    setPriceModalIsOpen(false);
    setIsDraft(true);
  };

  const getData = () => {
    //separating response by steps
    const plainData = [];
    const data = formData.fields;
    const _data = [];
    for (let i = 0; i < data.length; i++) {
      const step = data[i];
      let _step = [];
      for (let j = 0; j < step.length; j++) {
        const field = step[j];
        plainData.push(field);
        if (_step.length && field.subtype == "h1") {
          _data.push(_step);
          _step = [];
        }
        _step.push(field);
        if (step.length - 1 == j) {
          _data.push(_step);
          _step = [];
        }
      }
    }

    return {
      formulary_data: formData.formulary_data,
      data: _data.map((step) => step.map(transformField)),
      plainData: plainData.map(transformField),
      saved_fields: formData.saved_fields,
      date: Number(new Date()),
    };
  };

  const handleFormSave = async () => {
    const formData = formRef.current?.saveForm();
    const _plainData = arrayArrayToArray(formData?.localData);

    const request = {
      citizen_id: userData.payload.citizen_id,
      service_id: serviceDescription.id,
      expertform_id: serviceDescription.expertform_id,
      data: transformFormData(formData?.values, _plainData, formData?.errors).filter(
        (field) => field.type !== FIELD_TYPES.file
      ),
      grid: transformFormGrid(formData?.values, _plainData),
      appliedRuleList: localToArray(formData?.appliedRuleList),
      fakeStep: formData?.fakeStep,
      step: formData?.step,
      variations: [selectedVariation?.id],
      totalPayment: servicePrice,
      payment_info: selectedVariations,
    };

    if (isEmpty(request.data)) return;

    try {
      await saveDraft(request);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectPaymentCard = (paymentId) => {
    if (selectedPaymentCard !== paymentId) {
      setSelectedPaymentCard(paymentId);
    }
  };

  const sendRequest = async (valuesOfForm) => {
    const formData = formRef.current?.saveForm();
    const _plainData = arrayArrayToArray(formData?.localData);
    dispatch(ShowGlobalLoading("Cargando"));

    const response = await getServiceDescription(serviceID, userData.payload.citizen_id);
    const { send } = response;

    try {
      registerUserToBackoffice().then(() => {
        console.info("Usuario registrado en backoffice");
      });

      const FilesOfForm = transformFileData(valuesOfForm, _plainData);
      let canSubmitForm = true;
      let uploadedFilesRoutes = FilesOfForm.oldFile;

      const formDataOfFiles = new FormData();

      const totalFiles = FilesOfForm?.newFile.length + FilesOfForm?.oldFile.length;
      const maxRequestFiles = parseInt(process.env.REACT_APP_MAX_REQUEST_FILES || 20);

      if (totalFiles > maxRequestFiles) {
        dispatch(HideGlobalLoading());
        enqueueSnackbar(`La cantidad máxima es de ${maxRequestFiles} documentos por solicitud `, {
          variant: "error",
        });
        return;
      }

      if (FilesOfForm?.newFile.length > 0) {
        for (const newFile of FilesOfForm.newFile) {
          const fileExists = await readFile(newFile.file);

          if (fileExists) {
            formDataOfFiles.append("file[]", newFile.file, newFile.file.name);
          }
        }

        const uploadFilesConfig = {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            dispatch(ShowGlobalLoading(`Subiendo documentos ${percentCompleted}%`));
          },
        };

        let responseFilesUploaded = await uploadFormDocuments(formDataOfFiles, uploadFilesConfig);

        if (responseFilesUploaded.success) {
          uploadedFilesRoutes = [
            ...uploadedFilesRoutes,
            ...responseFilesUploaded.files.map((item, index) => {
              return {
                ...item,
                label: FilesOfForm.newFile[index].label,
              };
            }),
          ];
        } else {
          canSubmitForm = false;
        }
      }

      if (canSubmitForm) {
        let canFormContinue = true;
        let documentsArray = [];
        for (let i = 0; i < uploadedFilesRoutes.length; i++) {
          const file = {
            documents: [
              {
                ...uploadedFilesRoutes[i],
              },
            ],
            process_id: serviceDescription.process_id,
            names: [uploadedFilesRoutes[i].label],
            activity_id: serviceDescription.activity_id,
            new_request: true,
          };
          documentsArray.push(file);
        }
        const request = {
          req: {
            service_id: serviceID,
            doc_identification: userData.payload.citizen_id,
            name_service: serviceDescription.name,
            process_flow: serviceDescription.process_flow,
            form_version: cleanString(getData().formulary_data?.version),
            payment_amount: servicePrice,
            payment_status: "1",
            payment_method: "2",
            total: servicePrice,
            variations: [selectedVariation?.id],
            cant: "1",
            idAutorizacionPortal: "",
            bill_type: serviceDescription.bill_type,
            concept_type: serviceDescription.concept_type,
            payment_info: selectedVariations,
            is_claim: serviceDescription.is_claim || 0,
          },
          form: {
            citizen_record_id: userData.payload.citizen_id,
            expertform_id: serviceDescription.expertform_id,
            data: transformFormData(valuesOfForm, _plainData).filter(
              (field) => field.type != FIELD_TYPES.file && field.type != FIELD_TYPES.grid
            ),
            grid: transformFormGrid(valuesOfForm, _plainData),
          },
          documents: documentsArray,
          userInfo: {
            paissolicitante: "República Dominicana",
            provinciasolici: userData.payload.province,
            municipiosolic: userData.payload.municipality,
            sectorsolic: userData.payload.sector,
            horasolicitud: format(new Date(), "HH:mm"),
            numdocsolicita: userData.payload.citizen_id,
            tipodocsolicita: 1,
            nombressolicita: userData.payload.name,
            apellidossolici: `${userData.payload.first_last_name} ${userData.payload.second_last_name}`,
            fechanacsolic: moment(userData.payload.birth_date).format("YYYY-MM-DD"),
            sexosolicitante: userData.payload.sex === "M" ? "Masculino" : "Femenino",
            fechasolicitud: moment().format("YYYY-MM-DD"),
            direccsolic: userData.payload.address,
            nacionsolic: "Dominicano",
            celularsolic: cleanStringFromNumbers(userData.payload.phone),
            telefonosolic: cleanStringFromNumbers(userData.payload.phone),
            emailsolic: userData.payload.email,
            solicitudonline: 1,
            estadcivilsolic: getMaritalStatus(userData?.payload?.marital_status),
          },
        };

        dispatch(ShowGlobalLoading("Registrando solicitud"));

        let responseFormSubmit = await registerForm(request);

        if (responseFormSubmit.success) {
          if (uploadedFilesRoutes.length > 0) {
            dispatch(ShowGlobalLoading("Procesando solicitud"));
            if (send === 1 && serviceDescription.provider === "softexpert") {
              // Let uploadSoftExpertArrayAxios = [];
              for (let i = 0; i < uploadedFilesRoutes.length; i++) {
                // SOFTEXPERT HAVE A LIMIT OF 25MB, AND I REMOVE ALL uploadedFilesRoutes with > 25mb of size
                if (
                  uploadedFilesRoutes[i]?.size_mb < parseInt(process.env.REACT_APP_MAX_FILE_SIZE)
                ) {
                  const uploadSoftExpertConfig = {
                    documents: [
                      {
                        ...uploadedFilesRoutes[i],
                      },
                    ],
                    size_mb: uploadedFilesRoutes[i]?.size_mb,
                    title: responseFormSubmit.title,
                    record_id: responseFormSubmit.code,
                    attribute: responseFormSubmit.attributes,
                    process_id: serviceDescription.process_id,
                    acronym: responseFormSubmit.acronym,
                    names: [uploadedFilesRoutes[i].label],
                    activity_id: serviceDescription.activity_id,
                    new_request: true,
                  };
                  await linkingDocumentsToRequestInSoftExpert(uploadSoftExpertConfig);
                }
              }
            }

            let requestBackOffice = {
              documents: uploadedFilesRoutes,
            };
            let responseBackOffice = await linkingDocumentsToRequestInBackOffice(
              requestBackOffice,
              responseFormSubmit.RequestID
            );
            if (!responseBackOffice.success) {
              canFormContinue = false;
              enqueueSnackbar("Ha ocurrido un error favor intentar mas tarde.", {
                variant: "error",
              });
              throw Error;
            }
          }

          if (canFormContinue) {
            enqueueSnackbar("Solicitud enviada satisfactoriamente.", {
              variant: "success",
            });
            // history.push(`/app/serviceRequestedDetails/${responseFormSubmit.RequestID}payment`)
            // queryClient.invalidateQueries('serviceForm')
            await queryClient.invalidateQueries("requestsList");

            // setSuccessResponse(responseFormSubmit);
            setShowRequestDetail(true);

            if (successRef?.current) successRef.current.scrollIntoView();

            setTimeout(() => {
              history.push(`/app/serviceRequestedDetails/${responseFormSubmit.RequestID}`);
            }, 1000);
          }
        } else {
          enqueueSnackbar("Ha ocurrido un error favor intentar mas tarde.", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("Ha ocurrido un error subiendo los documentos.", {
          variant: "error",
        });
        throw Error;
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        "Ha ocurrido un error, contacte al soporte para mas información \n" + error.toString(),
        {
          variant: "error",
        }
      );
    }
    dispatch(HideGlobalLoading());
  };

  //componentDidUpdate
  useEffect(() => {
    if (formData === undefined) return;

    if (localToArray(getData()?.saved_fields?.data).length > 0 && isDraft !== true) {
      setShowRestoreFormModal(true);
      return;
    }

    if (
      !localToArray(getData()?.plainData).length ||
      !getData()?.saved_fields ||
      !localToArray(getData()?.saved_fields?.data).length ||
      isDraft === false
    )
      return;

    const { appliedRuleList, data, grid, fakeStep, step, totalPayment, variations, payment_info } =
      getData()?.saved_fields;

    dispatch(ShowGlobalLoading("Restableciendo"));
    setDraftLoading(true);

    const draftState = {
      rules: localToArray(appliedRuleList),
      fakeStep: localToNumber(fakeStep),
      data: reverseTransformFormData(data, getData()?.plainData),
      grid: reverseTransformFormGrid(grid, getData()?.plainData),
      step: localToNumber(step),
      totalPayment: totalPayment,
      variations: variations,
    };

    setState(draftState);

    const priceVariations = serviceDescription?.prices?.[0]?.variations;
    const formPriceVariation = priceVariations.find((variation) => variation.id === variations[0]);
    //const rulePrice = formData.fields[0].find((rule) => rule.type === "rulesprice");
    //const quantityFields = getQuantityFields(priceVariations, rulePrice);

    setSelectedVariation(formPriceVariation);
    setSelectedVariations(payment_info);
    setServicePrice(draftState.totalPayment);

    setTimeout(() => {
      //Simulate loading for 2.5s
      //Bug with React 17 for update some component value is needed to unmount and mount the Form component
      setDraftLoading(false);
      dispatch(HideGlobalLoading());
    }, 2500);

    return () => {};
  }, [formData, isDraft]);

  useLayoutEffect(() => {
    //UPDATE APP HEADER SUBTITLE
    dispatch(UpdateAppSubHeaderTitle(serviceDescription?.name));
  }, [serviceDescription]);

  useEffect(() => {
    const unloadCallback = (event) => {
      console.log("unloadCallback");
      event.preventDefault();
      event.returnValue = "Si cierra esta pestaña el proceso de la solicitud se vera afectado";
      return "Si cierra esta pestaña el proceso de la solicitud se vera afectado";
    };

    const alertUser = (event) => {
      event.preventDefault();
      handleFormSave();
      SweetAlert.fire(
        "Advertencia",
        "Si cierra esta pestaña el proceso de la solicitud se vera afectado",
        "warning"
      );
      event.returnValue = "Si cierra esta pestaña el proceso de la solicitud se vera afectado";
      return "Si cierra esta pestaña el proceso de la solicitud se vera afectado";
    };

    window.addEventListener("beforeunload", alertUser);
    window.addEventListener("unload", unloadCallback);

    return () => {
      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("unload", unloadCallback);
    };
  }, []);

  const onSecurityQuestionCancelClick = () => {
    history.push("/public");
  };

  const onSecurityQuestionFinishCallBack = () => {
    setSecurityQuestion(true);
  };

  const onFaceRecognitionCancelClick = () => {
    history.push("/public");
  };

  const onFaceRecognitionFinishCallBack = () => {
    setFaceRecognition(true);
  };

  if (isLoading || serviceDescriptionIsLoading || userDataIsLoading) return <CenterLoading />;

  if (faceRecognition === false && serviceDescription?.criticidad === 0) {
    return (
      <FaceRecognitionValidation
        citizenID={userData?.payload?.citizen_id}
        onCancelClick={onFaceRecognitionCancelClick}
        onFinishCallBack={onFaceRecognitionFinishCallBack}
      />
    );
  }

  if (securityQuestion === false && serviceDescription?.criticidad === 1) {
    return (
      <SecurityQuestionsValidation
        citizenID={userData?.payload?.citizen_id}
        onCancelClick={onSecurityQuestionCancelClick}
        onFinishCallBack={onSecurityQuestionFinishCallBack}
      />
    );
  }

  return (
    <Container>
      <SmallHeightDivider />
      <SmallHeightDivider />
      {!showRequestDetail ? (
        <Container>
          {draftLoading && selectedVariation === undefined ? null : (
            <Form
              ref={formRef}
              doRequest={sendRequest}
              data={getData()?.data}
              plainData={getData()?.plainData}
              setPriceModalIsOpen={setPriceModalIsOpen}
              handleFormSave={handleFormSave}
              multipleDocuments={serviceDescription?.multiple_document === "true"}
              initialForm={state}
              variations={[selectedVariation?.id]}
              isDraft={isDraft}
              isSurvey={false}
              userData={userData}
            />
          )}
          <ImportantInformationModal
            open={showRestoreFormModal}
            onBackDropClick={() => {}}
            onCloseClick={handleRestoreFormModal}
            CloseTitle="Cancelar"
            CloseButton
            buttonTitle="Confirmar"
            buttonClick={handleRestoreForm}
            content={
              <Fragment>
                <strong>Se ha encontrado información previa de una solicitud sin terminar.</strong>
                <br />
                <strong>
                  <p>¿Desea cargarla para esta solicitud?</p>
                </strong>
              </Fragment>
            }
          />
          <Dialog
            keepMounted
            sx={{ zIndex: 1000 }}
            disableEscapeKeyDown
            open={priceModalIsOpen}
            onClose={handleModalVisibility}
            maxWidth="xl"
            fullScreen
          >
            <PricesContainer>
              <Title>Tarifas del servicio</Title>
              <SmallHeightDivider />
              <SmallHeightDivider />
              <Grid
                container
                direction="row"
                alignItems="top"
                alignSelf="center"
                justifyContent="center"
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 16 }}
              >
                {serviceDescription?.prices?.map((price, index) => (
                  <Grid key={index} item xs={4} sm={8} md={4}>
                    <PricesItemContainer key={index} onClick={() => handleSelectPaymentCard(index)}>
                      <PaymentCard
                        title={
                          price.variations.length > 1 ? price.concept : price.variations[0].concept
                        }
                        price={price}
                        form={formData}
                        onClick={handleSelectVariation}
                        isSelected={index === selectedPaymentCard}
                        external_pay={serviceDescription?.external_pay}
                        sirit_code={serviceDescription?.sirit_code}
                      />
                    </PricesItemContainer>
                  </Grid>
                ))}
              </Grid>
            </PricesContainer>
          </Dialog>
        </Container>
      ) : (
        <Container ref={successRef}>
          <SuccessContainer>
            <StyledCheckCircleIcon />
            <SmallHeightDivider />
            <SubTitle>Solicitud enviada satisfactoriamente.</SubTitle>
            <MediumHeightDivider />
          </SuccessContainer>
        </Container>
      )}
    </Container>
  );
}

export default RequestService;
