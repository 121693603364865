import { Box } from "@mui/material";
import { DeleteAccountForm } from "./DeleteForm";

export function AccountDeletion() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box component={"img"} width={"400px"} src="/logo192.png" alt="Logo" />
      <h1>Eliminar cuenta</h1>
      <Box
        component={"form"}
        sx={{
          width: { xs: "90%", md: "60%" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DeleteAccountForm />
      </Box>
    </Box>
  );
}
