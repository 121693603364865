import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack } from "@mui/material";
import {
  ImageContainer,
  ImageContainerHeader,
  ImageContainerTitle,
} from "views/ServiceRequestedDetails/styles/ServiceRequestedDetailsStyles";
import PinIcon from "assets/icons/svgs/pin_generator.svg";
import CopyToClipboard from "components/CopyToClipboard";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import { refreshPin } from "api/ValidatePayment";
import { SweetAlert } from "components/SweetAlert/SweetAlert";
import Alert from "@mui/material/Alert";

export default function ShowPin({ requestData }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Grid item xs={6} sm={4} md={4}>
        <ImageContainer title="Pin de pago" onClick={() => setOpen(true)}>
          <ImageContainerHeader>
            <ImageContainerTitle>Visualizar Pin de Pago</ImageContainerTitle>
          </ImageContainerHeader>
          <Box
            component={"img"}
            sx={{
              width: "70px",
            }}
            src={PinIcon}
          />
        </ImageContainer>
      </Grid>

      <PinPaymentModal pin={requestData.request.pin} open={open} onClose={() => setOpen(false)} />
    </>
  );
}

function PinPaymentModal({ onClose, open = false, pin }) {
  const [currentPin, setCurrentPin] = useState(pin);
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true}>
      <DialogTitle>PIN DE PAGO</DialogTitle>
      <DialogContent dividers sx={{ display: "flex", flexDirection: "column" }}>
        <Box alignSelf={"center"} fontWeight={"bold"} fontSize={"1.2rem"}>
          Copiar el código de PIN de pago
        </Box>
        <Stack direction={"row"} alignItems="center">
          <CopyToClipboard text={currentPin} />
          <IconButton
            onClick={() => {
              SweetAlert.showLoading();
              refreshPin(pin).then((response) => {
                if (response.success) {
                  setCurrentPin(response.message);
                } else {
                  SweetAlert.fire({
                    title: response.message,
                    icon: "info",
                  });
                }
              });
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Stack>
        <Alert
          icon={<InfoIcon fontSize="inherit" />}
          severity="info"
          sx={{ fontWeight: "bold", fontSize: "1rem" }}
        >
          Luego de usted efectuar el pago, el sistema se encargará de validarlo a la mayor brevedad
          posible.
        </Alert>
      </DialogContent>
    </Dialog>
  );
}
