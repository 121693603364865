import backOfficeService from "services/ApiServiceCall";
import ApiServiceUploadCall from "services/ApiServiceUploadCall";

export const getForm = async (expertFormID, loggedUserCedula) => {
  const { data } = await backOfficeService().get(
    `/expertform/${expertFormID}/get/${loggedUserCedula}`
  );
  return data;
};

export const getFormByPortal = async (formularyID, loggedUserCedula) => {
  const { data } = await backOfficeService().get(
    `/formularies/${formularyID}/get/${loggedUserCedula}`
  );
  return data;
};

export const registerForm = async (requestData) => {
  const { data } = await backOfficeService().post("/requests", requestData);
  return data;
};

export const uploadFormDocuments = async (documentsList, uploadConfig) => {
  const { data } = await ApiServiceUploadCall().post("/files", documentsList, uploadConfig);
  return data;
};

export const linkingDocumentsToRequestInSoftExpert = async (request) => {
  const { data } = await ApiServiceUploadCall().post("/uploadsoftexpert", request);
  return data;
};

export const linkingDocumentsToRequestInBackOffice = async (filesList, requestID) => {
  const { data } = await backOfficeService().post(`/assigndocument/${requestID}`, filesList);
  return data;
};

export const cancelRequest = async (requestID) => {
  const { data } = await backOfficeService().post(`/cancelrequests/${requestID}`);
  return data;
};

export const requestCodesByUser = async (cedula) => {
  const { data } = await backOfficeService().get(`/requests/codes/${cedula}`);
  return data;
};
