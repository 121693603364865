import { Box, Divider, Stack, Typography } from "@mui/material";
import ServiceIcon from "assets/icons/svgs/services.svg";

export default function ServiceHeaderMessage() {
  return (
    <Box display={{ xs: "none", md: "block" }}>
      <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"}>
        <Box
          component="img"
          sx={{
            height: "80px",
            marginRight: "20px",
          }}
          alt="Institution Logo"
          src={ServiceIcon}
        />
        <Typography variant={"body1"} fontSize={{ xs: "0.9rem", lg: "1.1rem" }} color={"#666666"}>
          La Dirección General de Aduanas nace para facilitar y controlar el comercio de la
          República Dominicana con el resto del mundo, teniendo como fundamento la eficiencia y
          transparencia de los procesos aduaneros, acorde con las mejores prácticas internacionales
          de la administración aduanera, el control de la evasión fiscal.
        </Typography>
      </Stack>
      <Divider
        variant="middle"
        sx={{
          height: "1px",
          border: 0,
          width: "100%",
          marginTop: "20px",
          backgroundImage:
            "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(19, 71, 122, 1), rgba(0, 0, 0, 0))",
        }}
      />
    </Box>
  );
}
