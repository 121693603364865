import * as yup from "yup";
import ServicesHistoryIcon from "assets/icons/svgs/servicios_historial.svg";
import DocumentsIcon from "assets/icons/svgs/documents.svg";
import SearchIcon from "assets/icons/pngs/searchIcon.png";
import ScheduleIcon from "assets/icons/pngs/squeadule.png";
import ServicesIcon from "assets/icons/pngs/servicesIcon.png";
import AccesibilidadIcon from "assets/icons/pngs/accebilidadIcon.png";
import ChatIcon from "assets/icons/pngs/chatIcon.png";

export const FormSchema = yup
  .object({
    fullName: yup.string().required("Este campo es requerido"),
    relationTo: yup.string().required("Este campo es requerido"),
    email: yup.string().email("Ingrese un email valido").required("Este campo es requerido"),
    phoneNumber: yup.string().required("Este campo es requerido"),
    message: yup.string().required("Este campo es requerido"),
  })
  .required();

export const DataGuiUse = [
  {
    id: 1,
    title: "",
    descriptionStrong: "Servicios Informativos: ",
    description:
      "Son aquellos que vía Internet, se limitan a ofrecer al usuario la información necesaria para poder gestionar el servicio por otra vía diferente a la web. ",
    color: "darkBlue",
    icon: null,
  },

  {
    id: 2,
    title: "",
    descriptionStrong: "Servicios Interactivos: ",
    description:
      "Son los servicios que ofrece la capacidad al usuario de gestionar parcialmente el mismo a través de Internet y el medio web desde el cual accede, logrando agilizar la prestación o solicitud de estos",
    color: "lightBlue",
    icon: null,
  },

  {
    id: 3,
    title: "",
    descriptionStrong: "Servicios Transaccionales: ",
    description:
      "Son los servicios ofrecidos a través de Internet o por aplicaciones móviles que permiten realizar el trámite completo entre el usuario de un servicio y el proveedor del mismo de forma fácil y sin importar el lugar y la hora, lo que incluye la facilidad de realizar pagos electrónicos en aquellos servicios que lo requieran.",
    color: "green",

    icon: null,
  },

  {
    id: 4,
    title: "",
    descriptionStrong: "Servicios dirigidos a la mujer: ",
    description: "Área o departamento responsable del servicio. ",
    color: "n/a",
    icon: ServicesIcon,
  },

  {
    id: 5,
    title: "",
    descriptionStrong: "Servicios dirigidos a la mujer: ",
    description: " Botón para ejecutar una búsqueda. ",
    color: "n/a",
    icon: SearchIcon,
  },

  {
    id: 6,
    title: "",
    descriptionStrong: "EXPORTACIÓN: ",
    description: " Horario en que estará disponible el servicio. ",
    color: "n/a",
    icon: ScheduleIcon,
  },
  {
    id: 7,
    title: "",
    description:
      "Menú de accesibilidad que permite cambiar el tamaño de los textos, el brillo, el contraste y el cursor utilizado dentro del Portal de Servicio.",
    color: "n/a",
    icon: AccesibilidadIcon,
  },

  {
    id: 8,
    title: "",
    description:
      "Área donde se almacenan tanto los documentos cargados en el portal como aquellos enviados por la Dirección General de Aduanas.",
    color: "n/a",
    icon: DocumentsIcon,
  },

  {
    id: 9,
    title: "",
    descriptionStrong: "Servicios dirigidos a la mujer: ",
    description:
      "Botón que permite visualizar todas las solicitudes realizadas tanto las que están en proceso como aquellas que fueron finalizadas",
    color: "n/a",
    icon: ServicesHistoryIcon,
  },
  {
    id: 10,
    title: "",
    descriptionStrong: "Servicios dirigidos a la mujer: ",
    description:
      "Es una vía de comunicación que le permitirá al cliente consultar el estado de sus solicitudes tanto en SIGA como en VUCE.  Esta también permitirá al cliente comunicarse con un agente.",
    color: "n/a",
    icon: ChatIcon,
  },
];
