import { FormControl, FormHelperText, Grid } from "@mui/material";
import CheckBoxGroup from "components/CheckBoxGroup/CheckBoxGroup";
import DatePicker from "components/DatePicker/DatePicker";
import PhoneTextField from "components/PhoneTextField/PhoneTextField";
import RadioButtonGroup from "components/RadioButtonGroup/RadioButtonGroup";
import Select from "components/Select/Select";
import TextField from "components/TextField/TextField";
import TextFieldNumberFormat from "components/TextFieldNumberFormat/TextFieldNumberFormat";
import TimePicker from "components/TimePicker/TimePicker";
import UploadFile from "components/UploadFile/UploadFile";
import YearPicker from "components/YearPicker/YearPicker";
import { subMonths } from "date-fns";
import { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { SmallHeightDivider, StyledButton, SubTitle } from "theme/Styles";
import { localToArray } from "utilities/functions/ArrayUtil";
import { localToString } from "utilities/functions/StringUtil";
import { FIELD_TYPES, LOCATION_MASKS, MASK_TYPE } from "../FormConstants";
import { fieldMaskValidation } from "../MaskFunctions";
import { GridItemTable } from "./GridItemTable";
import ModalForm from "./ModalForm";
import {
  childrenDataFilter,
  deleteGridElement,
  insertGridData,
  localOnChange,
} from "./RenderFieldFunctions";

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function RenderField(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [textInputLoading, setTextInputLoading] = useState(false);
  const queryClient = useQueryClient();
  const query = useQuery();

  const handleValidationOnBlur = async (event) => {
    const value = event?.target.value;
    if (!value) return;

    try {
      props.setFieldTouched(props?.fieldKey, true, true);
      setTextInputLoading(true);

      await fieldMaskValidation(value, props);

      setTextInputLoading(false);
    } catch (error) {
      setTextInputLoading(false);
      console.error(error);
    }
  };

  const commonProps = {
    id: props.fieldKey,
    title: props.label,
    value: props.value,
    onChange: (event) => localOnChange(props, event),
    onBlur: handleValidationOnBlur,
    error: props.error,
    helperText: props.helperText,
    placeholder: props.placeholder,
    disabled: !props.enabled,
    required: props?.required,
    loading: props.loading,
  };

  const Field = () => {
    if (props.hidden) return null;

    if (["50"].includes(localToString(props.Mask))) {
      const defaultValue = query.get("requestCode");
      if (!props.value && !!defaultValue) props.setFieldValue(props.fieldKey, defaultValue);

      const requestCodes = queryClient.getQueryData(["requestCodesByUser", props.citizenId]);
      const requestCodesOptions = requestCodes.data.map((request) => {
        return {
          label: `${request.code} - ${request.name}`,
          value: request.code,
        };
      });

      return (
        <Grid item xs={3} sm={6} md={6}>
          <Select
            {...commonProps}
            data={!!requestCodes ? requestCodesOptions : [{ label: "Cargando...", id: 0 }]}
            search
          />
        </Grid>
      );
    }

    if (LOCATION_MASKS.includes(props.Mask)) {
      function getData() {
        let data = childrenDataFilter(props.values, props.father_id, props.fatherValue);

        const locationsToFilter =
          props.Mask === 55 ? ["DO01", "DO25", "DO32"] : props.MaskParam.split(",");

        if (locationsToFilter.length > 0) {
          data = data.filter((item) => locationsToFilter.includes(item.value));
        }

        return data;
      }

      return (
        <Grid item xs={3} sm={6} md={6}>
          <Select {...commonProps} data={getData()} search />
        </Grid>
      );
    }

    switch (props.type) {
      case FIELD_TYPES.radioGroup:
        return (
          <Grid item xs={12} sm={12} md={12}>
            <RadioButtonGroup {...commonProps} options={props.data} row />
          </Grid>
        );
      case FIELD_TYPES.checkboxGroup:
        return (
          <Grid item xs={3} sm={6} md={12}>
            <CheckBoxGroup {...commonProps} title={""} options={props.data} />
          </Grid>
        );
      case FIELD_TYPES.select:
        return (
          <Grid item xs={3} sm={6} md={6}>
            <Select
              {...commonProps}
              data={childrenDataFilter(props.data, props.father_id, props.fatherValue)}
              search
            />
          </Grid>
        );

      case FIELD_TYPES.text:
        if (["2", "3"].includes(localToString(props.Mask))) {
          return (
            <Grid item xs={3} sm={6} md={6}>
              <PhoneTextField {...commonProps} />
            </Grid>
          );
        }
        if (["0", "1", "6", "14", "15", "18", "19"].includes(localToString(props.Mask))) {
          return (
            <Grid item xs={3} sm={6} md={6}>
              <TextField
                {...commonProps}
                isLoading={textInputLoading}
                mask={localToString(MASK_TYPE[props.Mask || ""])}
                useMaskPresets={true}
                unMaskedValue={true}
                multiline={props.multiline}
                length={props?.length}
                min={props?.min}
                max={props?.max}
                minLength={props?.minLength}
                maxLength={props?.maxLength}
              />
            </Grid>
          );
        }
        if (["12"].includes(localToString(props.Mask))) {
          return (
            <Grid item xs={3} sm={6} md={6}>
              <TextFieldNumberFormat
                {...commonProps}
                isLoading={textInputLoading}
                maxLength={props.length}
                onChange={(event) => localOnChange(props, event)}
                onBlur={handleValidationOnBlur}
                multiline={props.multiline}
              />
            </Grid>
          );
        }

        if (["13"].includes(localToString(props.Mask))) {
          return (
            <Grid item xs={3} sm={6} md={6}>
              <YearPicker {...commonProps} />
            </Grid>
          );
        }

        return (
          <Grid item xs={3} sm={6} md={6}>
            <TextField
              {...commonProps}
              isLoading={textInputLoading}
              maxLength={props.length}
              multiline={props.multiline}
            />
          </Grid>
        );
      case FIELD_TYPES.textarea:
        return (
          <Grid item xs={12} sm={12} md={12}>
            <TextField {...commonProps} maxLength={props.length} multiline={true} />
          </Grid>
        );
      case FIELD_TYPES.date:
        const maskParams = props?.Mask === "9" && props?.MaskParam?.split("&");
        return (
          <Grid item xs={3} sm={6} md={6}>
            <DatePicker
              {...commonProps}
              maxDate={
                props?.Mask === "9" ? subMonths(new Date(), maskParams[0] || 1) : props?.maxDate
              }
              initialDate={
                props?.Mask === "9" ? subMonths(new Date(), maskParams[0] || 1) : props?.initialDate
              }
              minDate={props?.minDate}
              successHelper={props?.successHelper}
            />
          </Grid>
        );
      case FIELD_TYPES.time:
        return (
          <Grid item xs={3} sm={6} md={6}>
            <TimePicker {...commonProps} />
          </Grid>
        );
      case FIELD_TYPES.file:
        return (
          <Grid item xs={3} sm={6} md={6}>
            <UploadFile
              {...commonProps}
              extension={props?.["valid_exts"]}
              multipleDocuments={!!props?.multipleDocuments}
              ignoreType={["dwg", "jfif", "tiff"]}
              findDocuments
              hideDownloadButton
            />
          </Grid>
        );
      case FIELD_TYPES.grid: {
        const params = String(props?.MaskParam || "").split(/[~&]/);

        const maxGridSize = params[0] || props?.maxGridSize;
        const minGridSize = params[1] || props?.minGridSize;

        return (
          <Grid item xs={12} sm={12} md={12}>
            <FormControl disabled={!props.enabled} error={props.error} sx={{ width: "100%" }}>
              <SubTitle>{props.label}</SubTitle>
              <SmallHeightDivider />
              {localToArray(props.value).length > 0 && (
                <GridItemTable
                  props={props}
                  fields={props.fields}
                  values={props.value}
                  onEdit={setModalVisible}
                  onDelete={deleteGridElement}
                />
              )}

              <SmallHeightDivider />
              <StyledButton
                disabled={maxGridSize && parseInt(maxGridSize) <= localToArray(props.value).length}
                onClick={() => setModalVisible(true)}
              >
                Agregar
              </StyledButton>
              <FormHelperText sx={{ fontSize: "0.90rem" }}>{props.helperText}</FormHelperText>
              <ModalForm
                title={props.label}
                isVisible={modalVisible}
                onVisibleChange={setModalVisible}
                changeRule={props.changeRule}
                fields={props.fields}
                doRequest={(event) =>
                  insertGridData({
                    props,
                    setModalVisible,
                    ...event,
                  })
                }
                fatherKey={localToString(props.fieldKey)}
                formValues={props.formValues}
              />
            </FormControl>
          </Grid>
        );
      }

      case FIELD_TYPES.header:
        if (props.subtype === "h1") {
          return null;
        } else {
          return (
            <Grid item xs={12} sm={12} md={12}>
              <SubTitle id={props.fieldKey}>{props.label}</SubTitle>
            </Grid>
          );
        }

      case FIELD_TYPES.subtitle:
        if (props.subtype === "h1") {
          return null;
        } else {
          return (
            <Grid item xs={12} sm={12} md={12}>
              <SubTitle id={props.fieldKey}>{props.label}</SubTitle>
            </Grid>
          );
        }
      default:
        return null;
    }
  };

  return props.hidden === true ? null : Field();
}
