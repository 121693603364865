import { Box, Typography } from "@mui/material";
import { AppHeader } from "layout/app/AppLayout";

export default function Header({ background }) {
  return (
    <Box
      sx={{
        background: `
            linear-gradient(rgb(19, 71, 122, 0.35), rgb(19, 71, 122, 0.35)),
            url(${background})`,
        height: { xs: "50vw", md: "35vw", xl: "30vw" },
        width: "100%",
        backgroundPosition: "25% 30%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        margin: "0 auto",
        borderBottom: "10px solid rgb(19, 148, 208, 1)",
      }}
    >
      {/*Index*/}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <AppHeader />

        {/* Header text content */}
        <Box
          sx={{
            paddingX: { xs: "20px", md: "40px", lg: "60px", xl: "180px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <Box
            sx={{
              backgroundColor: "rgb(66, 108, 149, 0.51)",
              marginBottom: "4%",
              padding: "2%",
              paddingTop: "4%",
            }}
          >
            <Typography
              variant="h3"
              fontSize={{ xs: "1rem", sm: "1.25rem", md: "1.5rem", lg: "1.75rem", xl: "2rem" }}
              gutterBottom
              component="div"
              color={"white"}
              textAlign={"left"}
              letterSpacing={"6px"}
            >
              BIENVENIDOS A NUESTRO PORTAL
              <br /> DE SERVICIOS EN LINEA
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
