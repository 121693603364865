import MyDeskHeader from "components/MyDeskHeader";
import { Box, Grid, Stack } from "@mui/material";
import { TextFieldContainer, Title } from "views/Auth/Login/styles/LoginStyles";
import { BodyText, BodyTextBold, StyledButton } from "theme/Styles";
import TextField from "components/TextField/TextField";
import { getPackageStatus } from "api/myDesk";
import { useQueryClient } from "react-query";
import { useLayoutEffect, useState } from "react";
import { UpdateAppSubHeaderTitle } from "redux/actions/UiActions";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import { SweetAlert } from "components/SweetAlert/SweetAlert";

export default function CheckPackage() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData(["userData"]);
  const [search, setSearch] = useState("");
  const [response, setResponse] = useState({});

  async function handleSearch() {
    if (!search) return;
    const data = await getPackageStatus(userData.payload.citizen_id, search);
    if (data?.success && (data?.message?.mercancia || data?.message?.peso)) {
      setResponse(data);
    } else {
      setResponse({});
      await SweetAlert.fire({
        icon: "error",
        title: "Oops...",
        text: "No se encontró el paquete",
      });
    }
  }

  useLayoutEffect(() => {
    dispatch(UpdateAppSubHeaderTitle("Consultar Paquete")); //SET SUBHEADER TITLE
  }, [dispatch]);

  return (
    <MyDeskHeader header={"Consultar Paquete"} canGoBack={true}>
      <Stack direction={"column"} spacing={2} sx={{ width: { xs: "90%", md: "80%" }, p: 8 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: "none",
            width: "100%",
          }}
        >
          <Title>CONSULTAR PAQUETE</Title>
          <TextFieldContainer>
            <TextField
              onChange={(e) => setSearch(e.target.value)}
              type="text"
              placeholder="Número de paquete"
              id="password"
            />
          </TextFieldContainer>
          <StyledButton onClick={handleSearch}>Buscar</StyledButton>
        </Box>

        {response.success && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: "none",
              width: { xs: "auto", md: "100%" },
            }}
          >
            <Stack sx={{ border: "2px solid #1393D0", padding: "20px" }}>
              <Stack
                sx={{
                  textTransform: "none",
                  borderBottom: "1px solid #1393D0",
                  paddingLeft: "15px",
                  paddingBottom: "10px",
                  marginBottom: "20px",
                }}
                spacing={1}
              >
                <Box
                  component={"h4"}
                  sx={{
                    all: "unset",
                    color: "#134779",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    letterSpacing: "2px",
                  }}
                >
                  INFORMACIÓN DEL PAQUETE
                </Box>
              </Stack>

              <Grid
                alignItems="center"
                justifyContent="flex-start"
                container
                direction="row"
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 6, sm: 8, md: 12 }}
              >
                <Grid item xs={6} sm={4} md={4}>
                  <BodyTextBold>MERCANCÍA</BodyTextBold>
                  <BodyText>{response?.message?.mercancia}</BodyText>
                </Grid>

                <Grid item xs={6} sm={4} md={4}>
                  <BodyTextBold>FECHA DE LLEGADA</BodyTextBold>
                  <BodyText>
                    {moment(response?.message?.fechallegada).format("DD-MM-YYYY")}
                  </BodyText>
                </Grid>

                <Grid item xs={6} sm={4} md={4}>
                  <BodyTextBold>PESO</BodyTextBold>
                  <BodyText>{response?.message?.peso}</BodyText>
                </Grid>

                <Grid item xs={6} sm={4} md={4}>
                  <BodyTextBold>VALOR</BodyTextBold>
                  <BodyText>{response?.message?.valor}</BodyText>
                </Grid>

                <Grid item xs={6} sm={4} md={4}>
                  <BodyTextBold>ESTADO RETENIDO</BodyTextBold>
                  <BodyText>{response?.message?.estadoRetenido}</BodyText>
                </Grid>

                <Grid item xs={6} sm={4} md={4}>
                  <BodyTextBold>RAZÓN DE RETENCIÓN</BodyTextBold>
                  <BodyText>{response?.message?.razonRetencion}</BodyText>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        )}
      </Stack>
    </MyDeskHeader>
  );
}
