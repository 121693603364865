import TextInformation from "components/TextInformation/TextInformation";
import { SmallHeightDivider, StyledTextInput, SubTitle } from "theme/Styles";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  ButtonMessageContainer,
  ChatBottomContainer,
  ChatContainer,
  ChatTopContainer,
  Container,
  InputMessageContainer,
  MessageDateText,
  MessageItem,
  MessageText,
  StyledSendIcon,
} from "views/ServiceRequestedDetails/styles/ServiceRequestedDetailsStyles";
import { InformationFormSchema } from "./MessagesConstants";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { sendMessage } from "api/ActionRequired";
import { useFormik } from "formik";
import { HideGlobalLoading, ShowGlobalLoading } from "redux/actions/UiActions";
import { IconButton } from "@mui/material";
import { format } from "date-fns";
import es from "date-fns/locale/es";
import { replaceGuionToSlashFromString } from "utilities/functions/StringUtil";

function decode_utf8(s) {
  return decodeURIComponent(escape(s));
}

function Messages() {
  let { requestID } = useParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const requestData = queryClient.getQueryData(["serviceRequestedDetail", requestID]);

  const messageMutation = useMutation(sendMessage, {
    onMutate: () => {
      dispatch(ShowGlobalLoading("Cargando"));
    },
  });

  const textFormik = useFormik({
    initialValues: {
      information: "",
    },
    validationSchema: InformationFormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    const reqData = {
      request_id: requestData.request.id,
      text: values.information,
    };
    messageMutation.mutate(reqData, {
      onSuccess: () => {
        enqueueSnackbar("Información requerida enviada satisfactoriamente", { variant: "success" });
        queryClient.invalidateQueries(["serviceRequestedDetail", requestID]);
      },
      onError: () => {
        enqueueSnackbar("Ha ocurrido un error, contacte a soporte", { variant: "error" });
      },
      onSettled: () => {
        dispatch(HideGlobalLoading());
      },
    });
  };

  const messages = requestData.request.comments.map((message) => {
    return {
      id: message.id,
      text: message.text,
      user_id: message?.user_id,
      isUserMessage: message?.user_id !== 2,
      created_at: message.created_at,
    };
  });

  return (
    <Container>
      <TextInformation title="Mensajes" />
      <SmallHeightDivider />
      <ChatContainer>
        <ChatTopContainer isEmpty={messages.length <= 0}>
          <SmallHeightDivider />
          {messages.length > 0 ? (
            messages.map((message) => (
              <MessageItem isUserMessage={message.isUserMessage}>
                <MessageText>{decode_utf8(message.text ?? "")}</MessageText>
                <MessageDateText>
                  {format(
                    new Date(replaceGuionToSlashFromString(message.created_at)),
                    "dd 'de' MMMM yyyy hh:mm a",
                    { locale: es }
                  )}
                </MessageDateText>
              </MessageItem>
            ))
          ) : (
            <SubTitle style={{ alignSelf: "center", justifySelf: "center" }}>
              NO HAY MENSAJES
            </SubTitle>
          )}
        </ChatTopContainer>

        {requestData.request.request_actions_id == 3 && (
          <ChatBottomContainer>
            <InputMessageContainer>
              <StyledTextInput
                hiddenBorder
                multiline
                maxRows={1}
                placeholder="Escriba aquí su mensaje"
                inputProps={{ maxLength: 500 }}
                id="information"
                value={textFormik.values.information}
                onChange={textFormik.handleChange}
                onBlur={textFormik.handleBlur}
                error={textFormik.touched.information && Boolean(textFormik.errors.information)}
                helperText={textFormik.touched.information && textFormik.errors.information}
              />
            </InputMessageContainer>
            <ButtonMessageContainer>
              <IconButton onClick={() => textFormik.handleSubmit()}>
                <StyledSendIcon />
              </IconButton>
            </ButtonMessageContainer>
          </ChatBottomContainer>
        )}
      </ChatContainer>

      {/*<TextField*/}
      {/*  id="information"*/}
      {/*  title="Información"*/}
      {/*  value={textFormik.values.information}*/}
      {/*  onChange={textFormik.handleChange}*/}
      {/*  onBlur={textFormik.handleBlur}*/}
      {/*  error={textFormik.touched.information && Boolean(textFormik.errors.information)}*/}
      {/*  helperText={textFormik.touched.information && textFormik.errors.information}*/}
      {/*  multiline*/}
      {/*  maxLength={255}*/}
      {/*  required*/}
      {/*/>*/}
      {/*<SmallHeightDivider />*/}
      {/*<ButtonContainer>*/}
      {/*  <StyledButtonOutlined onClick={() => textFormik.handleSubmit()} variant="outlined">ENVIAR</StyledButtonOutlined>*/}
      {/*</ButtonContainer>*/}
    </Container>
  );
}

export default Messages;
