import { Alert, AlertTitle, Box, Grid, Stack } from "@mui/material";
import { BodyText, BodyTextBold } from "theme/Styles";
import { useDispatch } from "react-redux";
import { useLayoutEffect } from "react";
import { UpdateAppSubHeaderTitle } from "redux/actions/UiActions";
import { useQuery } from "react-query";
import CenterLoading from "components/CenterLoading/CenterLoading";
import { getDocumentCertificate } from "api/QR";
import { useParams } from "react-router-dom";

export default function QR() {
  const { token } = useParams();
  const dispatch = useDispatch();

  const { data: qrData, isLoading } = useQuery(["qrData"], () => getDocumentCertificate(token));

  useLayoutEffect(() => {
    dispatch(UpdateAppSubHeaderTitle("VALIDACIÓN DE CERTIFICADOS")); //SET SUBHEADER TITLE
  }, [window.location.pathname]);

  if (isLoading) return <CenterLoading />;

  if (!qrData.success)
    return (
      <Stack
        sx={{
          width: "100%",
          paddingX: { xs: "20px", md: "40px", lg: "60px", xl: "180px" },
          paddingY: "20px",
        }}
        spacing={2}
      >
        <Alert severity="error">
          <AlertTitle sx={{ fontWeight: "bold", fontSize: "1.4rem" }}>Error</AlertTitle>
          No se pudo encontrar el certificado
        </Alert>
      </Stack>
    );

  return (
    <Stack
      sx={{
        width: "100%",
        paddingX: { xs: "20px", md: "40px", lg: "60px", xl: "180px" },
        paddingY: "20px",
      }}
      spacing={2}
    >
      <Alert severity="success" sx={{ borderRadius: "0px" }}>
        <AlertTitle sx={{ fontWeight: "bold", fontSize: "1.4rem" }}>
          !Datos validados correctamente!
        </AlertTitle>
        La solicitud número <strong>{qrData?.request_id}</strong> ha sido validada exitosamente.
      </Alert>

      <Stack sx={{ border: "2px solid #1393D0", padding: "20px" }}>
        <Stack
          sx={{
            textTransform: "none",
            borderBottom: "1px solid #1393D0",
            paddingLeft: "15px",
            //marginTop: "14px",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
          spacing={1}
        >
          <Box
            component={"h4"}
            sx={{
              all: "unset",
              color: "#134779",
              fontSize: "1.3rem",
              fontWeight: "bold",
              letterSpacing: "2px",
            }}
          >
            INFORMACIÓN DEL DOCUMENTO
          </Box>
          <BodyText sx={{ textTransform: "none" }}>
            Aquí encontrarás los datos del documento.
          </BodyText>
        </Stack>

        <Grid
          alignItems="center"
          justifyContent="flex-start"
          container
          direction="row"
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 6, sm: 8, md: 12 }}
        >
          <Grid item xs={6} sm={4} md={4}>
            <BodyTextBold>Número de solicitud</BodyTextBold>
            <BodyText>{qrData.request_id}</BodyText>
          </Grid>

          {qrData?.data?.map((item) => (
            <Grid key={item.id} item xs={6} sm={4} md={4}>
              <BodyTextBold>{item.key}</BodyTextBold>
              <BodyText>{item.value}</BodyText>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
}
