import { useLayoutEffect, useState } from "react";
import { BodyText, Row, StyledButtonOutlined } from "theme/Styles";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  HideGlobalLoading,
  ShowGlobalLoading,
  UpdateAppSubHeaderTitle,
} from "redux/actions/UiActions";
import { Redirect, useParams } from "react-router-dom";
import { SolutionContainer } from "./styles/ServiceRequestedDetailsStyles";
import Payment from "./subViews/payments/Payments";
import Details from "./subViews/details/Details";
import DeskNotification from "components/DeskNotification/DeskNotification";
import ActionsRequired from "./subViews/actionsRequired/ActionsRequired";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getRequestDetail,
  sendQualificationAndRating,
  sendRequestConfirmation,
} from "api/ServiceRequestedDetails";
import { cacheConfig } from "cacheConfig";
import { FormRatingSchema, MenuOptions } from "./ServiceRequestedDetailsConstants";
import Messages from "./subViews/messages/Messages";
import CenterLoading from "components/CenterLoading/CenterLoading";
import FormModal from "components/FormModal/FormModal";
import { Box, Button, Rating, Stack } from "@mui/material";
import TextField from "components/TextField/TextField";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import RequiredForm from "./subViews/requiredForm/RequiredForm";
import ServicesIcon from "assets/icons/svgs/services.svg";
import { Text } from "components/NewDesign/Text";
import { replaceGuionToSlashFromString } from "utilities/functions/StringUtil";
import BlinkingText from "components/BlinkingText";
import { ProgressBar } from "./components/ProgressBar";
import { StyledTab, StyledTabs, TabsDivider } from "components/NewDesign/Tabs";
import COLORS from "theme/Colors";
import { SweetAlert } from "components/SweetAlert/SweetAlert";
import { Complains } from "./subViews/complains/Complains";

function ServiceRequestedDetails() {
  const history = useHistory();
  let { requestID } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [activeMenu, setActiveMenu] = useState(0);
  const [ratingModalIsOpen, setRatingModalIsOpen] = useState(false);
  const [rating, setRating] = useState(3);

  const formik = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema: FormRatingSchema,
    onSubmit: (values) => {
      handleSendRating(values);
    },
  });

  const userData = queryClient.getQueryData(["userData"]);

  const {
    data: serviceRequestedDetail,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ["serviceRequestedDetail", requestID],
    async () => {
      try {
        dispatch(ShowGlobalLoading("Cargando"));
        const response = await getRequestDetail(requestID, userData.payload.citizen_id);
        if (!response?.request) {
          history.push("/public");
        }
        dispatch(HideGlobalLoading());
        return response;
      } catch (error) {
        // history.push("/public");
        dispatch(HideGlobalLoading());
      }
    },
    {
      staleTime: cacheConfig.staleTimeForRequestedServiceDetail,
    }
  );

  const mutation = useMutation(sendQualificationAndRating);

  const handleChangeMenu = (menuID) => {
    setActiveMenu(menuID);
    window.location.hash = "";
  };

  const handleRatingModalVisibility = () => {
    setRatingModalIsOpen(!ratingModalIsOpen);
  };

  const handleConfirmation = async (value) => {
    try {
      SweetAlert.fire({
        title: "¿Estás seguro?",
        text: "No podrás revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: COLORS.primary,
        cancelButtonColor: COLORS.secondary,
        confirmButtonText: "Sí, confirmar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(ShowGlobalLoading("Enviando"));

          await sendRequestConfirmation({
            requestId: requestID,
            entityAttributeId: "logicoconfirm",
            entityAttributeValue: value,
          }).then(() => refetch());

          dispatch(HideGlobalLoading());

          await SweetAlert.fire({
            title: "¡Confirmado!",
            text: "Respuesta enviada",
            icon: "success",
            confirmButtonColor: COLORS.primary,
            confirmButtonText: "Aceptar",
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSendRating = (formData) => {
    dispatch(ShowGlobalLoading("Cargando"));
    mutation.mutate(
      { request_id: requestID, rating: rating, comment: formData.comment },
      {
        onSettled: () => {
          dispatch(HideGlobalLoading());
        },
        onSuccess: () => {
          enqueueSnackbar("Calificación enviada con éxito", {
            variant: "success",
          });
          queryClient.invalidateQueries([
            "serviceComments",
            serviceRequestedDetail.request.service.id + "",
          ]);
          queryClient.invalidateQueries(["serviceRequestedDetail", requestID]);
          handleRatingModalVisibility();
        },
        onError: () => {
          enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        },
      }
    );
  };

  useLayoutEffect(() => {
    //UPDATE APP HEADER SUBTITLE, SET THE SERVICE NAME AND TOGGLE TO SPECIFIC MENU
    if (serviceRequestedDetail) {
      dispatch(UpdateAppSubHeaderTitle(serviceRequestedDetail.request.service.name));
      if (serviceRequestedDetail.request.request_actions_id === 1) {
        setActiveMenu(MenuOptions.actionRequired);
      }
      if (serviceRequestedDetail.request.request_actions_id === 3) {
        setActiveMenu(MenuOptions.messages);
      }
      //Action required Payment
      if (serviceRequestedDetail.request.request_actions_id === 5) {
        setActiveMenu(MenuOptions.payment);
      }
      if (serviceRequestedDetail.request.request_actions_id === 7) {
        setActiveMenu(MenuOptions.payment);
      }
      if (
        serviceRequestedDetail.request.request_actions_id === 9 ||
        serviceRequestedDetail.request.request_actions_id === 10 ||
        serviceRequestedDetail.request.request_actions_id === 11
      ) {
        setActiveMenu(MenuOptions.requiredForm);
      }
      //Without required action
      if (serviceRequestedDetail.request.request_actions_id == null) {
        setActiveMenu(MenuOptions.details);
      }
    }
  }, [serviceRequestedDetail]);

  if (isLoading || !serviceRequestedDetail) return <CenterLoading />;
  if (isError) return <Redirect to={"/"} />;

  const TABS = [
    {
      label: "DETALLES",
      value: MenuOptions.details,
      enabled: true,
      blinks: false,
    },
    {
      label: "QUEJAS Y RECLAMACIONES",
      value: MenuOptions.claims,
      enabled: serviceRequestedDetail?.request?.is_claim !== 1,
      blinks: false,
    },
    {
      label: "PAGOS",
      value: MenuOptions.payment,
      enabled: serviceRequestedDetail?.request?.invoice_id,
      blinks:
        serviceRequestedDetail.request.payment.payment_status === "PENDIENTE" ? "orange" : false,
    },
    {
      label: "MENSAJES",
      value: MenuOptions.messages,
      enabled:
        serviceRequestedDetail?.request?.comments?.length > 0 ||
        serviceRequestedDetail?.request?.request_actions_id === 3,
      blinks: false,
    },
    {
      label: "ACCIÓN REQUERIDA",
      value: MenuOptions.actionRequired,
      enabled:
        serviceRequestedDetail?.request?.request_actions_id === 1 ||
        serviceRequestedDetail?.request?.request_actions_id === 1,
      blinks: "red",
    },
    {
      label: "FORMULARIO REQUERIDO",
      value: MenuOptions.requiredForm,
      enabled:
        serviceRequestedDetail?.request?.request_actions_id === 9 ||
        serviceRequestedDetail?.request?.request_actions_id === 10 ||
        serviceRequestedDetail?.request?.request_actions_id === 11,
      blinks: "red",
    },
  ];

  const ratingModalContent = () => (
    <>
      <Stack direction={"row"} alignItems={"center"} spacing={2} marginTop={"10px"}>
        <BodyText style={{ marginLeft: "5px", fontWeight: "bold" }}>Calificación</BodyText>

        <Rating
          onChange={(e, newValue) => setRating(newValue)}
          value={rating}
          precision={0.5}
          size="large"
        />
      </Stack>
      <TextField
        placeholder="Comentario"
        id="comment"
        value={formik.values.comment}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.comment && Boolean(formik.errors.comment)}
        helperText={formik.touched.comment && formik.errors.comment}
        multiline
        minRows={6}
      />
    </>
  );

  const ratingModalActions = () => (
    <StyledButtonOutlined onClick={formik.handleSubmit} variant="outlined">
      Enviar Calificación
    </StyledButtonOutlined>
  );

  return (
    <Box
      sx={{
        marginTop: "40px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Stack direction={"column"} width={{ xs: "90%", lg: "75%" }} spacing={2}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack direction={"row"} spacing={3}>
            <Box
              component="img"
              sx={{
                height: { xs: "80px", md: "120px" },
              }}
              alt="Institution Logo"
              src={ServicesIcon}
            />
            <Stack
              direction={"column"}
              color={COLORS.secondary}
              justifyContent={"flex-start"}
              spacing={1}
            >
              <Text>
                {new Date(
                  replaceGuionToSlashFromString(serviceRequestedDetail?.request?.created_at)
                )?.toLocaleDateString()}
              </Text>
              <Text sx={{ fontSize: "1.2rem", fontWeight: 600 }}>
                {serviceRequestedDetail?.request.service.name}
              </Text>
              <Text sx={{ fontSize: "1.2rem", fontWeight: 600 }}>
                {serviceRequestedDetail?.request.code}
              </Text>
            </Stack>
          </Stack>
        </Stack>

        <ProgressBar
          status={serviceRequestedDetail?.request.status.name}
          progress={serviceRequestedDetail?.request.progress}
        />

        {serviceRequestedDetail?.request.request_actions &&
          serviceRequestedDetail?.request.messages.map((message) => (
            <DeskNotification
              key={message.id}
              variant={"warning"}
              disableCloseButton={true}
              message={message.message}
              customActions={
                serviceRequestedDetail?.request?.request_actions_id === 8 && (
                  <Stack direction={"row"} marginRight={"10px"} spacing={1}>
                    <Button
                      aria-label="YES"
                      color="success"
                      size="small"
                      variant={"contained"}
                      sx={{
                        boxShadow: "none",

                        fontWeight: 600,
                        letterSpacing: "2px",
                      }}
                      onClick={() => handleConfirmation(true)}
                    >
                      SI
                    </Button>
                    <Button
                      aria-label="NO"
                      color="error"
                      size="small"
                      variant={"contained"}
                      sx={{
                        boxShadow: "none",

                        fontWeight: 600,
                        letterSpacing: "2px",
                      }}
                      onClick={() => handleConfirmation(false)}
                    >
                      NO
                    </Button>
                  </Stack>
                )
              }
            />
          ))}

        <Box
          sx={{
            borderBottom: `1px solid ${COLORS.primary}`,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <StyledTabs
            value={activeMenu}
            onChange={(e, val) => handleChangeMenu(val)}
            scrollButtons
            allowScrollButtonsMobile
          >
            {TABS.filter((tab) => tab.enabled).map((tab, index) => [
              //Only show the divider after the second tab
              index !== 0 && <TabsDivider orientation={"vertical"} key={`divider-${tab.value}`} />,
              <StyledTab
                key={`tab-${tab.value}`}
                value={tab.value}
                label={
                  tab.blinks ? (
                    <BlinkingText color={tab.blinks}>{tab.label}</BlinkingText>
                  ) : (
                    tab.label
                  )
                }
              />,
            ])}
          </StyledTabs>
        </Box>

        {serviceRequestedDetail.request.solution && (
          <Row
            style={{
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <SolutionContainer fullwidth={serviceRequestedDetail.request.rating?.length > 0}>
              <DeskNotification
                variant={serviceRequestedDetail.request.status.color}
                disableCloseButton={true}
                message={serviceRequestedDetail.request.solution}
              />
            </SolutionContainer>

            {serviceRequestedDetail.request.rating?.length <= 0 && (
              <div style={{ width: "20%" }}>
                <StyledButtonOutlined onClick={handleRatingModalVisibility} variant="outlined">
                  CALIFICAR
                </StyledButtonOutlined>

                <FormModal
                  title="Calificación de servicio"
                  open={ratingModalIsOpen}
                  onClose={handleRatingModalVisibility}
                  content={ratingModalContent()}
                  actions={ratingModalActions()}
                />
              </div>
            )}
          </Row>
        )}
        {activeMenu === MenuOptions.details && <Details />}
        {/*{activeMenu === MenuOptions.claims && <ComplaintsAndClaims />}*/}
        {activeMenu === MenuOptions.claims && (
          <Complains requestCode={serviceRequestedDetail.request.code} />
        )}
        {activeMenu === MenuOptions.payment && <Payment />}
        {activeMenu === MenuOptions.messages && <Messages />}
        {activeMenu === MenuOptions.requiredForm && <RequiredForm />}
        {activeMenu === MenuOptions.actionRequired && <ActionsRequired />}
      </Stack>
    </Box>
  );
}

export default ServiceRequestedDetails;
