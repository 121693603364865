import { hourToMilliseconds, minutesToMilliseconds } from "./utilities/functions/TimeUtil";

/*
  THIS FILE IS FOR GLOBAL AND SPECIFIC INFORMATION OF REACT-QUERY
  staleTime = Tiempo en que la información sera considerada fresca o valida;
  cacheTime = Tiempo en que la información inactiva (que no se este mostrando/visualizando o consumiendo) sera mantenida en cache
*/

export const cacheConfig = {
  staleTimeGeneral: hourToMilliseconds(24),
  staleTimeForRequestedServicesList: minutesToMilliseconds(9),
  staleTimeServicesList: minutesToMilliseconds(9),
  staleTimeForRequestedServiceDetail: minutesToMilliseconds(9),
  cacheTime: hourToMilliseconds(24),
};
