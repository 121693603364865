import { memo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import parse from "html-react-parser";
import { Alert, Collapse } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function DeskNotification({ variant, message, onClose, disableCloseButton, customActions }) {
  const [open, setOpen] = useState(true);

  const handleOnClose = () => {
    setOpen(false);
    setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, 1000);
  };

  return (
    <Collapse in={open}>
      <Alert
        variant={"standard"}
        severity={variant === "danger" ? "error" : variant}
        action={
          customActions
            ? customActions
            : !disableCloseButton && (
                <IconButton aria-label="close" color="inherit" size="small" onClick={handleOnClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              )
        }
      >
        <strong>{message && parse(message)}</strong>
      </Alert>
    </Collapse>
  );
}

export default memo(DeskNotification);
