import { styled } from "@mui/system";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

export const StyledOption = styled("option")({
  fontSize: "18px",
});
