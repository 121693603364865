//put all valid types for upload
export const types = [
  "application/pdf",
  "image/png",
  "image/jpeg",
  "image/jpg",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const typesForSelectedList = {
  "application/pdf": "pdf",
  "image/png": "png",
  "image/jpeg": "jpeg",
  "image/jpg": "jpg",
  "application/vnd.ms-excel": "xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  xls: "xls",
  xlsx: "xlsx",
};

export const typesForExcel = {
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export const textFieldStyle = {
  borderRadius: 0,
  "& .MuiOutlinedInput-root": {
    lineHeight: 1.5,
    fontSize: "0.875rem",
    padding: 0,

    "& fieldset": {
      border: "1px solid divider",
      borderRadius: 0,
      justifyContent: "center",
      alignItems: "center",
    },

    "&:hover fieldset": {
      background: "action.hover",
      border: "1px solid #1393D0",
    },

    "&.Mui-focused fieldset": {
      background: "action.hover",
      border: "1px solid #1393D0",
    },
  },
};
