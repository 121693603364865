import { Fragment } from "react";
import TextInformation from "components/TextInformation/TextInformation";
import { SmallHeightDivider, StyledButtonOutlined } from "theme/Styles";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  ButtonContainer,
  Container,
} from "views/ServiceRequestedDetails/styles/ServiceRequestedDetailsStyles";
import { FileFormSchema } from "./ActionsRequiredConstants";
import UploadFile from "components/UploadFile/UploadFile";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { assignDocumentsForRequiredActionInSoftExpert } from "api/ActionRequired";
import { useFormik } from "formik";
import { HideGlobalLoading, ShowGlobalLoading } from "redux/actions/UiActions";
import { linkingDocumentsToRequestInSoftExpert, uploadFormDocuments } from "api/RequestService";
import axios from "axios";

function ActionsRequired() {
  const history = useHistory();
  let { requestID } = useParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const requestData = queryClient.getQueryData(["serviceRequestedDetail", requestID]);
  const userData = queryClient.getQueryData(["userData"]);

  const actionRequiredFileMutation = useMutation(assignDocumentsForRequiredActionInSoftExpert, {
    onMutate: () => {
      dispatch(ShowGlobalLoading("Cargando"));
    },
  });

  const fileFormik = useFormik({
    initialValues: {
      file: null,
    },
    validationSchema: FileFormSchema,
    onSubmit: (values) => {
      handleSubmitFile(values);
    },
  });

  const handleSubmitFile = async (values) => {
    let uploadedFilesRoutes = [];
    const formFileData = new FormData();
    for (let i = 0; i < values.file.files.length; i++) {
      formFileData.append("file[]", values.file.files[i], values.file.files[i].name);
    }
    const uploadFilesConfig = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        dispatch(ShowGlobalLoading(`Subiendo documentos ${percentCompleted}%`));
      },
    };
    let responseFilesUpload = await uploadFormDocuments(formFileData, uploadFilesConfig);
    if (responseFilesUpload.success) {
      uploadedFilesRoutes = [
        ...responseFilesUpload.files.map((item) => {
          return {
            ...item,
            label: `DOC Acción requerida - ${item.name}`, //item.name //`Documento de accion requerida ${index + 1}`
          };
        }),
      ];
      let uploadSoftExpertArrayAxios = [];

      dispatch(ShowGlobalLoading("Procesando"));

      for (let i = 0; i < uploadedFilesRoutes.length; i++) {
        if (uploadedFilesRoutes[i]?.size_mb < parseInt(process.env.REACT_APP_MAX_FILE_SIZE)) {
          const softExpertRequest = {
            documents: [{ ...uploadedFilesRoutes[i] }],
            title: `documento-${userData.payload.citizen_id}`,
            record_id: requestData.request.code,
            attribute: `NumeroSolicitud=${requestData.request.code};DocumentoIdentidadSolicitante=${userData.payload.citizen_id};TipoDocumentoPortal=Adjunto`,
            process_id: requestData.request.service.process_id,
            acronym: "DGADE",
            names: [uploadedFilesRoutes[i].label],
            activity_id: requestData.request?.activity?.activity_id,
            new_request: i !== uploadedFilesRoutes.length - 1, // only false on the last iteration
          };

          // wait for response before next upload
          uploadSoftExpertArrayAxios.push(
            await linkingDocumentsToRequestInSoftExpert(softExpertRequest)
          );
        }
      }

      await axios.all(uploadSoftExpertArrayAxios);
      const assignmentData = {
        documents: uploadedFilesRoutes,
        record_id: requestData.request.code,
        status: true,
      };
      actionRequiredFileMutation.mutate(assignmentData, {
        onSuccess: () => {
          enqueueSnackbar("Documento requerido enviada satisfactoriamente", { variant: "success" });
          queryClient.invalidateQueries(["serviceRequestedDetail", requestID]);
          setTimeout(() => {
            history.push(`/app/serviceRequestedDetails/${requestID}#documents`);
            dispatch(HideGlobalLoading());
          }, 500);
        },
        onError: (error) => {
          console.error(error);
          dispatch(HideGlobalLoading());
          enqueueSnackbar("Ha ocurrido un error, contacte a soporte", { variant: "error" });
        },
        onSettled: () => {},
      });
    } else {
      console.error(responseFilesUpload);
      dispatch(HideGlobalLoading());
      enqueueSnackbar("Ha ocurrido un error, contacte a soporte", { variant: "error" });
    }
  };

  return (
    <Container>
      <Fragment>
        <TextInformation title="Documento requerido" />
        <SmallHeightDivider />
        <UploadFile
          id="file"
          title="Documento"
          onChange={fileFormik.handleChange}
          onBlur={fileFormik.handleBlur}
          error={fileFormik.touched.file && Boolean(fileFormik.errors.file)}
          helperText={fileFormik.touched.file && fileFormik.errors.file}
          required
          multipleDocuments
          value={fileFormik.values.file}
        />
        <SmallHeightDivider />
        <ButtonContainer>
          <StyledButtonOutlined onClick={fileFormik.handleSubmit} variant="outlined">
            ENVIAR
          </StyledButtonOutlined>
        </ButtonContainer>
      </Fragment>
    </Container>
  );
}

export default ActionsRequired;
