import { memo } from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Stack } from "@mui/material";
import { StyledButton } from "theme/Styles";
import {
  BodyText,
  CenterContainer,
  Container,
  Header,
  IconDivider,
  StyledAnnouncementOutlinedIcon,
  TextContainer,
} from "./styles/ImportantInformationModalStyles";
import COLORS from "theme/Colors";

function ImportantInformationModal({
  open,
  content,
  buttonTitle,
  buttonClick,
  onBackDropClick,
  onCloseClick,
  CloseTitle,
  CloseButton,
  isLoading = false,
  title = "Información Importante",
}) {
  return (
    <Dialog open={open} onClose={onCloseClick}>
      <Container>
        <Header
          sx={{
            borderBottom: "10px solid " + COLORS.tertiary,
          }}
        >
          <CenterContainer>
            <StyledAnnouncementOutlinedIcon />
            <IconDivider />
            <Box
              as={"h1"}
              sx={{
                color: "white",
                fontSize: "1.8rem",
              }}
            >
              {title}
            </Box>
          </CenterContainer>
        </Header>
        <CenterContainer>
          <TextContainer>
            <BodyText
              sx={{
                textAlign: "justify",
              }}
            >
              {content}
            </BodyText>
          </TextContainer>
        </CenterContainer>
        <CenterContainer>
          <Stack direction={"row"} width={"100%"} spacing={2}>
            <StyledButton onClick={buttonClick} disabled={isLoading}>
              {buttonTitle}
            </StyledButton>

            {CloseButton && <StyledButton onClick={onCloseClick}>{CloseTitle}</StyledButton>}
          </Stack>
        </CenterContainer>
      </Container>
    </Dialog>
  );
}

export default memo(ImportantInformationModal);
