import axios from "axios";

export const downloadFile = async (downloadUrl, fileType = "application/pdf") => {
  const response = await axios({
    url: downloadUrl,
    method: "GET",
    responseType: "blob",
  });
  const file = new Blob([response.data], { type: fileType });
  return URL.createObjectURL(file);
};
