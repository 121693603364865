import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMemo, useState } from "react";
import COLORS from "theme/Colors";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: COLORS.secondary,
    color: theme.palette.common.white,
    borderBottom: "1px solid rgb(23, 18, 18)",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    //border: 0,
  },
}));

export function GridItemTable({ props, fields, values, onEdit, onDelete }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const parseValue = (value, field) => {
    if (field?.type === "select") {
      return field?.values?.find((option) => option.value === value)?.label;
    }

    if (field?.type && ["checkbox-group", "radio-group"]?.includes(field.type)) {
      return field?.values
        ?.filter((option) => value?.includes(option.value))
        ?.map((gItem) => {
          return field?.type === "checkbox-group" ? "Si" : gItem.label;
        })
        .join(", ");
    }

    if (["date", "time"].includes(field?.type)) {
      return value ? new Date(value).toLocaleDateString() : "";
    }

    return value;
  };

  const columns = useMemo(() => {
    let col = fields
      .filter(
        (field) =>
          !["header", "label", "subtitle", "file", "rules", "rulesprice"].includes(field?.type)
      )
      .map((field) => {
        return {
          id: field.fieldKey,
          label: field.label,
          minWidth: 170,
          align: "left",
        };
      });

    return [...col];
  }, [fields]);

  const rows = values.map((value) => {
    return columns.reduce((acc, column) => {
      const fieldData = fields?.find((field) => field?.fieldKey === column.id);
      acc[column.id] = parseValue(value[column.id], fieldData);
      return acc;
    }, {});
  });

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", overflowX: "scroll" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                align={"center"}
                sx={{
                  fontWeight: "bold",
                  width: 120,
                  minWidth: 120,
                  position: "sticky",
                  left: 0,
                }}
              >
                Acciones
              </StyledTableCell>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    minWidth: column.minWidth,
                    fontWeight: "bold",
                  }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
              return (
                <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  <StyledTableCell
                    align="center"
                    sx={{
                      width: 120,
                      minWidth: 120,
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={2}
                      alignContent={"center"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <IconButton
                        aria-label={`edit row ${index}`}
                        onClick={() => onEdit({ ...values[index], listIndex: index })}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label={`delete-row-${index}`}
                        onClick={() => onDelete(props, index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </StyledTableCell>

                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <StyledTableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number" ? column.format(value) : value}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage="Filas por página"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
