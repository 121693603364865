import { Divider, styled } from "@mui/material";

/**
 *
 * @param {"light" || "dark"} variant
 * @type {*}
 */
export const CustomDivider = styled(Divider)(({ variant, theme }) => ({
  height: "1px",
  [theme.breakpoints.down("lg")]: {
    height: "2px",
  },
  border: 0,
  width: "100%",
  marginTop: "20px",
  backgroundImage:
    variant === "light"
      ? "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(19, 71, 122, 1), rgba(0, 0, 0, 0))"
      : "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 255.75), rgba(0, 0, 0, 0))",
}));
