import { citizenProfileValidation } from "api/RenderField";
import { SweetAlert } from "components/SweetAlert/SweetAlert";
import { addMonths, subDays } from "date-fns";
import { localToArray, mapArrayDiff } from "utilities/functions/ArrayUtil";
import {
  cleanNumbersFromString,
  formatNumberWithDecimal,
  localToString,
} from "utilities/functions/StringUtil";
import { dateRangeFromMonthAmount } from "utilities/functions/TimeUtil";
import { FIELD_TYPES, MASK_LIST } from "../FormConstants";

export function localOnChange(props, val) {
  // clean errors on change start
  if (props.fieldKey && props?.setFieldError && props?.setLocalFieldErrors) {
    props?.setLocalFieldErrors?.((prev) => {
      const newOb = { ...prev };
      delete newOb[props.fieldKey];
      return newOb;
    });
    props?.setFieldError?.(props.fieldKey, "");
  }

  switch (props.type) {
    case FIELD_TYPES.select:
      // data object of form json. reference -> ArrayUtil/dataToSelect.js
      const currentSelectedValue = localToArray(props.data).find(
        (item) => item.value === props.value
      );

      if (val?.target.value) {
        const rulesSelect = localToString(
          localToArray(props.data).find((item) => item.value === val.target.value)?.rule
        );
        if (rulesSelect.length > 0) {
          props.changeRule(rulesSelect, null, false, props.fieldKey);
        }
      } else if (currentSelectedValue?.invertRule) {
        const invertRulesSelect = localToString(currentSelectedValue.invertRule);
        if (invertRulesSelect.length > 0) {
          props.changeRule(invertRulesSelect, null, false, props.fieldKey);
        }
      }
      break;

    case FIELD_TYPES.checkboxGroup:
      const selectedOption = mapArrayDiff(val.target.value, props.value)[0];
      const unselectedOption = mapArrayDiff(props.value, val.target.value)[0];

      if (selectedOption) {
        const rulesCheckbox = props.values.find((item) => item.value === selectedOption)?.rule;
        if (rulesCheckbox?.length > 0) {
          props.changeRule(rulesCheckbox, null, false, props.fieldKey);
        }
      } else {
        const invertRuleCheckbox = props.values.find((item) => item.value === unselectedOption)?.[
          "ruleF"
        ];

        if (invertRuleCheckbox?.length > 0) {
          props.changeRule(invertRuleCheckbox, null, false, props.fieldKey);
        }
      }
      break;
    case FIELD_TYPES.radioGroup:
      if (props?.fieldKey === "fisicaojuridica" && val.target.value === "1") {
        if (
          !(
            "solicitatercero" in props?.formValues && props?.formValues?.["solicitatercero"] === "1"
          )
        ) {
          props?.setDisableStepsButtons(true);

          const citizenId = localStorage.getItem("user_cedula").replaceAll('"', "");

          citizenProfileValidation(
            citizenId,
            props?.formValues?.["perfilsigaregla"] || "EM01-11*EM01-12"
          )
            .then((response) => {
              if (response?.success) {
                props.setFieldValue("cedulacontribuy", citizenId);
              } else {
                props.setFieldValue("cedulacontribuy", "");
                SweetAlert.fire({
                  title: "Error en la validación",
                  text: response?.message,
                  icon: "error",
                  confirmButtonText: "Ok",
                }).then(() => {
                  window.history.back();
                });
              }
            })
            .catch((error) => {
              console.error(error);
              props.setFieldValue("cedulacontribuy", "");
            })
            .finally(() => {
              props?.setDisableStepsButtons(false);
            });
        }
      }
      // else {
      //   if (props?.formValues) {
      //     props?.setFieldValue("cedulacontribuy", "");
      //   }
      // }

      if (localToString(val.target.value).length > 0) {
        const rulesRadio = props.values.find((item) => item.value === val.target.value)?.rule;

        if (rulesRadio.length > 0) {
          props.changeRule(rulesRadio, null, false, props.fieldKey);
        }
      } else {
        const invertRuleRadio = props.values.find((item) => item.value === props.value)?.invertRule;
        if (invertRuleRadio.length > 0) {
          props.changeRule(invertRuleRadio, null, false, props.fieldKey);
        }
      }

      break;

    default:
      break;
  }

  //change val
  switch (MASK_LIST[props.Mask || ""]) {
    case MASK_LIST[7]:
      props.onChange(props.fieldKey, cleanNumbersFromString(val.target.value));
      break;
    case MASK_LIST[9]:
      const maskParams = props.MaskParam.split("&");
      const months = maskParams[0] || 1;
      props?.setFieldValue(
        maskParams[1],
        subDays(addMonths(new Date(val.target.value), months), 1)
      );
      props.setFieldProps({
        field: maskParams[1],
        prop: {
          enabled: false,
          successHelper: `El rango es de ${dateRangeFromMonthAmount(months)}`,
        },
      });

      props.onChange(props.fieldKey, val.target.value);
      break;
    case MASK_LIST[12]:
      props.onChange(props.fieldKey, formatNumberWithDecimal(val.target.value));
      break;
    default:
      props.onChange(props.fieldKey, val.target.value);
      break;
  }
}

/**
 * @param props - props of the field
 * @param response - response of the api
 * @param field - field to set the social reason
 */
export function setSocialReason(props, response, field) {
  const socialReasonField = field || props?.["razonsocial_field"] || "razonsocial";

  if (socialReasonField in props?.formValues) {
    let socialReason = ["1", "20"].includes(props?.Mask)
      ? response?.payload?.["RAZON_SOCIAL"]
      : response?.razon_social;

    props.setFieldValue(socialReasonField, response?.valid ? socialReason : "");
  } else {
    console.error(`${socialReasonField} no existe en el formulario`);
  }
}

export function setValueIfExist(props, field, value) {
  if (field in props?.formValues) {
    props.setFieldValue(field, value);
  } else {
    console.error(`${field} no existe en el formulario`);
  }
}

export function setValueAndBlockField(props, field, value) {
  if (field in props?.formValues) {
    props.setFieldValue(field, value);
    props.setFieldProps({
      field: field,
      prop: {
        enabled: false,
        disabled: true,
      },
    });
  } else {
    console.error(`${field} no existe en el formulario`);
  }
}

export function insertGridData({ props, setModalVisible, values, fatherKey, listIndex }) {
  const value = localToArray(props.value);

  // eslint-disable-next-line no-extend-native
  Array.prototype.includesObj = function (obj) {
    for (let i = 0; i < this.length; i++) {
      if (
        JSON.stringify(this[i], Object.keys(this[i]).sort()).toLowerCase() ===
        JSON.stringify(obj, Object.keys(obj).sort()).toLowerCase()
      )
        return true;
    }
    return false;
  };

  const isDuplicate = value?.includesObj(values);

  if (!isDuplicate) {
    if (listIndex !== undefined) {
      value[listIndex] = values;
      props.onChange(fatherKey, [...value]);
    } else {
      props.onChange(fatherKey, [...value, values]);
    }
    setModalVisible(false);
  } else {
    SweetAlert.fire({
      title: "Error",
      text: "Este registro ya existe",
      icon: "error",
      confirmButtonText: "Aceptar",
    }).then(() => {});
  }
}

export function deleteGridElement(props, position) {
  if (position != null) {
    const result = localToArray(props.value).filter((doc, index) => index !== position);
    props.onChange(props.fieldKey, result);
  }
}

// export function childrenDataFilter(arr, fatherKey, fatherVal) {
//   if (localToArray(arr).length === 0 || !fatherKey) {
//     return arr;
//   }
//   return localToArray(arr).filter((item) => item.father === fatherVal);
// }

export function childrenDataFilter(arr, fatherKey, fatherVal, maskParam) {
  let optionList = localToArray(arr);

  if (maskParam) {
    const options = maskParam.split(",");
    optionList = optionList.filter((item) => options.includes(item.value));
  }

  // if (optionList.length === 0 || !fatherKey) {
  //   const options = maskParam.split(",");
  //   optionList = optionList.filter((item) => options.includes(item.value));
  //   return optionList;
  // }

  if (fatherVal) {
    optionList = optionList.filter((item) => item.father === fatherVal);
  }

  return optionList;
}
