import LocalStorageService from "services/LocalStorageService";
import iucService from "services/ApiServerCall";

export async function getUserQuestions(citizenId) {
  const { data } = await iucService().get(`/get/questions/${citizenId}`);
  return data;
}

export async function sendUserQuestions(citizenId, data) {
  const { data: data_1 } = await iucService().post(`/send/questions/${citizenId}`, data);
  return data_1;
}

export async function validateDeviceQuestion(citizenId, data, save_device = 0) {
  const device_id = LocalStorageService.getItem("device_id");
  const { data: data_1 } = await iucService().post(`/validate/device/question`, {
    citizen_id: citizenId,
    data,
    device_id,
    save_device,
  });
  return data_1;
}
