import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import SearchIcon from "assets/icons/pngs/search.png";
import COLORS from "theme/Colors";

const textFieldStyle = {
  borderRadius: 0,
  "& .MuiOutlinedInput-root": {
    lineHeight: 1.5,
    fontSize: "0.875rem",
    padding: 0,

    "& fieldset": {
      border: "1px solid divider",
      borderRadius: 0,
      justifyContent: "center",
      alignItems: "center",
    },

    "&:hover fieldset": {
      background: "action.hover",
      border: "1px solid #1393D0",
    },
    "&.Mui-focused fieldset": {
      background: "action.hover",
      border: "1px solid #1393D0",
    },
  },
};

export function SearchInput(props) {
  return (
    <Stack
      direction={"row"}
      sx={{
        backgroundColor: "white",
        width: {
          xs: "100%",
          md: "350px",
        },
      }}
    >
      <Autocomplete
        options={props.isServiceListLoading ? [] : props.servicesForSearcher}
        getOptionLabel={(option) => option.title}
        autoHighlight
        freeSolo
        style={{
          width: "100%",
        }}
        onChange={(_, service) => props.goToSelectedService(service)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.defaultMuiPrevented = true;
          }
        }}
        renderInput={(params) => (
          <TextField
            fullWidth={true}
            sx={textFieldStyle}
            placeholder={"BUSCAR SERVICIOS"}
            {...params}
          />
        )}
      />
      <Box
        sx={{
          display: "flex",
          backgroundColor: COLORS.primary,
          height: "100%",
          width: "3rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component={"img"}
          src={SearchIcon}
          sx={{
            maxHeight: "20px",
            maxWidth: "20px",
          }}
        />
      </Box>
    </Stack>
  );
}
