import { memo, useState } from "react";
import RadioButtonGroup from "components/RadioButtonGroup/RadioButtonGroup";
import CheckBoxGroup from "components/CheckBoxGroup/CheckBoxGroup";
import { SmallHeightDivider } from "theme/Styles";
import { priceVariationToLaborableTime } from "utilities/functions/FormatterUtil";
import {
  Body,
  BodySubTitle,
  BodyTitle,
  ButtonsContainer,
  CardButton,
  Container,
  DisabledCardOverlay,
  Header,
  HeaderSubTitle,
  HeaderTitle,
} from "./styles/PaymentCardStyles";
import TextField from "components/TextField/TextField";

export function getQuantityFields(variations, rulePrice) {
  return variations?.map((variation, index) => {
    const ruleVariation = rulePrice?.data.find((item) => item.id === variation.id);
    const rule = ruleVariation?.attribute.split("&");
    return {
      id: variation.id,
      label: `(${variation.description})`,
      value: rule && rule[0] ? parseInt(rule[0]) : 1,
      quantity: !index ? 1 : 0,
      quantityMin: rule && rule[0] ? parseInt(rule[0]) : 1,
      quantityMax: rule && rule[1] ? parseInt(rule[1]) : 99,
      amount: parseInt(variation.price),
      extra: rule && rule[2] ? parseInt(rule[2]) : 0,
      lock: false,
      concept: variation.concept_type,
      conceptExtra: rule && rule[4] ? rule[4] : null,
      fixedPrice: rule && rule[3] ? rule[3] === "true" : false,
      enabled: !!(rule && rule[0] !== rule[1] && !index),
    };
  });
}

function PaymentCard({ title, onClick, price, form, isSelected, external_pay, sirit_code }) {
  const rulePrice = form.fields[0].find((rule) => rule.type === "rulesprice");
  const [servicePrice, setServicePrice] = useState(price.variations?.[0]?.price);

  const [selectedVariation, setSelectedVariation] = useState(price.variations?.[0]);
  const [selectedCheckVariation, setSelectedCheckVariation] = useState([price.variations?.[0].id]);
  const [quantityFields, setQuantityFields] = useState(
    getQuantityFields(price.variations, rulePrice)
  );

  const variationsForRadioGroup = price.variations?.map((variation) => {
    return {
      label: variation.concept,
      value: variation.id,
    };
  });

  const variationsForCheckGroup = price.variations?.map((variation) => {
    return {
      id: variation.id,
      label: variation.concept,
      value: variation.id,
    };
  });

  /* THESE FUNCTIONS ARE REMOVED BECAUSE ON THIS PROJECT ONLY HAVE 1 PRICE WITH VARIATIONS USER CANT PUT AMOUNTS FOR CALCS
        const handleValueChange = (value) => {
            const selectedVariationPrice = variations.find((variation) => variation.id == selectedVariation)?.price;
            setServicePrice(value * selectedVariationPrice)
            setQuantityForRequest(value)
        }

        const handleRadioChange = (value) => {
            const selectedVariationPrice = variations.find((variation) => variation.id == value)?.price;
            setServicePrice(quantityForRequest * selectedVariationPrice);
            setSelectedVariation(value);

        }
    */

  const handleValueChange = (iquantity) => {
    const item = iquantity.id;
    const value = iquantity.value;
    const ruleVariation = rulePrice?.data.find((variation) => variation.id == item);
    const rule = ruleVariation?.attribute.split("&");

    let actualPrice = 0;
    let actualValue = value >= 1 ? parseInt(value) : 1;
    let calcValue = actualValue;
    let quantityMin = 1;
    let quantityMax = 99;
    let fields = quantityFields;
    let newQuantityFields = [];

    if (rule[0] && !rule[1]) {
      quantityMin = parseInt(rule[0]);
    } else if (rule[0] && rule[1]) {
      quantityMin = parseInt(rule[0]);
      quantityMax = parseInt(rule[1]);
    }
    actualValue = actualValue < quantityMin ? quantityMin : actualValue;
    calcValue = actualValue > quantityMax ? quantityMax : actualValue;
    //calcValue = quantityMin != 1 ? actualValue - (quantityMin - 1) : actualValue;

    if (price.status == 1) {
      fields.map((element, index) => {
        if (calcValue > quantityMin && calcValue < quantityMax && index) {
          element.lock = false;
        }

        if (element.id == item) {
          !element.lock &&
            !element.fixedPrice &&
            (element.quantity =
              calcValue < element.value ? element.quantity - 1 : element.quantity + 1);
          element.value = calcValue;
        }

        if ((calcValue == quantityMin || calcValue == quantityMax) && index) {
          element.lock = true;
        }

        if (!index) {
          if (element.value > 6 && !element.lock) {
            element.lock = true;
            element.quantity -= 1;
            fields[index + 1].quantity = parseInt(fields[index + 1].quantity) + 1;
          } else if (element.value <= 6 && element.lock) {
            element.lock = false;
            element.quantity += 1;
            fields[index + 1].quantity = parseInt(fields[index + 1].quantity) - 1;
          }
        }

        if (selectedCheckVariation.includes(element.id) || element.quantity >= 1) {
          actualPrice += element.amount * element.quantity;
        }

        newQuantityFields.push(element);
      });
    } else {
      fields.map((element) => {
        if (calcValue > quantityMin && calcValue < quantityMax) {
          element.lock = false;
        }

        if (element.id == item) {
          !element.lock &&
            !element.fixedPrice &&
            (element.quantity =
              calcValue < element.value ? element.quantity - 1 : element.quantity + 1);
          element.value = calcValue;

          actualPrice += element.amount * element.quantity;
          actualPrice += element.extra;
        }

        if (calcValue == quantityMin || calcValue == quantityMax) {
          element.lock = true;
        }

        newQuantityFields.push(element);
      });
    }

    setServicePrice(actualPrice ? actualPrice + ".00" : "N/A");
    setQuantityFields(newQuantityFields);
  };

  const handleRadioChange = (value) => {
    const variation = price.variations.find((variation) => variation.id == value);
    let fields = quantityFields;
    let newQuantityFields = [];
    let actualPrice = 0;

    fields.map((element) => {
      if (element.id == value) {
        element.enabled = element.quantityMin != element.quantityMax ? true : false;
        element.value = element.quantityMin != element.quantityMax ? element.quantityMin : 1;
        element.quantity = 1;
        actualPrice += element.amount * element.quantity;
        actualPrice += element.extra;
      } else {
        element.enabled = false;
        element.quantity = 0;
      }

      newQuantityFields.push(element);
    });

    setServicePrice(actualPrice ? actualPrice + ".00" : "N/A");
    setSelectedVariation(variation);
    setQuantityFields(newQuantityFields);
  };

  const handleCheckChange = (check) => {
    const variations = check.value;
    const icheck = check.id;
    const value = price.variations.find((variation) => variation.id == icheck);
    let actualPrice = 0;
    let fields = quantityFields;
    let newQuantityFields = [];

    fields.forEach((element) => {
      element.lock = false;

      if (variations.includes(element.id)) {
        element.value =
          element.quantityMin != element.quantityMax && element.id == icheck
            ? element.quantityMin
            : 1;
        element.quantity = 1;
        element.enabled = true;
        actualPrice += element.amount * element.quantity;
        actualPrice += element.extra;
      } else {
        element.value = 1;
        element.quantity = 0;
        element.enabled = false;
      }

      newQuantityFields.push(element);
    });

    setQuantityFields(newQuantityFields);
    value && setSelectedVariation(value);
    setServicePrice(actualPrice ? actualPrice + ".00" : "N/A");
    setSelectedCheckVariation(variations);
  };

  return (
    <Container>
      <DisabledCardOverlay isSelected={isSelected} />
      <Header>
        {parseFloat(servicePrice) > 1 || servicePrice === "N/A" ? (
          <>
            <HeaderSubTitle>{title}</HeaderSubTitle>
            <HeaderTitle>
              {servicePrice !== "N/A" ? "RD$" + servicePrice : servicePrice}
            </HeaderTitle>
          </>
        ) : (
          <HeaderTitle>
            {!!external_pay || !!sirit_code ? "Costo Variable" : "Servicio Gratuito"}
          </HeaderTitle>
        )}
      </Header>
      <Body>
        {price.variations.length > 1 ? (
          <div>
            <SmallHeightDivider />

            {price.status == 0 ? (
              <RadioButtonGroup
                options={variationsForRadioGroup}
                title="Opciones de servicio"
                helperText={""}
                onChange={(e) => handleRadioChange(e.target.value)}
                value={selectedVariation?.id}
              />
            ) : (
              <CheckBoxGroup
                title="Opciones de servicio"
                value={selectedCheckVariation}
                options={variationsForCheckGroup}
                helperText={""}
                onChange={(e) => handleCheckChange(e.target)}
              />
            )}

            {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <strong>
                  <BodySubTitle>
                      Especifique cantidad a solicitar
                  </BodySubTitle>
                </strong>
                <div style={{ width: '15px' }} />
                <div style={{ width: '60px' }}>
                  <TextField value={quantityForRequest} onChange={(e) => handleValueChange(e.target.value)} type="number" />
                </div>
            </div> */}
          </div>
        ) : null}
        <SmallHeightDivider />
        <div
          style={{
            width: "100%",
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <BodySubTitle>Tiempo de entrega</BodySubTitle>
          <strong>
            <BodyTitle>
              {priceVariationToLaborableTime(
                price.variations?.find((variation) => variation.id === selectedVariation?.id)
                  ?.delivery_time
              ) || "Indefinido"}
            </BodyTitle>
          </strong>

          {quantityFields.map(
            (item, index) =>
              item.enabled && (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "70%",
                    alignSelf: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ width: "65%" }}>
                    <strong>
                      <BodySubTitle>Cantidad {item.label}</BodySubTitle>
                    </strong>
                  </div>
                  <div
                    style={{ width: "25%", minWidth: "50px", marginTop: -10, marginBottom: -20 }}
                  >
                    <TextField
                      id={item.id}
                      value={item.value}
                      onChange={(e) => handleValueChange(e.target)}
                      type="number"
                    />
                  </div>
                </div>
              )
          )}
        </div>
        <SmallHeightDivider />
      </Body>
      <ButtonsContainer>
        {servicePrice !== "N/A" ? (
          <CardButton
            onClick={() => onClick(selectedVariation, quantityFields, parseInt(servicePrice))}
          >
            Confirmar
          </CardButton>
        ) : (
          <CardButton>Seleccione una Opción</CardButton>
        )}
      </ButtonsContainer>
    </Container>
  );
}

export default memo(PaymentCard);
