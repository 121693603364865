import { useEffect, useState } from "react";
import { FormSchema } from "./LoginConstants";
import {
  BodyText,
  FlexStartContainer,
  FooterContainer,
  Image,
  LeftPanelContainer,
  LinkText,
  LoginContainer,
  LogoImage,
  TextError,
  TextFieldContainer,
  Title,
} from "./styles/LoginStyles";
import { MediumHeightDivider, SmallHeightDivider, StyledButtonLoading } from "theme/Styles";
import COLORS from "theme/Colors";
import { useFormik } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import TextField from "components/TextField/TextField";
import { useDispatch, useSelector } from "react-redux";
import { AuthLogin } from "redux/actions/AuthActions";
import { HideGlobalLoading } from "redux/actions/UiActions";
import iucService from "services/ApiServerCall";
import LocalStorageService from "services/LocalStorageService";
import { getUser, registerLoggedUserInServiceBackend } from "api/Auth";
import { useQuery } from "react-query";
import { removeGuionFromString } from "utilities/functions/StringUtil";
import Logo from "assets/logo.png";
import CenterLoading from "components/CenterLoading/CenterLoading";
import { getHomeDataFromWordpress } from "api/Home";
import { cleanStringFromNumbers } from "utilities/functions/NumberUtil";
import { validateDeviceQuestion } from "api/SecurityQuetionsValidation";
import SecurityQuestionsValidationLogin from "components/SecurityQuestionsValidationLogin/SecurityQuestionsValidationLogin";

function Login() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { authenticated, hasQuestions } = useSelector((state) => state.authReducer);

  const [errorMessage, setErrorMessage] = useState("");
  const [loginData, setLoginData] = useState("");
  const [showSecurityQuestions, setShowSecurityQuestions] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data: homeContent, isLoading: homeContentIsLoading } = useQuery(["homeData"], () =>
    getHomeDataFromWordpress()
  );

  const { refetch } = useQuery(["userData"], () => getUser(), {
    enabled: false,
  });

  const formik = useFormik({
    initialValues: {
      // id:'00111111111',
      // password: 'secret',
      id: "",
      password: "",
    },
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      await handleLogin(values);
    },
  });

  async function saveAuthData(data) {
    LocalStorageService.setItem("token", data?.payload.token);
    // const minutes = data?.payload.expiration_time;
    const minutes = parseInt(1 || process.env.REACT_APP_SESSION_DURATION_MINUTES || "15");
    const tokenDuration = new Date().getTime() + minutes * 60 * 1000;
    LocalStorageService.setItem("token_duration", tokenDuration);

    if (
      data?.payload.profile_img == null ||
      data?.payload.profile_img === "N/A" ||
      data?.payload?.profile_img?.original?.success === false
    ) {
      LocalStorageService.setItem("profile_img", "");
    } else {
      LocalStorageService.setItem("profile_img", data?.payload?.profile_img?.original);
    }

    let userResponse = await getUser();
    LocalStorageService.setItem("user_cedula", userResponse.payload.citizen_id);
    LocalStorageService.setItem("user_primary_email", userResponse.payload.email);
    await refetch();

    const requestData = {
      id: userResponse.payload.citizen_id,
      mail: userResponse.payload.email,
      name: userResponse.payload.name,
      surname: userResponse.payload.first_last_name,
      secsurname: userResponse.payload.second_last_name,
      phone: cleanStringFromNumbers(userResponse.payload.phone),
      city: userResponse.payload.municipality,
      created_date: {
        date: "2019-05-15 04:54:47.000000",
        timezone_type: 3,
        timezone: "UTC",
      },
    };

    await registerLoggedUserInServiceBackend(requestData);

    await refetch();

    dispatch(
      AuthLogin({
        authenticated: true,
        profileImg: data.payload.profile_img,
        hasQuestions: !!data.payload.citizen_questions,
        authorizedDevice: !!data.payload.authorized_device,
      })
    );

    dispatch(HideGlobalLoading());
  }

  const handleLogin = async (formData) => {
    try {
      // dispatch(ShowGlobalLoading("Iniciando sesión"));
      const device_id = LocalStorageService.getItem("device_id");
      setLoading(true);

      let response = await iucService().post("/auth/login", {
        citizen_id: removeGuionFromString(formData.id),
        password: formData.password,
        device_id,
      });

      if (response.data?.success) {
        setLoginData(response.data);

        LocalStorageService.setItem("device_id", response?.data?.payload?.device_id);

        if (response.data.payload.authorized_device === false) {
          setShowSecurityQuestions(true);
          return;
        }

        await saveAuthData(response?.data);
      } else {
        dispatch(HideGlobalLoading());
        setErrorMessage(response.data?.msg);
        setLoading(false);
      }

      setLoading(false);
    } catch (error) {
      LocalStorageService.removeItem("token");
      LocalStorageService.removeItem("profile_img");
      setErrorMessage("Ha ocurrido un error, favor contacte a soporte");
      setLoading(false);
      dispatch(HideGlobalLoading());
    }
  };

  const onSecurityQuestionCancelClick = () => {
    setLoading(false);
    setShowSecurityQuestions(false);
  };

  const onSecurityQuestionFinishCallBack = async () => {
    setShowSecurityQuestions(false);
    await saveAuthData(loginData);
  };

  useEffect(() => {
    if (authenticated) {
      if (!hasQuestions && hasQuestions !== undefined) return history.push("/app/user-questions");
      if (location?.hash.length > 0) return history.push(location.hash.slice(1));
      return history.push("/");
    }
  }, [authenticated, hasQuestions, history, location.hash, loginData]);

  if (homeContentIsLoading) return <CenterLoading />;

  return (
    <>
      {showSecurityQuestions ? (
        <SecurityQuestionsValidationLogin
          showMessages={true}
          citizenID={formik.values.id}
          validationFunction={validateDeviceQuestion}
          onCancelClick={onSecurityQuestionCancelClick}
          onFinishCallBack={onSecurityQuestionFinishCallBack}
        />
      ) : null}
      <LoginContainer>
        <LeftPanelContainer>
          <LogoImage src={Logo} />
          <FlexStartContainer>
            <form
              style={{ width: "100%" }}
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <Title>Iniciar Sesión</Title>
              <TextFieldContainer>
                <TextField
                  type="text"
                  placeholder="Cédula"
                  id="id"
                  value={formik.values.id}
                  onChange={formik.handleChange}
                  error={formik.touched.id && Boolean(formik.errors.id)}
                  helperText={formik.touched.id && formik.errors.id}
                />
              </TextFieldContainer>

              <TextFieldContainer>
                <TextField
                  type="password"
                  placeholder="Contraseña"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
              </TextFieldContainer>

              <TextError>{errorMessage}</TextError>
              <MediumHeightDivider />
              <StyledButtonLoading type={"submit"} loading={loading}>
                Iniciar sesión
              </StyledButtonLoading>
            </form>

            <MediumHeightDivider />

            <LinkText to="/public/requestPassword">No recuerdo mi contraseña</LinkText>
            <SmallHeightDivider />
            <BodyText>
              ¿No tienes una cuenta?
              <LinkText
                to="/app/register"
                style={{
                  color: COLORS.primary,
                }}
              >
                Registrarse
              </LinkText>
            </BodyText>

            <SmallHeightDivider />
            <BodyText>
              ¿Su cuenta no ha sido activada o está bloqueada?{" "}
              <LinkText
                to="/public/activateAccount"
                style={{
                  color: COLORS.primary,
                }}
              >
                Activar o Desbloquear Cuenta.
              </LinkText>
            </BodyText>

            <FooterContainer>
              <BodyText
                style={{
                  color: COLORS.grayPlaceholder,
                  fontSize: "12px",
                  marginBottom: { xs: "0px", md: "10px" },
                }}
              >
                @{process.env.REACT_APP_CONFIG_YEAR} Dirección General de Aduanas. Todos los
                derechos reservados.
              </BodyText>
            </FooterContainer>
          </FlexStartContainer>
        </LeftPanelContainer>
        <Image
          style={{
            backgroundImage: `url(${homeContent?.image_url})`,
          }}
        />
      </LoginContainer>
    </>
  );
}

export default Login;
