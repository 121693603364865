import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import COLORS from "theme/Colors";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  cursor: "pointer",
  textAlign: "center",
});

export const UserImage = styled("img")({
  borderRadius: "100%",
  "@media (min-width:768px)": {
    height: "45px",
    width: "45px",
  },
  "@media (min-width:1200px)": {
    height: "60px",
    width: "60px",
  },
});

export const StyledPaper = styled(Paper)({
  boxShadow: "0 8px 10px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.30)",
  marginTop: "10px",
  minWidth: "200px",
});

export const MyUserContainer = styled("div")({
  // position: "relative",
  // bottom: "70px",
  borderTopStyle: "solid",
  borderWidth: "20px",
  borderColor: "#d0eaf6",
  padding: "20px",
  width: "120px",
  height: "120px",
  color: COLORS.white,
  backgroundColor: COLORS.secondary,
  ":hover": {
    cursor: "pointer",
  },
  "@media (min-width:768px)": {
    fontSize: "12.5px",
  },
  "@media (min-width:1200px)": {
    fontSize: "17px",
    // width: "80px",
    // height: "80px",
  },
});

export const MyUserContainerImage = styled("img")({
  width: "100px",
  height: "100px",
  "@media (min-width:1200px)": {
    // width: "40px",
    // height: "40px",
  },
});
