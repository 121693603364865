const FONTS_FAMILY = {
  regular: {
    body: "Roboto",
    title: "Roboto",
  },
  medium: {
    body: "Roboto",
    title: "Roboto",
  },
  bold: {
    body: "Roboto",
    title: "Roboto",
  },
};

export default FONTS_FAMILY;
