import { SCHEDULE } from "./ServiceDescriptionConstants";

// METHODS
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  HideGlobalLoading,
  ShowGlobalLoading,
  UpdateAppSubHeaderTitle,
} from "redux/actions/UiActions";
import { Link as RouterLink, useParams, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { getServiceDescription, getServiceDescriptionExtras } from "api/ServiceDescription";
import { useReactToPrint } from "react-to-print";

// COMPONENTS
import LoginOrRegisterModal from "components/LoginOrRegisterModal/LoginOrRegisterModal";
import { StyledTab, StyledTabs, TabPanel, TabsDivider } from "components/NewDesign/Tabs";
import CenterLoading from "components/CenterLoading/CenterLoading";
import { Box, Grid, Link, Rating, Stack, Typography } from "@mui/material";

// ICONS
import ScheduleIcon from "assets/icons/svgs/schedule.svg";
import PrintLogo1 from "assets/icons/svgs/print_1.svg";
import PrintLogo2 from "assets/icons/svgs/print_2.svg";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import StarIcon from "@mui/icons-material/Star";
import DetailsTab from "./components/DetailsTab";
import CommentsTab from "./components/CommentsTab";
import { ShareButtonGroup } from "./components/SocialMediaButtons";
import { LogInButton, RequestButton } from "./components/ServiceButtons";
import CardOwner from "./components/CardOwner";
import ServiceHeaderMessage from "./components/ServiceHeaderMessage";
import COLORS from "theme/Colors";
import { AllServicesSearchBar } from "views/AllServices/components/AllServicesSearchBar";

function ServiceDescription() {
  const location = useLocation();
  const history = useHistory();
  let { serviceID } = useParams();
  const dispatch = useDispatch();
  const { authenticated } = useSelector((state) => state.authReducer);
  const componentToPrintRef = useRef(null);
  const [contentToShow, setContentToShow] = useState("details");

  const [loginOrRegisterModalStatus, setLoginOrRegisterModalStatus] = useState(false);

  /* New Methods - End */
  const { data: serviceDescription, isLoading } = useQuery(
    ["serviceDescription", serviceID],
    async () => {
      try {
        dispatch(ShowGlobalLoading("Cargando"));
        const response = await getServiceDescription(serviceID);
        dispatch(HideGlobalLoading());
        return response;
      } catch (error) {
        history.push("/public");
        dispatch(HideGlobalLoading());
      }
    }
  );

  const serviceSlug = serviceDescription?.slug;
  const { data: servicesExtraData, isLoading: servicesExtraDataLoading } = useQuery(
    ["servicesExtraData", serviceSlug],
    async () => {
      try {
        dispatch(ShowGlobalLoading("Cargando"));
        const response = await getServiceDescriptionExtras(serviceSlug);
        dispatch(HideGlobalLoading());
        return response;
      } catch (error) {
        history.push("/public");
        dispatch(HideGlobalLoading());
      }
    },
    { enabled: !!serviceSlug }
  );

  const handleServiceRequest = (serviceID) => {
    if (authenticated) {
      if (serviceDescription?.type?.id === 1) {
        window.open(serviceDescription.helper_link, "_blank").focus();
      } else {
        history.push(`/app/requestService/${serviceID}`);
      }
    } else {
      setLoginOrRegisterModalStatus(!loginOrRegisterModalStatus);
    }
  };

  const reactToPrintContent = useCallback(() => {
    return componentToPrintRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: serviceDescription?.name,
    pageStyle: "width:400px",
    removeAfterPrint: true,
  });

  useLayoutEffect(() => {
    if (serviceDescription?.success === false) history.push("/public");

    if (serviceDescription !== undefined) {
      // SHOW HEADER DEPENDING ON SERVICE TYPE
      switch (serviceDescription.type.id) {
        case 1:
          dispatch(UpdateAppSubHeaderTitle("SERVICIOS INFORMATIVOS"));
          break;
        case 2:
          dispatch(UpdateAppSubHeaderTitle("SERVICIOS INTERACTIVOS"));
          break;
        case 3:
          dispatch(UpdateAppSubHeaderTitle("SERVICIOS TRANSACCIONALES"));
          break;
        default:
          dispatch(UpdateAppSubHeaderTitle("SERVICIOS GENERALES"));
      }
    }
  }, [dispatch, history, serviceDescription]);

  if (isLoading || servicesExtraDataLoading) return <CenterLoading />;

  return (
    <Box
      sx={{
        width: { xs: "90%", md: "95%", lg: "90%", xl: "90%" },
        marginTop: "30px",
        paddingBottom: "40px",
      }}
    >
      <LoginOrRegisterModal
        open={loginOrRegisterModalStatus}
        onBackDropClick={() => setLoginOrRegisterModalStatus(false)}
        onCloseClick={() => setLoginOrRegisterModalStatus(false)}
      />

      <ServiceHeaderMessage />

      {/* CONTENT */}
      <Grid
        container
        spacing={3}
        paddingTop={{ xs: 0, md: "40px" }}
        direction={{ xs: "column", md: "row" }}
      >
        {/* RIGHT GRID ITEM */}
        <Grid item xs={12} md={12} lg={8} ref={componentToPrintRef}>
          <Stack direction={"column"}>
            <Box
              component={"h1"}
              sx={{
                fontSize: { xs: "1.5rem", md: "2rem" },
                color: "#134779",
                fontWeight: 600,
                margin: "0px",
                marginBottom: "20px",
              }}
            >
              {serviceDescription?.name}
            </Box>

            <Box sx={{ display: { xs: "block", lg: "none" } }}>
              {authenticated && serviceDescription.type.id !== 1 && (
                <RequestButton onClick={() => handleServiceRequest(serviceDescription.id)} />
              )}

              {!authenticated && serviceDescription.type.id !== 1 && (
                <LogInButton onClick={() => history.push("/public/login#" + location.pathname)} />
              )}
            </Box>

            <Box
              sx={{
                borderBottom: "1px solid #1393D0",
                paddingLeft: "15px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              <StyledTabs
                value={contentToShow}
                onChange={(e, val) => setContentToShow(val)}
                allowScrollButtonsMobile
              >
                <StyledTab value="details" label="DETALLES" />
                <TabsDivider orientation={"vertical"} />
                <StyledTab value="comments" label="COMENTARIOS" />
              </StyledTabs>
            </Box>

            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent={"flex-end"}
              alignItems={"center"}
              spacing={{ xs: 2, md: 4 }}
              sx={{
                borderBottom: "1px solid #1393D0",
                paddingLeft: "15px",
                paddingY: "8px",
              }}
            >
              <Stack
                direction={"row"}
                component={"button"}
                sx={{
                  all: "unset",
                  color: "#134779",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  cursor: "pointer",

                  "&:hover": {
                    color: "#1393D0",
                  },
                }}
                spacing={1}
                onClick={handlePrint}
              >
                <Typography color={"inherit"} fontSize={"1rem"} fontWeight={600}>
                  IMPRIMIR
                </Typography>
                <Box component={"img"} src={PrintLogo1} color={"inherit"} sx={{ height: "14px" }} />
                <Box
                  component={"img"}
                  src={PrintLogo2}
                  color={"inherit"}
                  sx={{ height: "14px", paddingBottom: "1px" }}
                />
              </Stack>

              <Stack
                direction={"row"}
                alignItems={"center"}
                alignContent={"center"}
                justifyContent={"center"}
                spacing={1}
              >
                <Typography color={"#134779"} fontSize={"1rem"} fontWeight={600}>
                  VALORACIÓN
                </Typography>
                <Rating
                  value={parseFloat(serviceDescription?.rating)}
                  max={5}
                  precision={0.5}
                  readOnly={true}
                  sx={{
                    paddingBottom: "3px",
                    fontSize: "1.2rem",
                  }}
                  icon={<StarIcon color={"primary"} />}
                  emptyIcon={<StarBorderOutlinedIcon color={"primary"} />}
                />
              </Stack>
              <Stack
                direction={"row"}
                sx={{
                  all: "unset",
                  color: "#134779",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
                spacing={1}
              >
                <Typography color={"inherit"} fontSize={"1rem"} fontWeight={600}>
                  COMPARTIR
                </Typography>
                <ShareButtonGroup href={window.location.href} />
              </Stack>
            </Stack>

            {/* DETAILS TAB */}
            <TabPanel value={contentToShow} index={"details"}>
              <DetailsTab
                serviceDescription={serviceDescription}
                requirements={servicesExtraData[1]}
              />
            </TabPanel>

            {/* COMMENTS TAB */}
            <TabPanel value={contentToShow} index={"comments"}>
              <CommentsTab serviceID={serviceID} />
            </TabPanel>
          </Stack>
        </Grid>

        {/* RIGHT GRID ITEM */}
        <Grid item xs={12} md={12} lg={4}>
          <Stack direction={"column"} spacing={4}>
            <AllServicesSearchBar />
            {/* CARD OWNER */}
            <CardOwner serviceDescription={serviceDescription} />

            {/* DIRECTED TO */}
            <Stack direction={"column"} display={{ xs: "none", md: "flex" }}>
              <Typography fontWeight={600} fontSize={"1.2rem"} color={"#666666"}>
                DIRIGIDO A
              </Typography>
              {serviceDescription.publics.map((public_) => (
                <Typography key={public_.id} fontSize={"1.1rem"} color={"#666666"}>
                  {public_.name}
                </Typography>
              ))}
            </Stack>

            {/* SERVICE INFORMATION */}
            <Stack direction={"column"} spacing={3.5}>
              <Stack direction={"row"} spacing={3}>
                <Box
                  component="img"
                  sx={{
                    height: { xs: "70px", md: "70px" },
                  }}
                  alt="Schedule Logo"
                  src={ScheduleIcon}
                />
                <Stack direction={"column"} spacing={0}>
                  <Typography fontWeight={600} fontSize={"1.2rem"} color={"#666666"} margin={0}>
                    HORARIO DE PRESENTACIÓN
                  </Typography>
                  <Typography fontSize={"1.1rem"} color={"#666666"} margin={0}>
                    {SCHEDULE[serviceDescription.type.id]}
                  </Typography>
                </Stack>
              </Stack>

              <Box sx={{ display: { xs: "none", lg: "block" } }}>
                {authenticated && serviceDescription.type.id !== 1 && (
                  <RequestButton onClick={() => handleServiceRequest(serviceDescription.id)} />
                )}

                {!authenticated && serviceDescription.type.id !== 1 && (
                  <LogInButton onClick={() => history.push("/public/login#" + location.pathname)} />
                )}
              </Box>

              <Stack direction={"column"} spacing={2}>
                <Box
                  sx={{
                    textTransform: "none",
                    color: "#134779",

                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    borderBottom: "1px solid #1393D0",
                    paddingBottom: "10px",
                    display: serviceDescription.relation_service.length > 0 ? "block" : "none",
                  }}
                >
                  SERVICIOS RELACIONADOS
                </Box>

                <Stack direction={"column"} paddingLeft={"15px"} spacing={2}>
                  {serviceDescription.relation_service.map((service) => (
                    <div>
                      <Link
                        component={RouterLink}
                        sx={{
                          all: "unset",
                          textTransform: "uppercase",
                          color: COLORS.secondary,

                          fontSize: "0.9rem",
                          fontWeight: "bold",
                          cursor: "pointer",
                          "&:hover": {
                            color: COLORS.primary,
                          },
                        }}
                        to={`/app/serviceDescription/${service.id}`}
                      >
                        {service.name}
                      </Link>
                    </div>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ServiceDescription;
