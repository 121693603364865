import iucService from "services/ApiServerCall";

export const modifyPassword = (data) => {
  return iucService().post("citizen/change/password?=&=.", data);
};

export const modifyEmail = (data) => {
  return iucService().post("/citizen/change/email", data);
};

export const modifyUserData = (data) => {
  return iucService().post("/citizen-update", data);
};
