import { Button, Stack } from "@mui/material";
import { styled } from "@mui/system";

import COLORS from "theme/Colors";

export const TextFieldStyle = {
  borderRadius: "9px",
  "& .MuiOutlinedInput-root": {
    lineHeight: 1.5,
    fontSize: "0.875rem",
    padding: 0,

    "& fieldset": {
      border: "1px solid divider",
      borderRadius: 0,
      justifyContent: "center",
      alignItems: "center",
    },

    "&:hover fieldset": {
      background: "action.hover",
      border: "1px solid #1393D0",
    },

    "&.Mui-focused fieldset": {
      background: "action.hover",
      border: "1px solid #1393D0",
    },
  },
};

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

export const ButtonContainer = styled("div")({
  width: "200px",
  marginTop: "20px",
});

export const SectionText = styled("h1")({
  lineHeight: "1.4",
  color: "#6d6e71",
  fontSize: "13px",
  bottom: 0,
  "@media (min-width:320px)": {
    //fontSize: '1.1rem',
  },
  "@media (min-width:768px)": {
    // fontSize: '1.3rem',
  },
  "@media (min-width:1200px)": {
    //fontSize: '2rem',
  },
});

export const SectionTitle = styled("h1")({
  color: COLORS.white,

  fontWeight: 600,
  padding: "5px",
  textTransform: "uppercase",
  "@media (min-width:320px)": {
    fontSize: "1.1rem",
  },
  "@media (min-width:768px)": {
    fontSize: "1.2rem",
  },
  "@media (min-width:1200px)": {
    fontSize: "2rem",
  },
});

export const StackContainer = styled(Stack)({
  display: "flex",
  padding: "5px",

  alignItems: "center",
  color: "white",
  "@media (min-width:320px)": {
    flexDirection: "column",
    margin: "10px",
  },
  "@media (min-width:768px)": {
    flexDirection: "row",
  },
  "@media (min-width:1200px)": {
    flexDirection: "row",
  },
});

export const MainContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

export const ButtonsMenuContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  borderRadius: "0px",
});

export const StyledButtonOutlined = styled(Button)((props) => ({
  minHeight: "70px",
  width: "100%",
  borderColor: COLORS.secondary,
  borderRadius: "9px",
  color: props.active ? COLORS.white : COLORS.secondary,
  backgroundColor: props.active ? COLORS.primary : COLORS.white,
  "@media (min-width:320px)": {
    fontSize: "0.575rem",
  },
  "@media (min-width:768px)": {
    fontSize: "0.875rem",
  },
  "@media (min-width:1200px)": {
    fontSize: "0.875rem",
  },

  "&:hover,&:focus,&:active": {
    color: COLORS.white,
    backgroundColor: COLORS.primary,
    borderColor: COLORS.primary,
  },
}));
