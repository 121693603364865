import * as React from "react";
import { memo } from "react";
import { Box, LinearProgress } from "@mui/material";

function CenterLoading() {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LinearProgress sx={{ width: { xs: "60%", md: "40%" }, height: "10px" }} />
    </Box>
  );
}

export default memo(CenterLoading);
