import backOfficeService from "services/ApiServiceCall";

export const getRequestDetail = async (id, citizenID) => {
  const { data } = await backOfficeService().get(`/requests/${id}?citizenId=${citizenID}`);
  return data;
};

export const sendQualificationAndRating = async (requestData) => {
  const { data } = await backOfficeService().post(`/services/extras/rating`, requestData);
  return data;
};

export const sendRequestConfirmation = async (data) => {
  const { data: data_1 } = await backOfficeService().post("/requests/action/save", data);
  return data_1;
};
