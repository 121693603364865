import * as yup from "yup";

export const FormSchema = yup
  .object({
    reason: yup.string().required("Este campo es requerido"),
    message: yup.string().required("Este campo es requerido"),
  })
  .required();

export const FormRatingSchema = yup
  .object({
    comment: yup.string().required("Este campo es requerido"),
  })
  .required();

export const MenuOptions = {
  details: 0,
  claims: 1,
  payment: 2,
  actionRequired: 3,
  messages: 4,
  requiredForm: 5,
};
